<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Pemantauan PK Ditunda
				</div>
				<div class="columns is-desktop">
					<div class="column is-5">
						<b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Jenis PK</span>
							</template>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-select
									@input="loadAsyncData"
									placeholder=""
									expanded
									class="box-field"
									v-model="PKType"
									required>
									<option selected>All</option>
									<option>User</option>
									<option>Wilayah Baru (Cabang / DCI)</option>
									<option>Referensi</option>
								</b-select>
								<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
							</ValidationProvider>
						</b-field>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						backend-filtering
						@filters-change="onFilter"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:is-row-checkable="validateCheckedRow"
						:checkbox-position="checkboxPosition"
						:row-class="(row, index) => (row.status_pk == 'Lead Time Habis' || row.status_pk == 'Kadarluarsa') && 'has-background-danger has-text-white'"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column searchable sortable header-class="has-background-light" field="id_pk" label="NOMOR PK" centered v-slot="props">
							<a class="is-underlined" @click="routePage('pk-ditunda', 'detail-pk', props.row.id)">{{props.row.id_pk}}</a>
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="nomor_pk" label="ID PK" centered v-slot="props">
							{{props.row.nomor_pk}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="jenis_pk" label="JENIS PK" centered v-slot="props">
							{{props.row.jenis_pk}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="unit" label="UNIT/PT" centered v-slot="props">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="cabang" label="CABANG" centered v-slot="props">
							{{props.row.cabang}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="departemen" label="BAGIAN/SUB BAGIAN" centered v-slot="props">
							{{props.row.departemen}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="status_pk" label="STATUS" centered v-slot="props">
							{{props.row.status_pk}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="tanggal_aktif_kembali" label="TANGGAL PK AKTIF KEMBALI" centered v-slot="props">
							{{props.row.tanggal_aktif_kembali ? props.row.tanggal_aktif_kembali : '-'}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="jumlah_kebutuhan" label="KEBUTUHAN" centered v-slot="props">
							{{props.row.jumlah_kebutuhan}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="jumlah_terpenuhi" label="TERPENUHI" centered v-slot="props">
							{{props.row.jumlah_terpenuhi}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="sisa" label="SISA" centered v-slot="props">
							{{props.row.sisa}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="estimasi_lead_time" label="LEAD TIME" centered v-slot="props">
							{{props.row.estimasi_lead_time}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="estimasi_life_time" label="LIFE TIME" centered v-slot="props">
							{{props.row.estimasi_life_time}}
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 5)}} - {{total - (varPageNumbering * 5) > 0 ? varPageNumbering * 5 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-primary-light has-text-white has-text-weight-bold"
									@click="routePage('pk-ditunda', 'detail-rekrutmen', null)"
								>RINCIAN</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-success has-text-white has-text-weight-bold"
									@click="openModal('continue')"
								>LANJUT</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-tooltip
									:active="isDisableCancel"
									label="Terdapat PK yang sudah terpenuhi sebagian"
									size="is-small"
									position="is-top"
									multilined
								>
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-bold"
										@click="openModal('cancel')"
										:disabled="isDisableCancel"
									>BATAL</b-button>
								</b-tooltip>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isContinueModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Pengaktifan Kembali</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isContinueModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah anda yakin ingin melanjutkan PK di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.id_pk}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="updateStatusPK('Lanjut')"
									>YA</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isContinueModal = false"
									>TIDAK</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
		<b-modal has-modal-card v-model="isCancelModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Pembatalan PK</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isCancelModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin membatalkan PK di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.id_pk}}
								</li>
							</ul>
						</div>
						<form ref="cancel">
							<div class="column is-half is-offset-one-fifth">
								<b-field class="hz-label-area" label="">
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Input Alasan Batal<span class="mandatory">*</span></span>
									</template>
									<b-input
										:loading="isSubmitLoading"
										class="modal_area"
										placeholder="Maks 100 karakter"
										maxlength="100"
										type="textarea"
										icon-pack="fas"
										icon-right="pencil-alt"
										v-model="reasonCancel"
										@keypress.native="onlyAlphaNum"
										pattern="^[A-Za-z0-9\s]*"
										validation-message="Hanya bisa alfabet, angka dan spasi"
										required>
									</b-input>
								</b-field>
							</div>
							<div class="columns mt-4">
								<div class="column is-3 is-offset-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-success has-text-white has-text-weight-semibold"
											@click="updateStatusPK('Dibatalkan')"
										>YA</b-button>
									</b-field>
								</div>
								<div class="column is-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-danger-light has-text-white has-text-weight-semibold"
											@click="isCancelModal = false"
										>TIDAK</b-button>
									</b-field>
								</div>
							</div>
						</form>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
	</section>
</template>

<script>
	import moment from 'moment'
	import debounce from 'lodash.debounce'
	import { mapGetters, mapActions } from "vuex";

    export default {
        data() {
            return {
				data: [],
				PKType: 'All',
                checkboxPosition: 'left',
                defaultSortDirection: 'desc',
				checkedRows: [],
				sortOrder: 'desc',
				sortField: 'id',
				search: '',
				isRecruitModal: false,
				isContinueModal: false,
				isCancelModal: false,
				isEditModal: false,
				textModal: '',
				selectedDate: null,
				total: 0,
				perPage: 5,
				page: 1,
				varPageNumbering: 1,
				isLoading: true,
				isSubmitLoading: true,
				activateDate: null,
				activateDateTemp: null,
				reasonCancel: null,
				editingPKId: null,
				totalPeople: 1,
				men: 0,
				women: 0,
				bothGender: 0,
				isInputLoading: false,
				isValidTotal: false,
				exceedBudget: false,
				gender: 'Bebas',
				max: 0,
				isDisableCancel: false,
				filter: {},
				isheaderCheckable: true,
				isRouting: false,
            }
		},

		watch: {
			checkedRows () {
				let done = false
				if (this.checkedRows.length > 0) {
					this.checkedRows.forEach( element => {
						if (element.jumlah_terpenuhi > 0 && !done) {
							this.isDisableCancel = true
							done = true
						} else if (!done) {
							this.isDisableCancel = false
						}
					})
				} else {
					this.isDisableCancel = false
				}
			}
		},

		computed: {
			...mapGetters({
				userLogin: "isUserLogin",
				access: "access",
				storedPKDitunda: "storedPKDitunda",
			}),
		},
		
		async mounted () {
			await this.loadAsyncData()
			if (this.storedPKDitunda) {
				this.checkedRows = this.storedPKDitunda
			}
			this.isSubmitLoading = false
		},

		methods: {
			...mapActions(["setStoredPKDitunda"]),

			onFilter (filter) {
                this.filter = filter
                this.loadAsyncData()
			},

			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						if (this.checkedRows.length <=4) {
							this.checkedRows.push(row)
						} else {
							this.$buefy.snackbar.open({
								message: 'Maksimal data yang dapat dipilih hanya 5',
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					}
				}
			},

			// handleCheckAll () {
			// 	if (this.checkedRows.length == 5) {
			// 		this.removeCheckedRows()
			// 	}
			// },

			// removeCheckedRows () {
			// 	this.$nextTick(() => {
			// 		this.checkedRows = []
			// 	});
			// },

			validateCheckedRow (row) {
				let isCheckable = false
				this.checkedRows.forEach((item) => {
					if (item.id == row.id) {
						isCheckable = true
					}
				})
				
				if (this.checkedRows.length <=4) {
					isCheckable = true
				}

				// if (this.checkedRows.length > 0 && this.checkedRows.length != 5) {
				// 	this.isheaderCheckable = false
				// } else {
				// 	this.isheaderCheckable = true
				// }

				return isCheckable
			},

			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},

			async updateStatusPK (action) {
				if (action == 'Dibatalkan') {
					if (!this.$refs.cancel.checkValidity()) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return
					}
				}

				// this.isSubmitLoading = true

				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				let index = 0
				let isError = false

				this.checkedRows.forEach( element => {
					textMessage += `${element.id_pk}<br/>`
				})

				if (action == 'Dibatalkan') {
					this.$swal({
						html: true,
						icon: 'question',
						title: 'Apakah Anda yakin ingin membatalkan PK di bawah ini?',
						html: textMessage,
						showDenyButton: true,
						confirmButtonText: 'Ya',
						denyButtonText: 'Tidak',
						customClass: {
							icon: 'is-warning',
							title: 'has-text-primary is-family-primary has-text-weight-semibold',
							htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
							confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold',
							denyButton: 'has-background-danger-light is-family-primary button-sizing has-text-white has-text-weight-bold'
						}
					}).then(async (result) => {
						if (result.isConfirmed) {
							this.isSubmitLoading = true
							let sendData = []
							this.checkedRows.forEach( element => {
								element.status_pk = action
								if (action == 'Dibatalkan') {
									element.alasan_batal = this.reasonCancel
								}

								let tempData = {
									id: element.id,
									status_pk: element.status_pk,
									alasan_batal: action == 'Dibatalkan' ? element.alasan_batal : null
								}
								sendData.push(tempData)
							})

							let postData = {
								row: sendData,
								code: 5
							}

							//console.log(JSON.stringify(postData))

							await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
								.then( response => {
									if (action == 'Lanjut') {
										titleMessage = 'PK berhasil dilanjutkan'
									} else {
										titleMessage = 'PK berhasil dibatalkan'
									}
									iconType = 'success',
									iconColor = 'is-success'
									this.isSubmitLoading = false
								})
								.catch( e => {
									if (e.response.status != 401) {
										if (action == 'Lanjut') {
											titleMessage = 'Gagal melanjutkan PK!'
										} else {
											titleMessage = 'Gagal membatalkan PK!'
										}
										iconType = 'error'
										if (e.response.data.status && e.response.data.status != 0) {
											textMessage = e.response.data.message
										} else {
											textMessage = 'Terjadi kesalahan pada server'
										}
										iconColor = 'is-danger'
									}
									
									console.log(e)
									this.isSubmitLoading = false
									isError = true
								})
								.finally(() => {
									if (titleMessage) {
										this.$swal({
											html: true,
											icon: iconType,
											title: titleMessage,
											html: textMessage,
											customClass: {
												icon: iconColor,
												title: 'has-text-primary is-family-primary has-text-weight-semibold',
												htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
												confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
											}
										}).then((isConfirmed) => {
											if (isConfirmed && !isError)
											window.location.reload()
										})
									}
								})
						} else if (result.isDenied) {
							// this.isCancelModal = false
						}
					})
				} else if (action == 'Lanjut') {
					this.isSubmitLoading = true
					let sendData = []
					this.checkedRows.forEach( element => {
						element.status_pk = action

						let tempData = {
							id: element.id,
							status_pk: element.status_pk
						}
						sendData.push(tempData)
					})

					let postData = {
						row: sendData,
						code: 5
					}

					await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
						.then( response => {
							if (action == 'Lanjut') {
								titleMessage = 'PK berhasil dilanjutkan'
							} else {
								titleMessage = 'PK berhasil dibatalkan'
							}
							iconType = 'success',
							iconColor = 'is-success'
							this.isSubmitLoading = false
						})
						.catch( e => {
							if (e.response.status != 401) {
								if (action == 'Lanjut') {
									titleMessage = 'Gagal melanjutkan PK!'
								} else {
									titleMessage = 'Gagal membatalkan PK!'
								}
								iconType = 'error'
								if (e.response.data.status && e.response.data.status != 0) {
									textMessage = e.response.data.message
								} else {
									textMessage = 'Terjadi kesalahan pada server'
								}
								iconColor = 'is-danger'
							}
							
							console.log(e)
							this.isSubmitLoading = false
							isError = true
						})
						.finally(() => {
							if (titleMessage) {
								this.$swal({
									html: true,
									icon: iconType,
									title: titleMessage,
									html: textMessage,
									customClass: {
										icon: iconColor,
										title: 'has-text-primary is-family-primary has-text-weight-semibold',
										htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
										confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
									}
								}).then((isConfirmed) => {
									if (isConfirmed && !isError)
									window.location.reload()
								})
							}
						})
				}
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
			},

			onPageChange(page) {
                this.page = page
                this.loadAsyncData()
			},

			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id_pk')) {
                    pushFilter.id_pk = ""
                } else {
					pushFilter.id_pk = this.filter.id_pk
				}
				if (!this.filter.hasOwnProperty('nomor_pk')) {
                    pushFilter.nomor_pk = ""
                } else {
					pushFilter.nomor_pk = this.filter.nomor_pk
				}
				if (!this.filter.hasOwnProperty('jenis_pk')) {
                    pushFilter.jenis_pk = ""
                } else {
					pushFilter.jenis_pk = this.filter.jenis_pk
				}
                if (!this.filter.hasOwnProperty('unit')) {
                    pushFilter.unit = ""
                } else {
					pushFilter.unit = this.filter.unit
				}
				if (!this.filter.hasOwnProperty('cabang')) {
                    pushFilter.cabang = ""
                } else {
					pushFilter.cabang = this.filter.cabang
				}
                if (!this.filter.hasOwnProperty('departemen')) {
                    pushFilter.departemen = ""
                } else {
					pushFilter.departemen = this.filter.departemen
				}
                if (!this.filter.hasOwnProperty('status_pk')) {
                    pushFilter.status_pk = ""
                } else {
					pushFilter.status_pk = this.filter.status_pk
				}
                if (!this.filter.hasOwnProperty('tanggal_aktif_kembali')) {
                    pushFilter.tanggal_aktif_kembali = ""
                } else {
					pushFilter.tanggal_aktif_kembali = this.filter.tanggal_aktif_kembali
				}
				if (!this.filter.hasOwnProperty('jumlah_kebutuhan')) {
                    pushFilter.jumlah_kebutuhan = ""
				} else {
					pushFilter.jumlah_kebutuhan = this.filter.jumlah_kebutuhan
				}
				if (!this.filter.hasOwnProperty('jumlah_terpenuhi')) {
                    pushFilter.jumlah_terpenuhi = ""
				} else {
					pushFilter.jumlah_terpenuhi = this.filter.jumlah_terpenuhi
				}
				if (!this.filter.hasOwnProperty('sisa')) {
                    pushFilter.sisa = ""
				} else {
					pushFilter.sisa = this.filter.sisa
				}
				if (!this.filter.hasOwnProperty('estimasi_lead_time')) {
                    pushFilter.estimasi_lead_time = ""
				} else {
					pushFilter.estimasi_lead_time = this.filter.estimasi_lead_time
				}
				if (!this.filter.hasOwnProperty('estimasi_life_time')) {
                    pushFilter.estimasi_life_time = ""
				} else {
					pushFilter.estimasi_life_time = this.filter.estimasi_life_time
				}

				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
				// 	`column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`search=${searchBypass}`,
				// 	`jenis_pk=${this.PKType}`
				// ].join('&')
				// console.log(params)
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					jenis_pk: this.PKType,
					filter: JSON.stringify(pushFilter),
					code: 8
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
							// if (item.tanggal_aktif_kembali) {
							// 	item.tanggal_aktif_kembali = item.tanggal_aktif_kembali.substring(0, item.tanggal_aktif_kembali.indexOf(" "))
							// }
							// if (item.estimasi_lead_time) {
							// 	item.estimasi_lead_time = item.estimasi_lead_time.substring(0, item.estimasi_lead_time.indexOf(" "))
							// }
							// if (item.estimasi_life_time) {
							// 	item.estimasi_life_time = item.estimasi_life_time.substring(0, item.estimasi_life_time.indexOf(" "))
							// }
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(this.data)
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),

			openModal(modalType, PK) {
				if (this.checkedRows.length == 0 && !PK) {
					this.$buefy.snackbar.open({
						message: 'Pilih PK yang ingin diproses terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					this.isSubmitLoading = false
					if(modalType == 'continue') {
						let canOpenContinueModal = true
						let message = ''
						if (this.checkedRows.length > 5) {
							canOpenContinueModal = false
							message = "Maksimal data yang dapat dipilih hanya 5"
						}
						if (this.access.group == 'Direktur HRS') {
							canOpenContinueModal = false
							message = 'Direktur HRS tidak dapat melanjutkan PK'
						}

						if (canOpenContinueModal) {
							this.isContinueModal = true
						} else if (!canOpenContinueModal) {
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						}
					} else if(modalType == 'cancel') {
						let canOpenCancelModal = true
						let isCreator = true
						let isNotFulfilled = true
						let message = ''
						if (this.checkedRows.length > 5) {
							canOpenCancelModal = false
							message = "Maksimal data yang dapat dipilih hanya 5"
						}
						this.checkedRows.forEach( element => {
							if (canOpenCancelModal) {
								if (parseInt(element.jumlah_terpenuhi) > 0 && isCreator) {
									isNotFulfilled = false
									if (canOpenCancelModal) {
										canOpenCancelModal = false
										message = "Terdapat PK yang sudah terpenuhi sebagian:<ul><li>> " + element.id_pk + "</li>"
									} else if (!canOpenCancelModal) {
										message += "<li>> " + element.id_pk + "</li>"
									}
								}
								if (element.nik_dibuat != this.userLogin.nik && isNotFulfilled) {
									isCreator = false
									if (canOpenCancelModal) {
										canOpenCancelModal = false
										message = "Terdapat PK yang pembuat nya bukan anda:<ul><li>> " + element.id_pk + "</li>"
									} else if (!canOpenCancelModal) {
										message += "<li>> " + element.id_pk + "</li>"
									}
								}
							}
						})

						if (this.access.group == 'Direktur HRS') {
							canOpenCancelModal = false
							message = 'Direktur HRS tidak dapat membatalkan PK'
						}

						if (canOpenCancelModal) {
							this.reasonCancel = null
							this.isCancelModal = true
						} else if (!canOpenCancelModal) {
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						}
					}
				}
			},

			routePage(routingFrom, routingTo, PK) {
				if (routingTo == 'detail-rekrutmen' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih PK yang ingin dilihat terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'detail-rekrutmen' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 PK untuk masuk detail rekrutmen',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					this.isRouting = true
					if (!PK){
						PK = this.checkedRows[0].id
					}
					if (this.checkedRows.length > 0) {
						this.setStoredPKDitunda(this.checkedRows)
					}
					this.$router.push({name: routingTo, params: { routing: routingFrom, PK: PK }})
				}
			},
		}
    }
</script>
