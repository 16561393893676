<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Master Manual Book
				</div>
				<div class="columns">
					<div class="column is-one-fifth">
						<b-button
							expanded
							class="has-background-success has-text-white has-text-weight-bold"
							@click="routePage('add')"
						>TAMBAH BARU
						</b-button>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						backend-filtering
						@filters-change="onFilter"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:checkbox-position="checkboxPosition"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column searchable sortable header-class="has-background-light" field="id" label="ID" centered v-slot="props" width="50px">
							{{props.row.id}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="name" label="NAMA FILE" width="140" centered v-slot="props">
							{{props.row.name}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="role" label="ROLE" width="140" centered v-slot="props">
							{{props.row.role}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="group" label="GROUP" width="140" centered v-slot="props">
							{{props.row.group}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="modified_by" label="USER UPLOAD" centered v-slot="props">
							{{props.row.modified_by}} - {{props.row.modified_by_name}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="updated_at" label="WAKTU UPLOAD" centered v-slot="props">
							{{props.row.updated_at}}
						</b-table-column>
                        <b-table-column header-class="has-background-light" field="file_path" label="DOWNLOAD FILE" centered v-slot="props">
							<b-button
                                expanded
                                size="is-small"
                                :loading="isDownloading"
                                class="has-background-primary-light has-text-white has-text-weight-bold"
                                @click="downloadGuide(props.row.file_path)"
                            >MANUAL BOOK
                            </b-button>
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-warning has-text-white has-text-weight-bold"
									@click="routePage('edit')"
								>UBAH</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-danger-light has-text-white has-text-weight-bold"
									@click="openModal('delete')"
								>HAPUS</b-button>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isDeleteModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Hapus Manual Book</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isDeleteModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menghapus data di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										class="mr-1"
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.name}} - {{row.role}} - {{row.group}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="deleteGuide"
									>YA</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isDeleteModal = false"
									>TIDAK</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                </div>
        </b-modal>
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'

    export default {
        data() {
            return {
                data: [],
                checkboxPosition: 'left',
				defaultSortDirection: 'desc',
				sortOrder: 'desc',
				sortField: 'id',
				checkedRows: [],
				search: '',
				isApproveModal: false,
				isDeleteModal: false,
				textModal: '',
				total: 0,
				page: 1,
				varPageNumbering: 1,
				perPage: 10,
				isLoading: true,
				filter: {},
				isDownloading: false,
				file: [],
				isUploading: false,
				isheaderCheckable: true,
            }
		},

		async mounted () {
			await this.loadAsyncData()
		},

		methods: {
			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						this.checkedRows.push(row)
						// if (this.checkedRows.length <=4) {
						// 	this.checkedRows.push(row)
						// } else {
						// 	this.$buefy.snackbar.open({
						// 		message: 'Maksimal data yang dapat dipilih hanya 5',
						// 		type: 'is-info',
						// 		position: 'is-top',
						// 		actionText: 'Tutup',
						// 		indefinite: false,
						// 		duration: 7000
						// 	})
						// }
					}
				}
			},

			async downloadGuide (filePath) {
                this.isDownloading = true
                this.$buefy.snackbar.open({
                    message: "Mohon tunggu beberapa saat hingga file pdf muncul pada halaman baru",
                    type: 'is-info',
                    position: 'is-top',
                    actionText: 'Tutup',
                    indefinite: false,
                    duration: 7000
                })
				let postData = {
                    code: 6,
                    file_path: filePath
                }
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/guides', postData,
					{responseType: 'arraybuffer'})
					.then( response => {
						//handle kembalian file pdf
						let blob=new Blob([response.data], {type: 'application/pdf'});
						// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
						// 	window.navigator.msSaveOrOpenBlob(blob);
						// 	return;
						// }
						let blobObject=window.URL.createObjectURL(blob);
						window.open(blobObject, "_blank")
						setTimeout(function () {
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(blobObject)
						}, 30000)
					})
					.catch( e => {
						console.log(e)
						let converter = require('arraybuffer-to-string');
						let convertResponse = converter(e.response); //terjemahkan response arraybuffer ke string
						let tempMessage = JSON.parse(convertResponse);
						if (tempMessage.status != 401) {
							convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
							tempMessage = JSON.parse(convertResponse);
							let message = 'Terjadi kesalahan pada server'
							if (tempMessage.status && tempMessage.status != 0) {
								message = tempMessage.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}
						} else {
                            message = 'Terjadi kesalahan pada server'
                        }
                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
					})
					.finally(this.isDownloading = false)
			},

			onFilter (filter) {
                this.filter = filter
                this.loadAsyncData()
			},
			
			openModal(modalType) {
				if (this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin dihapus',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					if (modalType == 'delete') {
						this.isDeleteModal = true
					}
				}
			},

			async deleteGuide () {
				this.isDeleteModal = false
				let sendData = []
				this.checkedRows.forEach( element => {
					let tempData = {
						id: element.id
					}
					sendData.push(tempData)
				})
				let postData = {
					row: sendData,
					code: 5
				}
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/guides`, postData)
                    .then( response => {
                            iconType = 'success'
                            titleMessage = 'Data manual book berhasil dihapus'
                            iconColor = 'is-success'
							this.loadAsyncData()
							this.checkedRows = []
					})
					.catch( e => {
						if (e.response.status != 401) {
							iconType = 'error'
							titleMessage = 'Gagal menghapus data manual book!'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server'
							}
							iconColor = 'is-danger'
						}
						
						console.log(e)
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								icon: iconType,
								title: titleMessage,
								text: textMessage,
								customClass: {
									icon: iconColor,
									title: 'has-text-primary is-family-primary has-text-weight-semibold',
									htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
									confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
								}
							})
						}
					})
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

            loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id')) {
                    pushFilter.id = ""
                } else {
					pushFilter.id = this.filter.id
				}
                if (!this.filter.hasOwnProperty('name')) {
                    pushFilter.name = ""
                } else {
					pushFilter.name = this.filter.name
				}
                if (!this.filter.hasOwnProperty('role')) {
                    pushFilter.role = ""
                } else {
					pushFilter.role = this.filter.role
				}
                if (!this.filter.hasOwnProperty('group')) {
                    pushFilter.group = ""
                } else {
					pushFilter.group = this.filter.group
				}
                if (!this.filter.hasOwnProperty('modified_by')) {
                    pushFilter.modified_by = ""
                } else {
					pushFilter.modified_by = this.filter.modified_by
				}
                if (!this.filter.hasOwnProperty('updated_at')) {
                    pushFilter.updated_at = ""
				} else {
					pushFilter.updated_at = this.filter.updated_at
                }
				
				this.isLoading = true
				//let searchBypass = this.search.replace("&", "%26")
                let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					filter: JSON.stringify(pushFilter),
					code: 1
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/guides`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),
			
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

			routePage(routingTo) {
				if (routingTo == 'edit' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'edit' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 data master untuk diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					let master = null
					if (routingTo == 'edit') {
						master = this.checkedRows[0]
					}
					this.$router.push({name: 'form-manual-book', params: { routingTo: routingTo, master: master }})
				}
			}
		}
    }
</script>
