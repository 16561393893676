import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Beranda from '../views/Beranda.vue'
import PengajuanPK from '../views/PengajuanPK.vue'
//import PKReferensi from '../views/PengajuanPK/PKReferensi.vue'
import PersetujuanPK from '../views/PersetujuanPK/PersetujuanPK.vue'
import PKBelumDiprosesAtasan from '../views/PersetujuanPK/PKBelumDiprosesAtasan.vue'
import PKBelumGiliranSaya from '../views/PersetujuanPK/PKBelumGiliranSaya.vue'
import PersetujuanGantiPejabat from '../views/PersetujuanPK/PersetujuanGantiPejabat.vue'
import PersetujuanUbahPK from '../views/PersetujuanPK/PersetujuanUbahPK.vue'
import PersetujuanGaransi from '../views/PersetujuanPK/PersetujuanGaransi.vue'
import PersetujuanMasterPejabatApproval from '../views/PersetujuanPK/PersetujuanMasterPejabatApproval.vue'
import PKAktif from '../views/PemantauanPKFinal/PKAktif.vue'
import PKDitunda from '../views/PemantauanPKFinal/PKDitunda.vue'
// import RiwayatPK from '../views/RiwayatPK.vue'
import PKDibatalkan from '../views/RiwayatPK/PKDibatalkan.vue'
import PKDitolak from '../views/RiwayatPK/PKDitolak.vue'
import PKKadaluarsa from '../views/RiwayatPK/PKKadaluarsa.vue'
import PKTerpenuhi from '../views/RiwayatPK/PKTerpenuhi.vue'
import MasterPejabatApproval from '../views/Master/MasterPejabatApproval.vue'
import FormPejabatApproval from '../views/Master/FormPejabatApproval.vue'
import MasterLeadTime from '../views/Master/MasterLeadTime.vue'
import FormLeadTime from '../views/Master/FormLeadTime.vue'
import MasterDepartemen from '../views/Master/MasterDepartemen.vue'
import FormDepartemen from '../views/Master/FormDepartemen.vue'
import MasterJabatan from '../views/Master/MasterJabatan.vue'
import FormJabatan from '../views/Master/FormJabatan.vue'
import MasterHakAkses from '../views/Master/MasterHakAkses.vue'
import FormHakAkses from '../views/Master/FormHakAkses.vue'
import BudgetPK from '../views/Master/BudgetPK.vue'
import FormBudgetPK from '../views/Master/FormBudgetPK.vue'
import MasterManualBook from '../views/Master/MasterManualBook.vue'
import FormManualBook from '../views/Master/FormManualBook.vue'
import ManualBook from '../views/ManualBook.vue'
import Report from '../views/Report.vue'
// import LPKB from '../views/Report/LPKB.vue'
// import LSLP from '../views/Report/LSLP.vue'
import DetailPK from '../views/DetailPK.vue'
import DetailRekrutmen from '../views/DetailRekrutmen.vue'
import DetailMaster from '../views/Master/DetailMaster.vue'
import store from '@/store'


Vue.use(VueRouter)
Vue.use(Buefy)

const routes = [
	{
		path: '/',
		name: 'login',
		component: Login
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		children: [
			{ path: 'beranda', name: 'beranda', component: Beranda },
			{ path: 'pengajuan-pk', name: 'pengajuan-pk', component: PengajuanPK },
			//{ path: 'pk-referensi', name: 'pk-referensi', component: PKReferensi },
			{ path: 'persetujuan-pk', name: 'persetujuan-pk', component: PersetujuanPK },
			{ path: 'pk-belum-diproses-atasan', name: 'pk-belum-diproses-atasan', component: PKBelumDiprosesAtasan },
			{ path: 'pk-belum-giliran-saya', name: 'pk-belum-giliran-saya', component: PKBelumGiliranSaya },
			{ path: 'persetujuan-ganti-pejabat', name: 'persetujuan-ganti-pejabat', component: PersetujuanGantiPejabat },
			{ path: 'persetujuan-ubah-pk', name: 'persetujuan-ubah-pk', component: PersetujuanUbahPK },
			// { path: 'persetujuan-garansi', name: 'persetujuan-garansi', component: PersetujuanGaransi },
			{ path: 'persetujuan-master-pejabat-approval', name: 'persetujuan-master-pejabat-approval', component: PersetujuanMasterPejabatApproval },
			{ path: 'pk-aktif', name: 'pk-aktif', component: PKAktif },
			{ path: 'pk-ditunda', name: 'pk-ditunda', component: PKDitunda },
			// { path: 'riwayat-pk', name: 'riwayat-pk', component: RiwayatPK },
			{ path: 'pk-dibatalkan', name: 'pk-dibatalkan', component: PKDibatalkan },
			{ path: 'pk-ditolak', name: 'pk-ditolak', component: PKDitolak },
			{ path: 'pk-kadaluarsa', name: 'pk-kadaluarsa', component: PKKadaluarsa },
			{ path: 'pk-terpenuhi', name: 'pk-terpenuhi', component: PKTerpenuhi },
			{ path: 'master-pejabat-approval', name: 'master-pejabat-approval', component: MasterPejabatApproval },
			{ path: 'master-pejabat-approval/:routingTo', name: 'form-pejabat-approval', component: FormPejabatApproval },
			{ path: 'master-lead-time', name: 'master-lead-time', component: MasterLeadTime },
			{ path: 'master-lead-time/:routingTo', name: 'form-lead-time', component: FormLeadTime },
			{ path: 'master-departemen', name: 'master-departemen', component: MasterDepartemen },
			{ path: 'master-departemen/:routingTo', name: 'form-departemen', component: FormDepartemen },
			{ path: 'master-jabatan', name: 'master-jabatan', component: MasterJabatan },
			{ path: 'master-jabatan/:routingTo', name: 'form-jabatan', component: FormJabatan },
			{ path: 'master-hak-akses', name: 'master-hak-akses', component: MasterHakAkses },
			{ path: 'master-hak-akses/:routingTo', name: 'form-hak-akses', component: FormHakAkses },
			{ path: 'budget-pk', name: 'budget-pk', component: BudgetPK },
			{ path: 'budget-pk/:routingTo', name: 'form-budget-pk', component: FormBudgetPK },
			{ path: 'master-manual-book', name: 'master-manual-book', component: MasterManualBook },
			{ path: 'master-manual-book/:routingTo', name: 'form-manual-book', component: FormManualBook },
			{ path: 'manual-book', name: 'manual-book', component: ManualBook },
			{ path: 'report', name: 'report', component: Report },
			// { path: 'lpkb', name: 'lpkb', component: LPKB },
			// { path: 'lslp', name: 'lslp', component: LSLP },
			{ path: ':routing/detail-pk', name: 'detail-pk', component: DetailPK },
			{ path: ':routing/detail-rekrutmen', name: 'detail-rekrutmen', component: DetailRekrutmen },
			{ path: ':routing/detail-master', name: 'detail-master', component: DetailMaster },
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	//const isAuth = store.getters.isAuthenticated
	//const isUserLogin = store.getters.isUserLogin
	const storedPKAktif = store.getters.storedPKAKtif
	const storedPKDitunda = store.getters.storedPKDitunda
	const storedPersetujuanPK = store.getters.storedPersetujuanPK
	const storedGantiPejabat = store.getters.storedGantiPejabat
	const storedUbahPK = store.getters.storedUbahPK
	// const storedPersetujuanGaransi = store.getters.storedPersetujuanGaransi
	const storedPersetujuanMasterPejabatApproval = store.getters.storedPersetujuanMasterPejabatApproval

	if (!localStorage.user) {
		if (to.name == 'login') {
			next()
		} else {
			localStorage.routingTo = to.name
			next({name: "login"})
		}
	} else {
		if (store.dispatch('isAuthenticated')) {
			store.dispatch('checkRole').then(response => {
				if (to.name == 'login') {
					next({name: "beranda"})
				} else if (to.name == 'pengajuan-pk' && (response.group == 'Direktur User' || response.group == 'Direktur HRS')) {
					next({name: "beranda"})
				} else if (to.name == 'persetujuan-ganti-pejabat' && response.role != 'Admin' && response.role != 'Super Admin') {
					next({name: "beranda"})
				}
				// else if (to.name == 'persetujuan-garansi' && !((response.role == 'Admin' && response.group == 'RnP 2') || response.role == 'Super Admin')) {
				// 	next({name: "beranda"})
				// }
				else if (to.name == 'master-pejabat-approval' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'form-pejabat-approval' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'master-lead-time' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'form-lead-time' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'master-departemen' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'form-departemen' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'master-jabatan' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'form-jabatan' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'master-hak-akses' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'form-hak-akses' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'budget-pk' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'form-budget-pk' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
					next({name: "beranda"})
				} else if (to.name == 'master-manual-book' && !response.group == 'IT Support') {
					next({name: "beranda"})
				} else if (to.name == 'form-manual-book' && !response.group == 'IT Support') {
          next({name: "beranda"})
				} else if (to.name == 'persetujuan-master-pejabat-approval' &&  !response.master_approver) {
					next({name: "beranda"})
				} else {
					if (storedPKAktif && from.name == 'pk-aktif' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
						store.dispatch('setStoredPKAktif', null)
					} else if (storedPKAktif && to.name != 'pk-aktif' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
						store.dispatch('setStoredPKAktif', null)
					}
					if (storedPKDitunda && from.name == 'pk-ditunda' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
						store.dispatch('setStoredPKDitunda', null)
					} else if (storedPKDitunda && to.name != 'pk-ditunda' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
						store.dispatch('setStoredPKDitunda', null)
					}
					if (storedPersetujuanPK && from.name == 'pk-belum-diproses-atasan' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
						store.dispatch('setStoredPKBelumDiprosesAtasan', null)
					} else if (storedPersetujuanPK && to.name != 'pk-belum-diproses-atasan' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
						store.dispatch('setStoredPKBelumGiliranSaya', null)
					}
					if (storedPersetujuanPK && from.name == 'pk-belum-giliran-saya' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
						store.dispatch('setStoredPKBelumGiliranSaya', null)
					} else if (storedPersetujuanPK && to.name != 'pk-belum-giliran-saya' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
						store.dispatch('setStoredPKBelumGiliranSaya', null)
					}
					if (storedPersetujuanPK && from.name == 'persetujuan-pk' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
						store.dispatch('setStoredPersetujuanPK', null)
					} else if (storedPersetujuanPK && to.name != 'persetujuan-pk' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
						store.dispatch('setStoredPersetujuanPK', null)
					}
					if (storedGantiPejabat && from.name == 'persetujuan-ganti-pejabat' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
						store.dispatch('setStoredGantiPejabat', null)
					} else if (storedGantiPejabat && to.name != 'persetujuan-ganti-pejabat' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
						store.dispatch('setStoredGantiPejabat', null)
					}
					if (storedUbahPK && from.name == 'persetujuan-ubah-pk' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
						store.dispatch('setStoredUbahPK', null)
					} else if (storedUbahPK && to.name != 'persetujuan-ubah-pk' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
						store.dispatch('setStoredUbahPK', null)
					}
					// if (storedPersetujuanGaransi && from.name == 'persetujuan-garansi' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
					// 	store.dispatch('setStoredPersetujuanGaransi', null)
					// } else if (storedPersetujuanGaransi && to.name != 'persetujuan-garansi' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
					// 	store.dispatch('setStoredPersetujuanGaransi', null)
					// }
					if (storedPersetujuanMasterPejabatApproval && from.name == 'persetujuan-master-pejabat-approval' && to.name != 'detail-master') {
						store.dispatch('setStoredPersetujuanMasterPejabatApproval', null)
					} else if (storedPersetujuanMasterPejabatApproval && to.name != 'persetujuan-master-pejabat-approval' && from.name == 'detail-master') {
						store.dispatch('setStoredPersetujuanMasterPejabatApproval', null)
					}
					localStorage.routingTo = to.name
					next()
				}
			})
		} else {
			store.dispatch('logout')
			localStorage.routingTo = to.name
			next({name: "login"})
		}
	}
});

export default router
