<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Pemantauan PK Aktif
				</div>
				<div class="columns is-desktop">
					<div class="column is-5">
						<b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Jenis PK</span>
							</template>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-select
									@input="loadAsyncData"
									placeholder=""
									expanded
									class="box-field"
									v-model="PKType"
									required>
									<option selected>All</option>
									<option>User</option>
									<option>Wilayah Baru (Cabang / DCI)</option>
									<option>Referensi</option>
								</b-select>
								<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
							</ValidationProvider>
						</b-field>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						backend-filtering
						@filters-change="onFilter"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:is-row-checkable="validateCheckedRow"
						:checkbox-position="checkboxPosition"
						:row-class="(row, index) => (row.status_pk == 'Lead Time Habis' || row.status_pk == 'Kadarluarsa') && 'has-background-danger has-text-white'"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column searchable sortable header-class="has-background-light" field="id_pk" label="NOMOR PK" centered v-slot="props">
							<a class="is-underlined" @click="routePage('pk-aktif', 'detail-pk', props.row.id)">{{props.row.id_pk}}</a>
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="nomor_pk" label="ID PK" centered v-slot="props">
							{{props.row.nomor_pk}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="jenis_pk" label="JENIS PK" centered v-slot="props">
							{{props.row.jenis_pk}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="unit" label="UNIT/PT" centered v-slot="props">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="cabang" label="CABANG" centered v-slot="props">
							{{props.row.cabang}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="departemen" label="BAGIAN/SUB BAGIAN" centered v-slot="props">
							{{props.row.departemen}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="status_pk" label="STATUS" centered v-slot="props">
							{{props.row.status_pk}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="tanggal_aktif" label="TANGGAL PK AKTIF" centered v-slot="props">
							{{props.row.tanggal_aktif}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="estimasi_lead_time" label="ESTIMASI WAKTU PEMENUHAN" centered v-slot="props">
							{{props.row.estimasi_lead_time}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="jumlah_kebutuhan" label="KEBUTUHAN" centered v-slot="props">
							{{props.row.jumlah_kebutuhan}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="jumlah_terpenuhi" label="TERPENUHI" centered v-slot="props">
							{{props.row.jumlah_terpenuhi}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="sisa" label="SISA" centered v-slot="props" width="520px">
							{{props.row.sisa}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="usia_pk" label="UMUR PK" centered v-slot="props">
							{{props.row.usia_pk}}
						</b-table-column>
						<b-table-column header-class="has-background-light" centered v-slot="props">
							<b-tooltip
								:active="props.row.status_edit == 0 ? true : false"
								label="Sedang diajukan perubahan jumlah kebutuhan"
								size="is-small"
								position="is-left"
								multilined>
								<b-field class="">
									<b-button
										expanded
										class="has-background-primary-light has-text-white has-text-weight-regular"
										@click="openModal('edit', props.row)"
										:disabled="props.row.status_edit == 0 ? true : false"
									>Edit PK</b-button>
								</b-field>
							</b-tooltip>
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 5)}} - {{total - (varPageNumbering * 5) > 0 ? varPageNumbering * 5 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-primary-light has-text-white has-text-weight-bold"
									@click="routePage('pk-aktif', 'detail-rekrutmen', null)"
								>RINCIAN</b-button>
							</b-field>
						</div>
						<!-- <div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-success has-text-white has-text-weight-bold"
									@click="openModal('recruit')"
								>PILIH REKRUTER</b-button>
							</b-field>
						</div> -->
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-warning has-text-white has-text-weight-bold"
									@click="openModal('suspend')"
								>TUNDA</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-tooltip
									:active="isDisableCancel"
									label="Terdapat PK yang sudah terpenuhi sebagian"
									size="is-small"
									position="is-top"
									multilined
								>
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-bold"
										@click="openModal('cancel')"
										:disabled="isDisableCancel"
									>BATAL</b-button>
								</b-tooltip>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isRecruitModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Pilih Grup Rekruter</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isRecruitModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Untuk PK di bawah ini:
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.id_pk}}
								</li>
							</ul>
						</div>
						<div class="column is-half is-offset-one-quarter mt-4">
							<b-field class="hz-label" label="">
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Pilih grup rekruter<span class="mandatory">*</span></span>
								</template>
								<b-select placeholder="" expanded>
									<option>Pilihan 1</option>
									<option>Pilihan 2</option>
								</b-select>
							</b-field>
						</div>
						<div class="columns mt-4">
							<div class="column is-flex is-justify-content-center">
								<b-field class="">
									<b-button
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="isRecruitModal = false"
									>Simpan</b-button>
								</b-field>
							</div>
							<!-- <div class="column is-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-bold"
										@click="isRecruitModal = false"
									>TIDAK</b-button>
								</b-field>
							</div> -->
						</div>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
		<b-modal has-modal-card v-model="isSuspendModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Penundaan PK</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isSuspendModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
						<div class="has-text-weight-medium has-text-primary">
							Data yang akan diproses :
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.id_pk}}
								</li>
							</ul>
						</div>
						<form ref="suspend">
							<div class="column is-half is-offset-one-quarter mt-2">
								<b-field class="hz-label" label="">
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Tanggal diaktifkan kembali<span class="mandatory">*</span></span>
								</template>
								<b-datepicker
									:loading="isSubmitLoading"
									v-model="activateDateTemp"
									:date-formatter="dateFormatter"
									@input="saveDate"
									icon-pack="fas"
									icon-right="calendar-alt"
									:min-date="todayDate"
									trap-focus
									:mobile-native="false"
									required>
								</b-datepicker>
							</b-field>
							</div>
							<div class="columns mt-4">
								<div class="column is-3 is-offset-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-success has-text-white has-text-weight-semibold"
											@click="updateStatusPK('Ditunda')"
										>YA</b-button>
									</b-field>
								</div>
								<div class="column is-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-danger-light has-text-white has-text-weight-semibold"
											@click="isSuspendModal = false"
										>TIDAK</b-button>
									</b-field>
								</div>
							</div>
						</form>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
		<b-modal has-modal-card v-model="isCancelModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Pembatalan PK</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isCancelModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
						<div class="has-text-weight-medium has-text-primary">
							Data yang akan diproses :
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.id_pk}}
								</li>
							</ul>
						</div>
						<form ref="cancel">
							<div class="column is-half is-offset-one-fifth">
								<b-field class="hz-label-area" label="">
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Input Alasan Batal<span class="mandatory">*</span></span>
									</template>
									<b-input
										:loading="isSubmitLoading"
										class="modal_area"
										placeholder="Maks 100 karakter"
										maxlength="100"
										type="textarea"
										icon-pack="fas"
										icon-right="pencil-alt"
										v-model="reasonCancel"
										@keypress.native="onlyAlphaNum"
										pattern="^[A-Za-z0-9\s]*"
										validation-message="Hanya bisa alfabet, angka dan spasi"
										required>
									</b-input>
								</b-field>
							</div>
							<div class="columns mt-4">
								<div class="column is-3 is-offset-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-success has-text-white has-text-weight-semibold"
											@click="updateStatusPK('Dibatalkan')"
										>YA</b-button>
									</b-field>
								</div>
								<div class="column is-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-danger-light has-text-white has-text-weight-semibold"
											@click="isCancelModal = false"
										>TIDAK</b-button>
									</b-field>
								</div>
							</div>
						</form>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
		<b-modal has-modal-card v-model="isEditModal">
            <div class="modal-card" style="width: 1000px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Edit PK</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isEditModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Silakan masukan jumlah kebutuhan yang baru
						</div>
						<form ref="suspend">
							<div class="columns mt-4 is-desktop is-flex is-align-items-center">
								<div class="column is-8 is-offset-1 minMaxValidation">
									<b-field 
										class="hz-label is-flex is-align-items-center" 
										label="" 
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium">Jumlah Kebutuhan<span class="mandatory">*</span></span>
										</template>
										<ValidationProvider :rules="`required|minMax:${minEdit != 0 ? minEdit : 1},${max},${totalPeople}`" v-slot="{ errors }">
											<b-numberinput
												:loading="isInputLoading"
												v-model="totalPeople"
												@input="validateTotal"
												controls-alignment="right"
												icon-pack="fa"
												type="is-primary"
												:max="max"
												:min="minEdit != 0 ? minEdit : 1"
												required>
											</b-numberinput>
											<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
										</ValidationProvider>
									</b-field>
								</div>
								<div class="column is-3 is-size-6" v-if="!isValidTotal">
									<div class="is-family-primary has-text-weight-medium has-text-danger">Total laki-laki, perempuan, bebas harus sama dengan jumlah kebutuhan</div>
								</div>
							</div>
							<div class="columns is-desktop">
								<div class="column is-7 is-offset-2 minMaxValidation">
									<b-field 
										class="hz-label-right is-flex is-align-items-center" 
										label="" 
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium">Laki - Laki</span>
										</template>
										<ValidationProvider :rules="`required|minMax:0,${totalPeople - women - bothGender},${totalPeople}`" v-slot="{ errors }">
											<b-numberinput
												:disabled="totalPeople - women - bothGender <= men && men <= women && men <= bothGender && !(men == women && men == bothGender && women == bothGender)"
												:loading="isInputLoading"
												v-model="men"
												@input="validateTotal"
												controls-alignment="right"
												icon-pack="fa"
												type="is-primary"
												:max="totalPeople - women - bothGender"
												min="0"
												required>
											</b-numberinput>
											<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
										</ValidationProvider>
										<!-- <b-input
											v-if="gender == 'Laki-laki'"
											disabled
											:loading="isInputLoading"
											v-model="men"
											:required="gender == 'Laki-laki'">
										</b-input> -->
									</b-field>
									<b-field 
										class="hz-label-right is-flex is-align-items-center" 
										label="" 
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium">Perempuan</span>
										</template>
										<ValidationProvider :rules="`required|minMax:0,${totalPeople - men - bothGender},${totalPeople}`" v-slot="{ errors }">
											<b-numberinput
												:disabled="totalPeople - men - bothGender <= women && women <= men && women <= bothGender && !(men == women && men == bothGender && women == bothGender)"
												:loading="isInputLoading"
												v-model="women"
												@input="validateTotal"
												controls-alignment="right"
												icon-pack="fa"
												type="is-primary"
												:max="totalPeople - men - bothGender"
												min="0"
												required>
											</b-numberinput>
											<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
										</ValidationProvider>
										<!-- <b-input
											v-if="gender == 'Perempuan'"
											disabled
											:loading="isInputLoading"
											v-model="women"
											:required="gender == 'Perempuan'">
										</b-input> -->
									</b-field>
									<b-field 
										class="hz-label-right is-flex is-align-items-center" 
										label="" 
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium">Bebas</span>
										</template>
										<ValidationProvider :rules="`required|minMax:0,${totalPeople - men - women},${totalPeople}`" v-slot="{ errors }">
											<b-numberinput
												:disabled="totalPeople - men - women <= bothGender && bothGender <= men && bothGender <= women && !(men == women && men == bothGender && women == bothGender)"
												:loading="isInputLoading"
												v-model="bothGender"
												@input="validateTotal"
												controls-alignment="right"
												icon-pack="fa"
												type="is-primary"
												:max="totalPeople - men - women"
												min="0"
												required>
											</b-numberinput>
											<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
										</ValidationProvider>
									</b-field>
								</div>
							</div>
							<div class="columns mt-4">
								<div class="column is-3 is-offset-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-success has-text-white has-text-weight-semibold"
											@click="updateTotalNeeded()"
										>YA</b-button>
									</b-field>
								</div>
								<div class="column is-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-danger-light has-text-white has-text-weight-semibold"
											@click="isEditModal = false"
										>TIDAK</b-button>
									</b-field>
								</div>
							</div>
						</form>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
	</section>
</template>

<script>
	import moment from 'moment'
	import debounce from 'lodash.debounce'
	import { mapGetters, mapActions } from "vuex";

    export default {
        data() {
            // const data = [
            //     { 
			// 		'id': 1,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'id_pk': '2017123123',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'status': 'Assign Recruiter',
			// 		'active_pk': '22-01-2022',
			// 		'estimation': '31-08-2022',
			// 		'demand': '2',
			// 		'fulfilled': '0',
			// 		'remains': '2',
			// 		'life': '1 bulan 2 hari',
			// 		'group': null, },
            //     { 
			// 		'id': 2,
			// 		'no_pk': '1234-HO-IDM-08-33-IT',
			// 		'id_pk': '20159876',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'status': 'Assign Recruiter',
			// 		'active_pk': '22-01-2022',
			// 		'estimation': '31-08-2022',
			// 		'demand': '5',
			// 		'fulfilled': '1',
			// 		'remains': '4',
			// 		'life': '3 bulan 24 hari',
			// 		'group': 'R&P 1', },
            //     { 
			// 		'id': 3,
			// 		'no_pk': '2222-JKT-IDM-01-22-HR',
			// 		'id_pk': '20125678',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'HR Recruitment',
			// 		'status': 'Proses Seleksi',
			// 		'active_pk': '15-09-2021',
			// 		'estimation': '21-04-2022',
			// 		'demand': '1',
			// 		'fulfilled': '1',
			// 		'remains': '0',
			// 		'life': '1 bulan 2 hari',
			// 		'group': 'R&P 2', },
            //     { 
			// 		'id': 4,
			// 		'no_pk': '4321-HO-IGR-30-15-IT',
			// 		'id_pk': '2017123123',
			// 		'unit': 'PT Inti Cakrawala Citra',
			// 		'bagian': 'IT Software Development 1',
			// 		'status': 'Training',
			// 		'active_pk': '22-01-2022',
			// 		'estimation': '31-08-2022',
			// 		'demand': '2',
			// 		'fulfilled': '0',
			// 		'remains': '2',
			// 		'life': '1 bulan 2 hari',
			// 		'group': 'R&P 1', },
            //     { 
			// 		'id': 5,
			// 		'no_pk': '1234-HO-IDM-01-22-HR',
			// 		'id_pk': '2017123123',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'HR Recruitment',
			// 		'status': 'PKWT',
			// 		'active_pk': '22-01-2022',
			// 		'estimation': '31-08-2022',
			// 		'demand': '2',
			// 		'fulfilled': '0',
			// 		'remains': '2',
			// 		'life': '1 bulan 2 hari',
			// 		'group': 'R&P 1', },
            //     { 
			// 		'id': 6,
			// 		'no_pk': '2223-HO-IGR-01-22-IT',
			// 		'id_pk': '2015987',
			// 		'unit': 'PT Inti Cakrawala Citra',
			// 		'bagian': 'IT Software Development 1',
			// 		'status': 'Penempatan',
			// 		'active_pk': '22-01-2022',
			// 		'estimation': '31-08-2022',
			// 		'demand': '3',
			// 		'fulfilled': '2',
			// 		'remains': '1',
			// 		'life': '5 bulan 1 hari',
			// 		'group': 'R&P 2', },
            // ]

            return {
				data: [],
				PKType: 'All',
                checkboxPosition: 'left',
                defaultSortDirection: 'desc',
				checkedRows: [],
				sortOrder: 'desc',
				sortField: 'id',
				search: '',
				isRecruitModal: false,
				isSuspendModal: false,
				isCancelModal: false,
				isEditModal: false,
				textModal: '',
				selectedDate: null,
				total: 0,
				perPage: 5,
				page: 1,
				varPageNumbering: 1,
				isLoading: true,
				isSubmitLoading: true,
				activateDate: null,
				activateDateTemp: null,
				reasonCancel: null,
				editingPKId: null,
				totalPeople: 1,
				men: 0,
				women: 0,
				bothGender: 0,
				isInputLoading: false,
				isValidTotal: false,
				exceedBudget: false,
				gender: 'Bebas',
				max: 0,
				minEdit: 0,
				todayDate: new Date(),
				isDisableCancel: false,
				filter: {},
				isheaderCheckable: true,
				isRouting: false,
            }
		},

		watch: {
			checkedRows () {
				let done = false
				if (this.checkedRows.length > 0) {
					this.checkedRows.forEach( element => {
						if (element.jumlah_terpenuhi > 0 && !done) {
							this.isDisableCancel = true
							done = true
						} else if (!done) {
							this.isDisableCancel = false
						}
					})
				} else {
					this.isDisableCancel = false
				}
			}
		},
		
		async mounted () {
			await this.loadAsyncData()
			if (this.storedPKAktif) {
				this.checkedRows = this.storedPKAktif
			}
			this.isSubmitLoading = false
		},

		computed: {
			...mapGetters({
				userLogin: "isUserLogin",
				access: "access",
				storedPKAktif: "storedPKAktif",
			}),
		},

		methods: {
			...mapActions(["setStoredPKAktif"]),

			onFilter (filter) {
                this.filter = filter
                this.loadAsyncData()
			},

			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						if (this.checkedRows.length <=4) {
							this.checkedRows.push(row)
						} else {
							this.$buefy.snackbar.open({
								message: 'Maksimal data yang dapat dipilih hanya 5',
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					}
				}
			},

			// handleCheckAll () {
			// 	if (this.checkedRows.length == 5) {
			// 		this.removeCheckedRows()
			// 	}
			// },

			// removeCheckedRows () {
			// 	this.$nextTick(() => {
			// 		this.checkedRows = []
			// 	});
			// },

			dateFormatter(dt){
				return moment(dt).format('DD-MM-YYYY')
			},
			
			validateCheckedRow (row) {
				let isCheckable = false
				this.checkedRows.forEach((item) => {
					if (item.id == row.id) {
						isCheckable = true
					}
				})
				
				if (this.checkedRows.length <=4) {
					isCheckable = true
				}

				// if (this.checkedRows.length > 0 && this.checkedRows.length != 5) {
				// 	this.isheaderCheckable = false
				// } else {
				// 	this.isheaderCheckable = true
				// }

				return isCheckable
			},

			async updateTotalNeeded () {
				if (!this.isValidTotal) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return
				}
				//this.isSubmitLoading = true

				let postData = {
					id: this.editingPKId,
					jumlah_kebutuhan: this.totalPeople,
					jumlah_laki: this.men,
					jumlah_perempuan: this.women,
					jumlah_bebas: this.bothGender,
					code: 3
				}

				//console.log(JSON.stringify(postData))

				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				let index = 0
				let isError = false

				this.$swal({
					html: true,
					icon: 'question',
					title: 'Apakah anda yakin edit jumlah kebutuhan untuk PK ini?',
					html: this.id_pk,
					showDenyButton: true,
					confirmButtonText: 'Ya',
					denyButtonText: 'Tidak',
					customClass: {
						icon: 'is-warning',
						title: 'has-text-primary is-family-primary has-text-weight-semibold',
						htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
						confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold',
						denyButton: 'has-background-danger-light is-family-primary button-sizing has-text-white has-text-weight-bold'
					}
				}).then(async (result) => {
					if (result.isConfirmed) {
						this.isSubmitLoading = true
						await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pk-edits`, postData)
							.then( response => {
								titleMessage = 'Perubahan jumlah kebutuhan PK berhasil diajukan'
								iconType = 'success',
								textMessage = this.id_pk
								iconColor = 'is-success'
								this.isSubmitLoading = false
							})
							.catch( e => {
								if (e.response.status != 401) {
									titleMessage = 'Gagal mengajukan perubahan kebutuhan PK'
									iconType = 'error'
									if (e.response.data.status && e.response.data.status != 0) {
										textMessage = e.response.data.message
									} else {
										textMessage = 'Terjadi kesalahan pada server'
									}
									iconColor = 'is-danger'
								}
								
								console.log(e)
								this.isSubmitLoading = false
								isError = true
							})
							.finally(() => {
								if (titleMessage) {
									this.$swal({
										html: true,
										icon: iconType,
										title: titleMessage,
										html: textMessage,
										customClass: {
											icon: iconColor,
											title: 'has-text-primary is-family-primary has-text-weight-semibold',
											htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
											confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
										}
									}).then((isConfirmed) => {
										if (isConfirmed && !isError)
										window.location.reload()
									})
								}
							})
					} else if (result.isDenied) {
						//this.isEditModal = false
					}
				})
			},

			validateTotal () {
				// if (this.gender == 'Laki-laki') {
				// 	this.men = this.totalPeople
				// } else if (this.gender == "Perempuan") {
				// 	this.women = this.totalPeople
				// }

				let input = parseInt(this.men) + parseInt(this.women) + parseInt(this.bothGender)
				//console.log(input)
				if (input != this.totalPeople || this.totalPeople <= 0 || this.totalPeople > this.max || this.totalPeople < this.minEdit) {
					this.isValidTotal = false
				} else {
					this.isValidTotal = true
				}

				// if (this.totalPeople > this.budget) {
				// 	this.exceedBudget = true
				// } else {
				// 	this.exceedBudget = false
				// }
			},

			saveDate() {
				this.activateDate = moment(this.activateDateTemp).format('YYYY-MM-DD')
			},

			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},

			async updateStatusPK (action) {
				if (action == 'Ditunda') {
					if (!this.$refs.suspend.checkValidity()) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return
					}
				} else if (action == 'Dibatalkan') {
					if (!this.$refs.cancel.checkValidity()) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return
					}
				}

				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				let index = 0
				let isError = false

				//this.isSubmitLoading = true

				this.checkedRows.forEach( element => {
					textMessage += `${element.id_pk}<br/>`
				})

				if (action == 'Ditunda') {
					titleMessage = 'Apakah Anda yakin ingin menunda PK di bawah ini?'
				} else if (action == 'Dibatalkan') {
					titleMessage = 'Apakah Anda yakin ingin membatalkan PK di bawah ini?'
				}

				this.$swal({
					html: true,
					icon: 'question',
					title: titleMessage,
					html: textMessage,
					showDenyButton: true,
					confirmButtonText: 'Ya',
					denyButtonText: 'Tidak',
					customClass: {
						icon: 'is-warning',
						title: 'has-text-primary is-family-primary has-text-weight-semibold',
						htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
						confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold',
						denyButton: 'has-background-danger-light is-family-primary button-sizing has-text-white has-text-weight-bold'
					}
				}).then(async (result) => {
					if (result.isConfirmed) {
						this.isSubmitLoading = true
						let sendData = []
						this.checkedRows.forEach( element => {
							element.status_pk = action
							if (action == 'Ditunda') {
								element.tanggal_aktif_kembali = this.activateDate
							} else if (action == 'Dibatalkan') {
								element.alasan_batal = this.reasonCancel
							}

							let tempData = {
								id: element.id,
								status_pk: element.status_pk,
								tanggal_aktif_kembali: action == 'Ditunda' ? element.tanggal_aktif_kembali : null,
								alasan_batal: action == 'Dibatalkan' ? element.alasan_batal : null
							}
							sendData.push(tempData)
						})

						let postData = {
							row: sendData,
							code: 5
						}

						await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
							.then( response => {
								if (action == 'Ditunda') {
									titleMessage = 'PK berhasil ditunda'
								} else {
									titleMessage = 'PK berhasil dibatalkan'
								}
								iconType = 'success',
								iconColor = 'is-success'
								this.isSubmitLoading = false
							})
							.catch( e => {
								if (e.response.status != 401) {
									if (action == 'Ditunda') {
										titleMessage = 'Gagal menunda PK!'
									} else {
										titleMessage = 'Gagal membatalkan PK!'
									}
									iconType = 'error'
									if (e.response.data.status && e.response.data.status != 0) {
										textMessage = e.response.data.message
									} else {
										textMessage = 'Terjadi kesalahan pada server'
									}
									iconColor = 'is-danger'
								}
								
								console.log(e)
								this.isSubmitLoading = false
								isError = true
							})
							.finally(() => {
								if (titleMessage) {
									this.$swal({
										html: true,
										icon: iconType,
										title: titleMessage,
										html: textMessage,
										customClass: {
											icon: iconColor,
											title: 'has-text-primary is-family-primary has-text-weight-semibold',
											htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
											confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
										}
									}).then((isConfirmed) => {
										if (isConfirmed && !isError)
										window.location.reload()
									})
								}
							})
					} else if (result.isDenied) {
						// this.isCancelModal = false
						// this.isSuspendModal = false
					}
				})

				// this.isSubmitLoading = true

				// this.checkedRows.forEach( element => {
				// 	element.status_pk = action
				// 	if (action == 'Ditunda') {
				// 		element.tanggal_aktif = this.activateDate
				// 	} else if (action == 'Dibatalkan') {
				// 		element.alasan_batal = this.reasonCancel
				// 	}
				// })

				// let postData = {
				// 	row: this.checkedRows
				// }

				// console.log(JSON.stringify(postData))

				// let iconType = ''
				// let titleMessage = ''
				// let textMessage = ''
				// let iconColor = ''
				// let index = 0
				// let isError = false

				// await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pk/status-pk`, postData)
				// 	.then( response => {
				// 		if (action == 'Ditunda') {
				// 			titleMessage = 'PK berhasil ditunda'
				// 		} else {
				// 			titleMessage = 'PK berhasil dibatalkan'
				// 		}
				// 		iconType = 'success',
				// 		response.data.result.forEach(() => {
				// 			textMessage += `${this.checkedRows[index].id_pk}<br/>`
				// 			index++
				// 		})
				// 		iconColor = 'is-success'
				// 		this.isSubmitLoading = false
				// 	})
				// 	.catch( e => {
				// 		if (action == 'Ditunda') {
				// 			titleMessage = 'Gagal menunda PK!'
				// 		} else {
				// 			titleMessage = 'Gagal membatalkan PK!'
				// 		}
				// 		iconType = 'error'
				// 		textMessage = 'Terjadi kesalahan pada server'
				// 		iconColor = 'is-danger'
				// 		console.log(e)
				// 		this.isSubmitLoading = false
				// 		isError = true
				// 	})
				// 	.finally(() => {
				// 		this.$swal({
				// 			html: true,
				// 			icon: iconType,
				// 			title: titleMessage,
				// 			html: textMessage,
				// 			customClass: {
				// 				icon: iconColor,
				// 				title: 'has-text-primary is-family-primary has-text-weight-semibold',
				// 				htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
				// 				confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
				// 			}
				// 		}).then((isConfirmed) => {
				// 			if (isConfirmed && !isError)
				// 			window.location.reload()
				// 		})
				// 	})
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
			},

			onPageChange(page) {
                this.page = page
                this.loadAsyncData()
			},

			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id_pk')) {
                    pushFilter.id_pk = ""
                } else {
					pushFilter.id_pk = this.filter.id_pk
				}
				if (!this.filter.hasOwnProperty('nomor_pk')) {
                    pushFilter.nomor_pk = ""
                } else {
					pushFilter.nomor_pk = this.filter.nomor_pk
				}
				if (!this.filter.hasOwnProperty('jenis_pk')) {
                    pushFilter.jenis_pk = ""
                } else {
					pushFilter.jenis_pk = this.filter.jenis_pk
				}
                if (!this.filter.hasOwnProperty('unit')) {
                    pushFilter.unit = ""
                } else {
					pushFilter.unit = this.filter.unit
				}
				if (!this.filter.hasOwnProperty('cabang')) {
                    pushFilter.cabang = ""
                } else {
					pushFilter.cabang = this.filter.cabang
				}
                if (!this.filter.hasOwnProperty('departemen')) {
                    pushFilter.departemen = ""
                } else {
					pushFilter.departemen = this.filter.departemen
				}
                if (!this.filter.hasOwnProperty('status_pk')) {
                    pushFilter.status_pk = ""
                } else {
					pushFilter.status_pk = this.filter.status_pk
				}
                if (!this.filter.hasOwnProperty('tanggal_aktif')) {
                    pushFilter.tanggal_aktif = ""
                } else {
					pushFilter.tanggal_aktif = this.filter.tanggal_aktif
				}
                if (!this.filter.hasOwnProperty('estimasi_lead_time')) {
                    pushFilter.estimasi_lead_time = ""
				} else {
					pushFilter.estimasi_lead_time = this.filter.estimasi_lead_time
				}
				if (!this.filter.hasOwnProperty('jumlah_kebutuhan')) {
                    pushFilter.jumlah_kebutuhan = ""
				} else {
					pushFilter.jumlah_kebutuhan = this.filter.jumlah_kebutuhan
				}
				if (!this.filter.hasOwnProperty('jumlah_terpenuhi')) {
                    pushFilter.jumlah_terpenuhi = ""
				} else {
					pushFilter.jumlah_terpenuhi = this.filter.jumlah_terpenuhi
				}
				if (!this.filter.hasOwnProperty('sisa')) {
                    pushFilter.sisa = ""
				} else {
					pushFilter.sisa = this.filter.sisa
				}
				if (!this.filter.hasOwnProperty('usia_pk')) {
                    pushFilter.usia_pk = ""
				} else {
					pushFilter.usia_pk = this.filter.usia_pk
				}

				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
				// 	`column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`search=${searchBypass}`,
				// 	`jenis_pk=${this.PKType}`
				// ].join('&')
				// console.log(params)
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					jenis_pk: this.PKType,
					filter: JSON.stringify(pushFilter),
					code: 7
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
							// if (item.tanggal_aktif) {
							// 	item.tanggal_aktif = item.tanggal_aktif.substring(0, item.tanggal_aktif.indexOf(" "))
							// }
							// if (item.estimasi_lead_time) {
							// 	item.estimasi_lead_time = item.estimasi_lead_time.substring(0, item.estimasi_lead_time.indexOf(" "))
							// }
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(this.data)
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),

			async openModal(modalType, PK) {
				if (this.checkedRows.length == 0 && !PK) {
					this.$buefy.snackbar.open({
						message: 'Pilih PK yang ingin diproses terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					this.isSubmitLoading = false
					if (modalType == 'recruit') {
						this.isRecruitModal = true
					} else if(modalType == 'suspend') {
						let canOpenSuspendModal = true
						let message = ''
						if (this.checkedRows.length > 5) {
							canOpenSuspendModal = false
							message = "Maksimal data yang dapat dipilih hanya 5"
						}
						this.checkedRows.forEach( element => {
							if (canOpenSuspendModal) {
								if (element.tunda == '1') {
									canOpenSuspendModal = false
									message = 'PK terpilih sudah pernah ditunda'
								} else if (element.locked == '1') {
									canOpenSuspendModal = false
									message = 'PK terpilih memiliki kandidat yang telah PKWT'
								}
							}
						})

						if (this.access.group == 'Direktur HRS') {
							canOpenSuspendModal = false
							message = 'Direktur HRS tidak dapat menunda PK'
						}

						if (canOpenSuspendModal) {
							this.activateDate = null
							this.activateDateTemp = null
							this.isSuspendModal = true
						} else if (!canOpenSuspendModal) {
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						}
					} else if(modalType == 'cancel') {
						let canOpenCancelModal = true
						let isCreator = true
						let isNotFulfilled = true
						let message = ''
						if (this.checkedRows.length > 5) {
							canOpenCancelModal = false
							message = "Maksimal data yang dapat dipilih hanya 5"
						}
						this.checkedRows.forEach( element => {
							if (canOpenCancelModal) {
								if (parseInt(element.jumlah_terpenuhi) > 0 && isCreator) {
									isNotFulfilled = false
									if (canOpenCancelModal) {
										canOpenCancelModal = false
										message = "Terdapat PK yang sudah terpenuhi sebagian:<ul><li>> " + element.id_pk + "</li>"
									} else if (!canOpenCancelModal) {
										message += "<li>> " + element.id_pk + "</li>"
									}
								}
								if (element.nik_dibuat != this.userLogin.nik && isNotFulfilled) {
									isCreator = false
									if (canOpenCancelModal) {
										canOpenCancelModal = false
										message = "Terdapat PK yang pembuat nya bukan anda:<ul><li>> " + element.id_pk + "</li>"
									} else if (!canOpenCancelModal) {
										message += "<li>> " + element.id_pk + "</li>"
									}
								}
							}
						})

						if (this.access.group == 'Direktur HRS') {
							canOpenCancelModal = false
							message = 'Direktur HRS tidak dapat membatalkan PK'
						}

						if (canOpenCancelModal) {
							this.isCancelModal = true
							this.reasonCancel = null
						} else if (!canOpenCancelModal) {
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000,
							})
						}
					} else if(modalType == 'edit' && PK) {
						let canOpenEditModal = true
						let message = ''
						// if (PK.locked == '1') {
						// 	canOpenEditModal = false
						// 	message = 'Tidak bisa edit PK karena memiliki kandidat yang telah PKWT'
						// }

						if (PK.nik_dibuat != this.userLogin.nik) {
							canOpenEditModal = false
							message = 'Hanya pembuat PK yang dapat melakukan edit PK'
						}

						if (canOpenEditModal) {
							let postData = {
								code: '6',
								id: PK.id
							}
							await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pk-edits`, postData)
								.then( response => {
									this.editingPKId = PK.id
									this.id_pk = PK.id_pk
									//console.log(this.editingPKId)
									this.isValidTotal = true
									this.totalPeople = parseInt(PK.jumlah_kebutuhan)
									this.max = this.totalPeople
									this.men = parseInt(PK.jumlah_laki)
									this.women = parseInt(PK.jumlah_perempuan)
									this.bothGender = parseInt(PK.jumlah_bebas)
									this.minEdit = parseInt(response.data.result.jumlah_minimal)
									this.gender = PK.jenis_kelamin
									this.isEditModal = true
								})
								.catch( e => {
									if (e.response.status != 401) {
										let message = null
										if (e.response.data.status && e.response.data.status != 0) {
											message = e.response.data.message
										} else {
											message = 'Terjadi kesalahan pada server'
										}

										this.$buefy.snackbar.open({
											message: message,
											type: 'is-info',
											position: 'is-top',
											actionText: 'Tutup',
											indefinite: false,
											duration: 7000
										})
									}
								})
						} else if (!canOpenEditModal) {
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						}
						
					}
				}
			},

			routePage(routingFrom, routingTo, PK) {
				if (routingTo == 'detail-rekrutmen' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih PK yang ingin dilihat terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'detail-rekrutmen' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 PK untuk masuk detail rekrutmen',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					this.isRouting = true
					if (!PK){
						PK = this.checkedRows[0].id
					}
					if (this.checkedRows.length > 0) {
						this.setStoredPKAktif(this.checkedRows)
					}
					this.$router.push({name: routingTo, params: { routing: routingFrom, PK: PK }})
				}
			},
		}
    }
</script>
