<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<b-field class="has-text-weight-semibold has-text-primary is-size-3">
					Budget PK
				</b-field>
				<b-button
					class="has-background-success has-text-white has-text-weight-bold mb-3"
					@click="routePage('add')"
				>UPLOAD BUDGET
				</b-button>
				<br><br>
				<div class="columns is-desktop">
                    <div class="column is-9-desktop">
                        <b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Unit/PT</span>
							</template>
							<b-autocomplete
								:loading="isInputLoading"
								open-on-focus
								v-model="unit"
								:data="getAsyncUnitData"
								field="unit"
								@typing="setPickUnit(false)"
								@select="option => filterBranches((option ? option.unit : null))"
								placeholder="Ketik dan pilih dari dropdown"
								@keypress.native="departmentPositionValidation"
								pattern="^[A-Za-z0-9\s\-&().+/]*"
								validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
								<template slot="empty">
									Tidak ditemukan hasil untuk
									{{ unit }}
								</template>
							</b-autocomplete>
						</b-field>
                        <b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Cabang</span>
							</template>
							<b-autocomplete
								:loading="isInputLoading"
								open-on-focus
								v-model="branch"
								:data="getAsyncBranchData"
								field="cabang"
								@typing="setPickBranch(false)"
								@select="option => filterDepartments((option ? option.cabang : null))"
								placeholder="Ketik dan pilih dari dropdown"
								@keypress.native="departmentPositionValidation"
								pattern="^[A-Za-z0-9\s\-&().+/]*"
								validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
								<template slot="empty">
									Tidak ditemukan hasil untuk
									{{ branch }}
								</template>
							</b-autocomplete>
						</b-field>
						<b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Bagian Khusus</span>
							</template>
							<b-autocomplete
								:loading="isInputLoading"
								open-on-focus
								v-model="department"
								:data="getAsyncDepartmentData"
								field="departemen"
								@typing="setPickDepartment(false)"
								@select="option => filterPositions((option ? option.id : null), (option ? option.dept_khusus : null))"
								placeholder="Ketik dan pilih dari dropdown"
								@keypress.native="departmentPositionValidation"
								pattern="^[A-Za-z0-9\s\-&().+/]*"
								validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
								<template slot="empty">
									Tidak ditemukan hasil untuk
									{{ department }}
								</template>
							</b-autocomplete>
						</b-field>
						<b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Jabatan Khusus</span>
							</template>
							<b-autocomplete
								:loading="isInputLoading"
								open-on-focus
								v-model="position"
								:data="getAsyncPositionData"
								field="jab_khusus"
								@typing="setPickPosition(false)"
								@select="setPickPosition(true)"
								placeholder="Ketik dan pilih dari dropdown"
								@keypress.native="departmentPositionValidation"
								pattern="^[A-Za-z0-9\s\-&().+/]*"
								validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
								<template slot="empty">
									Tidak ditemukan hasil untuk
									{{ position }}
								</template>
							</b-autocomplete>
						</b-field>
                    </div>
                </div>
				<div class="columns is-desktop">
					<div class="column is-2 is-offset-5">
						<b-field class="has-text-centered mt-1">
							<b-button
								expanded
								class="has-background-success has-text-white has-text-weight-bold mb-2"
								@click="loadAsyncData"
							>FILTER
							</b-button>
						</b-field>
					</div>
				</div>
				<div class="mt-1 is-size-7">
					<!-- <div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div> -->
					<b-table
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						:loading="isLoading"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:mobile-cards="false"
					>
						<b-table-column header-class="has-background-light" field="unit" label="UNIT/PT" centered v-slot="props">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="cabang" label="CABANG" centered v-slot="props">
							{{props.row.cabang}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="departemen" label="BAGIAN KHUSUS" centered v-slot="props">
							{{props.row.dept_khusus}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="jabatan" label="JABATAN KHUSUS" centered v-slot="props">
							{{props.row.jab_khusus}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="januari" label="JAN" centered v-slot="props">
							{{props.row.jan}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="februari" label="FEB" centered v-slot="props">
							{{props.row.feb}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="maret" label="MAR" centered v-slot="props">
							{{props.row.mar}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="april" label="APR" centered v-slot="props">
							{{props.row.apr}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="mei" label="MEI" centered v-slot="props">
							{{props.row.mei}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="juni" label="JUN" centered v-slot="props">
							{{props.row.jun}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="juli" label="JUL" centered v-slot="props">
							{{props.row.jul}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="agustus" label="AGU" centered v-slot="props">
							{{props.row.agt}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="september" label="SEP" centered v-slot="props">
							{{props.row.sep}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="oktober" label="OKT" centered v-slot="props">
							{{props.row.okt}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="november" label="NOV" centered v-slot="props">
							{{props.row.nov}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="desember" label="DES" centered v-slot="props">
							{{props.row.des}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="total" label="TOTAL" centered v-slot="props">
							{{props.row.total}}
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}}</b>
						</template>
					</b-table>
				</div>
			</div>
		</div>
		<!-- <b-modal has-modal-card v-model="isDeleteModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Hapus Jabatan</p>
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menghapus data di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> Data {{row.id}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="isDeleteModal = false"
									>YA</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isDeleteModal = false"
									>TIDAK</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                </div>
        </b-modal> -->
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'

    export default {
        data() {
            return {
                data: [],
                // checkboxPosition: 'left',
				defaultSortDirection: 'desc',
				sortOrder: 'desc',
				sortField: 'id',
				// checkedRows: [],
				search: '',
				page: 1,
				total: 0,
				varPageNumbering: 1,
				perPage: 10,
				isApproveModal: false,
				isDeleteModal: false,
				textModal: '',
				isLoading: true,
				isInputLoading: true,
				unit: '',
				branch: '',
				department: '',
				position: '',
				masterUnits: [],
				masterBranches: [],
				filteredBranches: [],
				masterDepartments: [],
				filteredDepartments: [],
				masterPositions: [],
				filteredPositions: [],
				pickUnit: false,
				pickBranch: false,
				pickDepartment: false,
				pickPosition: false,
            }
		},

		async mounted () {
			this.isInputLoading = true
			this.isLoading = true
			await this.getBudgetUnits()
			await this.getBudgetBranches()
			await this.getBudgetDepartments()
			await this.getBudgetPositions()
			await this.loadAsyncData()
			this.isInputLoading = false
		},

		computed: {
			getAsyncUnitData() {
				if (this.pickUnit == false) {
					return this.masterUnits.filter((option) => {
						return option.unit
							.toString()
							.toLowerCase()
							.indexOf(this.unit.toLowerCase()) >= 0
					})
				} else {
					return this.masterUnits
				}
			},

			getAsyncBranchData () {
				if (this.pickBranch == false) {
					return this.filteredBranches.filter((option) => {
						return option.cabang
							.toString()
							.toLowerCase()
							.indexOf(this.branch.toLowerCase()) >= 0
					})
				} else {
					return this.filteredBranches
				}
			},

			getAsyncDepartmentData() {
				if (this.pickDepartment == false) {
					return this.filteredDepartments.filter((option) => {
						return option.departemen
							.toString()
							.toLowerCase()
							.indexOf(this.department.toLowerCase()) >= 0
					})
				} else {
					return this.filteredDepartments
				}
			},

			getAsyncPositionData() {
				if (this.pickPosition) {
					return this.filteredPositions.filter((option) => {
						return option.jab_khusus
							.toString()
							.toLowerCase()
							.indexOf(this.position.toLowerCase()) >= 0
					})
				} else {
					return this.filteredPositions
				}
			},
		},

		methods: {
			setPickPosition (selecting) {
				this.pickPosition = selecting
			},

			setPickDepartment (selecting) {
				this.pickDepartment = selecting
			},

			setPickBranch (selecting) {
				this.pickBranch = selecting
			},
			
			setPickUnit (selecting) {
				this.pickUnit = selecting
			},

			filterPositions (departmentId, department) {
				this.filteredPositions = this.masterPositions.filter((option) => {
					return option.id_master_departemen == departmentId
				})
				this.masterDepartmentsId = departmentId
				if (this.department != department) {
					this.position = ''
				}
				this.setPickDepartment(true)
			},

			filterDepartments (branch) {
				//console.log("cabang: "+branch)
				//console.log("unit: "+this.unit)
				this.filteredDepartments = this.masterDepartments.filter((option) => {
					return option.cabang == branch && option.unit == this.unit
				})
				if (this.branch != branch) {
					this.department = ''
					this.position = ''
				}
				this.setPickBranch(true)
			},

			filterBranches (unit) {
				this.filteredBranches = this.masterBranches.filter((option) => {
					return option.unit == unit
				})
				if (this.unit != unit) {
					this.branch = ''
					this.department = ''
					this.position = ''
				}
				this.setPickUnit(true)
			},

			departmentPositionValidation($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
		
			// filterBranches (unit) {
			// 	this.filteredBranches = this.masterDepartments.filter((option) => {
			// 		return option.unit == unit
			// 	})
			// },

			// filterDepartments (branch) {
			// 	this.filteredDepartments = this.filteredBranches.filter((option) => {
			// 		return option.cabang == branch
			// 	})
			// },

			async getBudgetDepartments () {
				let postData = {
					code: 11
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'budgets', postData)
					.then( response => {
						response.data.result.forEach(element => {
							this.masterDepartments.push(element)
						});
						//console.log(this.masterDepartments)
					})
					.catch( e => {
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
			},
		
			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
		
			async getBudgetUnits () {
				let postData = {
					code: 9
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'budgets', postData)
					.then( response => {
						response.data.result.forEach(element => {
							this.masterUnits.push(element)
						});
						//console.log(this.masterUnits)
					})
					.catch( e => {
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
			},

			async getBudgetBranches () {
				let postData = {
					code: 10
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'budgets', postData)
					.then( response => {
						response.data.result.forEach(element => {
							this.masterBranches.push(element)
						});
						//console.log(this.masterBranches)
					})
					.catch( e => {
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
			},

			async getBudgetPositions () {
				let postData = {
					code: 12
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'budgets', postData)
					.then( response => {
						response.data.result.forEach(element => {
							this.masterPositions.push(element)
						});
						//console.log(this.masterPositions)
					})
					.catch( e => {
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
			},
		
			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
			},
			
			onPageChange(page) {
                this.page = page
                this.loadAsyncData()
			},
			
			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				this.isLoading = true
				// let unitBypass = this.unit.replace("&", "%26")
				// let departmentBypass = this.department.replace("&", "%26")
				// let positionBypass = this.position.replace("&", "%26")
                // const params = [
				// 	`column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`unit=${unitBypass}`,
				// 	`cabang=${this.branch}`,
				// 	`departemen=${departmentBypass}`,
				// 	`jabatan=${positionBypass}`
				// ].join('&')
				// console.log(params)
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					unit: this.unit,
					cabang: this.branch,
					departemen: this.department,
					jabatan: this.position,
					code: 1
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `budgets`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(this.data)
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),
			
			// openModal(modalType) {
			// 	if (this.checkedRows.length == 0) {
			// 		this.$buefy.snackbar.open({
			// 			message: 'Pilih data master yang ingin dihapus',
			// 			type: 'is-info',
			// 			position: 'is-top',
			// 			actionText: 'Tutup',
			// 			indefinite: false,
			// 		})
			// 	} else {
			// 		if (modalType == 'delete') {
			// 			this.isDeleteModal = true
			// 		}
			// 	}
			// },

			routePage(routingTo) {
				if (routingTo == 'edit' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'edit' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 data master untuk diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					let master = null
					if (routingTo == 'edit') {
						master = this.checkedRows[0].id
					}
					this.$router.push({name: 'form-budget-pk', params: { routingTo: routingTo, master: master }})
				}
			}
		}
    }
</script>
