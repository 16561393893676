<template>
	<div class="container hero">
		<div class="mx-5">
			<b-field class="has-text-weight-semibold has-text-primary is-size-3">
                Master Lead Time
            </b-field>
            <b-button
                icon-pack="fas"
                icon-left="angle-left"
                class="has-background-warning has-text-white has-text-weight-semibold mb-5"
                @click="goBack()"
            >KEMBALI
            </b-button>
            <div class="ml-5">
                <div class="has-text-weight-medium has-text-primary is-size-4 mt-1">
                    {{ title }}
                </div>
                <ValidationObserver ref="observer">
                    <form ref="form">
                        <div class="ml-5 mt-5 pt-2 has-text-weight-medium has-text-primary">
                            <div class="columns is-desktop">
                                <div class="column is-9">
                                    <b-field 
                                        class="hz-label-master is-flex is-align-items-center"
                                        label="" 
                                        horizontal
                                    >
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">Deskripsi<span class="mandatory">*</span></span>
                                        </template>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-input
                                                :loading="isInputLoading"
                                                v-model="leadTimeName"
                                                @keypress.native="leadTimeNameValidation"
                                                maxlength="45"
                                                pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                required>
                                            </b-input>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </b-field>
                                    <b-field 
                                        class="hz-label-master is-flex is-align-items-center" 
                                        label="" 
                                        horizontal
                                    >
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">Lead Time (Hari Kalender)<span class="mandatory">*</span></span>
                                        </template>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-input
                                                :loading="isInputLoading"
                                                v-model="leadTime"
                                                @input="validateLifeTime"
                                                @keypress.native="onlyNumber"
                                                pattern="^[0-9]*"
                                                validation-message="Hanya bisa angka"
                                                required>
                                            </b-input>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-desktop">
							    <div class="column is-9">
                                    <b-field 
									class="hz-label-master is-flex is-justify-content-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Jumlah Ideal (Orang)</span>
									</template>
									<b-field>
										<ValidationProvider>
											<b-input
                                                :loading="isInputLoading"
                                                v-model="ideal"
                                                @keypress.native="onlyNumber"
                                                pattern="^[0-9]*"
                                                validation-message="Hanya bisa angka">
                                            </b-input>
											<p class="is-size-7">*Tidak perlu diisi jika ingin dikosongkan</p>
										</ValidationProvider>
									</b-field>
								</b-field>
							    </div>
                            </div>
                            <div class="columns is-desktop">
							    <div class="column is-9">
                                    <b-field 
                                        class="hz-label-master is-flex is-align-items-center" 
                                        label="" 
                                        horizontal
                                    >
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">Variabel Penambah Jumlah Ideal (Hari)</span>
                                        </template>
                                        <b-field>
                                            <ValidationProvider>
                                                <b-input
                                                    :loading="isInputLoading"
                                                    v-model="idealIncrementingVariable"
                                                    @keypress.native="onlyNumber"
                                                    pattern="^[0-9]*"
                                                    validation-message="Hanya bisa angka"
                                                    >
                                                </b-input>
                                                <p class="is-size-7">*Tidak perlu diisi jika ingin dikosongkan</p>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-field>
                                    <b-field 
                                        class="hz-label-master is-flex is-align-items-center" 
                                        label="" 
                                        horizontal
                                    >
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">Life Time (Hari Kalender)<span class="mandatory">*</span></span>
                                        </template>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-input
                                                :loading="isInputLoading"
                                                v-model="lifeTime"
                                                @input="validateLifeTime"
                                                @keypress.native="onlyNumber"
                                                pattern="^[0-9]*"
                                                validation-message="Hanya bisa angka"
                                                required>
                                            </b-input>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            <p class="is-size-7 has-text-danger" v-if="exceedLifeTime">Life time harus lebih besar dari lead time</p>
                                        </ValidationProvider>
                                    </b-field>
                                </div>
                            </div>
                            <b-field class="has-text-centered mt-5">
                                <b-button
                                    class="is-size-5 has-background-success has-text-white has-text-weight-bold"
                                    @click="updateId ? updateLeadTime() : createLeadTime()"
                                    :loading="isSubmitLoading"
                                    >Simpan</b-button>
                            </b-field>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            editData: null,
            updateId: null,
            title: null,
            leadTimeName: null,
            leadTime: null,
            ideal: null,
            idealIncrementingVariable: null,
            lifeTime: null,
            isInputLoading: false,
            isJabatanLoading: false,
            isSubmitLoading: false,
            exceedLifeTime: false,
        };
    },
    created() {
        if (this.$route.params.routingTo == 'add') {
            this.title = 'Tambah Lead Time Baru'
        } else if (this.$route.params.routingTo == 'edit') {
            this.title = 'Ubah Lead Time'
        }

        if (this.$route.params.master) {
            this.editData = this.$route.params.master
        }
        //console.log(this.editData)
    },

    async mounted() {
        this.isInputLoading = true
        this.isJabatanLoading = true
        this.isSubmitLoading = true
        if (this.editData) {
            this.editingData()
        }
        this.isInputLoading = false
        this.isJabatanLoading = false
        this.isSubmitLoading = false
    },

    methods: {
        async validateLifeTime () {
			if (parseInt(this.leadTime) >= parseInt(this.lifeTime)) {
				this.exceedLifeTime = true
			} else {
				this.exceedLifeTime = false
			}
        },

        editingData () {
            this.updateId = this.editData.id
            this.leadTimeName = this.editData.description
            this.leadTime = this.editData.lead_time
            this.ideal = this.editData.jumlah_ideal
            this.idealIncrementingVariable = this.editData.variabel_penambah
            this.lifeTime = this.editData.life_time
            this.editData = null
        },

        async updateLeadTime () {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
                    if (!this.$refs.form.checkValidity() || this.exceedLifeTime) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            this.ideal = this.ideal ? this.ideal.replace(/^0*/, "") : null
                            this.idealIncrementingVariable = this.idealIncrementingVariable ? this.idealIncrementingVariable.replace(/^0*/, "") : null
                            let postData = {
                                description: this.leadTimeName,
                                lead_time : this.leadTime,
                                jumlah_ideal : this.ideal,
                                variabel_penambah : this.idealIncrementingVariable,
                                life_time : this.lifeTime,
                                code: 4,
                                id: this.updateId
                            }
                            console.log(JSON.stringify(postData))
                            let iconType = ''
                            let titleMessage = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/lead-times`, postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data lead time berhasil diubah',
                                    textMessage = this.leadTimeName + ' - ' + response.data.result.lead_time + ' hari'
                                    iconColor = 'is-success'
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-lead-time'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal mengubah data lead time!'
                                        if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            text: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        },

        onlyNumber($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[0-9]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        leadTimeNameValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) { //including . - & ( )
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        clearDate () {
            this.selectedDate = null
        },

        goBack() {
            this.$router.push({name: 'master-lead-time'})
        },

        emptyField () {
            this.position = null
            this.jobClassFrom = null
            this.jobClassTo = null
            this.education = null
            this.leadTime = null
            this.ideal = null
            this.idealIncrementingVariable = null
            this.lifeTime = null
        },

        async createLeadTime () {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
                    if (!this.$refs.form.checkValidity() || this.exceedLifeTime) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            this.ideal = this.ideal ? this.ideal.replace(/^0*/, "") : null
                            this.idealIncrementingVariable = this.idealIncrementingVariable ? this.idealIncrementingVariable.replace(/^0*/, "") : null
                            let postData = {
                                description: this.leadTimeName,
                                lead_time : this.leadTime,
                                jumlah_ideal : this.ideal,
                                variabel_penambah : this.idealIncrementingVariable,
                                life_time : this.lifeTime,
                                code: 3
                            }
                            //console.log(JSON.stringify(postData))
                            let iconType = ''
                            let titleMessage = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/lead-times', postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data lead time baru berhasil disimpan',
                                    textMessage = this.leadTimeName + ' - ' + response.data.result.lead_time + ' hari'
                                    iconColor = 'is-success'
                                    //this.emptyField()
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-lead-time'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal menyimpan data lead time!'
                                        if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            text: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        }
    }
};
</script>
