<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<b-field class="has-text-weight-semibold has-text-primary is-size-3">
					Beranda
				</b-field>
				<!-- <div class="columns is-desktop">
					<div class="column is-8-desktop is-12-touch"> -->
						<b-notification
							class="has-text-white box-beranda-tablet box-beranda-touch" 
							type="is-warning"
							:closable="false">
							<div class="columns is-flex is-align-items-center is-justify-content-center">
								<div class="column is-half">
									<label class="is-size-5-desktop is-size-6-touch has-text-weight-bold">Sisa Pemenuhan PK : <span class="mandatory">{{ leftOverTotal }}</span></label>
								</div>
								<div class="column has-text-right">
									<label class="is-size-6-desktop is-size-7-touch has-text-weight-semibold">Jumlah yang diajukan: {{ submittedTotal }}</label><br>
									<label class="is-size-6-desktop is-size-7-touch has-text-weight-semibold">Jumlah yang dipenuhi: {{ fulfilledTotal }}</label>
								</div>
							</div>
						</b-notification>
					<!-- </div>
				</div> -->
				<div class="mt-1 is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search jabatan..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						:loading="isLoading"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:mobile-cards="false"
					>
						<b-table-column header-class="has-background-light" field="jabatan" label="JABATAN" centered v-slot="props">
							{{props.row.jab_khusus}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="januari" label="JANUARI" centered v-slot="props">
							{{props.row.jan}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="februari" label="FEBRUARI" centered v-slot="props">
							{{props.row.feb}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="maret" label="MARET" centered v-slot="props">
							{{props.row.mar}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="april" label="APRIL" centered v-slot="props">
							{{props.row.apr}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="mei" label="MEI" centered v-slot="props">
							{{props.row.mei}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="juni" label="JUNI" centered v-slot="props">
							{{props.row.jun}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="juli" label="JULI" centered v-slot="props">
							{{props.row.jul}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="agustus" label="AGUSTUS" centered v-slot="props">
							{{props.row.agt}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="september" label="SEPTEMBER" centered v-slot="props">
							{{props.row.sep}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="oktober" label="OKTOBER" centered v-slot="props">
							{{props.row.okt}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="november" label="NOVEMBER" centered v-slot="props">
							{{props.row.nov}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="desember" label="DESEMBER" centered v-slot="props">
							{{props.row.des}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="total" label="TOTAL" centered v-slot="props">
							{{props.row.total}}
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}}</b>
						</template>
					</b-table>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'
	import { mapGetters } from "vuex";

    export default {
        data() {
            // const data = [
            //     { 
			// 		'id': 1,
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'branch': 'Head Office',
			// 		'department': 'IT Software Development 2',
			// 		'position': 'MDP Specialist',
			// 		'january': '5',
			// 		'february': '0',
			// 		'march': '5',
			// 		'april': '0',
			// 		'may': '0',
			// 		'june': '10',
			// 		'july': '0',
			// 		'august': '5',
			// 		'september': '0',
			// 		'october': '5',
			// 		'november': '0',
			// 		'december': '0',
			// 		'total': '30', },
            //     { 
			// 		'id': 2,
			// 		'unit': 'PT Inti Cakrawala Citra',
			// 		'branch': 'Head Office',
			// 		'department': 'HR',
			// 		'position': 'MDP',
			// 		'january': '4',
			// 		'february': '4',
			// 		'march': '4',
			// 		'april': '4',
			// 		'may': '4',
			// 		'june': '4',
			// 		'july': '4',
			// 		'august': '4',
			// 		'september': '4',
			// 		'october': '4',
			// 		'november': '4',
			// 		'december': '4',
			// 		'total': '48', },
            //     { 
			// 		'id': 3,
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'branch': 'Yogyakarta',
			// 		'department': 'Operation',
			// 		'position': 'Staff',
			// 		'january': '5',
			// 		'february': '5',
			// 		'march': '5',
			// 		'april': '5',
			// 		'may': '5',
			// 		'june': '5',
			// 		'july': '5',
			// 		'august': '5',
			// 		'september': '5',
			// 		'october': '5',
			// 		'november': '5',
			// 		'december': '5',
			// 		'total': '60', },
            //     { 
			// 		'id': 4,
			// 		'unit': 'unit',
			// 		'branch': 'branch',
			// 		'department': 'department',
			// 		'position': 'general_position',
			// 		'january': '2',
			// 		'february': '2',
			// 		'march': '2',
			// 		'april': '2',
			// 		'may': '2',
			// 		'june': '2',
			// 		'july': '2',
			// 		'august': '2',
			// 		'september': '2',
			// 		'october': '2',
			// 		'november': '2',
			// 		'december': '2',
			// 		'total': '24', },
            //     { 
			// 		'id': 5,
			// 		'unit': 'unit',
			// 		'branch': 'branch',
			// 		'department': 'department',
			// 		'position': 'general_position',
			// 		'january': '0',
			// 		'february': '0',
			// 		'march': '0',
			// 		'april': '0',
			// 		'may': '0',
			// 		'june': '0',
			// 		'july': '0',
			// 		'august': '0',
			// 		'september': '0',
			// 		'october': '0',
			// 		'november': '0',
			// 		'december': '0',
			// 		'total': '0', },
            //     { 
			// 		'id': 6,
			// 		'unit': 'unit',
			// 		'branch': 'branch',
			// 		'department': 'department',
			// 		'position': 'general_position',
			// 		'january': '0',
			// 		'february': '0',
			// 		'march': '0',
			// 		'april': '0',
			// 		'may': '0',
			// 		'june': '0',
			// 		'july': '0',
			// 		'august': '0',
			// 		'september': '0',
			// 		'october': '0',
			// 		'november': '0',
			// 		'december': '0',
			// 		'total': '0', },
            // ]

            return {
                data: [],
				defaultSortDirection: 'desc',
				sortOrder: 'desc',
				sortField: 'id',
				search: '',
				page: 1,
				total: 0,
				varPageNumbering: 1,
				perPage: 10,
				unit: '',
				branch: '',
				department: '',
				position: '',
				leftOverTotal: null,
				submittedTotal: null,
				fulfilledTotal: null,
				isApproveModal: false,
				isDeleteModal: false,
				textModal: '',
				isLoading: true,
            }
		},
		
		async mounted () {
			await this.loadAsyncData()
			await this.getTotalPKByUser()
			this.isLoading = false
		},

		computed: {
			...mapGetters({
				access: "access"
			}),
			// filter: function() {
			// 	var searching = new RegExp(this.search, 'i')
			// 	var data = []
			// 	for (var i in this.data) {
			// 		if (this.data[i].unit.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].branch.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].department.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].position.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].january.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].february.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].march.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].april.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].may.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].june.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].july.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].august.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].september.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].october.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].november.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].december.match(searching)) {
			// 			data.push(this.data[i])
			// 		} else if (this.data[i].total.match(searching)) {
			// 			data.push(this.data[i])
			// 		}
			// 	}
			// 	return data
			// }
		},

		methods: {
			async getTotalPKByUser () {
				let postData = {
					code: 13
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
					.then( response => {
						this.leftOverTotal = response.data.result.total_sisa
						this.submittedTotal = response.data.result.total_kebutuhan
						this.fulfilledTotal = response.data.result.total_terpenuhi
						//console.log(response.data.result)
					})
					.catch( e => {
						console.log(e)
					})
			},

			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
				// 	`column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`search=${searchBypass}`
				// ].join('&')
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					code: 13
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `budgets`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(this.data)
                    })
                    .catch((error) => {
                        this.data = []
						this.total = 0
						console.log(error)
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
			},
			
			onPageChange(page) {
                this.page = page
                this.loadAsyncData()
			},

			openModal(modalType) {
				if (this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin dihapus',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					if (modalType == 'delete') {
						this.isDeleteModal = true
					}
				}
			},

			routePage(routingTo) {
				if (routingTo == 'edit' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'edit' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 data master untuk diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					let master = null
					if (routingTo == 'edit') {
						master = this.checkedRows[0].id
					}
					this.$router.push({name: 'form-budget-pk', params: { routingTo: routingTo, master: master }})
				}
			}
		}
    }
</script>
