<template>
	<div class="container hero">
		<div class="mx-5">
            <div class="detail-sticky-top-desktop detail-sticky-top-mobile has-background-white">
                <div class="has-text-weight-semibold has-text-primary is-size-3">
                    {{ title }}
                </div>
                <div class="columns">
                    <div class="column is-2">
                        <b-button
                            icon-pack="fas"
                            icon-left="angle-left"
                            class="has-background-warning has-text-white has-text-weight-semibold"
                            @click="goBack()"
                        >KEMBALI
                        </b-button>
                    </div>
                    <div class="column is-2">
                        <b-button
                            icon-pack="fas"
                            icon-left="sync"
                            class="has-background-success has-text-white has-text-weight-semibold"
                            @click="refreshData()"
                        >MUAT ULANG
                        </b-button>
                    </div>
                </div>
                <div class="has-text-weight-semibold has-text-primary is-size-4 ml-5">
                    Rincian PK {{ idPK }}
                </div>
                <p v-if="cancelReason" class="is-size-6 has-text-weight-medium has-text-danger ml-5">ALASAN DIBATALKAN : {{ cancelReason }}</p>
                <p v-if="rejection" class="is-size-6 has-text-weight-medium has-text-danger ml-5">ALASAN DITOLAK : {{ rejection }}</p>
                <hr class="line-break">
            </div>
            <div class="ml-5 detail-content-desktop detail-content-mobile">
				<div class="columns is-desktop has-text-weight-medium mt-1">
					<div class="column is-half">
                        <b-field 
							class="hz-label mt-2 mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jenis PK</span>
							</template>
							<p class="has-text-info-light">{{ typePK }}</p>
						</b-field>
                        <b-field 
                            v-if="idBackupPK != null"
							class="hz-label mt-2 mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">ID PK Cadangan</span>
							</template>
							<p class="has-text-info-light">{{ idBackupPK }}</p>
						</b-field>
                        <b-field 
							class="hz-label mt-2 mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Unit</span>
							</template>
							<p class="has-text-info-light">{{ unit }}</p>
						</b-field>
                        <b-field 
							class="hz-label mt-2 mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Cabang</span>
							</template>
							<p class="has-text-info-light">{{ branch }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Bagian/Sub Bagian</span>
							</template>
							<p class="has-text-info-light">{{ department }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jabatan Umum</span>
							</template>
							<p class="has-text-info-light">{{ generalPosition }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jabatan Khusus</span>
							</template>
							<p class="has-text-info-light">{{ specificPosition }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jabatan Teknis</span>
							</template>
							<p class="has-text-info-light">{{ technicalPosition }}</p>
						</b-field>
                        <div class="columns is-desktop is-flex is-align-items-center">
							<div class="column is-5">
								<span class="is-family-primary has-text-weight-medium has-text-left has-text-primary">Job Class</span>
							</div>
							<div class="column is-1">
                                <p class="has-text-info-light">{{ jobClassFrom }}</p>
							</div>
							<div class="column is-1">
								<div class="is-family-primary has-text-weight-medium has-text-centered has-text-primary">to</div>
							</div>
							<div class="column is-1">
								<p class="has-text-info-light">{{ jobClassTo }}</p>
							</div>
						</div>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jumlah Hari Kerja</span>
							</template>
							<p class="has-text-info-light">{{ workingDays }}</p>
						</b-field>
                        <b-field 
							class="hz-label my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Shift</span>
							</template>
							<p class="has-text-info-light">{{ shift }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Status Kerja</span>
							</template>
							<p class="has-text-info-light">{{ workStatus }}</p>
						</b-field>
						<b-field 
							class="hz-label mt-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Penempatan</span>
							</template>
							<p class="has-text-info-light">{{ placement }}</p>
						</b-field>
                        <div
                            :key="placement"
                            :class="placement ? placement != 'Head Office' ? 'columns is-desktop' : 'columns is-desktop  is-hidden' : 'columns is-desktop  is-hidden'"
                        >
                            <div class="column is-9 is-offset-1 mt-3">
                                <b-field 
                                    class="hz-label-right is-flex is-align-items-center" 
                                    label="" 
                                    horizontal
                                >
                                    <template #label>
                                        <span class="is-family-primary has-text-weight-medium has-text-primary">Lokasi Kerja</span>
                                    </template>
                                    <p class="has-text-info-light">{{ detailLocation }}</p>
                                </b-field>
                            </div>
                        </div>
                        <b-field 
							class="hz-label mt-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Alasan Permintaan</span>
							</template>
							<p class="has-text-info-light">{{ reason }}</p>
						</b-field>
                        <div
                            :key="reason"
                            :class="reason == 'Pengganti' ? 'columns is-desktop' : 'columns is-desktop  is-hidden'"
                        >
                            <div class="column is-9 is-offset-1 mt-3">
                                <b-field class="hz-label-right is-flex is-align-items-center" label="" horizontal>
                                    <template #label>
                                        <span class="is-family-primary has-text-weight-medium has-text-primary">NIK Keluar/Mutasi/Promosi</span>
                                    </template>
                                    <p class="has-text-info-light">{{ mutationNIK }}</p>
                                </b-field>
                                <b-field class="hz-label-right is-flex is-align-items-center" label="" horizontal>
                                    <template #label>
                                        <span class="is-family-primary has-text-weight-medium has-text-primary">Nama Karyawan</span>
                                    </template>
                                    <p class="has-text-info-light">{{ mutationName }}</p>
                                </b-field>
                                <b-field class="hz-label-right is-flex is-align-items-center" label="" horizontal>
                                    <template #label>
                                        <span class="is-family-primary has-text-weight-medium has-text-primary">Per Tanggal</span>
                                    </template>
                                    <p class="has-text-info-light">{{ mutationDate }}</p>
                                </b-field>
                                <!-- <b-field 
                                    class="hz-label" 
                                    label="Lokasi Mutasi" 
                                    horizontal>
                                    <b-select placeholder="" expanded>
                                        <option>Pilihan 1</option>
                                        <option>Pilihan 2</option>
                                    </b-select>
                                </b-field>
                                <b-field 
                                    class="hz-label" 
                                    label="Upload File" 
                                    horizontal>
                                    <b-field class="file is-dark" :class="{'has-name': !!file}">
                                        <b-upload v-model="file" class="file-label">
                                            <span class="file-cta">
                                                <b-icon class="file-icon" icon="upload"></b-icon>
                                                <span class="file-label">{{ file.name || "Klik untuk mengunggah"}}</span>
                                            </span>
                                        </b-upload>
                                    </b-field>
                                </b-field> -->
                            </div>
                        </div>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Sisa Budget</span>
							</template>
							<p class="has-text-info-light">{{ budget }}</p>
						</b-field>
                    </div>
                    <div class="column is-half">
                        <b-field 
							class="hz-label is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jumlah Kebutuhan</span>
							</template>
							<p class="has-text-info-light">{{ totalPeople }}</p>
						</b-field>
                        <div class="columns is-desktop">
                            <div class="column is-9 is-offset-1 my-3">
                                <b-field 
                                    class="hz-label-right is-flex is-align-items-center" 
                                    label="" 
                                    horizontal
                                >
                                    <template #label>
                                        <span class="is-family-primary has-text-weight-medium has-text-primary">Laki - Laki</span>
                                    </template>
                                    <p class="has-text-info-light">{{ men }}</p>
                                </b-field>
                                <b-field 
                                    class="hz-label-right is-flex is-align-items-center" 
                                    label="" 
                                    horizontal
                                >
                                    <template #label>
                                        <span class="is-family-primary has-text-weight-medium has-text-primary">Perempuan</span>
                                    </template>
                                    <p class="has-text-info-light">{{ women }}</p>
                                </b-field>
                                <b-field 
                                    class="hz-label-right is-flex is-align-items-center" 
                                    label="" 
                                    horizontal
                                >
                                    <template #label>
                                        <span class="is-family-primary has-text-weight-medium has-text-primary">Bebas</span>
                                    </template>
                                    <p class="has-text-info-light">{{ bothGender }}</p>
                                </b-field>
                            </div>
                        </div>
                        <b-field 
                            v-if="pathBA"
                            class="hz-label-right is-flex is-justify-content-center" 
                            label="" 
                            horizontal
                        >
                            <template #label>
                                <span class="is-family-primary has-text-weight-medium has-text-primary">Berita Acara</span>
                            </template>
                            <b-button
                                class="has-background-success has-text-white has-text-weight-medium mb-3"
                                @click="generateBAPDF()"
                            >Lihat BA
                            </b-button>
                        </b-field>
                        <b-field 
							class="hz-label mt-2 mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Lead Time</span>
							</template>
							<p class="has-text-info-light">{{ leadTime }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Life Time</span>
							</template>
							<p class="has-text-info-light">{{ lifeTime }}</p>
						</b-field>
                        <b-field 
							class="hz-label mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Pendidikan</span>
							</template>
							<p class="has-text-info-light">{{ education }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jurusan</span>
							</template>
							<p class="has-text-info-light">{{ educationDiscipline }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Status Pengalaman Kerja</span>
							</template>
							<p class="has-text-info-light">{{ experience }}</p>
						</b-field>
						<b-field 
							class="hz-label my-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Usia Maksimal</span>
							</template>
							<p class="has-text-info-light">{{ old }}</p>
						</b-field>
                        <b-field class="hz-label my-5 is-flex is-align-items-center" label="" horizontal>
                            <template #label>
                                <span class="is-family-primary has-text-weight-medium has-text-primary">Keterampilan Khusus</span>
                            </template>
                            <p class="has-text-info-light">{{ skill }}</p>
                        </b-field>
                        <b-field class="hz-label my-5 is-flex is-align-items-center" label="" horizontal>
                            <template #label>
                                <span class="is-family-primary has-text-weight-medium has-text-primary">Deskripsi Pekerjaan</span>
                            </template>
                            <p class="has-text-info-light">{{ description }}</p>
                        </b-field>
                        <b-field 
                            v-if="pathStruktur"
                            class="hz-label-right my-5 is-flex is-justify-content-center" 
                            label="" 
                            horizontal
                        >
                            <template #label>
                                <span class="is-family-primary has-text-weight-medium has-text-primary">Struktur Organisasi</span>
                            </template>
                            <b-button
                                class="has-background-success has-text-white has-text-weight-medium mb-3"
                                @click="generateStrukturPDF()"
                            >Lihat Struktur
                            </b-button>
                        </b-field>
                        <b-field 
							class="hz-label my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Sumber Pemenuhan</span>
							</template>
							<p class="has-text-info-light">{{ source }}</p>
						</b-field>
                    </div>
                </div>
                <div class="columns detail-fluid-container is-desktop is-size-6 has-text-primary has-text-weight-regular is-centered has-text-centered mt-5">
                    <div class="detail-fluid-item" v-for="approval in totalApproval" :key="approval.nik">
                        <div>
                            <p class="has-text-weight-medium">{{approval.title}}</p>
                            <div :class="approval.status == 0 ? 'is-hidden my-4' : 'my-4'">
                                <b-tooltip
                                    :active="approval.status == 1 ? true : false"
                                    :label="rejection"
                                    size="is-small"
                                    position="is-bottom"
                                    multilined>
                                    <b-icon
                                        :key="approval.status"
                                        :type="approval.status == 2 ? 'is-success' : 'is-danger'"
                                        class="is-size-4"
                                        pack="fas"
                                        size="is-large"
                                        :icon="approval.status == 2 ? 'check-circle' : 'times-circle'">
                                    </b-icon>
                                </b-tooltip>
                            </div>
                            <div :class="approval.status != 0 ? 'is-hidden mb-4 mt-5 has-text-danger' : 'mb-4 mt-5 has-text-danger'">
                                <i>Menunggu</i>
                                <br>
                                <i>Konfirmasi</i>
                            </div>
                            <div class="is-size-7">{{ approval.name }}</div>
                            <div class="is-size-7">{{ approval.position }}</div>
                            <span v-if="!showEditApproval && isEditedDate && approval.date">
                                <div class="is-size-7" :key="showEditApproval">
                                    {{ approval.date }}
                                    <b-tooltip
                                        v-if="approval.date ? true : false"
                                        :active="approval.date ? true : false"
                                        label="Tanggal approval (1)"
                                        size="is-small"
                                        position="is-top"
                                        multilined>
                                        <i v-if="approval.date ? true : false" class="fas fa-info-circle"></i>
                                    </b-tooltip>
                                </div>
                                <span :key="showEditApproval"  @click="changeDate(true)"><i class="fas fa-arrow-down custom-pointer"></i></span>
                            </span>
                            <span v-if="showEditApproval && isEditedDate && approval.editDate">
                                <span :key="showEditApproval"  @click="changeDate(false)"><i class="fas fa-arrow-up custom-pointer"></i></span>
                                <div class="is-size-7" v-for="(edit, index) in approval.editDate" :key="edit" >
                                    {{ edit }}
                                    <b-tooltip
                                        v-if="edit ? true : false"
                                        :active="edit ? true : false"
                                        :label="'Tanggal approval ubah PK (' + (index+1) + ')'"
                                        size="is-small"
                                        position="is-top"
                                        multilined>
                                        <i v-if="edit ? true : false" class="fas fa-info-circle"></i>
                                    </b-tooltip>
                                </div>
                            </span>
                            <span v-if="!isEditedDate && approval.date">
                                <div class="is-size-7" :key="showEditApproval">
                                    {{ approval.date }}
                                    <b-tooltip
                                        v-if="approval.date ? true : false"
                                        :active="approval.date ? true : false"
                                        label="Tanggal approval (1)"
                                        size="is-small"
                                        position="is-top"
                                        multilined>
                                        <i v-if="approval.date ? true : false" class="fas fa-info-circle"></i>
                                    </b-tooltip>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            idPK: null,
            cancelReason: null,
            masterPositionsId: null,
            typePK: null,
            idBackupPK: null,
            unit: null,
            branch: null,
            department: null,
            generalPosition: null,
            specificPosition: null,
            technicalPosition: null,
            jobClassFrom: null,
            jobClassTo: null,
            workingDays: null,
            workStatus: null,
            shift: null,
            placement: null,
            detailLocation: null,
            reason: null,
            mutationNIK: null,
            mutationName: null,
            mutationDate: null,
            budget: null,
            totalPeople: null,
            men: null,
            women: null,
            bothGender: null,
            pathBA: null,
            leadTime: null,
            lifeTime: null,
            education: null,
            educationDiscipline: null,
            experience: null,
            old: null,
            skill: null,
            description: null,
            pathStruktur: null,
            source: null,
            rejection: null,
            approvedStatus5: null,
            approvedNIK5: null,
            approvedName5: null,
            approvedDate5: null,
            approvedPosition5: null,
            approvedStatus4: null,
            approvedNIK4: null,
            approvedName4: null,
            approvedDate4: null,
            approvedPosition4: null,
            approvedStatus3: null,
            approvedNIK3: null,
            approvedName3: null,
            approvedDate3: null,
            approvedPosition3: null,
            approvedStatus2: null,
            approvedNIK2: null,
            approvedName2: null,
            approvedDate2: null,
            approvedPosition2: null,
            approvedStatus1: null,
            approvedNIK1: null,
            approvedName1: null,
            approvedDate1: null,
            approvedPosition1: null,
            functionalStatus4: null,
            functionalNIK4: null,
            functionalName4: null,
            functionalDate4: null,
            functionalPosition4: null,
            functionalStatus3: null,
            functionalNIK3: null,
            functionalName3: null,
            functionalDate3: null,
            functionalPosition3: null,
            functionalStatus2: null,
            functionalNIK2: null,
            functionalName2: null,
            functionalDate2: null,
            functionalPosition2: null,
            functionalStatus1: null,
            functionalNIK1: null,
            functionalName1: null,
            functionalDate1: null,
            functionalPosition1: null,
            structuralStatus6: null,
            structuralNIK6: null,
            structuralName6: null,
            structuralDate6: null,
            structuralPosition6: null,
            structuralStatus5: null,
            structuralNIK5: null,
            structuralName5: null,
            structuralDate5: null,
            structuralPosition5: null,
            structuralStatus4: null,
            structuralNIK4: null,
            structuralName4: null,
            structuralDate4: null,
            structuralPosition4: null,
            structuralStatus3: null,
            structuralNIK3: null,
            structuralName3: null,
            structuralDate3: null,
            structuralPosition3: null,
            structuralStatus2: null,
            structuralNIK2: null,
            structuralName2: null,
            structuralDate2: null,
            structuralPosition2: null,
            structuralStatus1: null,
            structuralNIK1: null,
            structuralName1: null,
            structuralDate1: null,
            structuralPosition1: null,
            createdStatus: null,
            createdNIK: null,
            createdName: null,
            createdDate: null,
            createdPosition: null,
            totalApproval: [],
            PK: null,
            isLoading: true,
            showEditApproval: false,
            isEditedDate: false,
        }
    },
    created() {
        if(this.$route.params.routing == 'persetujuan-pk') {
            this.title = 'Persetujuan PK'
        } else if (this.$route.params.routing == 'pk-aktif') {
            this.title = 'Pemantauan PK Aktif'
        } else if (this.$route.params.routing == 'pk-ditunda') {
            this.title = 'Pemantauan PK Ditunda'
        } else if (this.$route.params.routing == 'pk-dibatalkan') {
            this.title = 'Riwayat PK Dibatalkan'
        } else if (this.$route.params.routing == 'pk-ditolak') {
            this.title = 'Riwayat PK Ditolak'
        } else if (this.$route.params.routing == 'pk-kadaluarsa') {
            this.title = 'Riwayat PK Kadaluarsa'
        } else if (this.$route.params.routing == 'pk-terpenuhi') {
            this.title = 'Riwayat PK Terpenuhi'
        } else if (this.$route.params.routing == 'persetujuan-ganti-pejabat') {
            this.title = 'Persetujuan Ganti Pejabat'
        } else if (this.$route.params.routing == 'persetujuan-garansi') {
            this.title = 'Persetujuan Garansi'
        } else if (this.$route.params.routing == 'persetujuan-ubah-pk') {
            this.title = 'Persetujuan Ubah PK'
        } else if (this.$route.params.routing == 'pk-belum-diproses-atasan') {
            this.title = 'PK Belum Diproses Atasan'
        } else if (this.$route.params.routing == 'pk-belum-giliran-saya') {
            this.title = 'PK Belum Giliran Saya'
        }
        this.id = this.$route.params.PK
    },

    async mounted () {
        await this.getDetailPK()
        //await this.getBudgetPK()
        this.isLoading = false
    },

    methods: {
        changeDate (isShow) {
            this.showEditApproval = isShow
        },

        async refreshData () {
            this.isLoading = true
            await this.getDetailPK()
            //await this.getBudgetPK()
            this.isLoading = false
        },

        // async getBudgetPK () {
        //     let postData = {
        //         id: this.masterPositionsId,
        //         code: 8
        //     }
		// 	await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `budgets`, postData)
        //         .then( response => {
		// 			this.budget = parseInt(response.data.result.sisa)
		// 			//console.log(response.data.result)
        //         })
        //         .catch( e => {
        //             console.log(e)

        //             let message = null
        //             if (e.response.data.status && e.response.data.status != 0) {
        //                 message = e.response.data.message
        //             } else {
        //                 message = 'Terjadi kesalahan pada server'
        //             }

        //             this.$buefy.snackbar.open({
        //                 message: message,
        //                 type: 'is-info',
        //                 position: 'is-top',
        //                 actionText: 'Tutup',
        //                 indefinite: false,
        //                 duration: 7000
        //             })
        //         })
        // },
        
        async generateStrukturPDF () {
            let postData = {
                id: this.id,
                code: 12
            }
            //console.log(postData)
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'pks', postData, {responseType: 'arraybuffer'})
                .then( response => {
                    let blob=new Blob([response.data], {type: 'application/pdf'});
					// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
					// 	window.navigator.msSaveOrOpenBlob(blob);
					// 	return;
					// }
					let link=document.createElement('a');
					link.href=window.URL.createObjectURL(blob);
					link.target="_blank";
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(link.href)
							, 100
					})
                })
                .catch( e => {
                    let converter = require('arraybuffer-to-string');
                    if (e.response.status != 401) {
                        let convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
                        let tempMessage = JSON.parse(convertResponse);
                        let message = 'Terjadi kesalahan pada server'
                        if (tempMessage.status && tempMessage.status != 0) {
                            message = e.response.data.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }

                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    }
                })
        },

        async generateBAPDF () {
            let postData = {
                id: this.id,
                code: 11
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'pks', postData, {responseType: 'arraybuffer', dataType: 'blob'})
                .then( response => {
                    let blob=new Blob([response.data], {type: 'application/pdf'});
					// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
					// 	window.navigator.msSaveOrOpenBlob(blob);
					// 	return;
					// }
					let link=document.createElement('a');
					link.href=window.URL.createObjectURL(blob);
					link.target="_blank";
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(link.href)
							, 100
					})
                })
                .catch( e => {
                    let converter = require('arraybuffer-to-string');
                    if (e.response.status != 401) {
                        let convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
                        let tempMessage = JSON.parse(convertResponse);
                        let message = 'Terjadi kesalahan pada server'
                        if (tempMessage.status && tempMessage.status != 0) {
                            message = e.response.data.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }

                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    }
                })
        },

        async getDetailPK () {
            let postData = {
                id: this.id,
                code: 2
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'pks', postData)
                .then( response => {
                    if (response.data.result.nik_mutasi) {
                        response.data.result.nik_mutasi = response.data.result.nik_mutasi.replace(/\|\|/g, "|| ");
                    }
                    if (response.data.result.nama_mutasi) {
                        response.data.result.nama_mutasi = response.data.result.nama_mutasi.replace(/\|\|/g, "|| ");
                    }
                    if (response.data.result.tanggal_mutasi) {
                        response.data.result.tanggal_mutasi = response.data.result.tanggal_mutasi.replace(/\|\|/g, "|| ");
                    }
                    this.totalApproval = []
                    this.PK = response.data.result
                    this.cancelReason = this.PK.alasan_batal
                    this.idPK = this.PK.id_pk
                    this.masterPositionsId = this.PK.id_master_jabatan
                    this.typePK = this.PK.jenis_pk
                    this.idBackupPK = this.PK.id_pk_cadangan
                    this.unit = this.PK.unit
                    this.branch = this.PK.cabang
                    this.department = this.PK.departemen
                    this.generalPosition = this.PK.jab_umum
                    this.specificPosition = this.PK.jab_khusus
                    this.technicalPosition = this.PK.jab_teknis
                    this.jobClassFrom = this.PK.job_class_from
                    //console.log(this.jobClassFrom)
                    //console.log(this.jobClassTo)
                    this.jobClassTo = this.PK.job_class_to
                    this.workingDays = this.PK.jumlah_hari_kerja
                    this.shift = this.PK.shift
                    this.workStatus = this.PK.status_kerja
                    this.placement = this.PK.penempatan
                    this.detailLocation = this.PK.lokasi_kerja
                    this.reason = this.PK.alasan_permintaan
                    this.mutationNIK = this.PK.nik_mutasi
                    this.mutationName = this.PK.nama_mutasi
                    this.mutationDate = this.PK.tanggal_mutasi
                    this.budget = this.PK.sisa_budget
                    this.totalPeople = this.PK.jumlah_kebutuhan
                    this.men = this.PK.jumlah_laki
                    this.women = this.PK.jumlah_perempuan
                    this.bothGender = this.PK.jumlah_bebas
                    this.pathBA = this.PK.dir_ba
                    this.leadTime = this.PK.lead_time
                    this.lifeTime = this.PK.life_time
                    this.education = this.PK.jenjang_pendidikan
                    this.educationDiscipline = this.PK.jurusan
                    this.experience = this.PK.pengalaman_kerja
                    this.old = this.PK.usia
                    this.skill = this.PK.keterampilan_khusus
                    this.description = this.PK.deskripsi_pekerjaan
                    this.pathStruktur = this.PK.struktur_organisasi
                    this.source = this.PK.sumber_pemenuhan
                    this.rejection = this.PK.keterangan_reject
                    this.createdStatus = this.PK.status_dibuat
                    this.createdNIK = this.PK.nik_dibuat
                    this.createdName = this.PK.nama_dibuat ? this.PK.nama_dibuat.toUpperCase() : this.PK.nama_dibuat
                    this.createdDate = this.PK.tanggal_dibuat
                    this.createdPosition = this.PK.jabatan_dibuat
                    let arrayPkEdit = this.PK.edit_pk
                    let editCreator = []
                    let editStructural1 = []
                    let editStructural2 = []
                    let editStructural3 = []
                    let editStructural4 = []
                    let editStructural5 = []
                    let editStructural6 = []
                    let editFunctional1 = []
                    let editFunctional2 = []
                    let editFunctional3 = []
                    let editFunctional4 = []
                    let editApproved1 = []
                    let editApproved2 = []
                    let editApproved3 = []
                    let editApproved4 = []
                    let editApproved5 = []
                    if (arrayPkEdit) {
                        this.isEditedDate = true
                        arrayPkEdit.forEach( element => {
                            editCreator.push(element.created_at)
                            editStructural1.push(element.tanggal_diketahui_struktural_1)
                            editStructural2.push(element.tanggal_diketahui_struktural_2)
                            editStructural3.push(element.tanggal_diketahui_struktural_3)
                            editStructural4.push(element.tanggal_diketahui_struktural_4)
                            editStructural5.push(element.tanggal_diketahui_struktural_5)
                            editStructural6.push(element.tanggal_diketahui_struktural_6)
                            editFunctional1.push(element.tanggal_diketahui_fungsional_1)
                            editFunctional2.push(element.tanggal_diketahui_fungsional_2)
                            editFunctional3.push(element.tanggal_diketahui_fungsional_3)
                            editFunctional4.push(element.tanggal_diketahui_fungsional_4)
                            editApproved1.push(element.tanggal_disetujui_1)
                            editApproved2.push(element.tanggal_disetujui_2)
                            editApproved3.push(element.tanggal_disetujui_3)
                            editApproved4.push(element.tanggal_disetujui_4)
                            editApproved5.push(element.tanggal_disetujui_5)
                        })
                    } else {
                        this.isEditedDate = false
                    }
                    let tempApproval = this.createdNIK ? {status: this.createdStatus, nik: this.createdNIK, name: this.createdName, date: this.createdDate, position: this.createdPosition, title: "Dibuat", editDate: editCreator} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.structuralStatus1 = this.PK.status_diketahui_struktural_1
                    this.structuralNIK1 = this.PK.nik_diketahui_struktural_1
                    this.structuralName1 = this.PK.nama_diketahui_struktural_1 ? this.PK.nama_diketahui_struktural_1.toUpperCase() : this.PK.nama_diketahui_struktural_1
                    this.structuralDate1 = this.PK.tanggal_diketahui_struktural_1
                    this.structuralPosition1 = this.PK.jabatan_diketahui_struktural_1
                    tempApproval = this.structuralNIK1 ? {status: this.structuralStatus1, nik: this.structuralNIK1, name: this.structuralName1, date: this.structuralDate1, position: this.structuralPosition1, title: "Disetujui Struktural 1", editDate: editStructural1} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.structuralStatus2 = this.PK.status_diketahui_struktural_2
                    this.structuralNIK2 = this.PK.nik_diketahui_struktural_2
                    this.structuralName2 = this.PK.nama_diketahui_struktural_2 ? this.PK.nama_diketahui_struktural_2.toUpperCase() : this.PK.nama_diketahui_struktural_2
                    this.structuralDate2 = this.PK.tanggal_diketahui_struktural_2
                    this.structuralPosition2 = this.PK.jabatan_diketahui_struktural_2
                    tempApproval = this.structuralNIK2 ? {status: this.structuralStatus2, nik: this.structuralNIK2, name: this.structuralName2, date: this.structuralDate2, position: this.structuralPosition2, title: "Disetujui Struktural 2", editDate: editStructural2} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.structuralStatus3 = this.PK.status_diketahui_struktural_3
                    this.structuralNIK3 = this.PK.nik_diketahui_struktural_3
                    this.structuralName3 = this.PK.nama_diketahui_struktural_3 ? this.PK.nama_diketahui_struktural_3.toUpperCase() : this.PK.nama_diketahui_struktural_3
                    this.structuralDate3 = this.PK.tanggal_diketahui_struktural_3
                    this.structuralPosition3 = this.PK.jabatan_diketahui_struktural_3
                    tempApproval = this.structuralNIK3 ? {status: this.structuralStatus3, nik: this.structuralNIK3, name: this.structuralName3, date: this.structuralDate3, position: this.structuralPosition3, title: "Disetujui Struktural 3", editDate: editStructural3} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.structuralStatus4 = this.PK.status_diketahui_struktural_4
                    this.structuralNIK4 = this.PK.nik_diketahui_struktural_4
                    this.structuralName4 = this.PK.nama_diketahui_struktural_4 ? this.PK.nama_diketahui_struktural_4.toUpperCase() : this.PK.nama_diketahui_struktural_4
                    this.structuralDate4 = this.PK.tanggal_diketahui_struktural_4
                    this.structuralPosition4 = this.PK.jabatan_diketahui_struktural_4
                    tempApproval = this.structuralNIK4 ? {status: this.structuralStatus4, nik: this.structuralNIK4, name: this.structuralName4, date: this.structuralDate4, position: this.structuralPosition4, title: "Disetujui Struktural 4", editDate: editStructural4} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.structuralStatus5 = this.PK.status_diketahui_struktural_5
                    this.structuralNIK5 = this.PK.nik_diketahui_struktural_5
                    this.structuralName5 = this.PK.nama_diketahui_struktural_5 ? this.PK.nama_diketahui_struktural_5.toUpperCase() : this.PK.nama_diketahui_struktural_5
                    this.structuralDate5 = this.PK.tanggal_diketahui_struktural_5
                    this.structuralPosition5 = this.PK.jabatan_diketahui_struktural_5
                    tempApproval = this.structuralNIK5 ? {status: this.structuralStatus5, nik: this.structuralNIK5, name: this.structuralName5, date: this.structuralDate5, position: this.structuralPosition5, title: "Disetujui Struktural 5", editDate: editStructural5} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.structuralStatus6 = this.PK.status_diketahui_struktural_6
                    this.structuralNIK6 = this.PK.nik_diketahui_struktural_6
                    this.structuralName6 = this.PK.nama_diketahui_struktural_6 ? this.PK.nama_diketahui_struktural_6.toUpperCase() : this.PK.nama_diketahui_struktural_6
                    this.structuralDate6 = this.PK.tanggal_diketahui_struktural_6
                    this.structuralPosition6 = this.PK.jabatan_diketahui_struktural_6
                    tempApproval = this.structuralNIK6 ? {status: this.structuralStatus6, nik: this.structuralNIK6, name: this.structuralName6, date: this.structuralDate6, position: this.structuralPosition6, title: "Disetujui Struktural 6", editDate: editStructural6} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.functionalStatus1 = this.PK.status_diketahui_fungsional_1
                    this.functionalNIK1 = this.PK.nik_diketahui_fungsional_1
                    this.functionalName1 = this.PK.nama_diketahui_fungsional_1 ? this.PK.nama_diketahui_fungsional_1.toUpperCase() : this.PK.nama_diketahui_fungsional_1
                    this.functionalDate1 = this.PK.tanggal_diketahui_fungsional_1
                    this.functionalPosition1 = this.PK.jabatan_diketahui_fungsional_1
                    tempApproval = this.functionalNIK1 ? {status: this.functionalStatus1, nik: this.functionalNIK1, name: this.functionalName1, date: this.functionalDate1, position: this.functionalPosition1, title: "Disetujui Fungsional 1", editDate: editFunctional1} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.functionalStatus2 = this.PK.status_diketahui_fungsional_2
                    this.functionalNIK2 = this.PK.nik_diketahui_fungsional_2
                    this.functionalName2 = this.PK.nama_diketahui_fungsional_2 ? this.PK.nama_diketahui_fungsional_2.toUpperCase() : this.PK.nama_diketahui_fungsional_2
                    this.functionalDate2 = this.PK.tanggal_diketahui_fungsional_2
                    this.functionalPosition2 = this.PK.jabatan_diketahui_fungsional_2
                    tempApproval = this.functionalNIK2 ? {status: this.functionalStatus2, nik: this.functionalNIK2, name: this.functionalName2, date: this.functionalDate2, position: this.functionalPosition2, title: "Disetujui Fungsional 2", editDate: editFunctional2} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.functionalStatus3 = this.PK.status_diketahui_fungsional_3
                    this.functionalNIK3 = this.PK.nik_diketahui_fungsional_3
                    this.functionalName3 = this.PK.nama_diketahui_fungsional_3 ? this.PK.nama_diketahui_fungsional_3.toUpperCase() : this.PK.nama_diketahui_fungsional_3
                    this.functionalDate3 = this.PK.tanggal_diketahui_fungsional_3
                    this.functionalPosition3 = this.PK.jabatan_diketahui_fungsional_3
                    tempApproval = this.functionalNIK3 ? {status: this.functionalStatus3, nik: this.functionalNIK3, name: this.functionalName3, date: this.functionalDate3, position: this.functionalPosition3, title: "Disetujui Fungsional 3", editDate: editFunctional3} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.functionalStatus4 = this.PK.status_diketahui_fungsional_4
                    this.functionalNIK4 = this.PK.nik_diketahui_fungsional_4
                    this.functionalName4 = this.PK.nama_diketahui_fungsional_4 ? this.PK.nama_diketahui_fungsional_4.toUpperCase() : this.PK.nama_diketahui_fungsional_4
                    this.functionalDate4 = this.PK.tanggal_diketahui_fungsional_4
                    this.functionalPosition4 = this.PK.jabatan_diketahui_fungsional_4
                    tempApproval = this.functionalNIK4 ? {status: this.functionalStatus4, nik: this.functionalNIK4, name: this.functionalName4, date: this.functionalDate4, position: this.functionalPosition4, title: "Disetujui Fungsional 4", editDate: editFunctional4} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.approvedStatus1 = this.PK.status_disetujui_1
                    this.approvedNIK1 = this.PK.nik_disetujui_1
                    this.approvedName1 = this.PK.nama_disetujui_1 ? this.PK.nama_disetujui_1.toUpperCase() : this.PK.nama_disetujui_1
                    this.approvedDate1 = this.PK.tanggal_disetujui_1
                    this.approvedPosition1 = this.PK.jabatan_disetujui_1
                    tempApproval = this.approvedNIK1 ? {status: this.approvedStatus1, nik: this.approvedNIK1, name: this.approvedName1, date: this.approvedDate1, position: this.approvedPosition1, title: "Disetujui 1", editDate: editApproved1} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.approvedStatus2 = this.PK.status_disetujui_2
                    this.approvedNIK2 = this.PK.nik_disetujui_2
                    this.approvedName2 = this.PK.nama_disetujui_2 ? this.PK.nama_disetujui_2.toUpperCase() : this.PK.nama_disetujui_2
                    this.approvedDate2 = this.PK.tanggal_disetujui_2
                    this.approvedPosition2 = this.PK.jabatan_disetujui_2
                    tempApproval = this.approvedNIK2 ? {status: this.approvedStatus2, nik: this.approvedNIK2, name: this.approvedName2, date: this.approvedDate2, position: this.approvedPosition2, title: "Disetujui 2", editDate: editApproved2} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.approvedStatus3 = this.PK.status_disetujui_3
                    this.approvedNIK3 = this.PK.nik_disetujui_3
                    this.approvedName3 = this.PK.nama_disetujui_3 ? this.PK.nama_disetujui_3.toUpperCase() : this.PK.nama_disetujui_3
                    this.approvedDate3 = this.PK.tanggal_disetujui_3
                    this.approvedPosition3 = this.PK.jabatan_disetujui_3
                    tempApproval = this.approvedNIK3 ? {status: this.approvedStatus3, nik: this.approvedNIK3, name: this.approvedName3, date: this.approvedDate3, position: this.approvedPosition3, title: "Disetujui 3", editDate: editApproved3} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.approvedStatus4 = this.PK.status_disetujui_4
                    this.approvedNIK4 = this.PK.nik_disetujui_4
                    this.approvedName4 = this.PK.nama_disetujui_4 ? this.PK.nama_disetujui_4.toUpperCase() : this.PK.nama_disetujui_4
                    this.approvedDate4 = this.PK.tanggal_disetujui_4
                    this.approvedPosition4 = this.PK.jabatan_disetujui_4
                    tempApproval = this.approvedNIK4 ? {status: this.approvedStatus4, nik: this.approvedNIK4, name: this.approvedName4, date: this.approvedDate4, position: this.approvedPosition4, title: "Disetujui 4", editDate: editApproved4} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    this.approvedStatus5 = this.PK.status_disetujui_5
                    this.approvedNIK5 = this.PK.nik_disetujui_5
                    this.approvedName5 = this.PK.nama_disetujui_5 ? this.PK.nama_disetujui_5.toUpperCase() : this.PK.nama_disetujui_5
                    this.approvedDate5 = this.PK.tanggal_disetujui_5
                    this.approvedPosition5 = this.PK.jabatan_disetujui_5
                    tempApproval = this.approvedNIK5 ? {status: this.approvedStatus5, nik: this.approvedNIK5, name: this.approvedName5, date: this.approvedDate5, position: this.approvedPosition5, title: "Disetujui 5", editDate: editApproved5} : null
                    tempApproval ? this.totalApproval.push(tempApproval) : 0
                    //console.log(this.PK)
                })
                .catch( e => {
                    console.log(e)

                    if (e.response.status != 401) {
                        let message = null
                        if (e.response.data.status && e.response.data.status != 0) {
                            message = e.response.data.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }

                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
                    }
                })
        },

        goBack() {
            this.$router.push({name: this.$route.params.routing})
        }
    }
}
</script>
