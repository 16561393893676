<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Master Hak Akses
				</div>
				<div class="columns">
					<div class="column is-one-fifth">
						<b-button
							expanded
							class="has-background-success has-text-white has-text-weight-bold"
							@click="routePage('add')"
						>TAMBAH BARU
						</b-button>
					</div>
					<div class="column is-one-fifth">
						<b-button
							expanded
							:loading="isDownloading"
							class="has-background-warning has-text-white has-text-weight-bold"
							@click="downloadTemplate"
						>UNDUH FORMAT
						</b-button>
					</div>
					<div class="column is-one-fifth">
						<b-field>
							<b-upload
								expanded
								:loading="isUploading"
								v-model="file"
								class="button has-background-primary-light has-text-white has-text-weight-bold"
								@input="uploadExcel"
								accept=".xlsx"
							><span>{{ file.name || "UNGGAH EXCEL"}}</span>
							</b-upload>
						</b-field>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						backend-filtering
						@filters-change="onFilter"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:checkbox-position="checkboxPosition"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column searchable sortable header-class="has-background-light" field="username" label="NIK" width="70px" centered v-slot="props">
							{{props.row.username}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="name" label="NAMA" centered v-slot="props">
							{{props.row.name}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="role" label="ROLE" width="50px" centered v-slot="props">
							{{props.row.role}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="group" label="GROUP" centered v-slot="props">
							{{props.row.group}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="company_name" label="UNIT" centered v-slot="props">
							{{props.row.company_name ? props.row.company_name : "ALL"}}
						</b-table-column>
                        <b-table-column searchable sortable header-class="has-background-light" field="branch_name" label="CABANG" centered v-slot="props">
							{{props.row.branch_name ? props.row.branch_name : "ALL"}}
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-warning has-text-white has-text-weight-bold"
									@click="routePage('edit')"
								>UBAH</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-danger-light has-text-white has-text-weight-bold"
									@click="openModal('delete')"
								>HAPUS</b-button>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isDeleteModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Hapus Hak Akses</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isDeleteModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menghapus data di bawah ini?
						</div>
						<div class="column mt-2 is-offset-1 is-10 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										class="mr-1"
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.username}} - {{row.name}} - {{row.role}} - {{row.group}} - {{row.company_name ? row.company_name : "ALL"}} - {{row.branch_name ? row.branch_name : "ALL"}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="deleteUser"
									>YA</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isDeleteModal = false"
									>TIDAK</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'
	import { mapGetters, mapActions } from "vuex";

    export default {
        data() {
            return {
                data: [],
                checkboxPosition: 'left',
				defaultSortDirection: 'desc',
				sortOrder: 'desc',
				sortField: 'id',
				checkedRows: [],
				search: '',
				isApproveModal: false,
				isDeleteModal: false,
				textModal: '',
				total: 0,
				page: 1,
				varPageNumbering: 1,
				perPage: 10,
				isLoading: true,
				filter: {},
				isDownloading: false,
				file: [],
				isUploading: false,
				isheaderCheckable: true,
            }
		},

		async mounted () {
			await this.loadAsyncData()
		},

		computed: {
			...mapGetters({
				access: "access",
			}),
		},

		methods: {
			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						this.checkedRows.push(row)
						// if (this.checkedRows.length <=4) {
						// 	this.checkedRows.push(row)
						// } else {
						// 	this.$buefy.snackbar.open({
						// 		message: 'Maksimal data yang dapat dipilih hanya 5',
						// 		type: 'is-info',
						// 		position: 'is-top',
						// 		actionText: 'Tutup',
						// 		indefinite: false,
						// 		duration: 7000
						// 	})
						// }
					}
				}
			},

			async uploadExcel() {
				this.isUploading = true
								
				const postData = new FormData()
				postData.set("file", this.file)
				postData.set("code", 14)
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''

				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData, {responseType: 'arraybuffer'})
					.then( response => {
						this.file = []
						let converter = require('arraybuffer-to-string');
						let convertResponse = converter(response.data); //terjemahkan response arraybuffer ke string
						if (convertResponse[0] == '{') {
							iconType = 'success',
							titleMessage = 'Data hak akses baru berhasil diunggah & disimpan',
							iconColor = 'is-success'
							//this.emptyField()
							this.isUploading = false
							this.loadAsyncData()
						} else {
							let blob=new Blob([response.data]);
							// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
							// 	window.navigator.msSaveOrOpenBlob(blob);
							// 	return;
							// }
							let link=document.createElement('a');
							link.href=window.URL.createObjectURL(blob);
							link.setAttribute('download', 'GagalUploadHakAkses.xlsx');
							link.click();
							setTimeout(function () {
								// For Firefox it is necessary to delay revoking the ObjectURL
								window.URL.revokeObjectURL(link.href)
									, 100
							})

							iconType = 'error'
							titleMessage = 'Gagal mengunggah & menyimpan sebagian data hak akses!'
							textMessage = 'Silakan cek data yang tidak masuk pada file excel yang terdownload kembali'
							iconColor = 'is-danger'

							this.isUploading = false
							this.loadAsyncData()
						}
					})
					.catch( e => {
						console.log(e)
						this.file = []
						if (e.response.status != 401) {
							iconType = 'error'
							titleMessage = 'Gagal mengunggah & menyimpan data hak akses!'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server. Pastikan file yang diupload merupakan file template dari master hak akses'
							}
							iconColor = 'is-danger'
						}

						// let converter = require('arraybuffer-to-string');
						// let convertResponse = converter(e.response); //terjemahkan response arraybuffer ke string
						// let tempResponse = JSON.parse(convertResponse);
						// if (tempResponse.status != 401) {
						// 	convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
						// 	tempResponse = JSON.parse(convertResponse);
							
						// 	iconType = 'error'
						// 	titleMessage = 'Gagal mengunggah & menyimpan data user!'
						// 	if (tempResponse.status && tempResponse.status != 0) {
						// 		textMessage = tempResponse.message
						// 	} else {
						// 		textMessage = 'Terjadi kesalahan pada server'
						// 	}
						// 	iconColor = 'is-danger'
						// }
						
						// console.log(e)
						this.isUploading = false
						this.loadAsyncData()
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								icon: iconType,
								title: titleMessage,
								text: textMessage,
								customClass: {
									icon: iconColor,
									title: 'has-text-primary is-family-primary has-text-weight-semibold',
									htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
									confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
								}
							})
						}
					})
			},

			async downloadTemplate () {
				this.isDownloading = true
				let postData = {
					code: 15
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData,
					{responseType: 'arraybuffer'})
					.then( response => {
						//handle kembalian file pdf
						let blob=new Blob([response.data]);
						// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
						// 	window.navigator.msSaveOrOpenBlob(blob);
						// 	return;
						// }
						let link=document.createElement('a');
						link.href=window.URL.createObjectURL(blob);
						link.setAttribute('download', 'TemplateHakAkses.xlsx');
						link.click();
						setTimeout(function () {
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(link.href)
								, 100
						})
					})
					.catch( e => {
						console.log(e)
						let converter = require('arraybuffer-to-string');
						let convertResponse = converter(e.response); //terjemahkan response arraybuffer ke string
						let tempMessage = JSON.parse(convertResponse);
						if (tempMessage.status != 401) {
							convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
							tempMessage = JSON.parse(convertResponse);
							let message = 'Terjadi kesalahan pada server'
							if (tempMessage.status && tempMessage.status != 0) {
								message = tempMessage.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(this.isDownloading = false)
			},

			onFilter (filter) {
                this.filter = filter
                this.loadAsyncData()
			},

			openModal(modalType) {
				if (this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin dihapus',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					if (modalType == 'delete') {
						let canOpenDeleteModal = true
						this.checkedRows.forEach(element => {
							if (this.access.role == 'Admin' && element.role == 'Super Admin') {
								canOpenDeleteModal = false
							}
						})

						if (!canOpenDeleteModal) {
							this.$buefy.snackbar.open({
								message: 'Admin tidak dapat menghapus Super Admin',
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						}
						this.isDeleteModal = canOpenDeleteModal
					}
				}
			},

			async deleteUser () {
				this.isDeleteModal = false
				let sendData = []
				this.checkedRows.forEach( element => {
					let tempData = {
						id: element.id,
						username: element.username
					}
					sendData.push(tempData)
				})
				let postData = {
					row: sendData,
					code: 5
				}
				
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/users`, postData)
                    .then( response => {
                            iconType = 'success'
                            titleMessage = 'Data hak akses berhasil dihapus'
                            iconColor = 'is-success'
							this.loadAsyncData()
							this.checkedRows = []
					})
					.catch( e => {
						if (e.response.status != 401) {
							let message = null
							if (e.response.data.message == 'Data tidak dapat dihapus karena terdapat pada Master Pejabat Approver') {
								message = 'Data tidak dapat dihapus karena terdapat pada Master Pejabat Approver'
							} else if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							}
							iconType = 'error'
							titleMessage = 'Gagal menghapus data hak akses!'
							textMessage = message ? message : 'Terjadi kesalahan pada server'
							iconColor = 'is-danger'
						}
						
						console.log(e)
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								icon: iconType,
								title: titleMessage,
								text: textMessage,
								customClass: {
									icon: iconColor,
									title: 'has-text-primary is-family-primary has-text-weight-semibold',
									htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
									confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
								}
							})
						}
					})
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

            loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id')) {
                    pushFilter.id = ""
                } else {
					pushFilter.id = this.filter.id
				}
                if (!this.filter.hasOwnProperty('username')) {
                    pushFilter.username = ""
                } else {
					pushFilter.username = this.filter.username
				}
                if (!this.filter.hasOwnProperty('name')) {
                    pushFilter.name = ""
                } else {
					pushFilter.name = this.filter.name
				}
                if (!this.filter.hasOwnProperty('role')) {
                    pushFilter.role = ""
                } else {
					pushFilter.role = this.filter.role
				}
                if (!this.filter.hasOwnProperty('group')) {
                    pushFilter.group = ""
                } else {
					pushFilter.group = this.filter.group
				}
                if (!this.filter.hasOwnProperty('company_name')) {
                    pushFilter.company_name = ""
				} else {
					pushFilter.company_name = this.filter.company_name
				}
				if (!this.filter.hasOwnProperty('branch_name')) {
                    pushFilter.branch_name = ""
				} else {
					pushFilter.branch_name = this.filter.branch_name
				}

				this.isLoading = true
				//let searchBypass = this.search.replace("&", "%26")
                let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					filter: JSON.stringify(pushFilter),
					code: 1
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/users`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(this.data)
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)
						
						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
            }, 400),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

			routePage(routingTo) {
				if (routingTo == 'edit' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'edit' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 data master untuk diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					let master = null
					if (routingTo == 'edit') {
						if (this.access.role == 'Admin' && this.checkedRows[0].role == 'Super Admin') {
							this.$buefy.snackbar.open({
								message: 'Admin tidak dapat mengedit Super Admin',
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
							return
						}
						master = this.checkedRows[0]
					}
					this.$router.push({name: 'form-hak-akses', params: { routingTo: routingTo, master: master }})
				}
			}
		}
    }
</script>
