<template>
    <section>
        <div class="container hero">
            <div class="mx-5">
                <b-field class="has-text-weight-semibold has-text-primary is-size-3">
                    Master Jabatan
                </b-field>
                <b-button
                    icon-pack="fas"
                    icon-left="angle-left"
                    class="has-background-warning has-text-white has-text-weight-semibold mb-5"
                    @click="goBack()"
                >KEMBALI
                </b-button>
                <div class="ml-5">
                    <div class="has-text-weight-medium has-text-primary is-size-4 mt-1">
                        {{ title }}
                    </div>
                    <ValidationObserver ref="observer">
                        <form ref="form">
                            <div class="ml-5 mt-5 pt-2 has-text-weight-medium has-text-primary">
                                <div class="columns is-desktop">
                                    <div class="column is-9">
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Unit/PT<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="unit"
                                                    :data="getAsyncUnitData"
                                                    field="unit"
                                                    @typing="setPickUnit(false)"
                                                    @select="option => filterBranches((option ? option.unit : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentPositionLeadTimeValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ unit }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            id="elBranch"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Cabang<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="branch"
                                                    :data="getAsyncBranchData"
                                                    field="cabang"
                                                    @typing="setPickBranch(false)"
                                                    @select="option => filterDepartments((option ? option.cabang : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentPositionLeadTimeValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ branch }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Bagian/Sub Bagian<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="department"
                                                    :data="getAsyncDepartmentData"
                                                    field="dept_khusus"
                                                    @typing="setPickDepartment(false)"
                                                    @select="option => setDepartmentId(option ? option.id : null)"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentPositionLeadTimeValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ department }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <!-- <b-select placeholder="" expanded class="box-field" v-model="department" required>
                                                <option
                                                    v-for="masterDepartment in masterDepartments"
                                                    :key='masterDepartment.NamaCabang'
                                                    :value="masterDepartment.OMOPERATINGUNITNUMBER + '-' + masterDepartment.NAME">
                                                {{ masterDepartment.NAME }}</option>
                                            </b-select> -->
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jabatan Umum<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-select
                                                    :loading="isInputLoading"
                                                    placeholder=""
                                                    expanded
                                                    class="box-field"
                                                    v-model="generalPosition"
                                                    @input="setWarrantJobclass"
                                                    required>
                                                    <option
                                                        v-for="position in jobClassPositions"
                                                        :key="position.id"
                                                        :value="position">
                                                    {{position.nama}}</option>
                                                </b-select>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jabatan Khusus<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input
                                                    :loading="isInputLoading"
                                                    v-model="specificPosition"
                                                    @keypress.native="departmentPositionLeadTimeValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                </b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jabatan Teknis<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="technicalPosition"
                                                    :data="getAsyncTechnicalPositionData"
                                                    field="DESCRIPTION"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickTechnicalPosition(false)"
                                                    @select="option => setPositionCode((option ? option.JOBID : 'KODE'))"
                                                    @keypress.native="departmentPositionLeadTimeValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ technicalPosition }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Kode Jabatan<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input
                                                    :loading="isInputLoading"
                                                    v-model="positionCode"
                                                    disabled
                                                    required>
                                                </b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="columns is-desktop is-flex is-align-items-center" id="elJobClass">
                                    <div class="column is-3">
                                        <span class="is-family-primary has-text-weight-medium has-text-left">Job Class<span class="mandatory">*</span></span>
                                    </div>
                                    <div class="column is-2">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-input
                                                :loading="isInputLoading"
                                                v-model="jobClassFrom"
                                                disabled
                                                required>
                                            </b-input>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="column is-1">
                                        <div class="is-family-primary has-text-weight-medium has-text-centered">to</div>
                                    </div>
                                    <div class="column is-2">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-input
                                                :loading="isInputLoading"
                                                v-model="jobClassTo"
                                                disabled
                                                required>
                                            </b-input>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns is-desktop">
                                    <div class="column is-9">
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Master Lead Time<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="leadTimeName"
                                                    :data="getAsyncLeadTimeData"
                                                    field="description"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickLeadTime(false)"
                                                    @select="option => setLeadTime((option ? option.id : null), (option ? option.lead_time : null))"
                                                    @keypress.native="departmentPositionLeadTimeValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ leadTimeName }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Lead Time (Hari Kalender)<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input 
                                                    :loading="isInputLoading"
                                                    v-model="leadTime"
                                                    required
                                                    disabled
                                                ></b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Usia Maksimal<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input
                                                    :loading="isInputLoading"
                                                    v-model="old"
                                                    @keypress.native="onlyNumber"
                                                    pattern="^[0-9]*"
                                                    validation-message="Hanya bisa angka"
                                                    required>
                                                </b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jenis Kelamin<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-select 
                                                    :loading="isInputLoading" 
                                                    placeholder="" 
                                                    expanded 
                                                    class="box-field" 
                                                    v-model="gender" 
                                                    @input="isGenderModal = true"
                                                    required
                                                >
                                                    <option>Laki-laki</option>
                                                    <option>Perempuan</option>
                                                    <option>Bebas</option>
                                                </b-select>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            id="elEducation"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Pendidikan<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="education"
                                                    :data="getAsyncEducationData"
                                                    field="DESCRIPTION"
                                                    @typing="setPickEducation(false, null)"
                                                    @select="option => setPickEducation(true, (option ? option.RECID : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="educationValidation"
                                                    pattern="^[A-Za-z0-9\s/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda garis miring"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ education }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            id="elEduDisc"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jurusan<span class="mandatory">*</span></span>
                                            </template>
                                            <MultiSelect
                                                :loading="isInputLoading"
                                                v-model="educationDisciplineCollection"
                                                :options="masterEducationDisciplines"
                                                :multiple="true"
                                                :taggable="false"
                                                tagPlaceholder="Data jurusan tidak ada"
                                                selectLabel="Klik / Enter untuk pilih"
                                                selectedLabel="Sudah terpilih"
                                                deselectLabel="Klik / Enter untuk hapus"
                                                placeholder="Ketik dan pilih dari dropdown"
                                                label="DESCRIPTION"
                                                track-by="RECID"
                                                @select="checkEducationDiscipline"
                                                @remove="checkRemoveEducationDiscipline"
                                                :blockKeys="['Delete']"
                                            >
                                                <template #caret="{toggle}">
                                                    <div
                                                        @mousedown.prevent.stop="toggle()"
                                                        class="arrow_custom"
                                                    >
                                                        <b-icon
                                                            pack="fas"
                                                            icon="chevron-down"
                                                            type="is-info-light"
                                                            size="is-small"
                                                        >
                                                        </b-icon>
                                                    </div>
                                                </template>
                                                <span slot="noResult">Tidak ada data sesuai inputan anda</span>
                                                <span slot="noOptions">Error! Data jurusan tidak termuat</span>
                                            </MultiSelect>
                                        </b-field>
                                    </div>
                                </div>
                                <div
                                    class="columns is-desktop"
                                    v-if="educationDisciplineCollection.length == 0"
                                >
                                    <div class="column is-5 is-offset-3">
                                        <b-field class="is-flex is-align-items-center">
                                            <p class="is-size-7 has-text-danger">Belum ada data jurusan yang ditambahkan</p>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="columns is-desktop">
                                    <div class="column is-9">
                                    <!-- </div>
                                </div>
                                <div class="columns is-desktop">
                                    <div class="column is-9">
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            id="elEduDisc"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jurusan<span class="mandatory">*</span></span>
                                            </template>
                                            <b-autocomplete
                                                :loading="isInputLoading"
                                                open-on-focus
                                                v-model="educationDiscipline"
                                                :data="getAsyncEducationDisciplineData"
                                                field="DESCRIPTION"
                                                @typing="setPickEducationDiscipline(false, null, null)"
                                                @select="option => setPickEducationDiscipline(true, (option ? option.RECID : null), (option ? option.DESCRIPTION : null))"
                                                placeholder="Ketik dan pilih dari dropdown"
                                                @keypress.native="educationDisciplineValidation"
                                                pattern="^[A-Za-z0-9\s/\.&()-]*"
                                                validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: / . & ( ) -">
                                                <template slot="empty">
                                                    Tidak ditemukan hasil untuk
                                                    {{ educationDiscipline }}
                                                </template>
                                            </b-autocomplete>
                                        </b-field>
                                    </div>
                                </div>
                                <div
                                    class="columns is-desktop"
                                >
                                    <div class="column is-5 is-offset-3">
                                        <b-field class="is-flex is-align-items-center">
                                            <p class="is-size-7 has-text-danger" v-if="educationDisciplineCollection.length == 0">Belum ada data jurusan yang ditambahkan</p>
                                            <p class="is-size-7 has-text-primary" v-if="educationDisciplineCollection.length > 0">Data jurusan yang telah ditambahkan:</p>
                                        </b-field>
                                    </div>
                                </div>
                                <div
                                    v-for="(eduDisc, index) in educationDisciplineCollection"
                                    :key="eduDisc.jurusan_id"
                                    :class="educationDisciplineCollection.length > 0 ? 'columns is-desktop mb-5' : 'columns is-desktop is-hidden'"
                                >
                                    <div class="column is-5 is-offset-3">
                                        <b-field class="is-flex is-align-items-center">
                                            <b-input
                                                :value="eduDisc.jurusan"
                                                expanded
                                                readonly>
                                            </b-input>
                                            <p class="control">
                                                <b-button
                                                    type="is-danger"
                                                    label="X"
                                                    @click="deleteEduDisc(index)"
                                                />
                                            </p>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="columns is-desktop">
                                    <div class="column is-9"> -->
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Pengalaman Kerja<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-select :loading="isInputLoading" placeholder="" expanded class="box-field" v-model="experience" required>
                                                    <option>Freshgraduate</option>
                                                    <option>Experience</option>
                                                </b-select>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                    </div>
                                </div>
                                <div
                                    v-if="experience == 'Experience'"
                                    :key="experience"
                                    :class="experience == 'Experience' ? 'columns is-desktop mb-5' : 'columns is-desktop is-hidden'"
                                >
                                    <div class="column is-8 is-offset-1">
                                        <b-field class="hz-label-right" label="" horizontal>
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Tahun<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input 
                                                    v-model="yearExperience"
                                                    @keypress.native="onlyNumber"
                                                    pattern="^[0-9]*"
                                                    validation-message="Hanya bisa angka"
                                                    maxlength="2"
                                                    :required="experience == 'Experience'"
                                                ></b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="columns is-desktop">
                                    <div class="column is-9">
                                        <b-field 
                                            class="hz-label-master mt-2 is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jumlah Hari Kerja<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-radio
                                                    v-model="workingDays"
                                                    name="workingDays"
                                                    required
                                                    native-value=5>
                                                    5 hari
                                                </b-radio>
                                                <b-radio
                                                    v-model="workingDays"
                                                    name="workingDays"
                                                    required
                                                    native-value=6>
                                                    6 hari
                                                </b-radio>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master my-5 is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Shift<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-radio
                                                    v-model="shift"
                                                    name="shift"
                                                    required
                                                    native-value=NS>
                                                    Non Shift
                                                </b-radio>
                                                <b-radio
                                                    v-model="shift"
                                                    name="shift"
                                                    required
                                                    native-value=2S>
                                                    2 Shift
                                                </b-radio>
                                                <b-radio
                                                    v-model="shift"
                                                    name="shift"
                                                    required
                                                    native-value=3S>
                                                    3 Shift
                                                </b-radio>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Keterampilan Khusus<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input
                                                    :loading="isInputLoading"
                                                    v-model="skill"
                                                    @keypress.native="skillDescriptionValidation"
                                                    pattern="^[A-Za-z0-9\s,.()]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: , . ( )"
                                                    required>
                                                </b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Deskripsi Pekerjaan<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input
                                                    :loading="isInputLoading"
                                                    v-model="description"
                                                    @keypress.native="skillDescriptionValidation"
                                                    pattern="^[A-Za-z0-9\s,.()]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: , . ( )"
                                                    required>
                                                </b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <!-- <b-field 
                                            class="hz-label-master mt-5 is-flex is-align-items-center" 
                                            v-if="canWarrant"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Flag Garansi<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-radio
                                                    v-model="warranty"
                                                    name="warranty"
                                                    required
                                                    native-value="Ya">
                                                    Ya
                                                </b-radio>
                                                <b-radio
                                                    v-model="warranty"
                                                    name="warranty"
                                                    required
                                                    native-value="Tidak">
                                                    Tidak
                                                </b-radio>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field> -->
                                    </div>
                                </div>
                                <!-- <div class="columns is-desktop mb-5">
                                    <div class="column is-8 is-offset-1" v-if="warranty == 'Ya' && canWarrant">
                                        <b-field class="hz-label-right" label="" horizontal>
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Masa Garansi<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }" v-if="warranty == 'Ya'">
                                                <b-input 
                                                    v-model="warrantyPeriod"
                                                    @keypress.native="onlyNumber"
                                                    pattern="^[0-9]*"
                                                    maxlength="2"
                                                    placeholder="hari"
                                                    validation-message="Hanya bisa angka"
                                                    :required="warranty == 'Ya'"
                                                ></b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                    </div>
                                </div> -->
                                <b-field class="has-text-centered mt-5">
                                    <b-button
                                        :loading="isSubmitLoading"
                                        class="is-size-5 has-background-success has-text-white has-text-weight-bold"
                                        @click="updateId ? updatePosition() : createPosition()">Simpan</b-button>
                                </b-field>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <b-modal has-modal-card v-model="isLeadTimeModal" :on-cancel="resetLeadTime">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Master lead time</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isApproveModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
                            Apakah Anda yakin menentukan jabatan {{this.technicalPosition}} memiliki lead time {{this.leadTime}} hari?
                        </div>
                        <div class="columns mt-4">
                            <div class="column is-3 is-offset-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-success has-text-white has-text-weight-semibold"
                                        @click="isLeadTimeModal = false"
                                    >YA</b-button>
                                </b-field>
                            </div>
                            <div class="column is-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-danger-light has-text-white has-text-weight-semibold"
                                        @click="resetLeadTime"
                                    >TIDAK</b-button>
                                </b-field>
                            </div>
                        </div>
                    </section>
                </div>
        </b-modal>
        <b-modal has-modal-card v-model="isGenderModal" :on-cancel="resetGender">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Jenis kelamin</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isApproveModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
                            Apakah Anda yakin menentukan jabatan {{this.technicalPosition}} memiliki jenis kelamin {{this.gender}}?
                        </div>
                        <div class="columns mt-4">
                            <div class="column is-3 is-offset-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-success has-text-white has-text-weight-semibold"
                                        @click="isGenderModal = false"
                                    >YA</b-button>
                                </b-field>
                            </div>
                            <div class="column is-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-danger-light has-text-white has-text-weight-semibold"
                                        @click="resetGender"
                                    >TIDAK</b-button>
                                </b-field>
                            </div>
                        </div>
                    </section>
                </div>
        </b-modal>
        <b-modal has-modal-card v-model="isEducationModal" :on-cancel="resetEducation">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Pendidikan</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isApproveModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
                            Apakah Anda yakin menentukan jabatan {{this.technicalPosition}} memiliki pendidikan {{this.education}}?
                        </div>
                        <div class="columns mt-4">
                            <div class="column is-3 is-offset-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-success has-text-white has-text-weight-semibold"
                                        @click="isEducationModal = false"
                                    >YA</b-button>
                                </b-field>
                            </div>
                            <div class="column is-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-danger-light has-text-white has-text-weight-semibold"
                                        @click="resetEducation"
                                    >TIDAK</b-button>
                                </b-field>
                            </div>
                        </div>
                    </section>
                </div>
        </b-modal>
    </section>
</template>

<script>
export default {
    data() {
        return {
            editData: null,
            updateId: null,
            id_data: null,
            title: null,
            unit: '',
            unitId: null,
            branch: '',
            branchId: null,
            department: '',
            generalPosition: null,
            specificPosition: null,
            technicalPosition: '',
            positionCode: null,
            jobClassFrom: null,
            jobClassTo: null,
            leadTimeName: '',
            leadTimeId: null,
            masterLeadTimes: null,
            leadTime: null,
            old: null,
            gender: null,
            education: '',
            educationRecid: null,
            educationDiscipline: '',
            educationDisciplineRecid: null,
            educationDisciplineCollection: [],
            experience: null,
            yearExperience: null,
            workingDays: null,
            shift: null,
            skill: null,
            description: null,
            warranty: null,
            warrantyPeriod: null,
            isPostModal: false,
            postMessage: null,
            postResult: null,
            distinctUnits: [],
            distinctBranches: [],
            filteredBranches: [],
            masterDepartments: null,
            filteredDepartments: [],
            masterDepartmentsId: null,
            jobClassPositions: [],
            masterTechnicalPositions: [],
            masterEducations: [],
            masterEducationDisciplines: [],
            isInputLoading: false,
            isDepartmentLoading: false,
            isSubmitLoading: false,
            pickUnit: false,
            pickBranch: false,
            pickDepartment: false,
            pickTechnicalPosition: false,
            pickLeadTime: false,
            pickEducation: false,
            pickEducationDiscipline: false,
            isScrolled: false,
            canWarrant: true,
            isDisabledSemuaJurusan: false,
            isLeadTimeModal: false,
            isEducationModal: false,
            isGenderModal: false,
        };
    },
    created() {
        if (this.$route.params.routingTo == 'add') {
            this.title = 'Tambah Jabatan Baru'
        } else if (this.$route.params.routingTo == 'edit') {
            this.title = 'Ubah Jabatan'
        }

        if (this.$route.params.master) {
            this.editData = this.$route.params.master
        }
        //console.log(this.editData)
    },

    async mounted () {
        this.isInputLoading = true
        this.isDepartmentLoading = true
        this.isSubmitLoading = true
        await this.getDistinctUnits()
		await this.getDistinctBranches()
        await this.getAllDepartments()
        await this.getJobCLassByPosition()
        await this.getAllTechnicalPositions()
        await this.getAllLeadTime()
        await this.getAllEducations()
        await this.getAllEducationDisciplines()
        if (this.editData) {
            this.editingData()
        }
        this.isInputLoading = false
        this.isDepartmentLoading = false
        this.isSubmitLoading = false
    },

    computed: {
        getAsyncLeadTimeData () {
            if (this.pickLeadTime == false) {
                return this.masterLeadTimes.filter((option) => {
                    if (option.description) {
                        return option.description
                            .toString()
                            .toLowerCase()
                            .indexOf(this.leadTimeName.toLowerCase()) >= 0
                    } else {
                        return
                    }
                })
            } else {
                return this.masterLeadTimes.filter((option) => {
                    if (option.description) {
                        return true
                    } else {
                        return
                    }
                })
            }
        },

        getAsyncEducationData() {
            if (this.pickEducation == false) {
                return this.masterEducations.filter((option) => {
                    return option.DESCRIPTION
                        .toString()
                        .toLowerCase()
                        .indexOf(this.education.toLowerCase()) >= 0
                })
            } else {
                return this.masterEducations
            }
        },

        getAsyncTechnicalPositionData() {
            if (this.pickTechnicalPosition == false) {
                return this.masterTechnicalPositions.filter((option) => {
                    return option.DESCRIPTION
                        .toString()
                        .toLowerCase()
                        .indexOf(this.technicalPosition.toLowerCase()) >= 0
                })
            } else {
                return this.masterTechnicalPositions
            }
        },

        getAsyncDepartmentData() {
            if (this.pickDepartment == false) {
                return this.filteredDepartments.filter((option) => {
                    return option.dept_khusus
                        .toString()
                        .toLowerCase()
                        .indexOf(this.department.toLowerCase()) >= 0
                })
            } else {
                return this.filteredDepartments
            }
        },

        getAsyncBranchData() {
            if (this.pickBranch == false) {
                return this.filteredBranches.filter((option) => {
                    return option.cabang
                        .toString()
                        .toLowerCase()
                        .indexOf(this.branch.toLowerCase()) >= 0
                })
            } else {
                return this.filteredBranches
            }
        },

        getAsyncUnitData() {
            if (this.pickUnit == false) {
                return this.distinctUnits.filter((option) => {
                    return option.unit
                        .toString()
                        .toLowerCase()
                        .indexOf(this.unit.toLowerCase()) >= 0
                })
            } else {
                return this.distinctUnits
            }
        },

        getAsyncEducationDisciplineData() {
            if (this.pickEducationDiscipline == false) {
                return this.masterEducationDisciplines.filter((option) => {
                    return option.DESCRIPTION
                        .toString()
                        .toLowerCase()
                        .indexOf(this.educationDiscipline.toLowerCase()) >= 0
                })
            } else {
                return this.masterEducationDisciplines
            }
        }
    },

    methods: {
        async getAllLeadTime () {
			let postData = {
				code: 6
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/lead-times', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterLeadTimes.push(element)
					// });
					this.masterLeadTimes = response.data.result
                    //console.log(this.masterLeadTimes)
                })
                .catch( e => {
                    console.log(e)
                })
        },
        
        setLeadTime (id, leadTime) {
            if (id) {
                this.leadTimeId = id
                this.leadTime = leadTime
                this.setPickLeadTime(true)
                this.isLeadTimeModal = true
            }
        },

        resetLeadTime () {
            this.isLeadTimeModal = false
            this.leadTimeId = null
            this.leadTime = null
            this.leadTimeName = ''
            this.setPickLeadTime(false)
        },
        
        resetGender () {
            this.gender = null
            this.isGenderModal = false
        },

        checkRemoveEducationDiscipline (option) {
            if (option.DESCRIPTION.toLowerCase() == "semua jurusan") {
                this.masterEducationDisciplines.forEach( element => {
                    element.$isDisabled = false
                })
            }
            if (this.educationDisciplineCollection.length <= 1) {
                this.masterEducationDisciplines.forEach( element => {
                    if (element.DESCRIPTION.toLowerCase() == "semua jurusan") {
                        element.$isDisabled = false
                    }
                })
                this.isDisabledSemuaJurusan = false
            }
        },

        checkEducationDiscipline (option) {
            if (option.DESCRIPTION.toLowerCase() == "semua jurusan") {
                this.masterEducationDisciplines.forEach( element => {
                    if (element.DESCRIPTION.toLowerCase() != "semua jurusan") {
                        element.$isDisabled = true
                    }
                })
            } else {
                if (this.isDisabledSemuaJurusan == false) {
                    this.masterEducationDisciplines.forEach( element => {
                        if (element.DESCRIPTION.toLowerCase() == "semua jurusan") {
                            element.$isDisabled = true
                        }
                    })
                    this.isDisabledSemuaJurusan = true
                }
            }
        },
        // deleteEduDisc (index) {
        //     this.educationDisciplineCollection.splice(index, 1)
        //     console.log(this.educationDisciplineCollection)
        // },

        setWarrantJobclass (positionObject) {
            if (positionObject.nama == 'Clerk' || positionObject.nama == 'SDP') {
                this.canWarrant = true
                this.warranty = null
                this.warrantyPeriod = null
            } else {
                this.canWarrant = false
                this.warranty = "Tidak"
                this.warrantyPeriod = null
            }

            this.jobClassFrom = positionObject.job_class_from
            this.jobClassTo = positionObject.job_class_to
        },
        
        // setPickEducationDiscipline (selecting, recid, description) {
        //     this.pickEducationDiscipline = selecting
        //     this.educationDisciplineRecid = recid

        //     if (recid && description) {
        //         let exists = this.educationDisciplineCollection.some(function(field) {
        //             return field.jurusan_id === recid
        //             });
                
        //         if (!exists) {
        //             let collection = {
        //                 jurusan: description,
        //                 jurusan_id: recid
        //             }
        //             this.educationDisciplineCollection.push(collection)
        //             console.log(JSON.stringify(this.educationDisciplineCollection))
        //         } else {
        //             this.$buefy.snackbar.open({
		// 				message: 'Jurusan ' + description + ' sudah ada',
		// 				type: 'is-info',
		// 				position: 'is-top',
		// 				actionText: 'Tutup',
		// 				indefinite: false,
        //             })
        //         }
        //     }
        // },

        setPickLeadTime (selecting) {
            this.pickLeadTime = selecting
        },

        setPickEducation (selecting, recid) {
            this.pickEducation = selecting
            if (recid) {
                this.educationRecid = recid
                this.isEducationModal = true
            }
        },

        resetEducation () {
            this.isEducationModal = false
            this.pickEducation = false
            this.educationRecid = null
            this.education = ''
        },

        setPickTechnicalPosition (selecting) {
            this.pickTechnicalPosition = selecting
        },

        setPickDepartment (selecting) {
            this.pickDepartment = selecting
        },

        setPickBranch (selecting) {
            this.pickBranch = selecting
        },

        setPickUnit (selecting) {
            this.pickUnit = selecting
        },

        setPositionCode (positionCode) {
            this.positionCode = positionCode
            this.setPickTechnicalPosition(true)
        },
        
        setDepartmentId (departmentId) {
            this.masterDepartmentsId = departmentId
            this.setPickDepartment(true)
            //console.log(departmentId)
        },

        filterDepartments (branch) {
            this.filteredDepartments = this.masterDepartments.filter((option) => {
                return option.cabang == branch && option.unit == this.unit
            })
            if (this.branch != branch) {
				this.department = ''
            }
            this.setPickBranch(true)
        },

        filterBranches (unit) {
            this.filteredBranches = this.distinctBranches.filter((option) => {
                return option.unit == unit
            })
            if (this.unit != unit) {
				this.branch = ''
				this.department = ''
            }
            this.setPickUnit(true)
        },

        async getAllDepartments () {
            let postData = {
                code: 6
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterDepartments.push(element)
                    // });
                    this.masterDepartments = response.data.result
                    //console.log(this.masterDepartments)
                })
                .catch( e => {
                    console.log(e)
                })
        },
        
        async getDistinctBranches () {
            let postData = {
                code: 8
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
					response.data.result.forEach(element => {
						this.distinctBranches.push(element)
					})
                    //console.log(this.distinctBranches)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getDistinctUnits () {
            let postData = {
                code: 7
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
					response.data.result.forEach(element => {
						this.distinctUnits.push(element)
					})
                    //console.log(this.distinctUnits)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async editingData () {
            let arrayPengalaman = this.editData.pengalaman.split(" ")

            this.updateId = this.editData.id
            this.unit = this.editData.unit
            this.branch = this.editData.cabang
            this.department = this.editData.dept_khusus
            this.masterDepartmentsId = this.editData.id_master_departemen
            this.filterBranches(this.unit)
            this.filterDepartments(this.branch)
            let tempGeneralPosition = this.jobClassPositions.filter((option) => {
                return option.nama
                    .toString()
                    .toLowerCase()
                    .indexOf(this.editData.jab_umum.toLowerCase()) >= 0
            })
            this.generalPosition = tempGeneralPosition[0]
            this.specificPosition = this.editData.jab_khusus
            this.technicalPosition = this.editData.jab_teknis
            this.positionCode = this.editData.kode_jabatan
            this.jobClassFrom = this.editData.job_class_from
            this.jobClassTo = this.editData.job_class_to
            this.leadTimeName = this.editData.description
            this.leadTimeId = this.editData.id_master_lead_time
            this.leadTime = this.editData.lead_time
            this.old = this.editData.usia
            this.gender = this.editData.jenis_kelamin
            this.education = this.editData.jenjang_pendidikan
            this.educationRecid = this.editData.jenjang_pendidikan_id
            this.educationDiscipline = this.editData.jurusan
            this.educationDisciplineRecid = this.editData.jurusan_id

            let description = this.educationDiscipline.split(", ")
            let recid = this.educationDisciplineRecid.split("||")
            
            for (let i = 0; i < description.length; i++) {
                let tempCollection = {
                    DESCRIPTION: description[i],
                    RECID: recid[i]
                }
                this.educationDisciplineCollection.push(tempCollection)
            }

            if (this.educationDisciplineCollection.length == 1) {
                if (this.educationDisciplineCollection[0].DESCRIPTION.toLowerCase() != "semua jurusan") {
                    this.masterEducationDisciplines.forEach( element => {
                        if (element.DESCRIPTION.toLowerCase() == "semua jurusan") {
                            element.$isDisabled = true
                        }
                    })
                    this.isDisabledSemuaJurusan = true
                } else if (this.educationDisciplineCollection[0].DESCRIPTION.toLowerCase() == "semua jurusan") {
                    this.masterEducationDisciplines.forEach( element => {
                        if (element.DESCRIPTION.toLowerCase() != "semua jurusan") {
                            element.$isDisabled = true
                        }
                    })
                    this.isDisabledSemuaJurusan = false
                }
            } else if (this.educationDisciplineCollection.length > 1) {
                this.masterEducationDisciplines.forEach( element => {
                    if (element.DESCRIPTION.toLowerCase() == "semua jurusan") {
                        element.$isDisabled = true
                    }
                })
                this.isDisabledSemuaJurusan = true
            }

            this.experience = arrayPengalaman[0]
            this.yearExperience = arrayPengalaman[1]
            this.workingDays = this.editData.jumlah_hari_kerja
            this.shift = this.editData.shift
            this.skill = this.editData.keterampilan_khusus
            this.description = this.editData.deskripsi_pekerjaan
            this.warranty = this.editData.flag_garansi
            this.warrantyPeriod = this.editData.masa_garansi
            this.pickUnit = true
            this.pickBranch = true
            this.pickDepartment = true
            this.pickTechnicalPosition = true
            this.pickLeadTime = true
            this.pickEducation = true
            this.pickEducationDiscipline = true
            //this.setWarrantJobclass()
            this.editData = null
        },

        async updatePosition () {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
                    })
                    
                    const errors = Object.entries(this.$refs.observer.errors)
						.map(([key, value]) => ({ key, value }))
						.filter(error => error["value"].length);
					
					if (errors.length > 0 && !this.isScrolled) {
						this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
							behavior: "smooth",
							block: "center"
						});
						this.isScrolled = true
                    }
                    this.isScrolled = false
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (this.educationDisciplineCollection.length == 0) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon menambahkan data jurusan minimal 1',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                            if (!this.isScrolled) {
                                document.getElementById('elEduDisc').scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                })
                                this.isScrolled = true
                            }
                            this.isScrolled = false
                        } else if (!this.pickUnit || !this.pickBranch || !this.pickDepartment) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi Unit, Cabang, dan Bagian/Sub Bagian dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                            if (!this.isScrolled) {
                                document.getElementById('elBranch').scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                })
                                this.isScrolled = true
                            }
                            this.isScrolled = false
                        } else if (!this.pickTechnicalPosition ||
                            !this.pickLeadTime ||
                            !this.pickEducation) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi Jabatan teknis, Master lead time dan Pendidikan dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                            if (!this.isScrolled) {
                                document.getElementById('elEducation').scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                })
                                this.isScrolled = true
                            }
                            this.isScrolled = false
                        } else if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            let formYearExperience = ''
                            if (this.experience == 'Experience') {
                                formYearExperience = ' ' + this.yearExperience + ' tahun'
                            }
                            if (this.warranty == "Tidak") {
                                this.warrantyPeriod = null
                            }

                            let postData = {
                                id_master_departemen : this.masterDepartmentsId,
                                id_master_lead_time : this.leadTimeId,
                                unit : this.unit,
                                cabang : this.branch,
                                departemen : this.department,
                                jab_umum : this.generalPosition.nama,
                                jab_khusus : this.specificPosition,
                                jab_teknis : this.technicalPosition,
                                kode_jabatan : this.positionCode,
                                job_class_from : this.jobClassFrom,
                                job_class_to : this.jobClassTo,
                                usia : this.old,
                                jenis_kelamin : this.gender,
                                jenjang_pendidikan : this.education,
                                jenjang_pendidikan_id : this.educationRecid,
                                pengalaman : this.experience + formYearExperience,
                                jurusan_array : this.educationDisciplineCollection,
                                jumlah_hari_kerja : this.workingDays,
                                shift : this.shift,
                                keterampilan_khusus : this.skill,
                                deskripsi_pekerjaan : this.description,
                                flag_garansi : this.warranty,
                                masa_garansi : this.warrantyPeriod,
                                code: 4,
                                id: this.updateId
                            }
                            let iconType = ''
                            let titleMessage = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/positions`, postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data jabatan berhasil diubah',
                                    textMessage = response.data.result.jab_khusus + ' - ' + response.data.result.jab_teknis
                                    iconColor = 'is-success'
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-jabatan'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal mengubah data jabatan!'
                                        if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            text: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        },

        skillDescriptionValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s,.()]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async getAllEducationDisciplines () {
            let postData = {
                code: 2
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'hrms', postData)
                .then( response => {
                    let insertedSemuaJurusan = false
                    response.data.result.forEach(element => {
                        if (insertedSemuaJurusan) {
                            return false
                        }
                        if (element.DESCRIPTION.toLowerCase() == "semua jurusan") {
                            this.masterEducationDisciplines.push(element)
                            insertedSemuaJurusan = true
                        }
                        //console.log(insertedSemuaJurusan)
                    });
                    response.data.result.forEach(element => {
                        if (element.DESCRIPTION.toLowerCase() != "semua jurusan") {
                            this.masterEducationDisciplines.push(element)
                        }
                    });
                    //console.log(this.masterEducationDisciplines.length)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        educationDisciplineValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s/\.&()-]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async getAllEducations () {
            let postData = {
                code: 1
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'hrms', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.masterEducations.push(element)
                    });
                    //console.log(this.masterEducations)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        educationValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s/]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        // getAsyncEducationData (name) {
        //     this.tempEducations = []
        //     this.tempEducations = this.masterEducations.filter((option) => {
        //         return option.DESCRIPTION.toString().toLowerCase().indexOf(name.toLowerCase()) >= 0
        //     })
        //     console.log(this.tempEducations)
        // },

        async getAllTechnicalPositions () {
            let postData = {
                code: 6
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'hrms', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.masterTechnicalPositions.push(element)
                    });
                    //console.log(this.masterTechnicalPositions)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getJobCLassByPosition () {
            let postData = {
                code: 12
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.jobClassPositions.push(element)
                    });
                })
                .catch( e => {
                    console.log(e)
                })
        },

        // async getAllJobClasses () {
        //     let postData = {
        //         code: 4
        //     }
        //     await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'hrms', postData)
        //         .then( response => {
        //             response.data.result.forEach(element => {
        //                 this.masterJobClasses.push(element)
        //             });
        //             //console.log(this.masterJobClasses)
        //         })
        //         .catch( e => {
        //             console.log(e)
        //         })
        // },

        departmentPositionLeadTimeValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) { //including . - & ( )
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        onlyNumber($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[0-9]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        onlyAlphaNum($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        // getAsyncDepartmentData (name) {
        //     this.tempDepartments = []
        //     this.tempDepartments = this.masterDepartments.filter((option) => {
        //         return option.NAME.toString().toLowerCase().indexOf(name.toLowerCase()) >= 0
        //     })
        //     console.log(this.tempDepartments)
        // },

        // getAsyncTechnicalPositionData (name) {
        //     this.tempTechnicalPositions = []
        //     this.tempTechnicalPositions = this.masterTechnicalPositions.filter((option) => {
        //         return option.DESCRIPTION.toString().toLowerCase().indexOf(name.toLowerCase()) >= 0
        //     })
        //     console.log(this.tempTechnicalPositions)
        // },

        async prepDepartments (level, id) {
            if (level == 'unit' && id) {
                this.unitId = id
                //console.log(this.unitId)
            } else if (level == 'branch' && id) {
                this.branchId = id
                //console.log(this.branchId)
            }
            if (this.unitId && this.branchId) {
                await this.getDepartmentsByUnitBranch(this.unitId, this.branchId)
            }
        },

        async getDepartmentsByUnitBranch (unitID, branchID) {
            this.isDepartmentLoading = true
            let postData = {
                Data: {
                    code: '7',
                    id_unit: unitID,
                    id_cabang: branchID
                }
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
                .then( response => {
                    if (!this.editData) {
                        this.department = ''
                    }
                    this.masterDepartments = []
                    response.data.Result.forEach(element => {
                        this.masterDepartments.push(element)
                    });
                    //console.log(this.masterDepartments)
                })
                .catch( e => {
                    this.department = ''
                    this.masterDepartments = []
                    console.log(e)
                })
            this.isDepartmentLoading = false
        },

        async getAllUnits () {
            let postData = {
                Data: {
                    code: '1'
                }
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
                .then( response => {
                    response.data.Result.forEach(element => {
                        this.masterUnits.push(element)
                    });
                    //console.log(this.masterUnits)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getAllBranches () {
            let postData = {
                Data: {
                    code: '2'
                }
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
                .then( response => {
                    response.data.Result.forEach(element => {
                        this.masterBranches.push(element)
                    });
                    //console.log(this.masterBranches)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        goBack() {
            this.$router.push({name: 'master-jabatan'})
        },

        emptyField () {
            this.unit = ''
            this.unitId = null
            this.branch = ''
            this.branchId = null
            this.department = ''
            this.generalPosition = null
            this.specificPosition = null
            this.technicalPosition = ''
            this.positionCode = 'KODE'
            this.jobClassFrom = null
            this.jobClassTo = null
            this.old = null
            this.gender = null
            this.education = ''
            this.educationDiscipline = ''
            this.experience = null
            this.yearExperience = null
            this.workingDays = null
            this.shift = null
            this.skill = null
            this.description = null
            this.warranty = null
            this.warrantyPeriod = null
        },

        async createPosition () {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
                    })
                    
                    const errors = Object.entries(this.$refs.observer.errors)
						.map(([key, value]) => ({ key, value }))
						.filter(error => error["value"].length);
					
					if (errors.length > 0 && !this.isScrolled) {
						this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
							behavior: "smooth",
							block: "center"
						});
						this.isScrolled = true
                    }
                    this.isScrolled = false
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (this.educationDisciplineCollection.length == 0) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon menambahkan data jurusan minimal 1',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                            if (!this.isScrolled) {
                                document.getElementById('elEduDisc').scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                })
                                this.isScrolled = true
                            }
                            this.isScrolled = false
                        } else if (!this.pickUnit || !this.pickBranch || !this.pickDepartment) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi Unit, Cabang, dan Bagian/Sub Bagian dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                            if (!this.isScrolled) {
                                document.getElementById('elBranch').scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                })
                                this.isScrolled = true
                            }
                            this.isScrolled = false
                        } else if (!this.pickTechnicalPosition ||
                            !this.pickLeadTime ||
                            !this.pickEducation) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi Jabatan teknis, Master lead time dan Pendidikan dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                            if (!this.isScrolled) {
                                document.getElementById('elEducation').scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                })
                                this.isScrolled = true
                            }
                            this.isScrolled = false
                        } else if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            let formYearExperience = ''
                            if (this.experience == 'Experience') {
                                formYearExperience = ' ' + this.yearExperience + ' tahun'
                            }
                            if (this.warranty == "Tidak") {
                                this.warrantyPeriod = null
                            }

                            let postData = {
                                id_master_departemen : this.masterDepartmentsId,
                                id_master_lead_time : this.leadTimeId,
                                unit : this.unit,
                                cabang : this.branch,
                                departemen : this.department,
                                jab_umum : this.generalPosition.nama,
                                jab_khusus : this.specificPosition,
                                jab_teknis : this.technicalPosition,
                                kode_jabatan : this.positionCode,
                                job_class_from : this.jobClassFrom,
                                job_class_to : this.jobClassTo,
                                usia : this.old,
                                jenis_kelamin : this.gender,
                                jenjang_pendidikan : this.education,
                                jenjang_pendidikan_id : this.educationRecid,
                                pengalaman : this.experience + formYearExperience,
                                jurusan_array : this.educationDisciplineCollection,
                                jumlah_hari_kerja : this.workingDays,
                                shift : this.shift,
                                keterampilan_khusus : this.skill,
                                deskripsi_pekerjaan : this.description,
                                flag_garansi : this.warranty,
                                masa_garansi : this.warrantyPeriod,
                                code: 3
                            }
                            //console.log(JSON.stringify(postData))
                            let iconType = ''
                            let titleMessage = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data jabatan baru berhasil disimpan',
                                    textMessage = response.data.result.jab_khusus + ' - ' + response.data.result.jab_teknis
                                    iconColor = 'is-success'
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-jabatan'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal menyimpan data jabatan!'
                                        if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            text: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        }
    }
};
</script>
