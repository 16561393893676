<template>
    <section>
        <div class="container hero">
            <div class="mx-5">
                <b-field class="has-text-weight-semibold has-text-primary is-size-3">
                    Master Pejabat Approval
                </b-field>
                <b-button
                    icon-pack="fas"
                    icon-left="angle-left"
                    class="has-background-warning has-text-white has-text-weight-semibold mb-5"
                    @click="goBack()"
                >KEMBALI
                </b-button>
                <div class="ml-5">
                    <div class="has-text-weight-medium has-text-primary is-size-4 mt-1">
                        {{ title }}
                    </div>
                    <ValidationObserver ref="observer">
                        <form ref="form">
                            <div class="ml-5 mt-5 pt-2 has-text-weight-medium has-text-primary">
                                <div class="columns is-desktop">
                                    <div class="column is-12">
                                        <b-field 
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jenis PK<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-select :loading="isInputLoading" placeholder="" expanded class="box-field" v-model="pkType" @input="getUserByPKType()">
                                                    <option>User</option>
                                                    <option>Wilayah Baru (Cabang / DCI)</option>
                                                    <option>Referensi</option>
                                                </b-select>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div style="width: 132px"></div>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Unit/ PT<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="unit"
                                                    :data="getAsyncUnitData"
                                                    field="unit"
                                                    @typing="setPickUnit(false)"
                                                    @select="option => filterBranches((option ? option.unit : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentPositionValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ unit }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div style="width: 72px"></div>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Cabang<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="branch"
                                                    :data="getAsyncBranchData"
                                                    field="cabang"
                                                    @typing="setPickBranch(false)"
                                                    @select="option => filterDepartments((option ? option.cabang : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentPositionValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ branch }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div style="width: 72px"></div>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Bagian/Sub Bagian<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="department"
                                                    :data="getAsyncDepartmentData"
                                                    field="dept_khusus"
                                                    @typing="setPickDepartment(false)"
                                                    @select="option => setDepartmentId(option ? option.id : null)"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentPositionValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ department }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div style="width: 72px"></div>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Jabatan Umum<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputPositionLoading"
                                                    open-on-focus
                                                    v-model="generalPosition"
                                                    :data="getAsyncGeneralPositionData"
                                                    @typing="setPickGeneralPosition(false)"
                                                    @select="setPickGeneralPosition(true)"
                                                    field="jab_umum"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentPositionValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ generalPosition }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div style="width: 72px"></div>
                                        </b-field>
                                        <b-field 
                                            v-if="generalPosition == 'Clerk'"
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Alasan Permintaan<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-select :loading="isInputLoading" placeholder="" expanded class="box-field" v-model="reason">
                                                    <option value="1">Permintaan Baru</option>
                                                    <option value="2">Pengganti Keluar/Mutasi/Promosi</option>
                                                </b-select>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div style="width: 172px"></div>
                                        </b-field>  
                                        <b-field 
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Dibuat<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isCreatorLoading"
                                                    open-on-focus
                                                    v-model="creator"
                                                    :data="getAsyncEmployeesCreator"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickCreator(false)"
                                                    @select="option => setPickCreator(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip"
                                                    :disabled="isCreatorDisabled"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ creator }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div style="width: 72px"></div>
                                        </b-field>
                                        <b-field 
                                            v-for="structural in totalStructural" :key="structural"
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Disetujui Struktural {{ structural }}<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }" v-if="structural == 1">
                                                <b-autocomplete
                                                    :loading="isStructural1Loading"
                                                    open-on-focus
                                                    v-model="structural1"
                                                    :data="getAsyncEmployeesStructural1"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickStructural1(false)"
                                                    @select="option => setPickStructural1(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ structural1 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="structural == 2">
                                                <b-autocomplete
                                                    :loading="isStructural2Loading"
                                                    open-on-focus
                                                    v-model="structural2"
                                                    :data="getAsyncEmployeesStructural2"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickStructural2(false)"
                                                    @select="option => setPickStructural2(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ structural2 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="structural == 3">
                                                <b-autocomplete
                                                    :loading="isStructural3Loading"
                                                    open-on-focus
                                                    v-model="structural3"
                                                    :data="getAsyncEmployeesStructural3"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickStructural3(false)"
                                                    @select="option => setPickStructural3(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ structural3 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="structural == 4">
                                                <b-autocomplete
                                                    :loading="isStructural4Loading"
                                                    open-on-focus
                                                    v-model="structural4"
                                                    :data="getAsyncEmployeesStructural4"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickStructural4(false)"
                                                    @select="option => setPickStructural4(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ structural4 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="structural == 5">
                                                <b-autocomplete
                                                    :loading="isStructural5Loading"
                                                    open-on-focus
                                                    v-model="structural5"
                                                    :data="getAsyncEmployeesStructural5"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickStructural5(false)"
                                                    @select="option => setPickStructural5(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ structural5 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="structural == 6">
                                                <b-autocomplete
                                                    :loading="isStructural6Loading"
                                                    open-on-focus
                                                    v-model="structural6"
                                                    :data="getAsyncEmployeesStructural6"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickStructural6(false)"
                                                    @select="option => setPickStructural6(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ structural6 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div>
                                                <b-button
                                                    v-if="structural == 1"
                                                    :loading="isAddSubstractStructural"
                                                    class="has-background-success has-text-white has-text-weight-bold custom-circle-button"
                                                    @click="addStructural()"
                                                >+</b-button>
                                                <b-button
                                                    v-if="structural != 1"
                                                    :loading="isAddSubstractStructural"
                                                    class="has-background-danger has-text-white has-text-weight-bold custom-circle-button"
                                                    @click="substractStructural(structural)"
                                                >-</b-button>
                                                <div style="width: 72px"></div>
                                            </div>
                                        </b-field>
                                        <b-field
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <b-checkbox v-model="noFunctional">
                                                Tidak diperlukan pejabat fungsional
                                            </b-checkbox>
                                        </b-field>
                                        <span v-if="!noFunctional">
                                            <b-field 
                                                v-for="functional in totalFunctional"
                                                :key="functional"
                                                class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Disetujui Fungsional {{ functional }}<span class="mandatory">*</span></span>
                                                </template>
                                                <ValidationProvider v-slot="{ errors }" v-if="functional == 1">
                                                    <b-autocomplete
                                                        :loading="isFunctional1Loading"
                                                        open-on-focus
                                                        v-model="functional1"
                                                        :data="getAsyncEmployeesFunctional1"
                                                        :custom-formatter="option => option.username + ' - ' + option.name"
                                                        placeholder="Ketik dan pilih dari dropdown"
                                                        @typing="setPickFunctional1(false)"
                                                        @select="option => setPickFunctional1(true, option ? option.jabatan : null)"
                                                        @keypress.native="userValidation"
                                                        pattern="^[A-Za-z0-9\s-]*"
                                                        validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                        <template slot="empty">
                                                            Tidak ditemukan hasil untuk
                                                            {{ functional1 }}
                                                        </template>
                                                    </b-autocomplete>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                                <ValidationProvider v-slot="{ errors }" v-if="functional == 2">
                                                    <b-autocomplete
                                                        :loading="isFunctional2Loading"
                                                        open-on-focus
                                                        v-model="functional2"
                                                        :data="getAsyncEmployeesFunctional2"
                                                        :custom-formatter="option => option.username + ' - ' + option.name"
                                                        placeholder="Ketik dan pilih dari dropdown"
                                                        @typing="setPickFunctional2(false)"
                                                        @select="option => setPickFunctional2(true, option ? option.jabatan : null)"
                                                        @keypress.native="userValidation"
                                                        pattern="^[A-Za-z0-9\s-]*"
                                                        validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                        <template slot="empty">
                                                            Tidak ditemukan hasil untuk
                                                            {{ functional2 }}
                                                        </template>
                                                    </b-autocomplete>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                                <ValidationProvider v-slot="{ errors }" v-if="functional == 3">
                                                    <b-autocomplete
                                                        :loading="isFunctional3Loading"
                                                        open-on-focus
                                                        v-model="functional3"
                                                        :data="getAsyncEmployeesFunctional3"
                                                        :custom-formatter="option => option.username + ' - ' + option.name"
                                                        placeholder="Ketik dan pilih dari dropdown"
                                                        @typing="setPickFunctional3(false)"
                                                        @select="option => setPickFunctional3(true, option ? option.jabatan : null)"
                                                        @keypress.native="userValidation"
                                                        pattern="^[A-Za-z0-9\s-]*"
                                                        validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                        <template slot="empty">
                                                            Tidak ditemukan hasil untuk
                                                            {{ functional3 }}
                                                        </template>
                                                    </b-autocomplete>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                                <ValidationProvider v-slot="{ errors }" v-if="functional == 4">
                                                    <b-autocomplete
                                                        :loading="isFunctional4Loading"
                                                        open-on-focus
                                                        v-model="functional4"
                                                        :data="getAsyncEmployeesFunctional4"
                                                        :custom-formatter="option => option.username + ' - ' + option.name"
                                                        placeholder="Ketik dan pilih dari dropdown"
                                                        @typing="setPickFunctional4(false)"
                                                        @select="option => setPickFunctional4(true, option ? option.jabatan : null)"
                                                        @keypress.native="userValidation"
                                                        pattern="^[A-Za-z0-9\s-]*"
                                                        validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                        <template slot="empty">
                                                            Tidak ditemukan hasil untuk
                                                            {{ functional4 }}
                                                        </template>
                                                    </b-autocomplete>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                                <div>
                                                    <b-button
                                                        v-if="functional == 1"
                                                        :loading="isAddSubstractFunctional"
                                                        class="has-background-success has-text-white has-text-weight-bold custom-circle-button"
                                                        @click="addFunctional()"
                                                    >+</b-button>
                                                    <b-button
                                                        v-if="functional != 1"
                                                        :loading="isAddSubstractFunctional"
                                                        class="has-background-danger has-text-white has-text-weight-bold custom-circle-button"
                                                        @click="substractFunctional(functional)"
                                                    >-</b-button>
                                                    <div style="width: 72px"></div>
                                                </div>
                                            </b-field>
                                        </span>
                                        <div style="height: 15px"></div>
                                        <b-field 
                                            v-for="approved in totalApproved"
                                            :key="approved"
                                            class="hz-label-master-approval-dynamic is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Disetujui {{ approved }}<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }" v-if="approved == 1">
                                                <b-autocomplete
                                                    :loading="isApproved1Loading"
                                                    open-on-focus
                                                    v-model="approved1"
                                                    :data="getAsyncEmployeesApproved1"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickApproved1(false)"
                                                    @select="option => setPickApproved1(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    required
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ approved1 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="approved == 2">
                                                <b-autocomplete
                                                    :loading="isApproved2Loading"
                                                    open-on-focus
                                                    v-model="approved2"
                                                    :data="getAsyncEmployeesApproved2"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickApproved2(false)"
                                                    @select="option => setPickApproved2(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ approved2 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="approved == 3">
                                                <b-autocomplete
                                                    :loading="isApproved3Loading"
                                                    open-on-focus
                                                    v-model="approved3"
                                                    :data="getAsyncEmployeesApproved3"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickApproved3(false)"
                                                    @select="option => setPickApproved3(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ approved3 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="approved == 4">
                                                <b-autocomplete
                                                    :loading="isApproved4Loading"
                                                    open-on-focus
                                                    v-model="approved4"
                                                    :data="getAsyncEmployeesApproved4"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickApproved4(false)"
                                                    @select="option => setPickApproved4(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ approved4 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{ errors }" v-if="approved == 5">
                                                <b-autocomplete
                                                    :loading="isApproved5Loading"
                                                    open-on-focus
                                                    v-model="approved5"
                                                    :data="getAsyncEmployeesApproved5"
                                                    :custom-formatter="option => option.username + ' - ' + option.name"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @typing="setPickApproved5(false)"
                                                    @select="option => setPickApproved5(true, option ? option.jabatan : null)"
                                                    @keypress.native="userValidation"
                                                    pattern="^[A-Za-z0-9\s-]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ approved5 }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <div>
                                                <b-button
                                                    v-if="approved == 1"
                                                    :loading="isAddSubstractApproved"
                                                    class="has-background-success has-text-white has-text-weight-bold custom-circle-button"
                                                    @click="addApproved()"
                                                >+</b-button>
                                                <b-button
                                                    v-if="approved != 1"
                                                    :loading="isAddSubstractApproved"
                                                    class="has-background-danger has-text-white has-text-weight-bold custom-circle-button"
                                                    @click="substractApproved(approved)"
                                                >-</b-button>
                                                <div style="width: 72px"></div>
                                            </div>
                                        </b-field>
                                    </div>
                                </div>
                                <b-field class="has-text-centered mt-5">
                                    <b-button
                                        :loading="isSubmitLoading"
                                        class="is-size-5 has-background-success has-text-white has-text-weight-bold"
                                        @click="updateId ? openModal(true) : openModal(false)"
                                        >Kirim</b-button>
                                </b-field>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <b-modal has-modal-card v-model="isCreateModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Penambahan master pejabat approval</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isApproveModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
                            Apakah Anda yakin tidak ingin mengisi data berikut:
                        </div>
                        <div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
                            <ul>
                                <li
                                    v-for="emptyApprover in emptyApprovers"
                                    :key="emptyApprover.approval"
                                    :value="emptyApprover.approval"
                                    class="is-flex is-align-items-center"
                                >
                                    <b-icon
                                        pack="far"
                                        icon="dot-circle">
                                    </b-icon> {{emptyApprover.approval}}
                                </li>
                            </ul>
                        </div>
                        <div class="columns mt-4">
                            <div class="column is-3 is-offset-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-success has-text-white has-text-weight-semibold"
                                        @click="confirmation(false)"
                                    >YA</b-button>
                                </b-field>
                            </div>
                            <div class="column is-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-danger-light has-text-white has-text-weight-semibold"
                                        @click="isCreateModal = false"
                                    >TIDAK</b-button>
                                </b-field>
                            </div>
                        </div>
                    </section>
                </div>
        </b-modal>
        <b-modal has-modal-card v-model="isUpdateModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Pengubahan master pejabat approval</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isApproveModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
                            Apakah Anda yakin tidak ingin mengisi data berikut:
                        </div>
                        <div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
                            <ul>
                                <li
                                    v-for="emptyApprover in emptyApprovers"
                                    :key="emptyApprover.approval"
                                    :value="emptyApprover.approval"
                                    class="is-flex is-align-items-center"
                                >
                                    <b-icon
                                        pack="far"
                                        icon="dot-circle">
                                    </b-icon> {{emptyApprover.approval}}
                                </li>
                            </ul>
                        </div>
                        <div class="columns mt-4">
                            <div class="column is-3 is-offset-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-success has-text-white has-text-weight-semibold"
                                        @click="confirmation(true)"
                                    >YA</b-button>
                                </b-field>
                            </div>
                            <div class="column is-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-danger-light has-text-white has-text-weight-semibold"
                                        @click="isUpdateModal = false"
                                    >TIDAK</b-button>
                                </b-field>
                            </div>
                        </div>
                    </section>
                </div>
        </b-modal>
        <b-modal has-modal-card v-model="isConfirmModal">
            <div class="modal-card" style="width: 860px">
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
                            Apakah Anda yakin akan mengirimkan master pejabat approval dengan rincian sebagai berikut:
                        </div>
                        <div class="column mt-3 is-offset-1 is-10 is-primary has-text-primary has-text-weight-medium">
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Jenis PK</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ pkType }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Unit</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ unit }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Cabang</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ branch }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Bagian / Sub Bagian</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ department }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Jabatan Umum</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ generalPosition }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="generalPosition == 'Clerk'"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Alasan Permintaan</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ reason == 1 ? 'Permintaan Baru' : 'Pengganti Keluar/Mutasi/Promosi' }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Dibuat</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ creator }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 1</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ structural1 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="structural2 && pickStructural2"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 2</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ structural2 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="structural3 && pickStructural3"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 3</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ structural3 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="structural4 && pickStructural4"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 4</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ structural4 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="structural5 && pickStructural5"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 5</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ structural5 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="structural6 && pickStructural6"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 6</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ structural6 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="functional1 && pickFunctional1 && !noFunctional"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional 1</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ functional1 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="functional2 && pickFunctional2 && !noFunctional"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional 2</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ functional2 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="functional3 && pickFunctional3 && !noFunctional"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional 3</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ functional3 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="functional4 && pickFunctional4 && !noFunctional"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional 4</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ functional4 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="approved1 && pickApproved1"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 1</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ approved1 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="approved2 && pickApproved2"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 2</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ approved2 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="approved3 && pickApproved3"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 3</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ approved3 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="approved4 && pickApproved4"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 4</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ approved4 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="approved5 && pickApproved5"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 5</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ approved5 }}</p>
                            </b-field>
                        </div>
                        <div class="columns mt-4">
                            <div class="column is-3 is-offset-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-success has-text-white has-text-weight-semibold"
                                        @click="updateId ? updateApproval() : createApproval()"
                                    >YA</b-button>
                                </b-field>
                            </div>
                            <div class="column is-3">
                                <b-field class="">
                                    <b-button
                                        expanded
                                        class="has-background-danger-light has-text-white has-text-weight-semibold"
                                        @click="isConfirmModal = false"
                                    >TIDAK</b-button>
                                </b-field>
                            </div>
                        </div>
                    </section>
                </div>
        </b-modal>
    </section>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
    data() {
        return {
            editData: null,
            updateId: null,
            title: null,
            pkType: null,
            location: '',
            unit: '',
            branch: '',
            department: '',
            masterDepartmentsId: null,
            generalPosition: '',
            creator : '',
            creatorPosition: null,
            structural1: '',
            structural1Position: null,
            structural2: '',
            structural2Position: null,
            structural3: '',
            structural3Position: null,
            structural4: '',
            structural4Position: null,
            structural5: '',
            structural5Position: null,
            structural6: '',
            structural6Position: null,
            functional1: '',
            functional1Position: null,
            functional2: '',
            functional2Position: null,
            functional3: '',
            functional3Position: null,
            functional4: '',
            functional4Position: null,
            approved1: '',
            approved1Position: null,
            approved2: '',
            approved2Position: null,
            approved3: '',
            approved3Position: null,
            approved4: '',
            approved4Position: null,
            approved5: '',
            approved5Position: null,
            distinctUnits: [],
            masterBranches: [],
            distinctBranches: [],
            masterDepartments: null,
            filteredBranches: [],
            filteredDepartments: [],
            filteredPositions: [],
            reasonPK: [
				{
					'id': 1,
					'alasan': 'Permintaan Baru'
				},
				{
					'id': 2,
					'alasan': 'Pengganti Keluar/Mutasi/Promosi'
				}
            ],
            reason: null,
            noFunctional: false,
            totalStructural: 1,
            totalFunctional: 1,
            totalApproved: 1,
            employeesCreator: null,
            employeesStructural1: null,
            employeesStructural2: null,
            employeesStructural3: null,
            employeesStructural4: null,
            employeesStructural5: null,
            employeesStructural6: null,
            employeesFunctional1: null,
            employeesFunctional2: null,
            employeesFunctional3: null,
            employeesFunctional4: null,
            employeesApproved1: null,
            employeesApproved2: null,
            employeesApproved3: null,
            employeesApproved4: null,
            employeesApproved5: null,
            isInputLoading: false,
            isSubmitLoading: false,
            isCreatorLoading: false,
            isCreatorDisabled: false,
            isStructural1Loading: false,
            isStructural2Loading: false,
            isStructural3Loading: false,
            isStructural4Loading: false,
            isStructural5Loading: false,
            isStructural6Loading: false,
            isFunctional1Loading: false,
            isFunctional2Loading: false,
            isFunctional3Loading: false,
            isFunctional4Loading: false,
            isApproved1Loading: false,
            isApproved2Loading: false,
            isApproved3Loading: false,
            isApproved4Loading: false,
            isApproved5Loading: false,
            isAddSubstractStructural: false,
            isAddSubstractFunctional: false,
            isAddSubstractApproved: false,
            pickLocation: true,
            pickUnit: false,
            pickBranch: false,
            pickDepartment: false,
            pickGeneralPosition: false,
            pickCreator: false,
            arrayStructural: [],
            pickStructural1: false,
            pickStructural2: false,
            pickStructural3: false,
            pickStructural4: false,
            pickStructural5: false,
            pickStructural6: false,
            arrayFunctional: [],
            pickFunctional1: false,
            pickFunctional2: false,
            pickFunctional3: false,
            pickFunctional4: false,
            arrayApproved: [],
            pickApproved1: false,
            pickApproved2: false,
            pickApproved3: false,
            pickApproved4: false,
            pickApproved5: false,
            isInputPositionLoading: false,
            isCreateModal: false,
            isUpdateModal: false,
            emptyApprovers: [],
            isConfirmModal: false,
        };
    },

    created() {
        if (this.$route.params.routingTo == 'add') {
            this.title = 'Tambah Pejabat Approval Baru'
        } else if (this.$route.params.routingTo == 'edit') {
            this.title = 'Ubah Pejabat Approval'
        }

        if (this.$route.params.master) {
            this.editData = this.$route.params.master
        }
        //console.log(this.editData)
    },

    async mounted() {
        this.isInputLoading = true
        this.isSubmitLoading = true
        this.isCreatorLoading = true
        this.isStructural1Loading = true
        this.isStructural2Loading = true
        this.isStructural3Loading = true
        this.isStructural4Loading = true
        this.isStructural5Loading = true
        this.isStructural6Loading = true
        this.isFunctional1Loading = true
        this.isFunctional2Loading = true
        this.isFunctional3Loading = true
        this.isFunctional4Loading = true
        this.isApproved1Loading = true
        this.isApproved2Loading = true
        this.isApproved3Loading = true
        this.isApproved4Loading = true
        this.isApproved5Loading = true
        this.isAddSubstractStructural = true
        this.isAddSubstractFunctional = true
        this.isAddSubstractApproved = true
        this.isInputPositionLoading = true
        await this.getDistinctUnits()
        // await this.getAllBranches()
        await this.getDistinctBranches()
        await this.getAllDepartments()
        await this.getUser()
        if (this.editData) {
            this.editingData()
        }
        this.isInputLoading = false
        this.isSubmitLoading = false
        this.isCreatorLoading = false
        this.isStructural1Loading = false
        this.isStructural2Loading = false
        this.isStructural3Loading = false
        this.isStructural4Loading = false
        this.isStructural5Loading = false
        this.isStructural6Loading = false
        this.isFunctional1Loading = false
        this.isFunctional2Loading = false
        this.isFunctional3Loading = false
        this.isFunctional4Loading = false
        this.isApproved1Loading = false
        this.isApproved2Loading = false
        this.isApproved3Loading = false
        this.isApproved4Loading = false
        this.isApproved5Loading = false
        this.isAddSubstractStructural = false
        this.isAddSubstractFunctional = false
        this.isAddSubstractApproved = false
        this.isInputPositionLoading = false
    },

    computed: {
        // getAsyncLocationData () {
        //     return this.masterBranches.filter((option) => {
        //         return option.AREANAME
        //             .toString()
        //             .toLowerCase()
        //             .indexOf(this.location.toLowerCase()) >= 0
        //     })
        // },

        getAsyncUnitData () {
            if (this.pickUnit == false) {
                return this.distinctUnits.filter((option) => {
                    return option.unit
                        .toString()
                        .toLowerCase()
                        .indexOf(this.unit.toLowerCase()) >= 0
                })
            } else {
                return this.distinctUnits
            }
        },

        getAsyncBranchData () {
            if (this.pickBranch == false) {
                return this.filteredBranches.filter((option) => {
                    return option.cabang
                        .toString()
                        .toLowerCase()
                        .indexOf(this.branch.toLowerCase()) >= 0
                })
            } else {
                return this.filteredBranches
            }
        },

        getAsyncDepartmentData() {
            if (this.pickDepartment == false) {
                return this.filteredDepartments.filter((option) => {
                    return option.dept_khusus
                        .toString()
                        .toLowerCase()
                        .indexOf(this.department.toLowerCase()) >= 0
                })
            } else {
                return this.filteredDepartments
            }
        },

        getAsyncGeneralPositionData() {
			if (this.pickGeneralPosition == false) {
				return this.filteredPositions.filter((option) => {
					return option.jab_umum
						.toString()
						.toLowerCase()
						.indexOf(this.generalPosition.toLowerCase()) >= 0
				})
			} else {
				return this.filteredPositions
			}
		},

        getAsyncEmployeesCreator() {
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickCreator == false) {
                return this.employeesCreator.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.creator.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.creator.toLowerCase())) &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesCreator.filter((option) => {
                    return option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesStructural1() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickStructural1 == false) {
                return this.employeesStructural1.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.structural1.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.structural1.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesStructural1.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesStructural2() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickStructural2 == false) {
                return this.employeesStructural2.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.structural2.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.structural2.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesStructural2.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesStructural3() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickStructural3 == false) {
                return this.employeesStructural3.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.structural3.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.structural3.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesStructural3.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesStructural4() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickStructural4 == false) {
                return this.employeesStructural4.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.structural4.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.structural4.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesStructural4.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },
        
        getAsyncEmployeesStructural5() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickStructural5 == false) {
                return this.employeesStructural5.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.structural5.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.structural5.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesStructural5.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesStructural6() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickStructural6 == false) {
                return this.employeesStructural6.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.structural6.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.structural6.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesStructural6.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesFunctional1() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickFunctional1 == false) {
                return this.employeesFunctional1.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.functional1.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.functional1.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesFunctional1.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesFunctional2() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickFunctional2 == false) {
                return this.employeesFunctional2.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.functional2.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.functional2.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesFunctional2.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesFunctional3() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickFunctional3 == false) {
                return this.employeesFunctional3.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.functional3.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.functional3.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesFunctional3.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesFunctional4() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickFunctional4 == false) {
                return this.employeesFunctional4.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.functional4.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.functional4.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesFunctional4.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesApproved1() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickApproved1 == false) {
                return this.employeesApproved1.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.approved1.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.approved1.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesApproved1.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesApproved2() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickApproved2 == false) {
                return this.employeesApproved2.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.approved2.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.approved2.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesApproved2.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesApproved3() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickApproved3 == false) {
                return this.employeesApproved3.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.approved3.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.approved3.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesApproved3.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved4[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesApproved4() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved5 = this.approved5.split(" ")
            if (this.pickApproved4 == false) {
                return this.employeesApproved4.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.approved4.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.approved4.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved5[0]
                })
            } else {
                return this.employeesApproved4.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved5[0]
                })
            }
        },

        getAsyncEmployeesApproved5() {
            let arrayCreator = this.creator.split(" ")
            let arrayStructural1 = this.structural1.split(" ")
            let arrayStructural2 = this.structural2.split(" ")
            let arrayStructural3 = this.structural3.split(" ")
            let arrayStructural4 = this.structural4.split(" ")
            let arrayStructural5 = this.structural5.split(" ")
            let arrayStructural6 = this.structural6.split(" ")
            let arrayFunctional1 = this.functional1.split(" ")
            let arrayFunctional2 = this.functional2.split(" ")
            let arrayFunctional3 = this.functional3.split(" ")
            let arrayFunctional4 = this.functional4.split(" ")
            let arrayApproved1 = this.approved1.split(" ")
            let arrayApproved2 = this.approved2.split(" ")
            let arrayApproved3 = this.approved3.split(" ")
            let arrayApproved4 = this.approved4.split(" ")
            if (this.pickApproved5 == false) {
                return this.employeesApproved5.filter((option) => {
                    return (option.name
                        .toString()
                        .toLowerCase()
                        .includes(this.approved5.toLowerCase()) ||
                        option.username
                        .toString()
                        .toLowerCase()
                        .includes(this.approved5.toLowerCase())) &&
                        option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0]
                })
            } else {
                return this.employeesApproved5.filter((option) => {
                    return option.username != arrayCreator[0] &&
                        option.username != arrayStructural1[0] &&
                        option.username != arrayStructural2[0] &&
                        option.username != arrayStructural3[0] &&
                        option.username != arrayStructural4[0] &&
                        option.username != arrayStructural5[0] &&
                        option.username != arrayStructural6[0] &&
                        option.username != arrayFunctional1[0] &&
                        option.username != arrayFunctional2[0] &&
                        option.username != arrayFunctional3[0] &&
                        option.username != arrayFunctional4[0] &&
                        option.username != arrayApproved1[0] &&
                        option.username != arrayApproved2[0] &&
                        option.username != arrayApproved3[0] &&
                        option.username != arrayApproved4[0]
                })
            }
        },
    },

    methods: {
        confirmation (isUpdate) {
            if (isUpdate)
                this.isUpdateModal = false
            else
                this.isCreateModal = false
            
            this.isConfirmModal = true
        },

        openModal (isUpdate) {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
                    })
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (!this.pickLocation || !this.pickUnit || !this.pickBranch || !this.pickDepartment) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi Unit, cabang, dan departemen dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (!this.pickGeneralPosition) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi jabatan umum dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (!this.pickCreator 
                            || (!this.pickStructural1 && this.structural1) || (!this.pickStructural2 && this.structural2) || (!this.pickStructural3 && this.structural3) || (!this.pickStructural4 && this.structural4) || (!this.pickStructural5 && this.structural5) || (!this.pickStructural6 && this.structural6) 
                            || (!this.pickFunctional1 && this.functional1 && !this.noFunctional) || (!this.pickFunctional2 && this.functional2 && !this.noFunctional) || (!this.pickFunctional3 && this.functional3 && !this.noFunctional) || (!this.pickFunctional4 && this.functional4 && !this.noFunctional) 
                            || (!this.pickApproved1 && this.approved1) || (!this.pickApproved2 && this.approved2) || (!this.pickApproved3 && this.approved3) || (!this.pickApproved4 && this.approved4) || (!this.pickApproved5 && this.approved5)) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi pejabat untuk tahapan approval dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (!this.approved1 || !this.pickApproved1) {
                            this.$swal({
                                icon: "error",
                                title: "Silakan pilih disetujui 1 terlebih dahulu",
                                customClass: {
                                    icon: "is-danger",
                                    title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                    confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                }
                            })
                        } else {
                            this.emptyApprovers = []
                            if ((!this.structural2 || !this.pickStructural2) && this.totalStructural > 1)
                                this.emptyApprovers.push({approval: "Disetujui Struktural 2"})
                            if ((!this.structural3 || !this.pickStructural3) && this.totalStructural > 2)
                                this.emptyApprovers.push({approval: "Disetujui Struktural 3"})
                            if ((!this.structural4 || !this.pickStructural4) && this.totalStructural > 3)
                                this.emptyApprovers.push({approval: "Disetujui Struktural 4"})
                            if ((!this.structural5 || !this.pickStructural5) && this.totalStructural > 4)
                                this.emptyApprovers.push({approval: "Disetujui Struktural 5"})
                            if ((!this.structural6 || !this.pickStructural6) && this.totalStructural > 5)
                                this.emptyApprovers.push({approval: "Disetujui Struktural 6"})
                            if (!this.noFunctional) {
                                if ((!this.functional1 || !this.pickFunctional1) && this.totalFunctional > 0)
                                    this.emptyApprovers.push({approval: "Disetujui Fungsional 1"})
                                if ((!this.functional2 || !this.pickFunctional2) && this.totalFunctional > 1)
                                    this.emptyApprovers.push({approval: "Disetujui Fungsional 2"})
                                if ((!this.functional3 || !this.pickFunctional3) && this.totalFunctional > 2)
                                    this.emptyApprovers.push({approval: "Disetujui Fungsional 3"})
                                if ((!this.functional4 || !this.pickFunctional4) && this.totalFunctional > 3)
                                    this.emptyApprovers.push({approval: "Disetujui Fungsional 4"})
                            }
                            if ((!this.approved2 || !this.pickApproved2) && this.totalApproved > 1)
                                this.emptyApprovers.push({approval: "Disetujui 2"})
                            if ((!this.approved3 || !this.pickApproved3) && this.totalApproved > 2)
                                this.emptyApprovers.push({approval: "Disetujui 3"})
                            if ((!this.approved4 || !this.pickApproved4) && this.totalApproved > 3)
                                this.emptyApprovers.push({approval: "Disetujui 4"})
                            if ((!this.approved5 || !this.pickApproved5) && this.totalApproved > 4)
                                this.emptyApprovers.push({approval: "Disetujui 5"})
                            
                            if (isUpdate && this.emptyApprovers.length > 0) {
                                this.isUpdateModal = true
                            } else if (!isUpdate && this.emptyApprovers.length > 0) {
                                this.isCreateModal = true
                            } else if (isUpdate && this.emptyApprovers.length == 0) {
                                this.confirmation(true)
                            } else if (!isUpdate && this.emptyApprovers.length == 0) {
                                this.confirmation(false)
                            }
                        }
                    }
                }
            })
        },

        async substractApproved (indexApproved) {
            await this.$nextTick()
            this.isAddSubstractApproved = true
            if (indexApproved == 5) {
                this.totalApproved -= 1
                this.setPickApproved5(false)
                this.approved5 = ''
                this.approved5Position = null
            } else if (indexApproved == 4) {
                this.approved4 = this.approved5

                this.approved4Position = this.approved5Position

                this.totalApproved -= 1
                if (this.totalApproved == 4) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                } else if (this.totalApproved == 3) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                    this.setPickApproved4(false)
                    this.approved4 = ''
                    this.approved4Position = null
                }
            } else if (indexApproved == 3) {
                this.approved3 = this.approved4
                this.approved4 = this.approved5

                this.approved3Position = this.approved4Position
                this.approved4Position = this.approved5Position

                this.totalApproved -= 1
                if (this.totalApproved == 4) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                } else if (this.totalApproved == 3) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                    this.setPickApproved4(false)
                    this.approved4 = ''
                    this.approved4Position = null
                } else if (this.totalApproved == 2) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                    this.setPickApproved4(false)
                    this.approved4 = ''
                    this.approved4Position = null
                    this.setPickApproved3(false)
                    this.approved3 = ''
                    this.approved3Position = null
                }
            } else if (indexApproved == 2) {
                this.approved2 = this.approved3
                this.approved3 = this.approved4
                this.approved4 = this.approved5

                this.approved2Position = this.approved3Position
                this.approved3Position = this.approved4Position
                this.approved4Position = this.approved5Position

                this.totalApproved -= 1
                if (this.totalApproved == 4) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                } else if (this.totalApproved == 3) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                    this.setPickApproved4(false)
                    this.approved4 = ''
                    this.approved4Position = null
                } else if (this.totalApproved == 2) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                    this.setPickApproved4(false)
                    this.approved4 = ''
                    this.approved4Position = null
                    this.setPickApproved3(false)
                    this.approved3 = ''
                    this.approved3Position = null
                } else if (this.totalApproved == 1) {
                    this.setPickApproved5(false)
                    this.approved5 = ''
                    this.approved5Position = null
                    this.setPickApproved4(false)
                    this.approved4 = ''
                    this.approved4Position = null
                    this.setPickApproved3(false)
                    this.approved3 = ''
                    this.approved3Position = null
                    this.setPickApproved2(false)
                    this.approved2 = ''
                    this.approved2Position = null
                }
            }
            await this.$nextTick()
            setTimeout(() => {
                this.isAddSubstractApproved = false
            }, 300);
        },

        async addApproved () {
            await this.$nextTick()
            this.isAddSubstractApproved = true
            if (this.totalApproved < 5) {
                this.totalApproved += 1
            } else {
                this.$buefy.snackbar.open({
                    message: 'Maksimal 5 pejabat disetujui',
                    type: 'is-info',
                    position: 'is-top',
                    actionText: 'Tutup',
                    indefinite: false,
                })
            }
            await this.$nextTick()
            setTimeout(() => {
                this.isAddSubstractApproved = false
            }, 300);
        },

        async substractFunctional (indexFunctional) {
            await this.$nextTick()
            this.isAddSubstractFunctional = true
            if (indexFunctional == 4) {
                this.totalFunctional -= 1
                this.setPickFunctional4(false)
                this.functional4 = ''
                this.functional4Position = null
            } else if (indexFunctional == 3) {
                this.functional3 = this.functional4

                this.functional3Position = this.functional4Position

                this.totalFunctional -= 1
                if (this.totalFunctional == 3) {
                    this.setPickFunctional4(false)
                    this.functional4 = ''
                    this.functional4Position
                } else if (this.totalFunctional == 2) {
                    this.setPickFunctional4(false)
                    this.functional4 = ''
                    this.functional4Position
                    this.setPickFunctional3(false)
                    this.functional3 = ''
                    this.functional3Position
                }
            } else if (indexFunctional == 2) {
                this.functional2 = this.functional3
                this.functional3 = this.functional4

                this.functional2Position = this.functional3Position
                this.functional3Position = this.functional4Position

                this.totalFunctional -= 1
                if (this.totalFunctional == 3) {
                    this.setPickFunctional4(false)
                    this.functional4 = ''
                    this.functional4Position
                } else if (this.totalFunctional == 2) {
                    this.setPickFunctional4(false)
                    this.functional4 = ''
                    this.functional4Position
                    this.setPickFunctional3(false)
                    this.functional3 = ''
                    this.functional3Position
                } else if (this.totalFunctional == 1) {
                    this.setPickFunctional4(false)
                    this.functional4 = ''
                    this.functional4Position
                    this.setPickFunctional3(false)
                    this.functional3 = ''
                    this.functional3Position
                    this.setPickFunctional2(false)
                    this.functional2 = ''
                    this.functional2Position
                }
            }
            await this.$nextTick()
            setTimeout(() => {
                this.isAddSubstractFunctional = false
            }, 300);
        },

        async addFunctional () {
            await this.$nextTick()
            this.isAddSubstractFunctional = true
            if (this.totalFunctional < 4) {
                this.totalFunctional += 1
            } else {
                this.$buefy.snackbar.open({
                    message: 'Maksimal 4 pejabat disetujui fungsional',
                    type: 'is-info',
                    position: 'is-top',
                    actionText: 'Tutup',
                    indefinite: false,
                })
            }
            await this.$nextTick()
            setTimeout(() => {
                this.isAddSubstractFunctional = false
            }, 300);
        },

        async substractStructural (indexStructural) {
            await this.$nextTick()
            this.isAddSubstractStructural = true
            if (indexStructural == 6) {
                this.setPickStructural6(false)
                this.structural6 = ''
                this.structural6Position = null
                this.totalStructural -= 1
            } else if (indexStructural == 5) {
                this.structural5 = this.structural6

                this.structural5Position = this.structural6Position

                this.totalStructural -= 1
                if (this.totalStructural == 5) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                } else if (this.totalStructural == 4) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                }
            } else if (indexStructural == 4) {
                this.structural4 = this.structural5
                this.structural5 = this.structural6

                this.structural4Position = this.structural5Position
                this.structural5Position = this.structural6Position

                this.totalStructural -= 1
                if (this.totalStructural == 5) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                } else if (this.totalStructural == 4) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                } else if (this.totalStructural == 3) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                    this.setPickStructural4(false)
                    this.structural4 = ''
                    this.structural4Position = null
                }
            } else if (indexStructural == 3) {
                this.structural3 = this.structural4
                this.structural4 = this.structural5
                this.structural5 = this.structural6

                this.structural3Position = this.structural4Position
                this.structural4Position = this.structural5Position
                this.structural5Position = this.structural6Position
                
                this.totalStructural -= 1
                if (this.totalStructural == 5) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                } else if (this.totalStructural == 4) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                } else if (this.totalStructural == 3) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                    this.setPickStructural4(false)
                    this.structural4 = ''
                    this.structural4Position = null
                } else if (this.totalStructural == 2) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                    this.setPickStructural4(false)
                    this.structural4 = ''
                    this.structural4Position = null
                    this.setPickStructural3(false)
                    this.structural3 = ''
                    this.structural3Position = null
                }
            } else if (indexStructural == 2) {
                this.structural2 = this.structural3
                this.structural3 = this.structural4
                this.structural4 = this.structural5
                this.structural5 = this.structural6

                this.structural2Position = this.structural3Position
                this.structural3Position = this.structural4Position
                this.structural4Position = this.structural5Position
                this.structural5Position = this.structural6Position

                this.totalStructural -= 1
                if (this.totalStructural == 5) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                } else if (this.totalStructural == 4) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                } else if (this.totalStructural == 3) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                    this.setPickStructural4(false)
                    this.structural4 = ''
                    this.structural4Position = null
                } else if (this.totalStructural == 2) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                    this.setPickStructural4(false)
                    this.structural4 = ''
                    this.structural4Position = null
                    this.setPickStructural3(false)
                    this.structural3 = ''
                    this.structural3Position = null
                } else if (this.totalStructural == 1) {
                    this.setPickStructural6(false)
                    this.structural6 = ''
                    this.structural6Position = null
                    this.setPickStructural5(false)
                    this.structural5 = ''
                    this.structural5Position = null
                    this.setPickStructural4(false)
                    this.structural4 = ''
                    this.structural4Position = null
                    this.setPickStructural3(false)
                    this.structural3 = ''
                    this.structural3Position = null
                    this.setPickStructural2(false)
                    this.structural2 = ''
                    this.structural2Position = null
                }
            }
            await this.$nextTick()
            setTimeout(() => {
                this.isAddSubstractStructural = false
            }, 300);
        },

        async addStructural () {
            await this.$nextTick()
            this.isAddSubstractStructural = true
            if (this.totalStructural < 6) {
                this.totalStructural += 1
            } else {
                this.$buefy.snackbar.open({
                    message: 'Maksimal 6 pejabat disetujui struktural',
                    type: 'is-info',
                    position: 'is-top',
                    actionText: 'Tutup',
                    indefinite: false,
                })
            }
            await this.$nextTick()
            setTimeout(() => {
                this.isAddSubstractStructural = false
            }, 300);
        },

        setPickGeneralPosition (selecting) {
            this.pickGeneralPosition = selecting
        },

        async setPickApproved5 (selecting, position) {
            await this.$nextTick()
            if (!this.approved1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved5 = ''
                return
            }
            if (!this.approved2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved5 = ''
                return
            }
            if (!this.approved3 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 3 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved5 = ''
                return
            }
            if (!this.approved4 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 4 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved5 = ''
                return
            }
            this.isApproved5Loading = true
            this.isAddSubstractApproved = true
            setTimeout(() => {
                this.isApproved5Loading = false
                this.isAddSubstractApproved = false
            }, 300);
            this.pickApproved5 = selecting
            this.approved5Position = position
        },
        
        async setPickApproved4 (selecting, position) {
            await this.$nextTick()
            if (!this.approved1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved4 = ''
                return
            }
            if (!this.approved2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved4 = ''
                return
            }
            if (!this.approved3 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 3 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved4 = ''
                return
            }
            this.isApproved4Loading = true
            this.isAddSubstractApproved = true
            setTimeout(() => {
                this.isApproved4Loading = false
                this.isAddSubstractApproved = false
            }, 300);
            this.pickApproved4 = selecting
            this.approved4Position = position
        },

        async setPickApproved3 (selecting, position) {
            await this.$nextTick()
            if (!this.approved1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved3 = ''
                return
            }
            if (!this.approved2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved3 = ''
                return
            }
            this.isApproved3Loading = true
            this.isAddSubstractApproved = true
            setTimeout(() => {
                this.isApproved3Loading = false
                this.isAddSubstractApproved = false
            }, 300);
            this.pickApproved3 = selecting
            this.approved3Position = position
        },

        async setPickApproved2 (selecting, position) {
            await this.$nextTick()
            if (!this.approved1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.approved2 = ''
                return
            }
            this.isApproved2Loading = true
            this.isAddSubstractApproved = true
            setTimeout(() => {
                this.isApproved2Loading = false
                this.isAddSubstractApproved = false
            }, 300);
            this.pickApproved2 = selecting
            this.approved2Position = position
        },

        async setPickApproved1 (selecting, position) {
            this.isApproved1Loading = true
            this.isAddSubstractApproved = true
            await this.$nextTick()
            setTimeout(() => {
                this.isApproved1Loading = false
                this.isAddSubstractApproved = false
            }, 300);
            this.pickApproved1 = selecting
            this.approved1Position = position
        },

        async setPickFunctional4 (selecting, position) {
            await this.$nextTick()
            if (!this.functional1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui fungsional 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.functional4 = ''
                return
            }
            if (!this.functional2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui fungsional 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.functional4 = ''
                return
            }
            if (!this.functional3 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui fungsional 3 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.functional4 = ''
                return
            }
            this.isFunctional4Loading = true
            this.isAddSubstractFunctional = true
            setTimeout(() => {
                this.isFunctional4Loading = false
                this.isAddSubstractFunctional = false
            }, 300);
            this.pickFunctional4 = selecting
            this.functional4Position = position
        },

        async setPickFunctional3 (selecting, position) {
            await this.$nextTick()
            if (!this.functional1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui fungsional 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.functional3 = ''
                return
            }
            if (!this.functional2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui fungsional 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.functional3 = ''
                return
            }
            this.isFunctional3Loading = true
            this.isAddSubstractFunctional = true
            setTimeout(() => {
                this.isFunctional3Loading = false
                this.isAddSubstractFunctional = false
            }, 300);
            this.pickFunctional3 = selecting
            this.functional3Position = position
        },

        async setPickFunctional2 (selecting, position) {
            await this.$nextTick()
            if (!this.functional1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui fungsional 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.functional2 = ''
                return
            }
            this.isFunctional2Loading = true
            this.isAddSubstractFunctional = true
            setTimeout(() => {
                this.isFunctional2Loading = false
                this.isAddSubstractFunctional = false
            }, 300);
            this.pickFunctional2 = selecting
            this.functional2Position = position
        },

        async setPickFunctional1 (selecting, position) {
            this.isFunctional1Loading = true
            this.isAddSubstractFunctional = true
            await this.$nextTick()
            setTimeout(() => {
                this.isFunctional1Loading = false
                this.isAddSubstractFunctional = false
            }, 300);
            this.pickFunctional1 = selecting
            this.functional1Position = position
        },

        async setPickStructural6 (selecting, position) {
            await this.$nextTick()
            if (!this.structural1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural6 = ''
                return
            }
            if (!this.structural2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural6 = ''
                return
            }
            if (!this.structural3 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 3 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural6 = ''
                return
            }
            if (!this.structural4 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 4 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural6 = ''
                return
            }
            if (!this.structural5 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 5 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural6 = ''
                return
            }
            this.isStructural1Loading = true
            this.isAddSubstractStructural = true
            setTimeout(() => {
                this.isStructural1Loading = false
                this.isAddSubstractStructural = false
            }, 300);
            this.pickStructural6 = selecting
            this.structural6Position = position
        },

        async setPickStructural5 (selecting, position) {
            await this.$nextTick()
            if (!this.structural1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural5 = ''
                return
            }
            if (!this.structural2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural5 = ''
                return
            }
            if (!this.structural3 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 3 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural5 = ''
                return
            }
            if (!this.structural4 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 4 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural5 = ''
                return
            }
            this.isStructural1Loading = true
            this.isAddSubstractStructural = true
            setTimeout(() => {
                this.isStructural1Loading = false
                this.isAddSubstractStructural = false
            }, 300);
            this.pickStructural5 = selecting
            this.structural5Position = position
        },

        async setPickStructural4 (selecting, position) {
            await this.$nextTick()
            if (!this.structural1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural4 = ''
                return
            }
            if (!this.structural2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural4 = ''
                return
            }
            if (!this.structural3 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 3 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural4 = ''
                return
            }
            this.isStructural1Loading = true
            this.isAddSubstractStructural = true
            setTimeout(() => {
                this.isStructural1Loading = false
                this.isAddSubstractStructural = false
            }, 300);
            this.pickStructural4 = selecting
            this.structural4Position = position
        },

        async setPickStructural3 (selecting, position) {
            await this.$nextTick()
            if (!this.structural1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural3 = ''
                return
            }
            if (!this.structural2 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 2 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural3 = ''
                return
            }
            this.isStructural1Loading = true
            this.isAddSubstractStructural = true
            setTimeout(() => {
                this.isStructural1Loading = false
                this.isAddSubstractStructural = false
            }, 300);
            this.pickStructural3 = selecting
            this.structural3Position = position
        },

        async setPickStructural2 (selecting, position) {
            await this.$nextTick()
            if (!this.structural1 && selecting) {
                this.$swal({
                    icon: "warning",
                    title: "Silakan pilih disetujui struktural 1 terlebih dahulu",
                    customClass: {
                        icon: "is-warning",
                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                    }
                })
                this.structural2 = ''
                return
            }
            this.isStructural1Loading = true
            this.isAddSubstractStructural = true
            setTimeout(() => {
                this.isStructural1Loading = false
                this.isAddSubstractStructural = false
            }, 300);
            this.pickStructural2 = selecting
            this.structural2Position = position
        },

        async setPickStructural1 (selecting, position) {
            this.isStructural1Loading = true
            this.isAddSubstractStructural = true
            await this.$nextTick()
            setTimeout(() => {
                this.isStructural1Loading = false
                this.isAddSubstractStructural = false
            }, 300);
            this.pickStructural1 = selecting
            this.structural1Position = position
        },

        async setPickCreator (selecting, position) {
            this.isCreatorLoading = true
            await this.$nextTick()
            setTimeout(() => {
                this.isCreatorLoading = false
            }, 300);
            this.pickCreator = selecting
            this.creatorPosition = position
        },

        setPickDepartment (selecting) {
            this.pickDepartment = selecting
        },

        setPickBranch (selecting) {
            this.pickBranch = selecting
        },

        setPickUnit (selecting) {
            this.pickUnit = selecting
        },

        setPickLocation (selecting) {
            this.pickLocation = selecting
        },

        async getUserByPKType () {
            this.isCreatorLoading = true
            this.creator = ''

            //let unitBypass = unit.replace("&", "%26")
            let postData = {
                jenis_pk: this.pkType,
                code: 11
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
                .then( response => {
                    this.employeesCreator = null
                    // response.data.result.forEach(element => {
                    //     this.employeesStructural1.push(element)
                    //     this.employeesStructural2.push(element)
                    //     this.employeesDisetujui1.push(element)
                    //     this.employeesDisetujui2.push(element)
                    // });
                    this.employeesCreator = response.data.result
                    this.isCreatorLoading = false
                    
                    //console.log(this.employeesCreator)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getUser () {
            this.isStructural1Loading = true
            this.isStructural2Loading = true
            this.isStructural3Loading = true
            this.isStructural4Loading = true
            this.isStructural5Loading = true
            this.isStructural6Loading = true
            this.isFunctional1Loading = true
            this.isFunctional2Loading = true
            this.isFunctional3Loading = true
            this.isFunctional4Loading = true
            this.isApproved1Loading = true
            this.isApproved2Loading = true
            this.isApproved3Loading = true
            this.isApproved4Loading = true
            this.isApproved5Loading = true
            this.isCreatorLoading = true

            //let unitBypass = unit.replace("&", "%26")
            let postData = {
                code: 10
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
                .then( response => {
                    this.employeesStructural1 = null
                    this.employeesStructural2 = null
                    this.employeesStructural3 = null
                    this.employeesStructural4 = null
                    this.employeesStructural5 = null
                    this.employeesStructural6 = null
                    this.employeesFunctional1 = null
                    this.employeesFunctional2 = null
                    this.employeesFunctional3 = null
                    this.employeesFunctional4 = null
                    this.employeesApproved1 = null
                    this.employeesApproved2 = null
                    this.employeesApproved3 = null
                    this.employeesApproved4 = null
                    this.employeesApproved5 = null
                    this.employeesCreator = []
                    // response.data.result.forEach(element => {
                    //     this.employeesStructural1.push(element)
                    //     this.employeesStructural2.push(element)
                    //     this.employeesDisetujui1.push(element)
                    //     this.employeesDisetujui2.push(element)
                    // });
                    this.employeesStructural1 = response.data.result
                    this.employeesStructural2 = response.data.result
                    this.employeesStructural3 = response.data.result
                    this.employeesStructural4 = response.data.result
                    this.employeesStructural5 = response.data.result
                    this.employeesStructural6 = response.data.result
                    this.employeesFunctional1 = response.data.result
                    this.employeesFunctional2 = response.data.result
                    this.employeesFunctional3 = response.data.result
                    this.employeesFunctional4 = response.data.result
                    this.employeesApproved1 = response.data.result
                    this.employeesApproved2 = response.data.result
                    this.employeesApproved3 = response.data.result
                    this.employeesApproved4 = response.data.result
                    this.employeesApproved5 = response.data.result
                    this.isStructural1Loading = false
                    this.isStructural2Loading = false
                    this.isStructural3Loading = false
                    this.isStructural4Loading = false
                    this.isStructural5Loading = false
                    this.isStructural6Loading = false
                    this.isFunctional1Loading = false
                    this.isFunctional2Loading = false
                    this.isFunctional3Loading = false
                    this.isFunctional4Loading = false
                    this.isApproved1Loading = false
                    this.isApproved2Loading = false
                    this.isApproved3Loading = false
                    this.isApproved4Loading = false
                    this.isApproved5Loading = false
                    this.isDiset1Loading = false
                    this.isDiset2Loading = false
                    this.isCreatorLoading = false
                    
                    //console.log(this.employeesCreator)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        // async getUserByDepartment (unit, branch, masterDepartmentsId) {
        //     this.isCreatorLoading = true

        //     //let unitBypass = unit.replace("&", "%26")
        //     let postData = {
        //         unit: unit,
        //         cabang: branch,
        //         code: 10
        //     }

        //     await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
        //         .then( response => {
        //             this.employeesCreator = null
        //             // response.data.result.forEach(element => {
        //             //     this.employeesCreator.push(element)
        //             // });
        //             this.employeesCreator = response.data.result
        //             this.isCreatorLoading = false
                    
        //             //console.log(this.employeesCreator)
        //         })
        //         .catch( e => {
        //             this.$buefy.snackbar.open({
		// 				message: 'User pembuat untuk departemen terpilih tidak ditemukan',
		// 				type: 'is-info',
		// 				position: 'is-top',
		// 				actionText: 'Tutup',
		// 				indefinite: false,
        //             })
        //             this.isSubmitLoading = true
        //             console.log(e)
        //         })
        // },
        // resetForm () {
        //     this.pkType = null
        //     this.location = ''
        //     this.unit = ''
        //     this.branch = ''
        //     this.department = ''
        //     this.masterDepartmentsId = null
        //     this.creator = ''
        //     this.structural1 = ''
        //     this.structural2 = ''
        //     this.structural3 = ''
        //     this.structural4 = ''
        //     this.structural5 = ''
        //     this.structural6 = ''
        //     this.disetujui1 = ''
        //     this.disetujui2 = ''
        //     this.employeesCreator = []
        //     this.employeesStructural1 = []
        //     this.employeesStructural2 = []
        //     this.employeesStructural3 = []
        //     this.employeesStructural4 = []
        //     this.employeesStructural5 = []
        //     this.employeesStructural6 = []
        //     this.employeesDisetujui1 = []
        //     this.employeesDisetujui2 = []
        // },

        async editingData () {
            this.updateId = this.editData.id
            this.pkType = this.editData.jenis_pk
            await this.getUserByPKType()
            this.location = this.editData.lokasi_kerja
            this.unit = this.editData.unit
            this.branch = this.editData.cabang
            this.department = this.editData.dept_khusus
            this.generalPosition = this.editData.jab_umum
            this.reason = this.editData.alasan_permintaan == "Baru" ? 1 : 2
            await this.$nextTick()
            this.creator = this.editData.nik_dibuat ? this.editData.nik_dibuat + " - " + this.editData.nama_dibuat : ''
            this.creatorPosition = this.editData.jabatan_dibuat
            this.structural1 = this.editData.nik_diketahui_struktural_1 ? this.editData.nik_diketahui_struktural_1 + " - " + this.editData.nama_diketahui_struktural_1 : ''
            this.structural1Position = this.editData.jabatan_diketahui_struktural_1
            this.structural1 ? this.arrayStructural.push(this.structural1) : 0
            this.structural2 = this.editData.nik_diketahui_struktural_2 ? this.editData.nik_diketahui_struktural_2 + " - " + this.editData.nama_diketahui_struktural_2 : ''
            this.structural2Position = this.editData.jabatan_diketahui_struktural_2
            this.structural2 ? this.arrayStructural.push(this.structural2) : 0
            this.structural3 = this.editData.nik_diketahui_struktural_3 ? this.editData.nik_diketahui_struktural_3 + " - " + this.editData.nama_diketahui_struktural_3 : ''
            this.structural3Position = this.editData.jabatan_diketahui_struktural_3
            this.structural3 ? this.arrayStructural.push(this.structural3) : 0
            this.structural4 = this.editData.nik_diketahui_struktural_4 ? this.editData.nik_diketahui_struktural_4 + " - " + this.editData.nama_diketahui_struktural_4 : ''
            this.structural4Position = this.editData.jabatan_diketahui_struktural_4
            this.structural4 ? this.arrayStructural.push(this.structural4) : 0
            this.structural5 = this.editData.nik_diketahui_struktural_5 ? this.editData.nik_diketahui_struktural_5 + " - " + this.editData.nama_diketahui_struktural_5 : ''
            this.structural5Position = this.editData.jabatan_diketahui_struktural_5
            this.structural5 ? this.arrayStructural.push(this.structural5) : 0
            this.structural6 = this.editData.nik_diketahui_struktural_6 ? this.editData.nik_diketahui_struktural_6 + " - " + this.editData.nama_diketahui_struktural_6 : ''
            this.structural6Position = this.editData.jabatan_diketahui_struktural_6
            this.structural6 ? this.arrayStructural.push(this.structural6) : 0
            this.functional1 = this.editData.nik_diketahui_fungsional_1 ? this.editData.nik_diketahui_fungsional_1 + " - " + this.editData.nama_diketahui_fungsional_1 : ''
            this.functional1Position = this.editData.jabatan_diketahui_fungsional_1
            this.functional1 ? this.arrayFunctional.push(this.functional1) : 0
            this.functional2 = this.editData.nik_diketahui_fungsional_2 ? this.editData.nik_diketahui_fungsional_2 + " - " + this.editData.nama_diketahui_fungsional_2 : ''
            this.functional2Position = this.editData.jabatan_diketahui_fungsional_2
            this.functional2 ? this.arrayFunctional.push(this.functional2) : 0
            this.functional3 = this.editData.nik_diketahui_fungsional_3 ? this.editData.nik_diketahui_fungsional_3 + " - " + this.editData.nama_diketahui_fungsional_3 : ''
            this.functional3Position = this.editData.jabatan_diketahui_fungsional_3
            this.functional3 ? this.arrayFunctional.push(this.functional3) : 0
            this.functional4 = this.editData.nik_diketahui_fungsional_4 ? this.editData.nik_diketahui_fungsional_4 + " - " + this.editData.nama_diketahui_fungsional_4 : ''
            this.functional4Position = this.editData.jabatan_diketahui_fungsional_4
            this.functional4 ? this.arrayFunctional.push(this.functional4) : 0
            this.approved1 = this.editData.nik_disetujui_1 ? this.editData.nik_disetujui_1 + " - " + this.editData.nama_disetujui_1 : ''
            this.approved1Position = this.editData.jabatan_disetujui_1
            this.approved1 ? this.arrayApproved.push(this.approved1) : 0
            this.approved2 = this.editData.nik_disetujui_2 ? this.editData.nik_disetujui_2 + " - " + this.editData.nama_disetujui_2 : ''
            this.approved2Position = this.editData.jabatan_disetujui_2
            this.approved2 ? this.arrayApproved.push(this.approved2) : 0
            this.approved3 = this.editData.nik_disetujui_3 ? this.editData.nik_disetujui_3 + " - " + this.editData.nama_disetujui_3 : ''
            this.approved3Position = this.editData.jabatan_disetujui_3
            this.approved3 ? this.arrayApproved.push(this.approved3) : 0
            this.approved4 = this.editData.nik_disetujui_4 ? this.editData.nik_disetujui_4 + " - " + this.editData.nama_disetujui_4 : ''
            this.approved4Position = this.editData.jabatan_disetujui_4
            this.approved4 ? this.arrayApproved.push(this.approved4) : 0
            this.approved5 = this.editData.nik_disetujui_5 ? this.editData.nik_disetujui_5 + " - " + this.editData.nama_disetujui_5 : ''
            this.approved5Position = this.editData.jabatan_disetujui_5
            this.arrayStructural.forEach((item, index) => {
                if (index > 0)
                    this.addStructural()
            })
            this.arrayFunctional.forEach((item, index) => {
                if (index > 0)
                    this.addFunctional()
            })
            this.arrayApproved.forEach((item, index) => {
                if (index > 0)
                    this.addApproved()
            })
            console.log(this.arrayFunctional.length)
            this.arrayFunctional.length == 0 ? this.noFunctional = true : 0
            this.filterBranches(this.unit)
            this.filterDepartments(this.branch)
            await this.setDepartmentId(this.editData.id_master_departemen)
            this.pickLocation = true
            this.pickUnit = true
            this.pickBranch = true
            this.pickDepartment = true
            this.pickGeneralPosition = true
            this.pickCreator = true
            this.pickStructural1 = true
            this.pickStructural2 = true
            this.pickStructural3 = true
            this.pickStructural4 = true
            this.pickStructural5 = true
            this.pickStructural6 = true
            this.pickFunctional1 = true
            this.pickFunctional2 = true
            this.pickFunctional3 = true
            this.pickFunctional4 = true
            this.pickApproved1 = true
            this.pickApproved2 = true
            this.pickApproved3 = true
            this.pickApproved4 = true
            this.pickApproved5 = true
            this.editData = null
        },

        async updateApproval () {
            console.log("update")
            this.isConfirmModal = false
            if (this.$refs.form.checkValidity()) {
                this.isSubmitLoading = true
                let arrayCreator = this.creator.split(" ")
                let arrayStructural1 = this.structural1.split(" ")
                let arrayStructural2 = this.structural2.split(" ")
                let arrayStructural3 = this.structural3.split(" ")
                let arrayStructural4 = this.structural4.split(" ")
                let arrayStructural5 = this.structural5.split(" ")
                let arrayStructural6 = this.structural6.split(" ")
                let arrayFunctional1 = this.functional1.split(" ")
                let arrayFunctional2 = this.functional2.split(" ")
                let arrayFunctional3 = this.functional3.split(" ")
                let arrayFunctional4 = this.functional4.split(" ")
                let arrayApproved1 = this.approved1.split(" ")
                let arrayApproved2 = this.approved2.split(" ")
                let arrayApproved3 = this.approved3.split(" ")
                let arrayApproved4 = this.approved4.split(" ")
                let arrayApproved5 = this.approved5.split(" ")

                let postData = {
                    id_master_departemen : this.masterDepartmentsId,
                    jab_umum : this.generalPosition,
                    jenis_pk : this.pkType,
                    alasan_permintaan : this.generalPosition == 'Clerk' ? this.reason == 1 ? "Baru" : "Pengganti" : null,
                    nik_dibuat : arrayCreator[0],
                    jabatan_dibuat : this.creatorPosition,
                    nama_dibuat : this.creator.substring(this.creator.indexOf("- ") + 1),
                    nik_diketahui_struktural_1 : arrayStructural1[0],
                    jabatan_diketahui_struktural_1 : this.structural1Position,
                    nama_diketahui_struktural_1 : this.structural1.substring(this.structural1.indexOf("- ") + 1),
                    nik_diketahui_struktural_2 : arrayStructural2[0],
                    jabatan_diketahui_struktural_2 : this.structural2Position,
                    nama_diketahui_struktural_2 : this.structural2.substring(this.structural2.indexOf("- ") + 1),
                    nik_diketahui_struktural_3 : arrayStructural3[0],
                    jabatan_diketahui_struktural_3 : this.structural3Position,
                    nama_diketahui_struktural_3 : this.structural3.substring(this.structural3.indexOf("- ") + 1),
                    nik_diketahui_struktural_4 : arrayStructural4[0],
                    jabatan_diketahui_struktural_4 : this.structural4Position,
                    nama_diketahui_struktural_4 : this.structural4.substring(this.structural4.indexOf("- ") + 1),
                    nik_diketahui_struktural_5 : arrayStructural5[0],
                    jabatan_diketahui_struktural_5 : this.structural5Position,
                    nama_diketahui_struktural_5 : this.structural5.substring(this.structural5.indexOf("- ") + 1),
                    nik_diketahui_struktural_6 : arrayStructural6[0],
                    jabatan_diketahui_struktural_6 : this.structural6Position,
                    nama_diketahui_struktural_6 : this.structural6.substring(this.structural6.indexOf("- ") + 1),
                    nik_diketahui_fungsional_1 : this.noFunctional ? null : arrayFunctional1[0],
                    jabatan_diketahui_fungsional_1 : this.noFunctional ? null : this.functional1Position,
                    nama_diketahui_fungsional_1 : this.noFunctional ? null : this.functional1.substring(this.functional1.indexOf("- ") + 1),
                    nik_diketahui_fungsional_2 : this.noFunctional ? null : arrayFunctional2[0],
                    jabatan_diketahui_fungsional_2 : this.noFunctional ? null : this.functional2Position,
                    nama_diketahui_fungsional_2 : this.noFunctional ? null : this.functional2.substring(this.functional2.indexOf("- ") + 1),
                    nik_diketahui_fungsional_3 : this.noFunctional ? null : arrayFunctional3[0],
                    jabatan_diketahui_fungsional_3 : this.noFunctional ? null : this.functional3Position,
                    nama_diketahui_fungsional_3 : this.noFunctional ? null : this.functional3.substring(this.functional3.indexOf("- ") + 1),
                    nik_diketahui_fungsional_4 : this.noFunctional ? null : arrayFunctional4[0],
                    jabatan_diketahui_fungsional_4 : this.noFunctional ? null : this.functional4Position,
                    nama_diketahui_fungsional_4 : this.noFunctional ? null : this.functional4.substring(this.functional4.indexOf("- ") + 1),
                    nik_disetujui_1 : arrayApproved1[0],
                    jabatan_disetujui_1 : this.approved1Position,
                    nama_disetujui_1 : this.approved1.substring(this.approved1.indexOf("- ") + 1),
                    nik_disetujui_2 : arrayApproved2[0],
                    jabatan_disetujui_2 : this.approved2Position,
                    nama_disetujui_2 : this.approved2.substring(this.approved2.indexOf("- ") + 1),
                    nik_disetujui_3 : arrayApproved3[0],
                    jabatan_disetujui_3 : this.approved3Position,
                    nama_disetujui_3 : this.approved3.substring(this.approved3.indexOf("- ") + 1),
                    nik_disetujui_4 : arrayApproved4[0],
                    jabatan_disetujui_4 : this.approved4Position,
                    nama_disetujui_4 : this.approved4.substring(this.approved4.indexOf("- ") + 1),
                    nik_disetujui_5 : arrayApproved5[0],
                    jabatan_disetujui_5 : this.approved5Position,
                    nama_disetujui_5 : this.approved5.substring(this.approved5.indexOf("- ") + 1),
                    code: 4,
                    id: this.updateId
                }
                //console.log(JSON.stringify(postData))
                let iconType = ''
                let titleMessage = ''
                let textMessage = ''
                let iconColor = ''

                await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/approvers`, postData)
                    .then( response => {
                        iconType = 'success'
                        titleMessage = 'Perubahan data pejabat approval berhasil dikirim'
                        textMessage = this.department + ' - ' + response.data.result.nama_dibuat
                        iconColor = 'is-success'
                        //this.emptyField()
                        this.isSubmitLoading = false
                        this.$router.push({name: 'master-pejabat-approval'})
                    })
                    .catch( e => {
                        if (e.response.status != 401) {
                            iconType = 'error'
                            titleMessage = 'Gagal mengirim data pejabat approval!'
                            if (e.response.data.status && e.response.data.status != 0) {
                                textMessage = e.response.data.message
                            } else {
                                textMessage = 'Terjadi kesalahan pada server'
                            }
                            iconColor = 'is-danger'
                        }
                        
                        console.log(e)
                        this.isSubmitLoading = false
                    })
                    .finally(() => {
                        if (titleMessage) {
                            this.$swal({
                                icon: iconType,
                                title: titleMessage,
                                text: textMessage,
                                customClass: {
                                    icon: iconColor,
                                    title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                    htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                    confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                }
                            })
                        }
                    })
            }
        },

        userValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z0-9\s-]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async getDistinctGeneralPositions (departmentId) {
			this.isInputPositionLoading = true
			// const params = [
			// 	`id_master_departemen=${departmentId}`
			// ]
			let postData = {
				id_master_departemen: departmentId,
				code: 7
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData)
                .then( response => {
					this.filteredPositions = []
                    // response.data.result.forEach(element => {
                    //     this.filteredPositions.push(element)
                    // });
                    this.filteredPositions = response.data.result
					//console.log(this.masterDepartments)
					this.isInputPositionLoading = false
                })
                .catch( e => {
                    console.log(e)
                })
		},

        async setDepartmentId (departmentId) {
            if (departmentId && this.unit && this.branch) {
                await this.getDistinctGeneralPositions(departmentId)
                //await this.getUserByDepartment(this.unit, this.branch, departmentId)
                //await this.getUserByUnitBranch(this.unit, this.branch)
                //console.log(this.editData)
                if (this.editData == null && this.masterDepartmentsId != departmentId) {
                    this.creator = ''
                    this.structural1 = ''
                    this.structural2 = ''
                    this.structural3 = ''
                    this.structural4 = ''
                    this.structural5 = ''
                    this.structural6 = ''
                    this.functional1 = ''
                    this.functional2 = ''
                    this.functional3 = ''
                    this.functional4 = ''
                    this.approved1 = ''
                    this.approved2 = ''
                    this.approved3 = ''
                    this.approved4 = ''
                    this.approved5 = ''
                }

                this.masterDepartmentsId = departmentId
                
                if (this.employeesCreator.length == 1) {
                    this.creator = this.employeesCreator[0].username + " - " + this.employeesCreator[0].name
                    this.isCreatorDisabled = true
                    this.setPickCreator(true)
                }
            }
            this.setPickDepartment(true)
        },

        filterBranches (unit) {
            this.filteredBranches = this.distinctBranches.filter((option) => {
                return option.unit == unit
            })
            if (this.unit != unit) {
				this.branch = ''
				this.department = ''
            }
            this.setPickUnit(true)
        },

        filterDepartments (branch) {
            this.filteredDepartments = this.masterDepartments.filter((option) => {
                return option.cabang == branch && option.unit == this.unit
            })
            if (this.branch != branch) {
				this.department = ''
            }
            this.setPickBranch(true)
        },

        departmentPositionValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        onlyAlphaNum($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async getAllDepartments () {
            let postData = {
                code: 6
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterDepartments.push(element)
                    // });
                    this.masterDepartments = response.data.result
                    //console.log(this.masterDepartments)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        // searchEmployees: debounce(function (jobClass, employee) {
        //     let search = null
        //     if (employee == 'creator') {
        //         this.isCreatorLoading = true
        //         search = this.creator
        //     } else if (employee == 'structural1') {
        //         this.isStructural1Loading = true
        //         this.employeesStructural1 = []
        //         search = this.structural1
        //     } else if (employee == 'structural2') {
        //         this.isStructural2Loading = true
        //         this.employeesStructural2 = []
        //         search = this.structural2
        //     } else if (employee == 'disetujui1') {
        //         this.isDiset1Loading = true
        //         this.employeesDisetujui1 = []
        //         search = this.disetujui1
        //     } else if (employee == 'disetujui2') {
        //         this.isDiset2Loading = true
        //         this.employeesDisetujui2 = []
        //         search = this.disetujui2
        //     }

        //     const params = [
        //         `jobClass=${jobClass}`,
        //         `search=${search}`
        //     ].join('&')

        //     console.log(params)

        //     this.$axios.get(process.env.VUE_APP_GATEWAY_API + 'hrms/master-employee-job-classes?' + params)
        //         .then( response => {
        //             if (employee == 'creator') {
        //                 this.employeesCreator = []
        //                 response.data.result.forEach(element => {
        //                     this.employeesCreator.push(element)
        //                 })
        //                 this.isCreatorLoading = false
        //             } else if (employee == 'structural1') {
        //                 this.employeesStructural1 = []
        //                 response.data.result.forEach(element => {
        //                     this.employeesStructural1.push(element)
        //                 })
        //                 this.isStructural1Loading = false
        //             } else if (employee == 'structural2') {
        //                 this.employeesStructural2 = []
        //                 response.data.result.forEach(element => {
        //                     this.employeesStructural2.push(element)
        //                 })
        //                 this.isStructural2Loading = false
        //             } else if (employee == 'disetujui1') {
        //                 this.employeesDisetujui1 = []
        //                 response.data.result.forEach(element => {
        //                     this.employeesDisetujui1.push(element)
        //                 })
        //                 this.isDiset1Loading = false
        //             } else if (employee == 'disetujui2') {
        //                 this.employeesDisetujui2 = []
        //                 response.data.result.forEach(element => {
        //                     this.employeesDisetujui2.push(element)
        //                 })
        //                 this.isDiset2Loading = false
        //             }
        //         })
        //         .catch( e => {
        //             console.log(e)
        //         })
        // }, 400),

        // async getAllBranches () {
        //     let postData = {
        //         Data: {
        //             code: '2'
        //         }
        //     }

        //     await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
        //         .then( response => {
        //             response.data.Result.forEach(element => {
        //                 this.masterBranches.push(element)
        //             });
        //             //console.log(this.masterBranches)
        //         })
        //         .catch( e => {
        //             console.log(e)
        //         })
        // },

        async getDistinctBranches () {
            let postData = {
                code: 8
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.distinctBranches.push(element)
                    });
                    //console.log(this.distinctBranches)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getDistinctUnits () {
            let postData = {
                code: 7
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.distinctUnits.push(element)
                    });
                    //console.log(this.distinctUnits)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        clearDate () {
            this.selectedDate = null
        },

        goBack() {
            this.$router.push({name: 'master-pejabat-approval'})
        },

        // emptyField () {
        //     this.pkType = null
        //     this.location = null
        //     this.unit = null
        //     this.branch = null
        //     this.department = null
        //     this.creator  = null
        //     this.manager = null
        //     this.director = null
        //     this.final = null
        // },

        async createApproval () {
            this.isConfirmModal = false
            if (this.$refs.form.checkValidity()) {
                this.isSubmitLoading=true

                let arrayCreator = this.creator.split(" ")
                let arrayStructural1 = this.structural1.split(" ")
                let arrayStructural2 = this.structural2.split(" ")
                let arrayStructural3 = this.structural3.split(" ")
                let arrayStructural4 = this.structural4.split(" ")
                let arrayStructural5 = this.structural5.split(" ")
                let arrayStructural6 = this.structural6.split(" ")
                let arrayFunctional1 = this.functional1.split(" ")
                let arrayFunctional2 = this.functional2.split(" ")
                let arrayFunctional3 = this.functional3.split(" ")
                let arrayFunctional4 = this.functional4.split(" ")
                let arrayApproved1 = this.approved1.split(" ")
                let arrayApproved2 = this.approved2.split(" ")
                let arrayApproved3 = this.approved3.split(" ")
                let arrayApproved4 = this.approved4.split(" ")
                let arrayApproved5 = this.approved5.split(" ")

                let postData = {
                    id_master_departemen : this.masterDepartmentsId,
                    jab_umum : this.generalPosition,
                    jenis_pk : this.pkType,
                    alasan_permintaan : this.generalPosition == 'Clerk' ? this.reason == 1 ? "Baru" : "Pengganti" : null,
                    nik_dibuat : arrayCreator[0],
                    jabatan_dibuat : this.creatorPosition,
                    nama_dibuat : this.creator.substring(this.creator.indexOf("- ") + 1),
                    nik_diketahui_struktural_1 : arrayStructural1[0],
                    jabatan_diketahui_struktural_1 : this.structural1Position,
                    nama_diketahui_struktural_1 : this.structural1.substring(this.structural1.indexOf("- ") + 1),
                    nik_diketahui_struktural_2 : arrayStructural2[0],
                    jabatan_diketahui_struktural_2 : this.structural2Position,
                    nama_diketahui_struktural_2 : this.structural2.substring(this.structural2.indexOf("- ") + 1),
                    nik_diketahui_struktural_3 : arrayStructural3[0],
                    jabatan_diketahui_struktural_3 : this.structural3Position,
                    nama_diketahui_struktural_3 : this.structural3.substring(this.structural3.indexOf("- ") + 1),
                    nik_diketahui_struktural_4 : arrayStructural4[0],
                    jabatan_diketahui_struktural_4 : this.structural4Position,
                    nama_diketahui_struktural_4 : this.structural4.substring(this.structural4.indexOf("- ") + 1),
                    nik_diketahui_struktural_5 : arrayStructural5[0],
                    jabatan_diketahui_struktural_5 : this.structural5Position,
                    nama_diketahui_struktural_5 : this.structural5.substring(this.structural5.indexOf("- ") + 1),
                    nik_diketahui_struktural_6 : arrayStructural6[0],
                    jabatan_diketahui_struktural_6 : this.structural6Position,
                    nama_diketahui_struktural_6 : this.structural6.substring(this.structural6.indexOf("- ") + 1),
                    nik_diketahui_fungsional_1 : this.noFunctional ? null : arrayFunctional1[0],
                    jabatan_diketahui_fungsional_1 : this.noFunctional ? null : this.functional1Position,
                    nama_diketahui_fungsional_1 : this.noFunctional ? null : this.functional1.substring(this.functional1.indexOf("- ") + 1),
                    nik_diketahui_fungsional_2 : this.noFunctional ? null : arrayFunctional2[0],
                    jabatan_diketahui_fungsional_2 : this.noFunctional ? null : this.functional2Position,
                    nama_diketahui_fungsional_2 : this.noFunctional ? null : this.functional2.substring(this.functional2.indexOf("- ") + 1),
                    nik_diketahui_fungsional_3 : this.noFunctional ? null : arrayFunctional3[0],
                    jabatan_diketahui_fungsional_3 : this.noFunctional ? null : this.functional3Position,
                    nama_diketahui_fungsional_3 : this.noFunctional ? null : this.functional3.substring(this.functional3.indexOf("- ") + 1),
                    nik_diketahui_fungsional_4 : this.noFunctional ? null : arrayFunctional4[0],
                    jabatan_diketahui_fungsional_4 : this.noFunctional ? null : this.functional4Position,
                    nama_diketahui_fungsional_4 : this.noFunctional ? null : this.functional4.substring(this.functional4.indexOf("- ") + 1),
                    nik_disetujui_1 : arrayApproved1[0],
                    jabatan_disetujui_1 : this.approved1Position,
                    nama_disetujui_1 : this.approved1.substring(this.approved1.indexOf("- ") + 1),
                    nik_disetujui_2 : arrayApproved2[0],
                    jabatan_disetujui_2 : this.approved2Position,
                    nama_disetujui_2 : this.approved2.substring(this.approved2.indexOf("- ") + 1),
                    nik_disetujui_3 : arrayApproved3[0],
                    jabatan_disetujui_3 : this.approved3Position,
                    nama_disetujui_3 : this.approved3.substring(this.approved3.indexOf("- ") + 1),
                    nik_disetujui_4 : arrayApproved4[0],
                    jabatan_disetujui_4 : this.approved4Position,
                    nama_disetujui_4 : this.approved4.substring(this.approved4.indexOf("- ") + 1),
                    nik_disetujui_5 : arrayApproved5[0],
                    jabatan_disetujui_5 : this.approved5Position,
                    nama_disetujui_5 : this.approved5.substring(this.approved5.indexOf("- ") + 1),
                    code: 3
                }
                let iconType = ''
                let titleMessage = ''
                let textMessage = ''
                let iconColor = ''

                await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/approvers', postData)
                    .then( response => {
                        iconType = 'success'
                        titleMessage = 'Data pejabat approval baru berhasil dikirim'
                        iconColor = 'is-success'
                        //this.emptyField()
                        this.isSubmitLoading = false
                        this.$router.push({name: 'master-pejabat-approval'})
                    })
                    .catch( e => {
                        if (e.response.status != 401) {
                            iconType = 'error'
                            titleMessage = 'Gagal mengirim data pejabat approval!'
                            if (e.response.data.status && e.response.data.status != 0) {
                                textMessage = e.response.data.message
                            } else {
                                textMessage = 'Terjadi kesalahan pada server'
                            }
                            iconColor = 'is-danger'
                        }
                        
                        console.log(e)
                        this.isSubmitLoading = false
                    })
                    .finally(() => {
                        if (titleMessage) {
                            this.$swal({
                                icon: iconType,
                                title: titleMessage,
                                text: textMessage,
                                customClass: {
                                    icon: iconColor,
                                    title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                    htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                    confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                }
                            })
                        }
                    })
            }
        }
    }
};
</script>
