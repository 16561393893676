<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Master Pejabat Approval
				</div>
				<div class="columns">
					<div class="column is-one-fifth">
						<b-button
							expanded
							class="has-background-success has-text-white has-text-weight-bold"
							@click="routePage('add')"
						>TAMBAH BARU
						</b-button>
					</div>
					<div class="column is-one-fifth">
						<b-button
							expanded
							:loading="isDownloading"
							class="has-background-warning has-text-white has-text-weight-bold"
							@click="downloadTemplate"
						>UNDUH FORMAT
						</b-button>
					</div>
					<div class="column is-one-fifth">
						<b-field>
							<b-upload
								expanded
								:loading="isUploading"
								v-model="file"
								class="button has-background-primary-light has-text-white has-text-weight-bold"
								@input="uploadExcel"
								accept=".xlsx"
							><span>{{ file.name || "UNGGAH EXCEL"}}</span>
							</b-upload>
						</b-field>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						class="sticky-column"
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						backend-filtering
						@filters-change="onFilter"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:checkbox-position="checkboxPosition"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-1" cell-class="has-background-white sticky-master-approval-1" field="id" label="ID" centered v-slot="props" width="1px">
							{{props.row.id}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-2" cell-class="has-background-white sticky-master-approval-2" field="jenis_pk" label="JENIS PK" centered v-slot="props" width="2px">
							{{props.row.jenis_pk}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-3" cell-class="has-background-white sticky-master-approval-3" field="unit" label="UNIT/PT" centered v-slot="props" width="3px">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-4" cell-class="has-background-white sticky-master-approval-4" field="cabang" label="CABANG" centered v-slot="props" width="4px">
							{{props.row.cabang}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-5" cell-class="has-background-white sticky-master-approval-5" field="dept_khusus" label="BAGIAN/ SUB BAGIAN" centered v-slot="props" width="5px">
							{{props.row.dept_khusus}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-6" cell-class="has-background-white sticky-master-approval-6" field="jab_umum" label="JABATAN UMUM" centered v-slot="props" width="6px">
							{{props.row.jab_umum}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-7" cell-class="has-background-white sticky-master-approval-7" field="alasan_permintaan" label="ALASAN PERMINTAAN" centered v-slot="props" width="7px">
							{{props.row.alasan_permintaan ? props.row.alasan_permintaan : '-'}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_dibuat" label="DIBUAT" centered v-slot="props" width="-1">
							{{props.row.nik_dibuat}} - {{ props.row.nama_dibuat }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_1" label="DISETUJUI STRUKTURAL 1" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_1}} - {{ props.row.nama_diketahui_struktural_1 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_2" label="DISETUJUI STRUKTURAL 2" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_2}} - {{ props.row.nama_diketahui_struktural_2 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_3" label="DISETUJUI STRUKTURAL 3" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_3}} - {{ props.row.nama_diketahui_struktural_3 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_4" label="DISETUJUI STRUKTURAL 4" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_4}} - {{ props.row.nama_diketahui_struktural_4 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_5" label="DISETUJUI STRUKTURAL 5" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_5}} - {{ props.row.nama_diketahui_struktural_5 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_6" label="DISETUJUI STRUKTURAL 6" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_6}} - {{ props.row.nama_diketahui_struktural_6 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_fungsional_1" label="DISETUJUI FUNGSIONAL 1" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_fungsional_1}} - {{ props.row.nama_diketahui_fungsional_1 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_fungsional_2" label="DISETUJUI FUNGSIONAL 2" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_fungsional_2}} - {{ props.row.nama_diketahui_fungsional_2 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_fungsional_3" label="DISETUJUI FUNGSIONAL 3" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_fungsional_3}} - {{ props.row.nama_diketahui_fungsional_3 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_fungsional_4" label="DISETUJUI FUNGSIONAL 4" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_fungsional_4}} - {{ props.row.nama_diketahui_fungsional_4 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_1" label="DISETUJUI 1" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_1}} - {{ props.row.nama_disetujui_1 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_2" label="DISETUJUI 2" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_2}} - {{ props.row.nama_disetujui_2 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_3" label="DISETUJUI 3" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_3}} - {{ props.row.nama_disetujui_3 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_4" label="DISETUJUI 4" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_4}} - {{ props.row.nama_disetujui_4 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_5" label="DISETUJUI 5" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_5}} - {{ props.row.nama_disetujui_5 }}
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-warning has-text-white has-text-weight-bold"
									@click="routePage('edit')"
								>UBAH</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-danger-light has-text-white has-text-weight-bold"
									@click="openModal('delete')"
								>HAPUS</b-button>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isDeleteModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Hapus Pejabat Approval</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isDeleteModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menghapus data di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										class="mr-1"
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.dept_khusus}} - {{row.nama_dibuat}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="deleteApprovers"
									>YA</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isDeleteModal = false"
									>TIDAK</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'

    export default {
        data() {
            // const data = [
            //     { 
			// 		'id': 1,
			// 		'type': 'PK User',
			// 		'location': 'Head Office',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'branch': 'Head Office',
			// 		'department': 'IT Development SD 2',
			// 		'creator': '2015276116 - Arnan',
			// 		'manager': '2021212023 - Dwika',
			// 		'director': '293012312 - Diasmara',
			// 		'final': '1023912309 - Orang', },
            //     { 
			// 		'id': 2,
			// 		'type': 'PK Cadangan',
			// 		'location': 'Jakarta 2',
			// 		'unit': 'PT Inti Cakrawala Citra',
			// 		'branch': 'Head Office',
			// 		'department': 'Operation',
			// 		'creator': '204829123 - Nanra',
			// 		'manager': '247021932 - Kadwi',
			// 		'director': '203954821 - Dias',
			// 		'final': '1023912309 - Orang', },
            //     { 
			// 		'id': 3,
			// 		'type': 'PK Referensi',
			// 		'location': 'Penempatan',
			// 		'unit': 'Unit',
			// 		'branch': 'Cabang',
			// 		'department': 'Departemen',
			// 		'creator': 'nik - nama',
			// 		'manager': 'nik - nama',
			// 		'director': 'nik - nama',
			// 		'final': 'nik - nama', },
            //     { 
			// 		'id': 4,
			// 		'type': 'PK Referensi',
			// 		'location': 'Penempatan',
			// 		'unit': 'Unit',
			// 		'branch': 'Cabang',
			// 		'department': 'Departemen',
			// 		'creator': 'nik - nama',
			// 		'manager': 'nik - nama',
			// 		'director': 'nik - nama',
			// 		'final': 'nik - nama', },
            //     { 
			// 		'id': 5,
			// 		'type': 'PK Referensi',
			// 		'location': 'Penempatan',
			// 		'unit': 'Unit',
			// 		'branch': 'Cabang',
			// 		'department': 'Departemen',
			// 		'creator': 'nik - nama',
			// 		'manager': 'nik - nama',
			// 		'director': 'nik - nama',
			// 		'final': 'nik - nama', },
            //     { 
			// 		'id': 6,
			// 		'type': 'PK Referensi',
			// 		'location': 'Penempatan',
			// 		'unit': 'Unit',
			// 		'branch': 'Cabang',
			// 		'department': 'Departemen',
			// 		'creator': 'nik - nama',
			// 		'manager': 'nik - nama',
			// 		'director': 'nik - nama',
			// 		'final': 'nik - nama', },
            // ]

            return {
                data: [],
                checkboxPosition: 'left',
				defaultSortDirection: 'desc',
				sortOrder: 'desc',
				sortField: 'id',
				checkedRows: [],
				search: '',
				isApproveModal: false,
				isDeleteModal: false,
				textModal: '',
				total: 0,
				page: 1,
				varPageNumbering: 1,
				perPage: 10,
				isLoading: true,
				filter: {},
				isDownloading: false,
				file: [],
				isUploading: false,
				isheaderCheckable: true,
            }
		},
		
		async mounted () {
			await this.loadAsyncData()
		},

		methods: {
			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						this.checkedRows.push(row)
						// if (this.checkedRows.length <=4) {
						// 	this.checkedRows.push(row)
						// } else {
						// 	this.$buefy.snackbar.open({
						// 		message: 'Maksimal data yang dapat dipilih hanya 5',
						// 		type: 'is-info',
						// 		position: 'is-top',
						// 		actionText: 'Tutup',
						// 		indefinite: false,
						// 		duration: 7000
						// 	})
						// }
					}
				}
			},
			
			async uploadExcel() {
				this.isUploading = true
								
				const postData = new FormData()
				postData.set("file", this.file)
				postData.set("code", 9)
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''

				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/approvers', postData, {responseType: 'arraybuffer'})
					.then( response => {
						this.file = []
						let converter = require('arraybuffer-to-string');
						let convertResponse = converter(response.data); //terjemahkan response arraybuffer ke string
						if (convertResponse[0] == '{') {
							iconType = 'success',
							titleMessage = 'Data pejabat approval baru berhasil diunggah & disimpan',
							iconColor = 'is-success'
							//this.emptyField()
							this.isUploading = false
							this.loadAsyncData()
						} else {
							let blob=new Blob([response.data]);
							// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
							// 	window.navigator.msSaveOrOpenBlob(blob);
							// 	return;
							// }
							let link=document.createElement('a');
							link.href=window.URL.createObjectURL(blob);
							link.setAttribute('download', 'GagalUploadPejabatApproval.xlsx');
							link.click();
							setTimeout(function () {
								// For Firefox it is necessary to delay revoking the ObjectURL
								window.URL.revokeObjectURL(link.href)
									, 100
							})

							iconType = 'error'
							titleMessage = 'Gagal mengunggah & menyimpan sebagian data pejabat approval!'
							textMessage = 'Silakan cek data yang tidak masuk pada file excel yang terdownload kembali'
							iconColor = 'is-danger'

							this.isUploading = false
							this.loadAsyncData()
						}
					})
					.catch( e => {
						this.file = []
						console.log(e)
						this.file = []
						if (e.response.status != 401) {
							iconType = 'error'
							titleMessage = 'Gagal mengunggah & menyimpan data pejabat approval!'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server. Pastikan file yang diupload merupakan file template dari master pejabat approval'
							}
							iconColor = 'is-danger'
						}
						// let converter = require('arraybuffer-to-string');
						// let convertResponse = converter(e.response); //terjemahkan response arraybuffer ke string
						// let tempResponse = JSON.parse(convertResponse);
						// if (tempResponse.status != 401) {
						// 	convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
						// 	tempResponse = JSON.parse(convertResponse);
							
						// 	iconType = 'error'
						// 	titleMessage = 'Gagal mengunggah & menyimpan data pejabat approval!'
						// 	if (tempResponse.status && tempResponse.status != 0) {
						// 		textMessage = tempResponse.message
						// 	} else {
						// 		textMessage = 'Terjadi kesalahan pada server'
						// 	}
						// 	iconColor = 'is-danger'
						// }
						
						// console.log(e)
						this.isUploading = false
						this.loadAsyncData()
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								icon: iconType,
								title: titleMessage,
								text: textMessage,
								customClass: {
									icon: iconColor,
									title: 'has-text-primary is-family-primary has-text-weight-semibold',
									htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
									confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
								}
							})
						}
					})
			},

			async downloadTemplate () {
				this.isDownloading = true
				let postData = {
					code: 10
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/approvers', postData,
					{responseType: 'arraybuffer'})
					.then( response => {
						//handle kembalian file pdf
						let blob=new Blob([response.data]);
						// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
						// 	window.navigator.msSaveOrOpenBlob(blob);
						// 	return;
						// }
						let link=document.createElement('a');
						link.href=window.URL.createObjectURL(blob);
						link.setAttribute('download', 'TemplatePejabatApproval.xlsx');
						link.click();
						setTimeout(function () {
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(link.href)
								, 100
						})
					})
					.catch( e => {
						console.log(e)
						let converter = require('arraybuffer-to-string');
						let convertResponse = converter(e.response); //terjemahkan response arraybuffer ke string
						let tempMessage = JSON.parse(convertResponse);
						if (tempMessage.status != 401) {
							convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
							tempMessage = JSON.parse(convertResponse);
							let message = 'Terjadi kesalahan pada server'
							if (tempMessage.status && tempMessage.status != 0) {
								message = tempMessage.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(this.isDownloading = false)
			},

			onFilter (filter) {
                this.filter = filter
                this.loadAsyncData()
			},

			openModal(modalType) {
				if (this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin dihapus',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					if (modalType == 'delete') {
						this.isDeleteModal = true
					}
				}
			},

			async deleteApprovers () {
				this.isDeleteModal = false
				let sendData = []
				this.checkedRows.forEach( element => {
					let tempData = {
						id: element.id
					}
					sendData.push(tempData)
				})
				let postData = {
					row: sendData,
					code: 5
				}
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/approvers`, postData)
                    .then( response => {
                            iconType = 'success'
                            titleMessage = 'Data pejabat approval berhasil dihapus'
                            iconColor = 'is-success'
							this.loadAsyncData()
							this.checkedRows = []
					})
					.catch( e => {
						if (e.response.status != 401) {
							iconType = 'error'
							titleMessage = 'Gagal menghapus data pejabat approval!'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server'
							}
							iconColor = 'is-danger'
						}
						
						console.log(e)
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								icon: iconType,
								title: titleMessage,
								text: textMessage,
								customClass: {
									icon: iconColor,
									title: 'has-text-primary is-family-primary has-text-weight-semibold',
									htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
									confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
								}
							})
						}
					})
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id')) {
                    pushFilter.id = ""
                } else {
					pushFilter.id = this.filter.id
				}
                if (!this.filter.hasOwnProperty('jenis_pk')) {
                    pushFilter.jenis_pk = ""
                } else {
					pushFilter.jenis_pk = this.filter.jenis_pk
				}
                if (!this.filter.hasOwnProperty('unit')) {
                    pushFilter.unit = ""
                } else {
					pushFilter.unit = this.filter.unit
				}
                if (!this.filter.hasOwnProperty('cabang')) {
                    pushFilter.cabang = ""
                } else {
					pushFilter.cabang = this.filter.cabang
				}
                if (!this.filter.hasOwnProperty('dept_khusus')) {
                    pushFilter.dept_khusus = ""
                } else {
					pushFilter.dept_khusus = this.filter.dept_khusus
				}
                if (!this.filter.hasOwnProperty('jab_umum')) {
                    pushFilter.jab_umum = ""
				} else {
					pushFilter.jab_umum = this.filter.jab_umum
				}
				if (!this.filter.hasOwnProperty('alasan_permintaan')) {
                    pushFilter.alasan_permintaan = ""
				} else {
					pushFilter.alasan_permintaan = this.filter.alasan_permintaan
				}
				if (!this.filter.hasOwnProperty('nik_dibuat')) {
                    pushFilter.nik_dibuat = ""
				} else {
					pushFilter.nik_dibuat = this.filter.nik_dibuat
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_1')) {
                    pushFilter.nik_diketahui_struktural_1 = ""
				} else {
					pushFilter.nik_diketahui_struktural_1 = this.filter.nik_diketahui_struktural_1
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_2')) {
                    pushFilter.nik_diketahui_struktural_2 = ""
				} else {
					pushFilter.nik_diketahui_struktural_2 = this.filter.nik_diketahui_struktural_2
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_3')) {
                    pushFilter.nik_diketahui_struktural_3 = ""
				} else {
					pushFilter.nik_diketahui_struktural_3 = this.filter.nik_diketahui_struktural_3
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_4')) {
                    pushFilter.nik_diketahui_struktural_4 = ""
				} else {
					pushFilter.nik_diketahui_struktural_4 = this.filter.nik_diketahui_struktural_4
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_5')) {
                    pushFilter.nik_diketahui_struktural_5 = ""
				} else {
					pushFilter.nik_diketahui_struktural_5 = this.filter.nik_diketahui_struktural_5
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_6')) {
                    pushFilter.nik_diketahui_struktural_6 = ""
				} else {
					pushFilter.nik_diketahui_struktural_6 = this.filter.nik_diketahui_struktural_6
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_fungsional_1')) {
                    pushFilter.nik_diketahui_fungsional_1 = ""
				} else {
					pushFilter.nik_diketahui_fungsional_1 = this.filter.nik_diketahui_fungsional_1
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_fungsional_2')) {
                    pushFilter.nik_diketahui_fungsional_2 = ""
				} else {
					pushFilter.nik_diketahui_fungsional_2 = this.filter.nik_diketahui_fungsional_2
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_fungsional_3')) {
                    pushFilter.nik_diketahui_fungsional_3 = ""
				} else {
					pushFilter.nik_diketahui_fungsional_3 = this.filter.nik_diketahui_fungsional_3
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_fungsional_4')) {
                    pushFilter.nik_diketahui_fungsional_4 = ""
				} else {
					pushFilter.nik_diketahui_fungsional_4 = this.filter.nik_diketahui_fungsional_4
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_1')) {
                    pushFilter.nik_disetujui_1 = ""
				} else {
					pushFilter.nik_disetujui_1 = this.filter.nik_disetujui_1
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_2')) {
                    pushFilter.nik_disetujui_2 = ""
				} else {
					pushFilter.nik_disetujui_2 = this.filter.nik_disetujui_2
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_3')) {
                    pushFilter.nik_disetujui_3 = ""
				} else {
					pushFilter.nik_disetujui_3 = this.filter.nik_disetujui_3
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_4')) {
                    pushFilter.nik_disetujui_4 = ""
				} else {
					pushFilter.nik_disetujui_4 = this.filter.nik_disetujui_4
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_5')) {
                    pushFilter.nik_disetujui_5 = ""
				} else {
					pushFilter.nik_disetujui_5 = this.filter.nik_disetujui_5
				}

				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
                //     `column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
                //     `page=${this.page}`,
				// 	`search=${searchBypass}`
				// ].join('&')
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					filter: JSON.stringify(pushFilter),
					code: 1
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/approvers`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
            }, 400),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

			routePage(routingTo) {
				if (routingTo == 'edit' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'edit' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 data master untuk diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					let master = null
					if (routingTo == 'edit') {
						master = this.checkedRows[0]
					}
					this.$router.push({name: 'form-pejabat-approval', params: { routingTo: routingTo, master: master }})
				}
			}
		}
    }
</script>
