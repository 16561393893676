<template>
	<div class="container hero">
		<div class="mx-5">
			<b-field class="has-text-weight-semibold has-text-primary is-size-3">
				Pengajuan PK
			</b-field>
			<br>
			<ValidationObserver ref="observer">
				<form ref="form">
					<div class="ml-5 mt-3 has-text-weight-medium has-text-primary">
						<div class="columns is-desktop">
							<div class="column is-9">
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Jenis PK<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-select :loading="isInputLoading" placeholder="" expanded class="box-field" v-model="PKType" @input="checkToGetPosition" required>
											<option
												v-for="tempDataPKType in dataPKTypes"
												:value="tempDataPKType.jenis_pk"
												:key="tempDataPKType.jenis_pk">
												{{tempDataPKType.jenis_pk}}
											</option>
											<!-- <option>User</option>
											<option
												:class="!(access.group == 'RnP 2' || access.group == 'Recruitment Cabang') ? 'has-background-gray' : ''"
												:disabled="!(access.group == 'RnP 2' || access.group == 'Recruitment Cabang')">Wilayah Baru (Cabang / DCI)</option>
											<option
												:class="access.role != 'Admin' ? 'has-background-gray' : ''"
												:disabled="access.role != 'Admin'">Referensi</option> -->
										</b-select>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<!-- <b-field 
									v-if="PKType ? PKType == 'User' ? true : false : false"
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">ID PK Cadangan</span>
									</template>
									<b-autocomplete
										:loading="isInputLoading"
										open-on-focus
										v-model="pkBackupId"
										:data="getAsyncPkBackupIdData"
										field="id_pk"
										@select="option => setAllInput((option ? option.id : null))"
										placeholder="Ketik dan pilih dari dropdown"
										@keypress.native="IdPkValidation"
										pattern="^[A-Za-z0-9\s-]*"
										validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip">
										<template slot="empty">
											Tidak ditemukan hasil untuk
											{{ pkBackupId }}
										</template>
									</b-autocomplete>
								</b-field> -->
								<b-field 
									class="hz-label is-flex is-align-items-center"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Unit/PT<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-autocomplete
											:disabled="isUnitDisabled"
											:loading="isInputLoading"
											open-on-focus
											v-model="unit"
											:data="getAsyncUnitData"
											field="unit"
											@typing="setPickUnit(false)"
											@select="option => filterBranches((option ? option.unit : null), (option ? option.unit_code : null))"
											placeholder="Ketik dan pilih dari dropdown"
											@keypress.native="departmentPositionValidation"
											pattern="^[A-Za-z0-9\s\-&().+/]*"
											validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
											required>
											<template slot="empty">
												Tidak ditemukan hasil untuk
												{{ unit }}
											</template>
										</b-autocomplete>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Cabang<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-autocomplete
											:disabled="isBranchDisabled"
											:loading="isInputLoading"
											open-on-focus
											v-model="branch"
											:data="getAsyncBranchData"
											field="cabang"
											@typing="setPickBranch(false)"
											@select="option => filterDepartments((option ? option.cabang : null))"
											placeholder="Ketik dan pilih dari dropdown"
											@keypress.native="departmentPositionValidation"
											pattern="^[A-Za-z0-9\s\-&().+/]*"
											validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
											required>
											<template slot="empty">
												Tidak ditemukan hasil untuk
												{{ branch }}
											</template>
										</b-autocomplete>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Bagian/Sub Bagian<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-autocomplete
											:disabled="isDepartmentDisabled"
											:loading="isInputLoading"
											open-on-focus
											v-model="department"
											:data="getAsyncDepartmentData"
											field="dept_khusus"
											@typing="setPickDepartment(false)"
											@select="option => filterPositions((option ? option.unit : null), (option ? option.cabang : null), (option ? option.direktorat : null), (option ? option.dept_umum : null), (option ? option.dept_khusus : null))"
											placeholder="Ketik dan pilih dari dropdown"
											@keypress.native="departmentPositionValidation"
											pattern="^[A-Za-z0-9\s\-&().+/]*"
											validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
											required>
											<template slot="empty">
												Tidak ditemukan hasil untuk
												{{ department }}
											</template>
										</b-autocomplete>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Jabatan Umum<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-autocomplete
											:loading="isInputPositionLoading"
											open-on-focus
											v-model="generalPosition"
											:data="getAsyncGeneralPositionData"
											@typing="setPickGeneralPosition(false)"
											@select="option => filterSpecificPositions((option ? option.jab_umum : null))"
											field="jab_umum"
											placeholder="Ketik dan pilih dari dropdown"
											@keypress.native="departmentPositionValidation"
											pattern="^[A-Za-z0-9\s\-&().+/]*"
											validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
											required>
											<template slot="empty">
												Tidak ditemukan hasil untuk
												{{ generalPosition }}
											</template>
										</b-autocomplete>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Jabatan Khusus<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-autocomplete
											:loading="isInputPositionLoading"
											open-on-focus
											v-model="specificPosition"
											:data="getAsyncSpecificPositionData"
											@typing="setPickSpecificPosition(false)"
											@select="option => filterTechnicalPositions((option ? option.jab_khusus : null))"
											field="jab_khusus"
											placeholder="Ketik dan pilih dari dropdown"
											@keypress.native="departmentPositionValidation"
											pattern="^[A-Za-z0-9\s\-&().+/]*"
											validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
											required>
											<template slot="empty">
												Tidak ditemukan hasil untuk
												{{ specificPosition }}
											</template>
										</b-autocomplete>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Jabatan Teknis<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-autocomplete
											:loading="isInputPositionLoading"
											open-on-focus
											v-model="technicalPosition"
											:data="getAsyncTechnicalPositionData"
											@typing="setPickTechnicalPosition(false)"
											@select="option => setJabatanValue((option ? option.jab_teknis : null))"
											field="jab_teknis"
											placeholder="Ketik dan pilih dari dropdown"
											@keypress.native="departmentPositionValidation"
											pattern="^[A-Za-z0-9\s\-&().+/]*"
											validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
											required>
											<template slot="empty">
												Tidak ditemukan hasil untuk
												{{ technicalPosition }}
											</template>
										</b-autocomplete>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop is-flex is-align-items-center">
							<div class="column is-3">
								<span class="is-family-primary has-text-weight-medium has-text-left">Job Class<span class="mandatory">*</span></span>
							</div>
							<div class="column is-2">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-input 
										class="box-field"
										:loading="isInputLoading"
										v-model="jobClassFrom"
										required
										disabled
									></b-input>
									<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
								</ValidationProvider>
							</div>
							<div class="column is-1">
								<div class="is-family-primary has-text-weight-medium">to</div>
							</div>
							<div class="column is-2">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-input 
										class="box-field"
										:loading="isInputLoading"
										v-model="jobClassTo"
										required
										disabled
									></b-input>
									<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
								</ValidationProvider>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-9">
								<b-field 
									class="hz-label my-5 is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Jumlah Hari Kerja<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-radio
											v-model="workingDays"
											name="workingDays"
											native-value="5"
											disabled>
											5 hari
										</b-radio>
										<b-radio
											v-model="workingDays"
											name="workingDays"
											native-value="6"
											disabled>
											6 hari
										</b-radio>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label my-5 is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Shift<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-radio
											v-model="shift"
											name="shift"
											native-value="NS"
											disabled>
											Non Shift
										</b-radio>
										<b-radio
											v-model="shift"
											name="shift"
											native-value="2S"
											disabled>
											2 Shift
										</b-radio>
										<b-radio
											v-model="shift"
											name="shift"
											native-value="3S"
											disabled>
											3 Shift
										</b-radio>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Status Kerja<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-select placeholder="" expanded v-model="workStatus" required>
											<option>Kontrak</option>
											<option>Tetap</option>
										</b-select>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Penempatan<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-select v-model="placement" placeholder="" expanded required>
											<option
												v-for="tempPlacement in placements"
												:value="tempPlacement.id"
												:key="tempPlacement.id">
												{{tempPlacement.nama}}
											</option>
										</b-select>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
							</div>
						</div>
						<div
							:key="placement"
							:class="placement ? placement == '1' ? 'columns is-desktop is-hidden' : 'columns is-desktop' : 'columns is-desktop is-hidden'"
						>
							<div class="column is-9 is-offset-1" id="elLocationBranch">
								<b-field 
									class="hz-label-right is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Lokasi Kerja<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }" v-if="placement == 2">
										<b-autocomplete
											v-if="placement == 2"
											:loading="isInputLoading"
											open-on-focus
											v-model="locationBranch"
											:data="getAsyncLocationBranchData"
											field="AREANAME"
											@typing="setPickLocationBranch(false)"
											@select="setPickLocationBranch(true)"
											placeholder="Ketik dan pilih dari dropdown"
											@keypress.native="validateWorkLocation"
											pattern="^[A-Za-z0-9\s\-&().+/]*"
											validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
											:required="placement == 2"
											expanded>
											<template slot="empty">
												Tidak ditemukan hasil untuk
												{{ locationBranch }}
											</template>
										</b-autocomplete>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
									<div>
										<ValidationProvider rules="required" v-slot="{ errors }" v-if="placement > 2">
											<b-input
												v-if="placement > 2"
												:loading="isInputLoading"
												v-model="location"
												@keypress.native="validateWorkLocation"
												pattern="^[A-Za-z0-9\s\-&().+/]*"
												validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
												:required="placement > 2"
												expanded>
											</b-input>
											<span v-if="placement != 2" class="is-size-7">*Pengisian wajib ditulis spesifik. Misal, Store/Outlet: Mister Donut Ciputra World</span>
											<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
										</ValidationProvider>
									</div>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-9" id="elMutation">
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Alasan Permintaan<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-select placeholder="" expanded v-model="reason" @input="fieldValidation" required>
											<option
												v-for="tempReason in reasonPK"
												:value="tempReason.id"
												:key="tempReason.id">
												{{tempReason.alasan}}
											</option>
										</b-select>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
							</div>
						</div>
						<div
							v-if="reason == '2'"
							:class="reason == '2' ? 'columns is-desktop' : 'columns is-desktop  is-hidden'"
						>
							<div class="column is-9 is-offset-1">
								<b-field class="hz-label-right is-flex is-align-items-center" label="" horizontal>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">NIK Keluar/Mutasi/Promosi</span>
									</template>
									<b-autocomplete
										:loading="isNikLoading"
										open-on-focus
										v-model="mutationNik"
										:data="employeesNik"
										maxlength="10"
										minlength="10"
										field="NIK"
										@typing="setSelectingAndSearch('nik', false)"
										@select.self="option => fillName((option ? option.NAMA : null), (option ? option.TANGGAL : null))"
										placeholder="Ketik dan pilih dari dropdown"
										@keypress.native="onlyNumber"
										pattern="^[0-9]*"
										validation-message="Hanya bisa angka">
										<template slot="empty">
											Tidak ditemukan hasil untuk
											{{ mutationNik }}
										</template>
									</b-autocomplete>
								</b-field>
								<b-field class="hz-label-right" label="" horizontal>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Nama Karyawan</span>
									</template>
									<b-autocomplete
										:loading="isNameLoading"
										open-on-focus
										v-model="mutationName"
										:data="employeesName"
										field="NAMA"
										@typing="setSelectingAndSearch('name', false)"
										@select.self="option => fillNik((option ? option.NIK : null), (option ? option.TANGGAL : null))"
										placeholder="Ketik dan pilih dari dropdown"
										@keypress.native="onlyAlphabet"
										pattern="^[A-Za-z\s]*"
										validation-message="Hanya bisa alfabet dan spasi">
										<template slot="empty">
											Tidak ditemukan hasil untuk
											{{ mutationName }}
										</template>
									</b-autocomplete>
								</b-field>
								<b-field class="hz-label-right is-flex is-align-items-center" label="" horizontal>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Per Tanggal</span>
									</template>
									<b-input 
										v-model="mutationDateTemp"
										required
										disabled
									></b-input>
									<!-- <b-datepicker
										v-model="mutationDateTemp"
										:date-formatter="dateFormatter"
										@input="saveDate"
										icon-pack="fas"
										icon-right="calendar-alt"
										trap-focus
										:mobile-native="false">
									</b-datepicker> -->
								</b-field>
								<div class="">
									<b-field class="">
										<b-button
											expanded
											class="has-background-success has-text-white has-text-weight-bold"
											@click="addMutationEmployee()"
										>+</b-button>
									</b-field>
								<br>
								</div>
								<div v-show="mutationArray.length > 0">
									<div
										v-for="(mutation, index) in mutationArray"
										:key="mutation.nik_mutasi"
									>
										<b-field class="mb-3 is-flex is-align-items-center">
											<!-- <b-tag
												close-type='is-danger'
												size="is-medium"
												attached
												closable
												aria-close-label="Close tag"
												>
												{{mutation.nik_mutasi}}/{{mutation.nama_mutasi}} || {{mutation.tanggal_mutasi}}
											</b-tag> -->
											<b-input
                                                :value="mutation.nik_mutasi + ' / ' + mutation.nama_mutasi + '  ||  ' + mutation.tanggal_mutasi"
                                                expanded
                                                readonly>
                                            </b-input>
                                            <p class="control">
                                                <b-button
                                                    type="is-danger"
                                                    label="X"
                                                    @click="deleteMutationArray(index)"
                                                />
                                            </p>
										</b-field>
									</div>
								</div>
								<div v-show="mutationArray.length == 0">
									<p class="has-text-danger">Belum ada data karyawan keluar/mutasi/promosi yang ditambahkan</p>
								</div>
								<!-- <b-field 
									class="hz-label" 
									label="Lokasi Mutasi" 
									horizontal>
									<b-select placeholder="" expanded>
										<option>Pilihan 1</option>
										<option>Pilihan 2</option>
									</b-select>
								</b-field>
								<b-field 
									class="hz-label" 
									label="Upload File" 
									horizontal>
									<b-field class="file is-dark" :class="{'has-name': !!file}">
										<b-upload v-model="file" class="file-label">
											<span class="file-cta">
												<b-icon class="file-icon" icon="upload"></b-icon>
												<span class="file-label">{{ file.name || "Klik untuk mengunggah"}}</span>
											</span>
										</b-upload>
									</b-field>
								</b-field> -->
							</div>
						</div>
						<div
							class="columns is-desktop"
						>
							<div class="column is-9">
								<b-field 
									class="hz-label is-flex is-align-items-center"
									label="" 
									horizontal
								>
									<template #label>
										<span
											:class="reason == 2 ? 'is-family-primary has-text-weight-medium has-text-grey' : 'is-family-primary has-text-weight-medium'"
										>
											Sisa budget
											<span
												:class="reason == 2 ? '' : 'mandatory'">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-input
											:loading="isInputLoading"
											v-model="budget"
											required
											disabled></b-input>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-9">
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									id="elTotalPeople"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Jumlah Kebutuhan<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-numberinput
											:disabled="!isNewEmployee"
											:loading="isInputLoading"
											v-model="totalPeople"
											@input="validateTotal"
											controls-alignment="right"
											icon-pack="fa"
											type="is-primary"
											min="1"
											required>
										</b-numberinput>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
							</div>
							<div class="column is-3" v-if="!isValidTotal">
								<div class="is-family-primary has-text-weight-medium has-text-danger">Total laki-laki, perempuan, bebas harus sama dengan jumlah kebutuhan</div>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-9 is-offset-1 minMaxValidation">
								<b-field 
									class="hz-label-right is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Laki - Laki</span>
									</template>
									<ValidationProvider :rules="`required|minMax:0,${totalPeople - women - bothGender},${totalPeople}`" v-slot="{ errors }">
										<b-numberinput
											:disabled="totalPeople - women - bothGender <= men && men <= women && men <= bothGender && !(men == women && men == bothGender && women == bothGender)"
											:loading="isInputLoading"
											v-model="men"
											@input="validateTotal"
											controls-alignment="right"
											icon-pack="fa"
											type="is-primary"
											min="0"
											:max="totalPeople - women - bothGender"
											required>
										</b-numberinput>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
									<!-- <b-input
										v-if="gender == 'Laki-laki'"
										disabled
										:loading="isInputLoading"
										v-model="men"
										:required="gender == 'Laki-laki'">
									</b-input> -->
								</b-field>
								<b-field 
									class="hz-label-right is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Perempuan</span>
									</template>
									<ValidationProvider :rules="`required|minMax:0,${totalPeople - men - bothGender},${totalPeople}`" v-slot="{ errors }">
										<b-numberinput
											:disabled="totalPeople - men - bothGender <= women && women <= men && women <= bothGender && !(men == women && men == bothGender && women == bothGender)"
											:loading="isInputLoading"
											v-model="women"
											@input="validateTotal"
											controls-alignment="right"
											icon-pack="fa"
											type="is-primary"
											min="0"
											:max="totalPeople - men - bothGender"
											required>
										</b-numberinput>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
									<!-- <b-input
										v-if="gender == 'Perempuan'"
										disabled
										:loading="isInputLoading"
										v-model="women"
										:required="gender == 'Perempuan'">
									</b-input> -->
								</b-field>
								<b-field 
									class="hz-label-right is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Bebas</span>
									</template>
									<ValidationProvider :rules="`required|minMax:0,${totalPeople - men - women},${totalPeople}`" v-slot="{ errors }">
										<b-numberinput
											:disabled="totalPeople - men - women <= bothGender && bothGender <= men && bothGender <= women && !(men == women && men == bothGender && women == bothGender)"
											:loading="isInputLoading"
											v-model="bothGender"
											@input="validateTotal"
											controls-alignment="right"
											icon-pack="fa"
											type="is-primary"
											min="0"
											:max="totalPeople - men - women"
											required>
										</b-numberinput>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
									<!-- <b-input
										:loading="isInputLoading"
										v-model="bothGender"
										@keypress.native="onlyNumber"
										@input="validateTotal"
										pattern="^[0-9]*"
										validation-message="Hanya bisa angka"
										required>
									</b-input> -->
								</b-field>
							</div>
						</div>
						<!-- <b-field 
							label=""
							class="hz-label is-flex is-align-items-center"
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Apakah jumlah kebutuhan yang akan anda input melebihi sisa budget?<span class="mandatory">*</span></span>
							</template>
							<div class="block has-text-left mt-3">
								<b-radio
									v-model="enoughBudget"
									name="enoughBudget"
									native-value="Ya">
									Ya
								</b-radio>
								<b-radio
									v-model="enoughBudget"
									name="enoughBudget"
									native-value="Tidak">
									Tidak
								</b-radio>
							</div>
						</b-field> -->
						<div
							v-show="(reason != '2' && exceedBudget) || PKType != 'User'"
							:class="(reason != '2' && exceedBudget) || PKType != 'User' ? 'columns is-desktop' : 'columns is-desktop  is-hidden'"
						>
							<div class="column is-9">
								<b-field 
									class="hz-label is-flex is-justify-content-center" 
									id="elBA"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Upload Berita Acara<span v-if="PKType != 'Wilayah Baru (Cabang / DCI)'" class="mandatory">*</span></span>
									</template>
									<b-field class="file is-dark" :class="{'has-name': !!fileBA}">
										<ValidationProvider rules="required" v-show="(reason != '2' && exceedBudget) || PKType != 'User'">
											<b-upload v-model="fileBA" class="file-label" accept=".pdf" :required="(reason != '2' && exceedBudget && PKType == 'User') || PKType == 'Referensi'" @input="errorsFileBA = null">
												<span class="button has-background-success has-text-white is-12">
													<span class="has-text-weight-medium file-label is-family-primary">{{ fileBA.name || "Upload File"}}</span>
												</span>
											</b-upload>
											<p class="is-size-7">*File pdf ukuran maksimal 1 MB</p>
											<p class="is-size-7 has-text-danger">{{ errorsFileBA }}</p>
										</ValidationProvider>
									</b-field>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-9">
								<b-field 
									class="hz-label is-flex is-align-items-center"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Lead Time<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-input 
											:loading="isLeadTimeLoading"
											v-model="leadTime"
											required
											disabled
										></b-input>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Life Time<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-input 
											:loading="isInputLoading"
											v-model="lifeTime"
											required
											disabled
										></b-input>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-9">
								<b-field 
									class="hz-label is-flex is-align-items-center"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Pendidikan<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-input 
											:loading="isInputLoading"
											v-model="education"
											required
											disabled
										></b-input>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Jurusan<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-input 
											:loading="isInputLoading"
											v-model="educationDiscipline"
											required
											disabled
										></b-input>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Status Pengalaman Kerja<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-input 
											:loading="isInputLoading"
											v-model="experience"
											required
											disabled
										></b-input>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
								<b-field 
									class="hz-label is-flex is-align-items-center"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Usia Maksimal<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-input 
											:loading="isInputLoading"
											v-model="old"
											required
											disabled
										></b-input>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
							</div>
						</div>
						<b-field class="hz-label-area is-flex is-align-items-center" label="" horizontal>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Keterampilan Khusus<span class="mandatory">*</span></span>
							</template>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-input
									class="max-width-area"
									maxlength="200"
									type="textarea"
									icon-pack="fas"
									icon-right="pencil-alt"
									:loading="isInputLoading"
									v-model="skill"
									required></b-input>
								<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
							</ValidationProvider>
						</b-field>
						<b-field class="hz-label-area is-flex is-align-items-center" label="" horizontal>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Deskripsi Pekerjaan<span class="mandatory">*</span></span>
							</template>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-input
									class="max-width-area"
									maxlength="200"
									type="textarea"
									icon-pack="fas"
									icon-right="pencil-alt"
									:loading="isInputLoading"
									v-model="description"
									required></b-input>
								<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
							</ValidationProvider>
						</b-field>
						<div
							class="columns is-desktop"
						>
							<div class="column is-9">
								<b-field 
									class="hz-label is-flex is-justify-content-center" 
									id="elStruktur"
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Struktur Organisasi<span class="mandatory">*</span></span>
									</template>
									<b-field class="file is-dark" :class="{'has-name': !!fileOrganization}">
										<ValidationProvider rules="required">
											<b-upload v-model="fileOrganization" class="file-label" accept=".pdf" required @input="errorsFileOrganization = null">
												<span class="button has-background-success has-text-white is-12">
													<span class="has-text-weight-medium file-label is-family-primary">{{ fileOrganization.name || "Upload File"}}</span>
												</span>
											</b-upload>
											<p class="is-size-7">*File pdf ukuran maksimal 1 MB</p>
											<p class="is-size-7 has-text-danger">{{ errorsFileOrganization }}</p>
										</ValidationProvider>
									</b-field>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-9">
								<b-field 
									class="hz-label is-flex is-align-items-center" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Sumber Pemenuhan<span class="mandatory">*</span></span>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-select placeholder="" expanded v-model="source" required disabled>
											<option value="Baru" selected>Karyawan Baru</option>
											<!-- <option class="has-background-gray" disabled value="Existing">Karyawan Existing (Belum tersedia)</option> -->
										</b-select>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</b-field>
							</div>
						</div>
						<!-- <b-field 
							label=""
							class="hz-label"
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Apakah anda mengetahui identitas karyawan yang mutasi?<span class="mandatory">*</span></span>
							</template>
							<div class="block has-text-left mt-3">
								<b-radio
									v-model="isRecognized"
									name="radio"
									native-value="Ya">
									Ya
								</b-radio>
								<b-radio
									v-model="isRecognized"
									name="radio"
									native-value="Tidak">
									Tidak
								</b-radio>
							</div>
						</b-field>
						<div
							:key="isRecognized"
							:class="isRecognized == 'Ya' ? 'column is-desktop' : 'column is-desktop  is-hidden'"
						>
							<div class="column is-half is-offset-one-quarter">
								<b-field>
										<b-field class="mr-4" label="" horizontal>
											<template #label>
												<span class="is-family-primary has-text-weight-medium">NIK<span class="mandatory">*</span></span>
											</template>
											<b-input 
												value="" 
											></b-input>
										</b-field>
										<b-field class="" label="" horizontal>
											<template #label>
												<span class="is-family-primary has-text-weight-medium">Nama<span class="mandatory">*</span></span>
											</template>
											<b-input 
												value="" 
											></b-input>
										</b-field>
								</b-field>
								<b-field 
									class="hz-label" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Cabang Sebelum<span class="mandatory">*</span></span>
									</template>
									<b-select placeholder="" expanded>
										<option>Pilihan 1</option>
										<option>Pilihan 2</option>
									</b-select>
								</b-field>
								<b-field 
									class="hz-label" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Dept Sebelum<span class="mandatory">*</span></span>
									</template>
									<b-select placeholder="" expanded>
										<option>Pilihan 1</option>
										<option>Pilihan 2</option>
									</b-select>
								</b-field>
								<b-field 
									class="hz-label" 
									label="" 
									horizontal
								>
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Tanggal Mutasi<span class="mandatory">*</span></span>
									</template>
									<b-datepicker
										v-model="selectedDate"
										locale="en-GB"
										icon-pack="fas"
										icon="calendar-alt"
										:icon-right="selectedDate ? 'close-circle' : ''"
										icon-right-clickable
										@icon-right-click="clearDate"
										trap-focus>
									</b-datepicker>
								</b-field>
							</div>
						</div> -->
						<b-field class="has-text-centered mt-5">
							<b-button
								:loading="isSubmitLoading"
								class="is-size-5 has-background-success has-text-white has-text-weight-bold"
								@click="createPK">
							Simpan</b-button>
						</b-field>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import debounce from 'lodash.debounce'
import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {
			pkBackups: [],
			pkBackupId: '',
			unit: '',
			unitId: null,
			masterUnits: [],
			distinctUnits: [],
			branch: '',
			distinctBranches: [],
			department: '',
			distinctDepartments: [],
			masterDepartments: null,
			filteredBranches: [],
			filteredDepartments: [],
			masterDepartmentsId: null,
			generalPosition: '',
			specificPosition: '',
			technicalPosition: '',
			masterPositions: null,
			filteredPositions: [],
			filteredSpecificPositions: [],
			filteredTechnicalPositions: [],
			masterPositionsId: null,
			fileBA: {},
			fileOrganization: {},
			enoughBudget: null,
			reason: null,
			mutationNik: null,
			employeesNik: [],
			mutationName: null,
			employeesName: [],
			mutationDateTemp: null,
			mutationDate: null,
			isRecognized: null,
			jobClassFrom: null,
			jobClassTo: null,
			workingDays: null,
			shift: null,
			placement: null,
			dataPKTypes: [],
			PKType: null,
			old: null,
			gender: 'Bebas',
			education: null,
			experience: null,
			educationDiscipline: null,
			description: null,
			skill: null,
			masterBranches: [],
			locationBranch: '',
			location: '',
			workStatus: null,
			budget: null,
			totalPeople: null,
			men: null,
			women: null,
			bothGender: null,
			isValidTotal: false,
			exceedBudget: false,
			masterLeadTimes: null,
			leadTime: null,
			leadTimeId: null,
			lifeTime: null,
			source: 'Baru',
			isScrolled: false,
			pickUnit: false,
			pickBranch: false,
			pickDepartment: false,
			pickGeneralPosition: false,
			pickSpecificPosition: false,
			pickTechnicalPosition: false,
			pickLocationBranch: false,
			placements: [
				{
					'id': 1,
					'nama': 'Head Office'
				},
				{
					'id': 2,
					'nama': 'Cabang / Depo'
				},
				{
					'id': 3,
					'nama': 'Pabrik'
				},
				{
					'id': 4,
					'nama': 'Store / Outlet'
				},
				{
					'id': 5,
					'nama': 'Restoran'
				},
				{
					'id': 6,
					'nama': 'Central Kitchen'
				},
			],
			reasonPK: [
				{
					'id': 1,
					'alasan': 'Permintaan Baru'
				},
				{
					'id': 2,
					'alasan': 'Pengganti Keluar/Mutasi/Promosi'
				}
			],
			isInputLoading: true,
			isSubmitLoading: true,
			isNikLoading: true,
			isNameLoading: true,
			isLeadTimeLoading: true,
			isInputPositionLoading: true,
			isUnitDisabled: false,
			isBranchDisabled: false,
			isDepartmentDisabled: false,
			showReminder: true,
			canCreatePK: null,
			errorsFileOrganization: null,
			errorsFileBA: null,
			mutationArray: [],
			pickMutationNik: false,
			pickMutationName: false,
			isNewEmployee: true,
		};
	},

	async mounted() {
		// no need to check active pk for user
		// await this.checkCreatePK()
		// if (this.canCreatePK == 0) {
		// 	this.$buefy.snackbar.open({
		// 		message: 'Tidak dapat mengajukan PK karena terdapat PK yang masih aktif',
		// 		type: 'is-info',
		// 		position: 'is-top',
		// 		actionText: 'Tutup',
		// 		indefinite: false,
		// 		duration: 7000
		// 	})
		// 	return
		// } else if (this.canCreatePK == 1) {
		if (this.showReminder) {
			this.$buefy.snackbar.open({
				message: 'Pastikan budget untuk jabatan yang ingin diajukan telah diupload, dan nik anda telah terdaftar pada pejabat approval',
				type: 'is-info',
				position: 'is-top',
				actionText: 'Tutup',
				indefinite: false,
				duration: 7000
			})
		}
		await this.getPKTypeByUser()
		await this.getAllUnits()
		await this.getDistinctUnits()
		await this.getDistinctBranches()
		await this.getDistinctDepartments()
		await this.getAllDepartments()
		// await this.getPkBackups()
		await this.getAllPositions()
		await this.getAllLeadTime()
		if (this.distinctUnits.length == 1 && this.distinctBranches.length == 1 && this.distinctDepartments.length == 1) {
			this.isUnitDisabled = true
			this.isBranchDisabled = true
			this.isDepartmentDisabled = true
			this.unit = this.distinctUnits[0].unit
			this.branch = this.distinctBranches[0].cabang
			this.department = this.distinctDepartments[0].dept_khusus
			let pickedDepartment = this.masterDepartments.filter((option) => {
				return option.cabang == this.branch && option.unit == this.unit && option.direktorat == this.distinctDepartments[0].direktorat && option.dept_umum == this.distinctDepartments[0].dept_umum && option.dept_khusus == this.department
			})

			if (this.PKType) {
				await this.getDistinctGeneralPositions(pickedDepartment[0].id)
			}

			this.masterDepartmentsId = pickedDepartment[0].id
		}
		this.isInputLoading = false
		this.isSubmitLoading = false
		this.isNikLoading = false
		this.isNameLoading = false
		this.isLeadTimeLoading = false
		this.isInputPositionLoading = false
		// } else {
		// 	this.$buefy.snackbar.open({
		// 		message: 'Terjadi kesalahan pada server saat melakukan pengecekan PK yang dibuat oleh user yang login',
		// 		type: 'is-info',
		// 		position: 'is-top',
		// 		actionText: 'Tutup',
		// 		indefinite: false,
		// 		duration: 7000
		// 	})
		// 	return
		// }
    },

	computed: {
		...mapGetters({
			userLogin: "isUserLogin",
			access: "access"
		}),

		getAsyncUnitData() {
			if (this.pickUnit == false) {
				return this.distinctUnits.filter((option) => {
					return option.unit
						.toString()
						.toLowerCase()
						.indexOf(this.unit.toLowerCase()) >= 0
				})
			} else {
				return this.distinctUnits
			}
		},

		// getAsyncPkBackupIdData() {
        //     return this.pkBackups.filter((option) => {
        //         return option.id_pk
        //             .toString()
        //             .toLowerCase()
        //             .indexOf(this.pkBackupId.toLowerCase()) >= 0
        //     })
		// },
		
		getAsyncBranchData () {
			if (this.pickBranch == false) {
				return this.filteredBranches.filter((option) => {
					return option.cabang
						.toString()
						.toLowerCase()
						.indexOf(this.branch.toLowerCase()) >= 0
				})
			} else {
				return this.filteredBranches
			}
        },

		getAsyncDepartmentData() {
			if (this.pickDepartment == false) {
				return this.filteredDepartments.filter((option) => {
					return option.dept_khusus
						.toString()
						.toLowerCase()
						.indexOf(this.department.toLowerCase()) >= 0
				})
			} else {
				return this.filteredDepartments
			}
		},
		
		getAsyncGeneralPositionData() {
			if (this.pickGeneralPosition == false) {
				return this.filteredPositions.filter((option) => {
					return option.jab_umum
						.toString()
						.toLowerCase()
						.indexOf(this.generalPosition.toLowerCase()) >= 0
				})
			} else {
				return this.filteredPositions
			}
		},
		
		getAsyncSpecificPositionData() {
			if (this.pickSpecificPosition == false) {
				return this.filteredSpecificPositions.filter((option) => {
					return option.jab_khusus
						.toString()
						.toLowerCase()
						.indexOf(this.specificPosition.toLowerCase()) >= 0
				})
			} else {
				return this.filteredSpecificPositions
			}
		},
		
		getAsyncTechnicalPositionData() {
			if (this.pickTechnicalPosition == false) {
				return this.filteredTechnicalPositions.filter((option) => {
					return option.jab_teknis
						.toString()
						.toLowerCase()
						.indexOf(this.technicalPosition.toLowerCase()) >= 0
				})
			} else {
				return this.filteredTechnicalPositions
			}
		},
		
		getAsyncLocationBranchData () {
			if (this.pickLocationBranch == false) {
				return this.masterBranches.filter((option) => {
					return option.AREANAME
						.toString()
						.toLowerCase()
						.indexOf(this.locationBranch.toLowerCase()) >= 0
				})
			} else {
				return this.masterBranches
			}
        },
	},

	methods: {
		async checkToGetPosition () {
			if (this.masterDepartmentsId) {
				await this.getDistinctGeneralPositions(this.masterDepartmentsId)
			}
		},

		fieldValidation () {
			if (this.reason == 1) {
				this.isNewEmployee = true
			} else {
				this.isNewEmployee = false
			}
			
			this.totalPeople = null
			this.men = null
			this.women = null
			this.bothGender = null
			this.validateTotal()
		},

		setSelectingAndSearch (search, selecting) {
			if (search == 'nik') {
				this.setPickMutationNik(selecting)
			} else if (search == 'name') {
				this.setPickMutationName(selecting)
			}
			this.searchEmployees(search)
		},

		setPickMutationNik (selecting) {
			this.pickMutationNik = selecting
		},

		setPickMutationName (selecting) {
			this.pickMutationName = selecting
		},

		async getPKTypeByUser () {
			let postData = {
				code: 8
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/approvers', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterPositions.push(element)
					// });
					this.dataPKTypes = response.data.result
                    //console.log(this.dataPKTypes)
                })
                .catch( e => {
					console.log(e)

					if (e.response.status != 401) {
						let tempMessage = null
						if (e.response.data.message == 'Data Approver tidak ditemukan') {
							tempMessage = 'Gagal! Data jenis PK untuk user login tidak ditemukan pada master pejabat approval. Silakan dapat menghubungi RnP HO untuk setting data'
						} else if (e.response.data.status && e.response.data.status != 0) {
							tempMessage = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							tempMessage = "Gagal! Terjadi kesalahan pada server saat mengambil data jenis PK untuk user login"
						}
						this.$buefy.snackbar.open({
							message: tempMessage,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		dateFormatter(dt){
			return moment(dt).format('DD-MM-YYYY')
		},

		deleteMutationArray (index) {
			this.mutationArray.splice(index, 1)
			this.totalPeople = this.mutationArray.length
			this.validateTotal()
		},
		
		addMutationEmployee () {
			if (this.mutationDate && this.mutationName && this.mutationNik) {
				let canPush = true
				let tempMessage = null
				this.mutationArray.forEach( element => {
					if (element.nik_mutasi == this.mutationNik) {
						canPush = false
						tempMessage = 'Gagal! Data karyawan keluar/mutasi/promosi sudah ada'
					}
				})
				if (!this.pickMutationName || !this.pickMutationNik) {
					canPush = false
					tempMessage = 'Gagal! Mohon mengisi NIK atau nama dengan cara memilih dari pilihan yang ada'
				}

				if (canPush) {
					let tempObject = {
						nik_mutasi: this.mutationNik,
						nama_mutasi: this.mutationName,
						tanggal_mutasi: this.mutationDate
					}
					this.mutationArray.push(tempObject)
					this.totalPeople = this.mutationArray.length
					// console.log(this.mutationArray)
					// console.log(JSON.stringify(this.mutationArray))
					this.mutationNik = null
					this.mutationName = null
					this.mutationDate = null
					this.mutationDateTemp = null
					this.setPickMutationNik(false)
					this.setPickMutationName(false)
					this.validateTotal()
				} else {
					this.$buefy.snackbar.open({
						message: tempMessage,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
			} else {
				this.$buefy.snackbar.open({
					message: 'Gagal! Data karyawan keluar/mutasi/promosi tidak lengkap',
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			}
		},

		setPickLocationBranch (selecting) {
            this.pickLocationBranch = selecting
		},

		setPickTechnicalPosition (selecting) {
            this.pickTechnicalPosition = selecting
		},

		setPickSpecificPosition (selecting) {
            this.pickSpecificPosition = selecting
		},

		setPickGeneralPosition (selecting) {
            this.pickGeneralPosition = selecting
		},

		setPickDepartment (selecting) {
            this.pickDepartment = selecting
		},

		setPickBranch (selecting) {
            this.pickBranch = selecting
		},

		setPickUnit (selecting) {
            this.pickUnit = selecting
		},

		// async checkCreatePK () {
		// 	await this.$axios.get(process.env.VUE_APP_GATEWAY_API + `pk/get-by-nik`)
        //         .then( response => {
		// 			this.canCreatePK = response.data.result
		// 			//console.log(response.data.result)
        //         })
        //         .catch( e => {
        //             console.log(e)
        //         })
		// },

		async getNewLeadTime () {
			this.isLeadTimeLoading = true
			// const params = [
			// 	`id_master_lead_time=${this.leadTimeId}`,
			// 	`jumlah_kebutuhan=${this.totalPeople}`
			// ].join('&')
			let postData = {
				id_master_lead_time: this.leadTimeId,
				jumlah_kebutuhan: this.totalPeople,
				code: 10
			}

			await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
                .then( response => {
					this.leadTime = parseInt(response.data.result.new_lead_time)
					//console.log(response.data.result)
					this.isLeadTimeLoading = false
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		async getBudgetPK () {
			let postData = {
                id: this.masterPositionsId,
                code: 8
            }
			await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `budgets`, postData)
                .then( response => {
					this.budget = parseInt(response.data.result.sisa)
					//console.log(response.data.result)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		fillNik (data, date) {
			this.mutationNik = data
			this.mutationDateTemp = date
			this.mutationDate = date
			this.setPickMutationName(true)
			this.setPickMutationNik(true)
			this.searchEmployees('nik')
		},

		fillName (data, date) {
			this.mutationName = data
			this.mutationDateTemp = date
			this.mutationDate = date
			this.setPickMutationNik(true)
			this.setPickMutationName(true)
			this.searchEmployees('name')
		},

		searchEmployees: debounce(function (search) {
			let param = null
			if (search == 'nik') {
				this.isNikLoading = true
				param = this.mutationNik
			} else if (search == 'name') {
				this.isNameLoading = true
				param = this.mutationName
			}

            let postData = {
				param: param,
				code: 3
			}

            this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'hrms', postData)
                .then( response => {
					if (search == 'nik') {
						this.employeesNik = []
						response.data.result.forEach(element => {
							this.employeesNik.push(element)
						});
						this.isNikLoading = false
					} else if (search == 'name') {
						this.employeesName = []
						response.data.result.forEach(element => {
							this.employeesName.push(element)
						});
						this.isNameLoading = false
					}
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		}, 700),
		
		// async setAllInput (id) {
		// 	let pkBackup = this.pkBackups.filter((option) => {
        //         return option.id == id
		// 	})
		// 	this.unit = pkBackup[0].unit
		// 	this.branch = pkBackup[0].cabang
		// 	this.department = pkBackup[0].departemen
		// 	this.generalPosition = pkBackup[0].master_jabatan.jab_umum
		// 	this.specificPosition = pkBackup[0].master_jabatan.jab_khusus
		// 	this.technicalPosition = pkBackup[0].master_jabatan.jab_teknis
		// 	this.workingDays = pkBackup[0].master_jabatan.jumlah_hari_kerja
		// 	this.shift = pkBackup[0].master_jabatan.shift
		// 	this.workStatus = pkBackup[0].status_kerja

		// 	if (pkBackup[0].penempatan == "Head Office") {
		// 		console.log("MASUK")
		// 		this.placement = 1
		// 	} else if (pkBackup[0].penempatan == "Cabang / Depo") {
		// 		console.log("MASUK")
		// 		this.placement = 2
		// 		this.locationBranch = pkBackup[0].lokasi_kerja
		// 	} else if (pkBackup[0].penempatan == "Pabrik") {
		// 		console.log("MASUK")
		// 		this.placement = 3
		// 		this.location = pkBackup[0].lokasi_kerja
		// 	} else if (pkBackup[0].penempatan == "Store / Outlet") {
		// 		console.log("MASUK")
		// 		this.placement = 4
		// 		this.location = pkBackup[0].lokasi_kerja
		// 	} else if (pkBackup[0].penempatan == "Restoran") {
		// 		console.log("MASUK")
		// 		this.placement = 5
		// 		this.location = pkBackup[0].lokasi_kerja
		// 	} else if (pkBackup[0].penempatan == "Central Kitchen") {
		// 		console.log("MASUK")
		// 		this.placement = 6
		// 		this.location = pkBackup[0].lokasi_kerja
		// 	}
			
		// 	if (pkBackup[0].alasan_permintaan == "Baru") {
		// 		this.reason = 1
		// 	} else if (pkBackup[0].alasan_permintaan == "Pengganti") {
		// 		this.reason = 2
		// 		this.mutationNik = pkBackup[0].nik_mutasi
		// 		this.mutationName = pkBackup[0].nama_mutasi
		// 		await this.searchEmployees('nik')
		// 		await this.searchEmployees('name')
		// 		this.mutationDate = pkBackup[0].tanggal_mutasi
		// 		this.mutationDateTemp = new Date(this.mutationDate)
		// 	}

		// 	this.totalPeople = pkBackup[0].jumlah_kebutuhan
		// 	this.men = pkBackup[0].jumlah_laki
		// 	this.women = pkBackup[0].jumlah_perempuan
		// 	this.bothGender = pkBackup[0].jumlah_bebas
		// 	this.file.name = pkBackup[0].dir_ba
		// 	this.leadTime = pkBackup[0].master_lead_time.lead_time
		// 	this.lifeTime = pkBackup[0].master_lead_time.life_time
		// 	this.education = pkBackup[0].master_jabatan.jenjang_pendidikan
		// 	this.educationDiscipline = pkBackup[0].master_jabatan.jurusan
		// 	this.experience = pkBackup[0].master_jabatan.pengalaman
		// 	this.old = pkBackup[0].master_jabatan.usia
		// 	this.skill = pkBackup[0].master_jabatan.keterampilan_khusus
		// 	this.description = pkBackup[0].master_jabatan.deskripsi_pekerjaan
		// 	this.source = pkBackup[0].sumber_pemenuhan
		// },
		
		async getDistinctUnits () {
			let postData = {
				code: 7,
				pk: true
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
					response.data.result.forEach(element => {
						this.distinctUnits.push(element)
					})
                    //console.log(this.distinctUnits)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
        },

		saveDate() {
			this.mutationDate = moment(this.mutationDateTemp).format('DD-MM-YYYY')
		},

		setLeadTime (leadTimeId) {
			let leadTimeByPosition = this.masterLeadTimes.filter((option) => {
                return option.id == leadTimeId
			})
			//console.log(leadTimeByPosition)
			if (leadTimeByPosition.length > 0) {
				this.leadTimeId = leadTimeByPosition[0].id
				//this.leadTime = leadTimeByPosition[0].lead_time
				this.lifeTime = leadTimeByPosition[0].life_time
				this.isSubmitLoading = false
				return true
			} else if (leadTimeByPosition.length == 0) {
				this.$buefy.snackbar.open({
					message: 'Jabatan yang diajukan belum memiliki lead time! Tidak dapat melanjutkan proses pengajuan PK',
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
					duration: 7000
				})
				this.isSubmitLoading = true
				return false
			} else {
				this.isSubmitLoading = true
				return false
			}
		},

		async getAllLeadTime () {
			let postData = {
				code: 6
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/lead-times', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterLeadTimes.push(element)
					// });
					this.masterLeadTimes = response.data.result
                    //console.log(this.masterLeadTimes)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		async validateTotal () {
			this.isValidTotal = true
			// if (this.gender == 'Laki-laki') {
			// 	this.men = this.totalPeople
			// } else if (this.gender == "Perempuan") {
			// 	this.women = this.totalPeople
			// }
			if (this.men != null || this.women != null || this.bothGender != null) {
				if (this.men == null) {
					this.men = 0
				}
				if (this.women == null) {
					this.women = 0
				}
				if (this.bothGender == null) {
					this.bothGender = 0
				}

				let input = parseInt(this.men) + parseInt(this.women) + parseInt(this.bothGender)

				if (input != this.totalPeople || this.totalPeople <= 0) {
					this.isValidTotal = false
				}
				if (this.reason == 2 && this.totalPeople != this.mutationArray.length) {
					this.isValidTotal = false
				}

				if (this.totalPeople > this.budget) {
					this.exceedBudget = true
				} else {
					this.exceedBudget = false
				}

				if (this.isValidTotal) {
					await this.getNewLeadTime()
				}
			} else {
				this.isValidTotal = false
			}
		},

		onlyNumber ($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[0-9]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
		},

		onlyAlphabet ($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
		},

		async getAllUnits () {
            let postData = {
                Data: {
                    code: '1',
                    nik: localStorage.user
                }
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
                .then( response => {
                    response.data.Result.forEach(element => {
                        this.masterUnits.push(element)
                    });
                    //console.log(this.masterUnits)
                })
                .catch( e => {
                    console.log(e)
                })
        },

		async getAllBranches () {
            let postData = {
				Data: {
                    code: '12',
                    id_unit: this.unitId,
                    level: '2',
                    nik: localStorage.user
                }
			}

            await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
                .then( response => {
					this.masterBranches = []
                    // response.data.Result.forEach(element => {
                    //     this.masterBranches.push(element)
					// });
					response.data.Result.data.sort(function(a, b){
                        if(a.AREANAME < b.AREANAME) { return -1; }
                        if(a.AREANAME > b.AREANAME) { return 1; }
                        return 0
					})
					this.masterBranches = response.data.Result.data
                    //console.log(this.masterBranches)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
				})
			
			// let postData = {
			// 	code: 8
			// }

            // await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'hrms', postData)
            //     .then( response => {
            //         // response.data.Result.forEach(element => {
            //         //     this.masterBranches.push(element)
			// 		// });
			// 		response.data.result.sort(function(a, b){
            //             if(a.AREANAME < b.AREANAME) { return -1; }
            //             if(a.AREANAME > b.AREANAME) { return 1; }
            //             return 0
			// 		})
			// 		this.masterBranches = response.data.result
            //         //console.log(this.masterBranches)
            //     })
            //     .catch( e => {
            //         console.log(e)
            //     })
		},

		async getDistinctBranches () {
			let postData = {
                code: 8,
				pk: true
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
					response.data.result.forEach(element => {
						this.distinctBranches.push(element)
					})
                    //console.log(this.distinctBranches)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
        },
		
		setJabatanValue (technicalPosition) {
			if (this.generalPosition && this.specificPosition) {
				let pickedPosition = this.masterPositions.filter((option) => {
					return option.jab_umum == this.generalPosition && option.jab_khusus == this.specificPosition && option.jab_teknis == technicalPosition && option.id_master_departemen == this.masterDepartmentsId
				})
				this.isInputLoading = true
				this.isLeadTimeLoading = true
				this.masterPositionsId = pickedPosition[0].id
				//console.log(this.masterPositionsId)
				this.jobClassFrom = pickedPosition[0].job_class_from
				//console.log(this.jobClassFrom)
				this.jobClassTo = pickedPosition[0].job_class_to
				this.workingDays = pickedPosition[0].jumlah_hari_kerja
				this.shift = pickedPosition[0].shift
				this.old = pickedPosition[0].usia
				this.gender = pickedPosition[0].jenis_kelamin
				this.education = pickedPosition[0].jenjang_pendidikan
				this.experience = pickedPosition[0].pengalaman
				this.educationDiscipline = pickedPosition[0].jurusan.replace(/\|\|/g, ", ")
				this.description = pickedPosition[0].deskripsi_pekerjaan
				this.skill = pickedPosition[0].keterampilan_khusus
				if (!this.setLeadTime(pickedPosition[0].id_master_lead_time)) {
					return
				}
				this.getBudgetPK()
				this.isValidTotal = false
				this.totalPeople = null
				this.men = null
				this.women = null
				this.bothGender = null
				this.fileOrganization = {}
				this.isInputLoading = false
				this.isLeadTimeLoading = false
				this.setPickTechnicalPosition(true)
			}
		},

		async filterTechnicalPositions (specificPosition) {
			await this.getDistinctTechnicalPositions(specificPosition)
			if (this.specificPosition != specificPosition) {
				this.technicalPosition = ''
			}
			this.setPickSpecificPosition(true)
		},

		async filterSpecificPositions (generalPosition) {
			await this.getDistinctSpecificPositions(generalPosition)
			if (this.generalPosition != generalPosition) {
				this.specificPosition = ''
				this.technicalPosition = ''
			}
			this.setPickGeneralPosition(true)
		},

		async getDistinctTechnicalPositions (specificPosition) {
			this.isInputPositionLoading = true
			// let specificPositionBypass = specificPosition.replace("&", "%26")
			// const params = [
			// 	`jab_khusus=${specificPositionBypass}`,
			// 	`id_master_departemen=${this.masterDepartmentsId}`
			// ].join('&')
			let postData = {
				jab_khusus: specificPosition,
				jab_umum: this.generalPosition,
				id_master_departemen: this.masterDepartmentsId,
				code: 9
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData)
                .then( response => {
					this.filteredTechnicalPositions = []
                    response.data.result.forEach(element => {
                        this.filteredTechnicalPositions.push(element)
                    });
					//console.log(this.masterDepartments)
					this.isInputPositionLoading = false
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		async getDistinctSpecificPositions (generalPosition) {
			this.isInputPositionLoading = true
			// let generalPositionBypass = generalPosition.replace("&", "%26")
			// const params = [
			// 	`jab_umum=${generalPositionBypass}`,
			// 	`id_master_departemen=${this.masterDepartmentsId}`
			// ].join('&')
			let postData = {
				jab_umum: generalPosition,
				id_master_departemen: this.masterDepartmentsId,
				code: 8
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData)
                .then( response => {
					this.filteredSpecificPositions = []
                    response.data.result.forEach(element => {
                        this.filteredSpecificPositions.push(element)
                    });
					//console.log(this.masterDepartments)
					this.isInputPositionLoading = false
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		async getDistinctGeneralPositions (departmentId) {
			this.isInputPositionLoading = true
			// const params = [
			// 	`id_master_departemen=${departmentId}`
			// ]
			let postData = {
				id_master_departemen: departmentId,
				jenis_pk: this.PKType,
				code: 7
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/approvers', postData)
                .then( response => {
					this.filteredPositions = []
                    response.data.result.forEach(element => {
                        this.filteredPositions.push(element)
                    });
					//console.log(this.masterDepartments)
					this.isInputPositionLoading = false
                })
                .catch( e => {
					console.log(e)
					if (e.response.data.status == 1006 && e.response.status != 401) {
						this.$buefy.snackbar.open({
							message: 'User login tidak ditemukan sebagai pembuat PK untuk departemen terpilih! Tidak dapat melanjutkan proses pengajuan PK. Silakan dapat menghubungi RnP HO untuk setting data',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		async getAllPositions () {
			let postData = {
				code: 6
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterPositions.push(element)
					// });
					this.masterPositions = response.data.result
                    //console.log(this.masterDepartments)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		async filterPositions (unit, branch, directorate, generalDepartment, specificDepartment) {
			let pickedDepartment = this.masterDepartments.filter((option) => {
                return option.cabang == branch && option.unit == unit && option.direktorat == directorate && option.dept_umum == generalDepartment && option.dept_khusus == specificDepartment
			})

			if (this.PKType) {
				await this.getDistinctGeneralPositions(pickedDepartment[0].id)
			}
			
			this.masterDepartmentsId = pickedDepartment[0].id
			if (this.department != specificDepartment) {
				this.generalPosition = ''
				this.specificPosition = ''
				this.technicalPosition = ''
			}
			this.setPickDepartment(true)
		},

		departmentPositionValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
		},
		
		filterDepartments (branch) {
            this.filteredDepartments = this.distinctDepartments.filter((option) => {
                return option.cabang == branch && option.unit == this.unit
			})
			if (this.branch != branch) {
				this.department = ''
				this.generalPosition = ''
				this.specificPosition = ''
				this.technicalPosition = ''
			}
			this.setPickBranch(true)
		},
		
		IdPkValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z0-9\s-]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
		},

		// async getPkBackups () {
        //     await this.$axios.get(process.env.VUE_APP_GATEWAY_API + 'pk/backups')
        //         .then( response => {
        //             response.data.result.forEach(element => {
        //                 this.pkBackups.push(element)
        //             });
        //             console.log(this.pkBackups)
        //         })
        //         .catch( e => {
        //             console.log(e)
        //         })
		// },
		async getDistinctDepartments () {
			let postData = {
                code: 9,
				pk: true
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.distinctDepartments.push(element)
					});
                    //console.log(this.distinctDepartments)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		async getAllDepartments () {
			let postData = {
                code: 6
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterDepartments.push(element)
					// });
					this.masterDepartments = response.data.result
                    //console.log(this.masterDepartments)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},
		
		async filterBranches (unit, unitAlias) {
            this.filteredBranches = this.distinctBranches.filter((option) => {
                return option.unit == unit
			})
			if (this.unit != unit) {
				this.branch = ''
				this.department = ''
				this.generalPosition = ''
				this.specificPosition = ''
				this.technicalPosition = ''
			}
			this.setPickUnit(true)

			let tempUnit = this.masterUnits.filter((option) => {
                return option.AliasUnit == unitAlias
			})
			this.unitId = tempUnit[0].KodeUnit
			await this.getAllBranches()
		},
		
		validateWorkLocation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
		},

        clearDate () {
            this.mutationDate = null
		},
		
		async createPK () {
			this.$refs.observer.validate().then(async success => {
				if (!success) {
					const errors = Object.entries(this.$refs.observer.errors)
						.map(([key, value]) => ({ key, value }))
						.filter(error => error["value"].length);
					
					if (errors.length > 0 && !this.isScrolled) {
						this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
							behavior: "smooth",
							block: "center"
						});
						this.isScrolled = true
					}
					//console.log(this.isScrolled)

					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					if (!this.fileOrganization.name) {
						this.errorsFileOrganization = "Field / inputan berikut wajib diisi"
					}
					if ((this.reason == 1 && this.exceedBudget && !this.fileBA.name && this.PKType == 'User') || (this.PKType == 'Referensi' && !this.fileBA.name)) {
						this.errorsFileBA = "Field / inputan berikut wajib diisi"
					}
					this.isScrolled = false
				} else {
					if (!this.$refs.form.checkValidity() || !this.isValidTotal) {
						//console.log("masuk")
						this.$buefy.snackbar.open({
							message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						if (!this.isValidTotal) {
							if (!this.isScrolled) {
								document.getElementById('elTotalPeople').scrollIntoView({
									behavior: "smooth",
									block: "center"
								})
								this.isScrolled = true
							}
						}
						if ((this.reason == 1 && this.exceedBudget && !this.fileBA.name && this.PKType == 'User') || (this.PKType == 'Referensi' && !this.fileBA.name)) {
							this.errorsFileBA = "Field / inputan berikut wajib diisi"
							if (!this.isScrolled) {
								document.getElementById('elBA').scrollIntoView({
									behavior: "smooth",
									block: "center"
								})
								this.isScrolled = true
							}
						}
						if (!this.fileOrganization.name) {
							this.errorsFileOrganization = "Field / inputan berikut wajib diisi"
							if (!this.isScrolled) {
								document.getElementById('elStruktur').scrollIntoView({
									behavior: "smooth",
									block: "center"
								})
								this.isScrolled = true
							}
						}
						
						this.isScrolled = false
						return
					}

					if (this.reason == 2 && this.mutationArray.length == 0) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Mohon menambahkan data karyawan keluar/mutasi/promosi minimal 1',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000,
						})
						if (!this.isScrolled) {
							document.getElementById('elMutation').scrollIntoView({
								behavior: "smooth",
								block: "center"
							})
							this.isScrolled = true
						}
						this.isScrolled = false
						return
					}

					if (this.leadTime > this.lifeTime) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Lead Time baru melebihi Life Time, mohon kurangi jumlah kebutuhan anda',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})

						if (!this.isScrolled) {
							document.getElementById('elTotalPeople').scrollIntoView({
								behavior: "smooth",
								block: "center"
							})
							this.isScrolled = true
						}
						this.isScrolled = false
						return
					}

					// if (!this.pickLocationBranch && this.placement == 2) {
					// 	this.$buefy.snackbar.open({
					// 		message: 'Gagal! Mohon mengisi lokasi kerja cabang / depo dengan cara memilih dari pilihan yang ada',
					// 		type: 'is-info',
					// 		position: 'is-top',
					// 		actionText: 'Tutup',
					// 		indefinite: false,
					// 		duration: 7000
					// 	})

					// 	if (!this.isScrolled) {
					// 		document.getElementById('elLocationBranch').scrollIntoView({
					// 			behavior: "smooth",
					// 			block: "center"
					// 		})
					// 		this.isScrolled = true
					// 	}
					// 	this.isScrolled = false
					// 	return
					// }

					this.isSubmitLoading = true

					let penempatan = null
					let lokasi_kerja = null
					if (this.placement == 1) {
						penempatan = "Head Office"
						lokasi_kerja = "Head Office"
					} else if (this.placement == 2) {
						penempatan = "Cabang / Depo"
						lokasi_kerja = this.locationBranch
					} else if (this.placement == 3) {
						penempatan = "Pabrik"
						lokasi_kerja = this.location
					} else if (this.placement == 4) {
						penempatan = "Store / Outlet"
						lokasi_kerja = this.location
					} else if (this.placement == 5) {
						penempatan = "Restoran"
						lokasi_kerja = this.location
					} else if (this.placement == 6) {
						penempatan = "Central Kitchen"
						lokasi_kerja = this.location
					}
					
					// let postData = {
					// 	id_master_jabatan : this.masterPositionsId,
					// 	//id_lead_time : this.masterLeadTimes[0].id,
					// 	lead_time : this.leadTime,
					// 	unit : this.unit,
					// 	cabang : this.branch,
					// 	departemen : this.department,
					// 	status_kerja : this.workStatus,
					// 	penempatan : penempatan,
					// 	lokasi_kerja :  lokasi_kerja,
					// 	dir_ba : (this.reason == 1 && this.exceedBudget && this.fileBA.name && this.PKType == 'User') || (this.PKType != 'User' && this.fileBA.name) ? this.fileBA.name : null,
					// 	struktur_organisasi : this.fileOrganization.name ? this.fileOrganization : null,
					// 	jumlah_kebutuhan : this.totalPeople,
					// 	jumlah_laki : this.men,
					// 	jumlah_perempuan : this.women,
					// 	jumlah_bebas : this.bothGender,
					// 	alasan_permintaan : this.reason == 1 ? 'Baru' : 'Pengganti',
					// 	nik_mutasi : this.reason == 2 ? this.mutationNik : null,
					// 	nama_mutasi : this.reason == 2 ? this.mutationName : null,
					// 	tanggal_mutasi : this.reason == 2 ? this.mutationDate : null,
					// 	pengalaman_kerja : this.experience,
					// 	keterampilan_khusus : this.skill,
					// 	deskripsi_pekerjaan : this.description,
					// 	sumber_pemenuhan : this.source,
					// 	status_pk : "Pending",
					// 	jenis_pk : this.PKType,
					// 	code: 3
					// }
					//let stringMutation = JSON.stringify(this.mutationArray)

					const postData = new FormData()
					postData.set("id_master_jabatan", this.masterPositionsId)
					postData.set("id_master_lead_time", this.leadTimeId)
					postData.set("lead_time", this.leadTime)
					postData.set("unit", this.unit)
					postData.set("cabang", this.branch)
					postData.set("departemen", this.department)
					postData.set("status_kerja", this.workStatus)
					postData.set("penempatan", penempatan)
					postData.set("lokasi_kerja", lokasi_kerja)
					postData.set("jumlah_kebutuhan", this.totalPeople)
					postData.set("sisa_budget", this.budget)
					postData.set("jumlah_laki", this.men)
					postData.set("jumlah_perempuan", this.women)
					postData.set("jumlah_bebas", this.bothGender)
					postData.set("alasan_permintaan", this.reason == 1 ? 'Baru' : 'Pengganti')
					// for (var i = 0; i < arr.length; i++) {
					// 	formData.append('arr[]', arr[i]);
					// }
					if (this.reason == 2) {
						this.mutationArray.forEach( element => {
							postData.append('nik_mutasi_array[]', element.nik_mutasi)
							postData.append('nama_mutasi_array[]', element.nama_mutasi)
							postData.append('tanggal_mutasi_array[]', element.tanggal_mutasi)
						})
					} else {
						postData.set('nik_mutasi_array', null)
						postData.set('nama_mutasi_array', null)
						postData.set('tanggal_mutasi_array', null)
					}
					// postData.append("mutasi_array", this.reason == 2 ?  stringMutation : null)
					// postData.set("nik_mutasi", this.reason == 2 ? this.mutationNik : null)
					// postData.set("nama_mutasi", this.reason == 2 ? this.mutationName : null)
					// if (this.reason == 2) {
					// 	postData.set("tanggal_mutasi", this.mutationDate)
					// } else {
					// 	postData.set("tanggal_mutasi", null)
					// }
					// postData.set("tanggal_mutasi", this.reason == 2 ? this.mutationDate : null)
					postData.set("pengalaman_kerja", this.experience)
					postData.set("keterampilan_khusus", this.skill)
					postData.set("deskripsi_pekerjaan", this.description)
					postData.set("sumber_pemenuhan", this.source)
					postData.set("status_pk", "Pending")
					postData.set("jenis_pk", this.PKType)
					postData.set("code", 3)
					if ((this.reason == 1 && this.exceedBudget && this.fileBA.name && this.PKType == 'User') || (this.PKType != 'User' && this.fileBA.name)) {
						postData.set("dir_ba", this.fileBA)
					}
					if (this.fileOrganization.name) {
						postData.set("struktur_organisasi", this.fileOrganization)
					}

					// if (this.PKType == 'User' && this.pkBackupId != '') {
					// 	postData.set("id_pk_cadangan", this.pkBackupId)
					// } else {
					// 	postData.set("id_pk_cadangan", null)
					// }
					//postData.set("id_pk_cadangan", this.PKType == 'User' ? this.pkBackupId == '' ? null : this.pkBackupId : null)

					// console.log(postData)
					// console.log(JSON.stringify(postData))
					// return
					// for (var pair of postData.entries()) {
					// 	console.log(pair['tanggal_mutasi']+ ', ' + pair['nama_mutasi']); 
					// }
					let iconType = ''
					let titleMessage = ''
					let textMessage = ''
					let iconColor = ''

					await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'pks', postData)
						.then( async response => {
							// if ((this.reason == 1 && this.exceedBudget && this.fileBA.name && this.PKType == 'User') || (this.PKType != 'User' && this.fileBA.name)) {
							// 	const postDataBA = new FormData()
							// 	postDataBA.set("dir_ba", (this.reason != '2' && this.exceedBudget && this.PKType == 'User') || this.PKType != 'User' ? this.fileBA.name ? this.fileBA : null : null)
							// 	postDataBA.set("id", response.data.result.id)
							// 	postDataBA.set("code", 13)
							// 	console.log(response.data.result.id)
							// 	await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'pks', postDataBA)
							// }
							// if (this.fileOrganization.name) {
							// 	const postDataOrganization = new FormData()
							// 	postDataOrganization.set("struktur_organisasi", this.fileOrganization.name ? this.fileOrganization : null)
							// 	postDataOrganization.set("id", response.data.result.id)
							// 	postDataOrganization.set("code", 14)
							// 	console.log(response.data.result.id)
							// 	await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'pks', postDataOrganization)
							// }

							iconType = 'success',
							titleMessage = 'PK baru berhasil dibuat',
							textMessage = response.data.result.id_pk
							iconColor = 'is-success'
							this.isSubmitLoading = false
							this.$router.push({name: 'persetujuan-pk'})
						})
						.catch( e => {
							console.log(e)
							
							if (e.response.status != 401) {
								iconType = 'error'
								titleMessage = 'Gagal membuat PK!'
								if (e.response.data.message == "Pejabat tidak ditemukan") {
									textMessage = "Gagal! Mohon setting master pejabat approval sebagai pembuat untuk NIK anda terlebih dahulu. Silakan dapat menghubungi RnP HO untuk setting data"
								} else if (e.response.data.status && e.response.data.status != 0) {
									textMessage = e.response.data.message + '! Silakan dapat menghubungi RnP HO untuk setting data'
								} else {
									textMessage = 'Terjadi kesalahan pada server'
								}

								// textMessage = 'Terjadi kesalahan pada server'
								// if (e.response.data.message == "Pejabat tidak ditemukan") {
								// 	textMessage += ": Mohon setting master pejabat approval sebagai pembuat untuk NIK anda terlebih dahulu"
								// } 
								iconColor = 'is-danger'
							}
							
							this.isSubmitLoading = false
						})
						.finally(() => {
							if (titleMessage) {
								this.$swal({
									icon: iconType,
									title: titleMessage,
									text: textMessage,
									customClass: {
										icon: iconColor,
										title: 'has-text-primary is-family-primary has-text-weight-semibold',
										htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
										confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
									}
								})
							}
						})
				}
			})
            
		}
    }
};
</script>
