<template>
    <section>
        <div class="container hero">
            <div class="mx-5">
                <b-field class="has-text-weight-semibold has-text-primary is-size-3">
                    Master Hak Akses
                </b-field>
                <b-button
                    icon-pack="fas"
                    icon-left="angle-left"
                    class="has-background-warning has-text-white has-text-weight-semibold mb-5"
                    @click="goBack()"
                >KEMBALI
                </b-button>
                <div class="ml-5">
                    <div class="has-text-weight-medium has-text-primary is-size-4 mt-1">
                        {{ title }}
                    </div>
                    <ValidationObserver ref="observer">
                        <form ref="form">
                            <div class="ml-5 mt-5 pt-2 has-text-weight-medium has-text-primary">
                                <div class="columns is-desktop">
                                    <div class="column is-9">
                                        <b-field class="hz-label-master is-flex is-align-items-center" label="" horizontal>
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">NIK<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isNikLoading"
                                                    open-on-focus
                                                    v-model="nik"
                                                    :data="employeesNik"
                                                    maxlength="10"
                                                    minlength="10"
                                                    field="NIK"
                                                    @typing="searchEmployees('nik', false)"
                                                    @select.self="option => fillName(
                                                        (option ? option.NIK : null),
                                                        (option ? option.NAMA : null),
                                                        //(option ? option.EMAIL : null),
                                                        (option ? option.UNIT_CODE : null),
                                                        (option ? option.BRANCH_CODE : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="onlyNumber"
                                                    pattern="^[0-9]*"
                                                    validation-message="Isi sesuai format NIK"
                                                    :disabled="(accessArray.length > 0 && updateId == null) || updateId != null"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ nik }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field class="hz-label-master" label="" horizontal>
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Nama<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isNameLoading"
                                                    open-on-focus
                                                    v-model="name"
                                                    :data="employeesName"
                                                    field="NAMA"
                                                    @typing="searchEmployees('name', false)"
                                                    @select.self="option => fillNik(
                                                        (option ? option.NIK : null),
                                                        //(option ? option.EMAIL : null),
                                                        (option ? option.UNIT_CODE : null),
                                                        (option ? option.BRANCH_CODE : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="onlyAlphabet"
                                                    pattern="^[A-Za-z\s]*"
                                                    validation-message="Hanya bisa alfabet dan spasi"
                                                    :disabled="(accessArray.length > 0 && updateId == null) || updateId != null"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ name }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <!-- <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Email<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input
                                                    :loading="isInputLoading"
                                                    type="email"
                                                    v-model="email"
                                                    @keypress.native="emailValidation"
                                                    pattern="^[A-Za-z0-9\s.@_&-]*"
                                                    validation-message="Harus sesuai dengan format email"
                                                    disabled
                                                    required>
                                                </b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field> -->
                                        <span v-if="updateId">
                                            <b-field 
                                                class="hz-label-master is-flex is-align-items-center" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Role<span class="mandatory">*</span></span>
                                                </template>
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <b-select
                                                        @input="getGroups"
                                                        :loading="isInputLoading"
                                                        expanded
                                                        v-model="role"
                                                        required>
                                                        <option
                                                            v-for="userRole in userRoles"
                                                            :value="userRole.id"
                                                            :key="userRole.id"
                                                            :disabled="access.role != 'Super Admin' && userRole.name == 'Super Admin'"
                                                            :class="access.role != 'Super Admin' && userRole.name == 'Super Admin' ? 'has-background-gray' : ''">
                                                            {{userRole.name}}
                                                        </option>
                                                    </b-select>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                            </b-field>
                                            <b-field 
                                                v-if="role > 1"
                                                :class="role > 1 ? 'hz-label-master is-flex is-align-items-center' : 'hz-label-master is-flex is-align-items-center is-hidden'" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Group<span class="mandatory">*</span></span>
                                                </template>
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <b-select
                                                        :loading="isGroupsLoading"
                                                        expanded
                                                        v-model="group"
                                                        required>
                                                        <option
                                                            v-for="userGroup in userGroups"
                                                            :value="userGroup.id"
                                                            :key="userGroup.id">
                                                            {{userGroup.name}}
                                                        </option>
                                                    </b-select>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                            </b-field>
                                            <b-field 
                                                v-if="(role > 2 && group != 15) || group == 12"
                                                :class="(role > 2 && group != 15) || group == 12 ? 'hz-label-master is-flex is-align-items-center' : 'hz-label-master is-flex is-align-items-center is-hidden'" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Unit/ PT<span class="mandatory">*</span></span>
                                                </template>
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <b-autocomplete
                                                        :loading="isInputLoading"
                                                        open-on-focus
                                                        v-model="unit"
                                                        :data="getAsyncUnitData"
                                                        field="unit"
                                                        @typing="setPickUnit(false)"
                                                        @select="option => filterBranches((option ? option.unit : null), (option ? option.unit_code : null))"
                                                        placeholder="Ketik dan pilih dari dropdown"
                                                        @keypress.native="departmentValidation"
                                                        pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                        validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                        required>
                                                        <template slot="empty">
                                                            Tidak ditemukan hasil untuk
                                                            {{ unit }}
                                                        </template>
                                                    </b-autocomplete>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                            </b-field>
                                            <b-field 
                                                v-if="(role > 2 && group != 15) || group == 12"
                                                :class="(role > 2 && group != 15) || group == 12 ? 'hz-label-master is-flex is-align-items-center' : 'hz-label-master is-flex is-align-items-center is-hidden'" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Cabang<span class="mandatory">*</span></span>
                                                </template>
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <b-autocomplete
                                                        :loading="isInputLoading"
                                                        open-on-focus
                                                        v-model="branch"
                                                        :data="getAsyncBranchData"
                                                        field="cabang"
                                                        @typing="setPickBranch(false)"
                                                        @select="option => setBranchId((option ? option.cabang_code : null))"
                                                        placeholder="Ketik dan pilih dari dropdown"
                                                        @keypress.native="departmentValidation"
                                                        pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                        validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                        required>
                                                        <template slot="empty">
                                                            Tidak ditemukan hasil untuk
                                                            {{ branch }}
                                                        </template>
                                                    </b-autocomplete>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                            </b-field>
                                        </span>
                                        <div
                                            class="hz-label-master is-flex is-align-items-center pt-4"
                                            v-if="updateId == null"
                                        >
                                            <span class="is-family-primary has-text-weight-medium">Hak Akses<span class="mandatory">*</span></span>
                                        </div>
                                        <div class="column is-9 is-offset-1" v-if="updateId == null">
                                            <b-field 
                                                class="hz-label-master is-flex is-align-items-center" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Role<span class="mandatory">*</span></span>
                                                </template>
                                                <b-select
                                                    @input="getGroups"
                                                    :loading="isInputLoading"
                                                    expanded
                                                    v-model="role">
                                                    <option
                                                        v-for="userRole in userRoles"
                                                        :value="userRole.id"
                                                        :key="userRole.id"
                                                        :disabled="access.role != 'Super Admin' && userRole.name == 'Super Admin'"
                                                        :class="access.role != 'Super Admin' && userRole.name == 'Super Admin' ? 'has-background-gray' : ''">
                                                        {{userRole.name}}
                                                    </option>
                                                </b-select>
                                            </b-field>
                                            <b-field 
                                                v-if="role > 1"
                                                :class="role > 1 ? 'hz-label-master is-flex is-align-items-center' : 'hz-label-master is-flex is-align-items-center is-hidden'" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Group<span class="mandatory">*</span></span>
                                                </template>
                                                <b-select
                                                    :loading="isGroupsLoading"
                                                    expanded
                                                    v-model="group">
                                                    <option
                                                        v-for="userGroup in userGroups"
                                                        :value="userGroup.id"
                                                        :key="userGroup.id">
                                                        {{userGroup.name}}
                                                    </option>
                                                </b-select>
                                            </b-field>
                                            <b-field 
                                                v-if="(role > 2 && group != 15) || group == 12"
                                                :class="(role > 2 && group != 15) || group == 12 ? 'hz-label-master is-flex is-align-items-center' : 'hz-label-master is-flex is-align-items-center is-hidden'" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Unit/ PT<span class="mandatory">*</span></span>
                                                </template>
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="unit"
                                                    :data="getAsyncUnitData"
                                                    field="unit"
                                                    @typing="setPickUnit(false)"
                                                    @select="option => filterBranches((option ? option.unit : null), (option ? option.unit_code : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ unit }}
                                                    </template>
                                                </b-autocomplete>
                                            </b-field>
                                            <b-field 
                                                v-if="(role > 2 && group != 15) || group == 12"
                                                :class="(role > 2 && group != 15) || group == 12 ? 'hz-label-master is-flex is-align-items-center' : 'hz-label-master is-flex is-align-items-center is-hidden'" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Cabang<span class="mandatory">*</span></span>
                                                </template>
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="branch"
                                                    :data="getAsyncBranchData"
                                                    field="cabang"
                                                    @typing="setPickBranch(false)"
                                                    @select="option => setBranchId((option ? option.cabang_code : null))"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ branch }}
                                                    </template>
                                                </b-autocomplete>
                                            </b-field>
                                            <b-field class="">
                                                <b-button
                                                    expanded
                                                    :loading="isCheckLoading"
                                                    class="has-background-primary-light has-text-white has-text-weight-bold"
                                                    @click="addMutationEmployee()"
                                                >+</b-button>
                                            </b-field>
                                            <div v-show="alreadyAddedAccesses.length > 0">
                                                <div
                                                    v-for="(access, index) in alreadyAddedAccesses"
                                                    :key="index"
                                                >
                                                    <b-field class="mb-3">
                                                        <!-- <b-tag
                                                            close-type='is-danger'
                                                            size="is-medium"
                                                            attached
                                                            closable
                                                            aria-close-label="Close tag"
                                                            >
                                                            {{mutation.nik_mutasi}}/{{mutation.nama_mutasi}} || {{mutation.tanggal_mutasi}}
                                                        </b-tag> -->
                                                        <b-input
                                                            v-if="access.company_code && access.branch_code"
                                                            :value="access.role + ' | ' + access.group + ' | ' + access.company_code + ' | ' + access.branch_name"
                                                            expanded
                                                            disabled>
                                                        </b-input>
                                                        <b-input
                                                            v-if="access.company_code == null && access.branch_code == null"
                                                            :value="access.role + ' | ' + access.group + ' | ALL | ALL'"
                                                            expanded
                                                            disabled>
                                                        </b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div v-show="accessArray.length > 0">
                                                <div
                                                    v-for="(access, index) in accessArray"
                                                    :key="index"
                                                >
                                                    <b-field class="mb-3 is-flex is-align-items-center">
                                                        <!-- <b-tag
                                                            close-type='is-danger'
                                                            size="is-medium"
                                                            attached
                                                            closable
                                                            aria-close-label="Close tag"
                                                            >
                                                            {{mutation.nik_mutasi}}/{{mutation.nama_mutasi}} || {{mutation.tanggal_mutasi}}
                                                        </b-tag> -->
                                                        <b-input
                                                            v-if="access.company && access.branch"
                                                            :value="access.role + ' | ' + access.group + ' | ' + access.company_code + ' | ' + access.branch"
                                                            expanded
                                                            readonly>
                                                        </b-input>
                                                        <b-input
                                                            v-if="access.company == null && access.branch == null"
                                                            :value="access.role + ' | ' + access.group + ' | ALL | ALL'"
                                                            expanded
                                                            readonly>
                                                        </b-input>
                                                        <p class="control">
                                                            <b-button
                                                                type="is-danger"
                                                                label="X"
                                                                @click="deleteAccessArray(index)"
                                                            />
                                                        </p>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div v-show="accessArray.length == 0">
                                                <p class="has-text-danger">Belum ada data hak akses yang ditambahkan</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <b-field class="has-text-centered mt-5">
                                    <b-button
                                        :loading="isSubmitLoading"
                                        class="is-size-5 has-background-success has-text-white has-text-weight-bold"
                                        @click="updateId ? updateUser() : createUser()">Simpan</b-button>
                                </b-field>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            editData: null,
            updateId: null,
            title: null,
            unit: '',
            unitId: null,
            unitIdArray: [],
            branch: '',
            branchId: null,
            branchIdArray: [],
            name: null,
            nik: null,
            email: null,
            role: null,
            roleArray: [],
            group: null,
            groupArray: [],
            status: null,
            distinctUnits: [],
            distinctBranches: [],
            filteredBranches: [],
            userGroups: [],
            userRoles: [],
            isInputLoading: true,
            isGroupsLoading: true,
            isSubmitLoading: true,
            isNikLoading: true,
            isNameLoading: true,
            employeesNik: [],
            employeesName: [],
            pickNikNama: false,
            pickUnit: false,
            pickBranch: false,
            accessArray: [],
            alreadyAddedAccesses: [],
            isCheckLoading: true,
        };
    },
    created() {
        if (this.$route.params.routingTo == 'add') {
            this.title = 'Tambah Hak Akses Baru'
        } else if (this.$route.params.routingTo == 'edit') {
            this.title = 'Ubah Hak Akses'
        }

        if (this.$route.params.master) {
            this.editData = this.$route.params.master
            this.updateId = 99999
        }
        //console.log(this.editData)
    },
    async mounted () {
        await this.getDistinctUnits()
        await this.getDistinctBranches()
        //await this.getUserGroups()
        await this.getUserRoles()
        if (this.editData) {
            this.editingData()
        }
        this.isInputLoading = false
        this.isSubmitLoading = false
        this.isGroupsLoading = false
        this.isNikLoading = false
        this.isNameLoading = false
        this.isCheckLoading = false
    },

    computed: {
        ...mapGetters({
            access: "access",
        }),

        getAsyncUnitData() {
            if (this.pickUnit == false) {
                return this.distinctUnits.filter((option) => {
                    return option.unit
                        .toString()
                        .toLowerCase()
                        .indexOf(this.unit.toLowerCase()) >= 0
                })
            } else {
                return this.distinctUnits
            }
        },

        getAsyncBranchData() {
            if (this.pickBranch == false) {
                return this.filteredBranches.filter((option) => {
                    return option.cabang
                        .toString()
                        .toLowerCase()
                        .indexOf(this.branch.toLowerCase()) >= 0
                })
            } else {
                return this.filteredBranches
            }
        },
    },

    methods: {
        async getUserAccesses (nik) {
            this.isCheckLoading = true
            let postData = {
                code: 13,
                nik: nik
            }

            this.alreadyAddedAccesses = []

            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
                .then( response => {
                    this.alreadyAddedAccesses = response.data.result
                    this.isCheckLoading = false
                })
                .catch( e => {
					console.log(e)
					
					let message = null
					if (e.response.data.status && e.response.data.status != 1006) {
                        message = 'Terjadi kesalahan pada server saat pengecekan hak akses'
                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
                    } else {
                        this.isCheckLoading = false
                    }
                })
        },

        deleteAccessArray (index) {
			this.accessArray.splice(index, 1)
        },
        
        addMutationEmployee () {
            if (this.nik && this.name &&
                ((this.role == 1) ||
                (this.role == 2 && (this.group == 10 || this.group == 11)) ||
                (this.role == 2 && this.group == 12 && this.unit && this.branch) ||
                (this.role == 3 && this.group == 15) ||
                (this.role == 3 && ((this.group == 13 || this.group == 14) && this.unit && this.branch)))) {
				let canPush = true
                let tempMessage = null
                
				this.accessArray.forEach( element => {
                    if ((this.role == 1 && element.role_id == this.role) ||
                        (this.role == 2 && (this.group == 10 || this.group == 11) && (element.role_id == this.role && element.group_id == this.group)) ||
                        (this.role == 2 && this.group == 12 && this.unit && this.branch && (element.role_id == this.role && element.group_id == this.group && element.company_code == this.unitId && element.branch_code == this.branchId)) ||
                        (this.role == 3 && this.group == 15 && (element.role_id == this.role && element.group_id == this.group)) ||
                        (this.role == 3 && ((this.group == 13 || this.group == 14) && this.unit && this.branch) && (element.role_id == this.role && element.group_id == this.group && element.company_code == this.unitId && element.branch_code == this.branchId))) {
						canPush = false
						tempMessage = 'Gagal! Data hak akses sudah ada'
					}
                })

                this.alreadyAddedAccesses.forEach( element => {
                    if ((this.role == 1 && element.role_id == this.role) ||
                        (this.role == 2 && (this.group == 10 || this.group == 11) && (element.role_id == this.role && element.group_id == this.group)) ||
                        (this.role == 2 && this.group == 12 && this.unit && this.branch && (element.role_id == this.role && element.group_id == this.group && element.company_code == this.unitId && element.branch_code == this.branchId)) ||
                        (this.role == 3 && this.group == 15 && (element.role_id == this.role && element.group_id == this.group)) ||
                        (this.role == 3 && ((this.group == 13 || this.group == 14) && this.unit && this.branch) && (element.role_id == this.role && element.group_id == this.group && element.company_code == this.unitId && element.branch_code == this.branchId))) {
						canPush = false
						tempMessage = 'Gagal! Data hak akses sudah terdaftar pada program'
					}
                })

				if (!this.pickNikNama) {
					canPush = false
					tempMessage = 'Gagal! Mohon mengisi NIK atau nama dengan cara memilih dari pilihan yang ada'
                }
                
                if ((this.role == 2 && this.group == 12 && (!this.pickUnit || !this.pickBranch)) || (this.role == 3 && this.group != 15 && (!this.pickUnit || !this.pickBranch))) {
                    canPush = false
                    tempMessage = 'Gagal! Mohon mengisi Unit dan Cabang dengan cara memilih dari pilihan yang ada'
                }

				if (canPush) {
                    let searchRole = null
                    let searchGroup = null
                    searchRole = this.userRoles.filter((option) => {
                        return option.id == this.role
                    })
                    if (this.group) {
                        searchGroup = this.userGroups.filter((option) => {
                            return option.id == this.group
                        })
                    }
                    if (this.role == 1) {
                        searchGroup = this.userGroups.filter((option) => {
                            return option.id == 9
                        })
                    }

					let tempObject = {
                        role_id: searchRole[0].id,
                        role: searchRole[0].name,
                        group_id: searchGroup[0].id,
                        group: searchGroup[0].name,
                        company_code: this.role == 1 || (this.role == 2 && (this.group == 10 || this.group == 11)) || (this.role == 3 && this.group == 15) ? null : this.unitId ? this.unitId : null,
                        company: this.role == 1 || (this.role == 2 && (this.group == 10 || this.group == 11)) || (this.role == 3 && this.group == 15) ? null : this.unit ? this.unit : null,
                        branch_code: this.role == 1 || (this.role == 2 && (this.group == 10 || this.group == 11)) || (this.role == 3 && this.group == 15) ? null : this.branchId ? this.branchId : null,
                        branch: this.role == 1 || (this.role == 2 && (this.group == 10 || this.group == 11)) || (this.role == 3 && this.group == 15) ? null : this.branch ? this.branch : null
                    }
                    this.accessArray.push(tempObject)
                    if (!(this.role == 1 || (this.role == 2 && (this.group == 10 || this.group == 11)) || (this.role == 3 && this.group == 15))) {
                        this.unit = ''
                        this.unitId = null
                        this.branch = ''
                        this.branchId = null
                        this.setPickUnit(false)
                        this.setPickBranch(false)
                    }
					// console.log(this.accessArray)
					// console.log(JSON.stringify(this.accessArray))
					this.role = null
                    this.group = null
				} else {
					this.$buefy.snackbar.open({
						message: tempMessage,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
			} else {
				this.$buefy.snackbar.open({
					message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			}
        },
        
        departmentValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },
        
        setPickBranch (selecting) {
            this.pickBranch = selecting
        },

        setPickUnit (selecting) {
            this.pickUnit = selecting
        },

        filterBranches (unit, unit_code) {
            this.filteredBranches = this.distinctBranches.filter((option) => {
                return option.unit == unit
            })
            if (this.unit != unit) {
				this.branch = ''
            }
            this.setPickUnit(true)
            this.setUnitId(unit_code)
        },

        onlyAlphabet ($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },
        
        async fillNik (nik, unitCode, branchCode) {
            this.nik = nik
            //this.email = email
            let unitSelected = this.distinctUnits.filter((option) => {
                return option.unit_code == unitCode
            })
            let branchSelected = this.distinctBranches.filter((option) => {
                return option.unit_code == unitCode && option.cabang_code == branchCode
            })
            if (unitSelected.length >= 1 && branchSelected.length >= 1) {
                this.isSubmitLoading = false
                this.unit = unitSelected[0].unit
                this.unitId = unitSelected[0].unit_code
                this.branch = branchSelected[0].cabang
                this.branchId = branchSelected[0].cabang_code
                this.filteredBranches = this.distinctBranches.filter((option) => {
                    return option.unit == this.unit
                })
                this.setPickUnit(true)
                this.setPickBranch(true)
            } else {
                this.$buefy.snackbar.open({
                    message: 'Departemen nik / nama terpilih belum terdaftar pada Master Departemen',
                    type: 'is-info',
                    position: 'is-top',
                    actionText: 'Tutup',
                    indefinite: false,
                })
                this.isSubmitLoading = true
            }

            await this.getUserAccesses(this.nik)
			this.searchEmployees('nik', true)
		},

		async fillName (nik, name, unitCode, branchCode) {
			this.name = name
            //this.email = email
            let unitSelected = this.distinctUnits.filter((option) => {
                return option.unit_code == unitCode
            })
            let branchSelected = this.distinctBranches.filter((option) => {
                return option.unit_code == unitCode && option.cabang_code == branchCode
            })
            if (unitSelected.length >= 1 && branchSelected.length >= 1) {
                this.isSubmitLoading = false
                this.unit = unitSelected[0].unit
                this.unitId = unitSelected[0].unit_code
                this.branch = branchSelected[0].cabang
                this.branchId = branchSelected[0].cabang_code
                this.filteredBranches = this.distinctBranches.filter((option) => {
                    return option.unit == this.unit
                })
                this.setPickUnit(true)
                this.setPickBranch(true)
            } else {
                this.$buefy.snackbar.open({
                    message: 'Departemen nik / nama terpilih belum terdaftar pada Master Departemen',
                    type: 'is-info',
                    position: 'is-top',
                    actionText: 'Tutup',
                    indefinite: false,
                })
                this.isSubmitLoading = true
            }
            
            await this.getUserAccesses(nik)
			this.searchEmployees('name', true)
        },
        
        searchEmployees: debounce(function (search, selecting) {
            this.pickNikNama = selecting
			let param = null
			if (search == 'nik') {
				this.isNikLoading = true
				param = this.nik
			} else if (search == 'name') {
				this.isNameLoading = true
				param = this.name
			}

            let postData = {
                jobClass: "D1",
                search: param,
                code: 5
            }


            this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'hrms', postData)
                .then( response => {
                    //console.log(response.data.result)
					if (search == 'nik') {
						this.employeesNik = []
						response.data.result.forEach(element => {
							this.employeesNik.push(element)
						});
						this.isNikLoading = false
					} else if (search == 'name') {
						this.employeesName = []
						response.data.result.forEach(element => {
							this.employeesName.push(element)
						});
						this.isNameLoading = false
					}
                })
                .catch( e => {
                    console.log(e)
                })
        }, 400),

        async getGroups () {
            this.group = null
            let roleName = this.userRoles.filter((option) => {
                return option.id == this.role
            })
            //console.log(roleName[0])
            await this.getUserGroups(roleName[0].name)
        },

        // setUnitBranch () {
        //     let unit = this.masterUnits.filter((option) => {
        //         return option.AliasUnit == this.unitId
        //     })
        //     this.unit = unit.NamaUnit

        //     let branch = this.distinctBranches.filter((option) => {
        //         return option.AREAID == this.branchId
        //     })
        //     this.branch = branch.AREANAME
        // },

        setUnitId (unitId) {
            //console.log(unitId)
            this.unitId = unitId
        },

        setBranchId (branchId) {
            //console.log(branchId)
            this.branchId = branchId
            this.setPickBranch(true)
        },

        emailValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s.@_&-]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async getUserRoles () {
            let postData = {
                code: 8
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.userRoles.push(element)
                    });
                    //console.log(this.userRoles)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getUserGroups (roleName) {
            this.isGroupsLoading  = true

            let postData = {
                role: roleName,
                code: 7
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
                .then( response => {
                    this.userGroups = []
                    response.data.result.forEach(element => {
                        this.userGroups.push(element)
                    });
                    //console.log(this.userGroups)
                    this.isGroupsLoading = false
                })
                .catch( e => {
                    console.log(e)
                })
        },
        
        // passwordValidation($event) {
        //     let char = String.fromCharCode($event.keyCode);
        //     if (/^[A-Za-z0-9._-]+$/i.test(char)) {
        //         return true;
        //     } else {
        //         $event.preventDefault();
        //         return false;
        //     }
        // },

        onlyNumber($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[0-9]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async editingData () {
            this.updateId = this.editData.id
            this.nik = this.editData.username
            this.name = this.editData.name
            this.email = this.editData.email

            let role = this.userRoles.filter((option) => {
                return option.name == this.editData.role
            })
            this.role = role[0].id

            await this.getUserGroups(this.editData.role)

            let group = this.userGroups.filter((option) => {
                return option.name == this.editData.group
            })
            this.group = group[0].id

            if ((this.role == 2 && this.group == 12) || (this.role == 3 && this.group != 15)) {
                this.unitId = this.editData.company_code
                //console.log(this.unitId)
                this.unit = this.editData.company_name

                //console.log(this.distinctBranches)

                this.branchId = this.editData.branch_code
                //console.log(this.branchId)
                this.branch = this.editData.branch_name

                this.filteredBranches = this.distinctBranches.filter((option) => {
                    return option.unit_code == this.unitId
                })
            }
            this.pickNikNama = true
            this.pickUnit = true
            this.pickBranch = true
            this.editData = null
        },

        async updateUser () {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (!this.pickNikNama) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi NIK dan Nama dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if ((this.role == 2 && this.group == 12 && (!this.pickUnit || !this.pickBranch)) || (this.role == 3 && this.group != 15 && (!this.pickUnit || !this.pickBranch))) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi Unit dan Cabang dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            let companyCode = null
                            let branchCode = null

                            if ((this.role == 2 && this.group == 12) || (this.role == 3 && this.group != 15)) {
                                companyCode = this.unitId
                                branchCode = this.branchId
                            }
                            //console.log(branchCode)

                            let postData = {
                                name: this.name,
                                username: this.nik,
                                email: this.email,
                                role_id: this.role,
                                group_id: this.role == 1 ? 9 : this.group,
                                company_code: companyCode,
                                branch_code: branchCode,
                                code: 4,
                                id: this.updateId
                            }
                            //console.log(postData)
                            let iconType = ''
                            let titleMessage = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/users`, postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data hak akses berhasil diubah',
                                    textMessage = response.data.result.name + ' - ' + response.data.result.username
                                    iconColor = 'is-success'
                                    //this.emptyField()
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-hak-akses'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal mengubah data hak akses!'
                                        if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            text: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        },

        onlyAlphaNum($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async getDistinctUnits () {
            let postData = {
                code: 7
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.distinctUnits.push(element)
                    });
                    //console.log(this.distinctUnits)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getDistinctBranches () {
            let postData = {
                code: 8
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.distinctBranches.push(element)
                    });
                    //console.log(this.distinctBranches)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        goBack() {
            this.$router.push({name: 'master-hak-akses'})
        },

        async createUser() {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (this.accessArray.length < 1) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon menambahkan hak akses minimal 1',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (!this.pickNikNama) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi NIK dan Nama dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            let companyCode = null
                            let branchCode = null

                            if ((this.role == 2 && this.group == 12) || (this.role == 3 && this.group != 15)) {
                                companyCode = this.unitId
                                branchCode = this.branchId
                            }

                            let postData = {
                                name: this.name,
                                username: this.nik,
                                email: this.email,
                                code: 3,
                                access_array: this.accessArray
                            }
                            //console.log(JSON.stringify(postData))
                            let iconType = ''
                            let titleMessage = ''
                            let accessAdded = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
                                .then( response => {
                                    if (response.data.result.accesses) {
                                        iconType = 'success',
                                        titleMessage = 'Data hak akses baru berhasil disimpan',
                                        iconColor = 'is-success'

                                        response.data.result.accesses.forEach( element => {
                                            let tempUnit = element.company_code ? element.company_code : 'ALL'
                                            let tempBranch = element.branch ? element.branch : 'ALL'
                                            accessAdded += '<li> ' + element.role + ' - ' + element.group + ' - ' + tempUnit + ' - ' + tempBranch + '</li>'
                                        })

                                        textMessage = 'User ' + this.nik + ' - ' + this.name + ' dengan hak akses: <br><ul">' + accessAdded  + '</ul>'
                                    } else {
                                        iconType = 'error',
                                        titleMessage = 'Gagal menyimpan sebagian data hak akses!',
                                        iconColor = 'is-success'

                                        response.data.result.accesses_not_inserted.forEach( element => {
                                            let tempUnit = element.company_code ? element.company_code : 'ALL'
                                            let tempBranch = element.branch ? element.branch : 'ALL'
                                            accessAdded += '<li> ' + element.role + ' - ' + element.group + ' - ' + tempUnit + ' - ' + tempBranch + '</li>'
                                        })

                                        textMessage = 'User ' + this.nik + ' - ' + this.name + ' dengan hak akses: <br><ul">' + accessAdded  + '</ul> sudah terdaftar'
                                    }

                                    //this.emptyField()
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-hak-akses'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal menyimpan data hak akses!'
                                        if (e.response.data.status && e.response.data.status == 1002) {
                                            this.accessArray.forEach( element => {
                                                let tempUnit = element.company_code ? element.company_code : 'ALL'
                                                let tempBranch = element.branch ? element.branch : 'ALL'
                                                accessAdded += '<li> ' + element.role + ' - ' + element.group + ' - ' + tempUnit + ' - ' + tempBranch + '</li>'
                                            })

                                            textMessage = 'User ' + this.nik + ' - ' + this.name + ' dengan hak akses: <br><ul">' + accessAdded  + '</ul> sudah terdaftar'
                                        } else if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            html: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        }
    }
};
</script>
