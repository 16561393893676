<template>
	<section>
		<b-sidebar
			type="is-info"
			:fullheight="true"
			:overlay="false"
			:right="false"
			:can-cancel="false"
			mobile="reduce"
			v-model="open"
		>
		<div class="is-flex is-flex-direction-column is-justify-content-space-between is-flex-grow-1">
			<div class="m-1">
				<b-field class="has-text-right">
					<b-button
						class="is-info is-hidden-tablet" 
						icon-pack="fas"
						icon-right="bars"
						@click="open = false"
					></b-button>
				</b-field>
				<b-field>
					<h3 class="is-size-3 has-text-light has-text-weight-bold has-text-centered mt-3"
					>PK Online</h3>
				</b-field>
				<b-field class="my-3">
					<b-notification 
						type="is-light has-text-info"             
						:closable="false"
						has-icon
						icon-pack="fas"
						icon="user-circle"
						class="px-2"
						>
							<h6 class="is-size-6 has-text-weight-bold">
								{{name}}
							</h6>
							<p class="is-size-7 has-text-weight-semibold">{{nik}} - {{position}}</p>
					</b-notification> 
				</b-field>
				<b-menu class="is-size-7">
					<b-menu-list>
						<b-menu-item
							icon-pack="fas" 
							icon="home" 
							label=""
							@click="routePage('beranda')"
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium ml-1">Beranda</span>
							</template>
						</b-menu-item>
						<b-menu-item 
							v-if="access ? access.group == 'Direktur User' || access.group == 'Direktur HRS' ? false : true : false"
							icon-pack="fas"
							icon="edit"
							label=""
							@click="routePage('pengajuan-pk')"
						>
							<template slot="label">
								<span class="is-family-primary has-text-weight-medium ml-1">Pengajuan PK</span>
								<!-- <b-icon
									:key="isExpanded"
									class="is-pulled-right is-size-5"
									pack="fas"
									:icon="props.expanded ? 'angle-up' : 'angle-down'">
								</b-icon> -->
							</template>
							<!-- <b-menu-item 
								icon-pack="fas"
								icon="edit"
								label=""
								@click="routePage('pk-user')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK User</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="edit"
								label=""
								@click="routePage('pk-referensi')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Referensi</span>
								</template>
							</b-menu-item> -->
						</b-menu-item>
						<b-menu-item 
							icon-pack="fas"
							icon="tasks" 
							label=""
							@click="routePage('')"
						>
							<template slot="label" slot-scope="props">
								<span class="is-family-primary has-text-weight-medium ml-1">Persetujuan</span>
								<b-icon
									:key="isExpanded"
									class="is-pulled-right is-size-5"
									pack="fas"
									:icon="props.expanded ? 'angle-up' : 'angle-down'">
								</b-icon>
							</template>
							<b-menu-item 
								icon-pack="fas"
								icon="tasks"
								label=""
								@click="routePage('persetujuan-pk')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Persetujuan PK</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="tasks"
								label=""
								@click="routePage('pk-belum-diproses-atasan')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Belum Diproses Atasan</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="tasks"
								label=""
								@click="routePage('pk-belum-giliran-saya')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Belum Giliran Saya</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								v-if="access ? access.role == 'Admin' || access.role == 'Super Admin' ? true : false : false"
								icon-pack="fas"
								icon="user-check"
								label=""
								@click="routePage('persetujuan-ganti-pejabat')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Persetujuan Ganti Pejabat</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="tasks"
								label=""
								@click="routePage('persetujuan-ubah-pk')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Persetujuan Ubah PK</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								v-if="access ? access.master_approver ? true : false : false"
								icon-pack="fas"
								icon="tasks"
								label=""
								@click="routePage('persetujuan-master-pejabat-approval')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Persetujuan Master Pejabat Approval</span>
								</template>
							</b-menu-item>
							<!-- <b-menu-item 
								v-if="access ? (access.role == 'Admin' && access.group == 'RnP 2') || access.role == 'Super Admin' ? true : false : false"
								icon-pack="fas"
								icon="tasks"
								label=""
								@click="routePage('persetujuan-garansi')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Persetujuan Garansi</span>
								</template>
							</b-menu-item> -->
						</b-menu-item>
						<b-menu-item
							icon-pack="fas" 
							icon="list-ul" 
							label=""
							@click="routePage('')"
						>
							<template slot="label" slot-scope="props">
								<span class="is-family-primary has-text-weight-medium ml-1">Pemantauan PK Final</span>
								<b-icon
									:key="isExpanded"
									class="is-pulled-right is-size-5"
									pack="fas"
									:icon="props.expanded ? 'angle-up' : 'angle-down'">
								</b-icon>
							</template>
							<b-menu-item 
								icon-pack="fas"
								icon="list-ul"
								label=""
								@click="routePage('pk-aktif')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Aktif</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="list-ul"
								label=""
								@click="routePage('pk-ditunda')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Ditunda</span>
								</template>
							</b-menu-item>
						</b-menu-item>
						<b-menu-item 
							icon-pack="fas" 
							icon="history" 
							label=""
							@click="routePage('')"
						>
							<template slot="label" slot-scope="props">
								<span class="is-family-primary has-text-weight-medium ml-1">Riwayat PK</span>
								<b-icon
									:key="isExpanded"
									class="is-pulled-right is-size-5"
									pack="fas"
									:icon="props.expanded ? 'angle-up' : 'angle-down'">
								</b-icon>
							</template>
							<b-menu-item 
								icon-pack="fas"
								icon="history"
								label=""
								@click="routePage('pk-terpenuhi')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Terpenuhi</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="history"
								label=""
								@click="routePage('pk-ditolak')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Ditolak</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="history"
								label=""
								@click="routePage('pk-dibatalkan')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Dibatalkan</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="history"
								label=""
								@click="routePage('pk-kadaluarsa')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">PK Kadaluarsa</span>
								</template>
							</b-menu-item>
						</b-menu-item>
						<b-menu-item 
							v-if="access ? access.role == 'Super Admin' || (access.role == 'Admin' && access.group != 'Recruitment Cabang') ? true : false : false"
							icon-pack="fas"
							icon="file-alt" 
							label=""
							@click="routePage('')"
						>
							<template slot="label" slot-scope="props">
								<span class="is-family-primary has-text-weight-medium ml-1">Master</span>
								<b-icon
									:key="isExpanded"
									class="is-pulled-right is-size-5"
									pack="fas"
									:icon="props.expanded ? 'angle-up' : 'angle-down'">
								</b-icon>
							</template>
							<b-menu-item 
								icon-pack="fas"
								icon="file-alt"
								label=""
								@click="routePage('master-departemen')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Master Departemen</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="file-alt"
								label=""
								@click="routePage('master-hak-akses')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Master Hak Akses</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="file-alt"
								label=""
								@click="routePage('master-lead-time')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Master Lead Time</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="file-alt"
								label=""
								@click="routePage('master-jabatan')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Master Jabatan</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="file-alt"
								label=""
								@click="routePage('master-pejabat-approval')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Master Pejabat Approval</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								v-if="access ? access.role == 'Super Admin' && access.group == 'IT Support' ? true : false : false"
								icon-pack="fas"
								icon="file-alt"
								label=""
								@click="routePage('master-manual-book')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Master Manual Book</span>
								</template>
							</b-menu-item>
							<b-menu-item 
								icon-pack="fas"
								icon="file-alt"
								label=""
								@click="routePage('budget-pk')"
							>
								<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Budget PK</span>
								</template>
							</b-menu-item>
						</b-menu-item>
						<b-menu-item 
							icon-pack="fas"
							icon="file-excel" 
							label=""
							@click="routePage('report')"
						>
							<template slot="label">
								<span class="is-family-primary has-text-weight-medium ml-1">Report</span>
								<!-- <b-icon
									:key="isExpanded"
									class="is-pulled-right is-size-5"
									pack="fas"
									:icon="props.expanded ? 'angle-up' : 'angle-down'">
								</b-icon> -->
							</template>
						</b-menu-item>
					</b-menu-list>
				</b-menu>
			</div>
			<footer id="footer-sidebar" class="mb-4">
				<b-menu class="is-size-7 m-1 mb-2">
					<b-menu-list>
						<b-menu-item 
							icon-pack="fas"
							icon="file-alt"
							label=""
							@click="routePage('manual-book')"
						>
							<template slot="label">
								<span class="is-family-primary has-text-weight-medium ml-1">Manual Book</span>
							</template>
						</b-menu-item>
						<b-menu-item 
							icon-pack="fas"
							icon="sign-out-alt"
							label=""
							@click="handleLogout"
						>
							<template slot="label">
									<span class="is-family-primary has-text-weight-medium ml-1">Logout</span>
								</template>
						</b-menu-item>
					</b-menu-list>
				</b-menu>
				<div class="is-family-primary has-text-centered has-text-weight-medium has-text-white">
					Version 1.13.14
				</div>
			</footer>
		</div>
			
		</b-sidebar>
		<b-field class="m-2 has-text-left">
			<b-button
				class="is-info" 
				icon-pack="fas"
				icon-right="bars"
				@click="open = true"
			></b-button>
		</b-field>
	</section>
</template>

<script>
import { watch } from 'vue';
import { mapGetters, mapActions } from "vuex";
export default {
	data() {
		return {
			open: true,
			overlay: true,
			fullheight: true,
			fullwidth: false,
			right: false,
			isExpanded: false,
			name: null,
			nik: null,
			position: null,
			canCreatePK: null,
		};
	},

	computed: {
		...mapGetters({
			userLogin: "isUserLogin",
			access: "access"
		}),
	},

	watch: {
		async userLogin () {
			if (this.userLogin) {
				this.showUserLogin()
				//await this.checkWorkEmail()
			}
		}
	},

	async mounted () {
		if (this.userLogin) {
			this.showUserLogin()
			//await this.checkWorkEmail()
		}
	},

	methods: {
		...mapActions(['logout']),

		async handleLogout () {
			await this.logout()
			this.$router.push({name: 'login'})
			localStorage.removeItem('routingTo')
		},

		showUserLogin () {
			this.name = this.userLogin.name
			this.nik = this.userLogin.nik
			this.position = this.userLogin.position
		},

		async routePage(name) {
			this.isExpanded = !this.isExpanded
			if(name != "") {
				// no need to check active pk for user
				// if (name == "pengajuan-pk") { 
				// 	await this.checkCreatePK()
				// 	if (this.canCreatePK == 0) {
				// 		this.$buefy.snackbar.open({
				// 			message: 'Tidak dapat mengajukan PK karena terdapat PK yang masih aktif',
				// 			type: 'is-info',
				// 			position: 'is-top',
				// 			actionText: 'Tutup',
				// 			indefinite: false,
				// 			duration: 7000
				// 		})
				// 		return
				// 	} else if (this.canCreatePK == null) {
				// 		this.$buefy.snackbar.open({
				// 			message: 'Terjadi kesalahan pada server saat pengecekan PK aktif user yang login',
				// 			type: 'is-info',
				// 			position: 'is-top',
				// 			actionText: 'Tutup',
				// 			indefinite: false,
				// 			duration: 7000
				// 		})
				// 		return
				// 	}
				// }
				this.$router.push({name: name})
			}
		},

		// async checkCreatePK () {
		// 	await this.$axios.get(process.env.VUE_APP_GATEWAY_API + `pk/get-by-nik`)
        //         .then( response => {
		// 			this.canCreatePK = response.data.result
		// 			//console.log(response.data.result)
        //         })
        //         .catch( e => {
        //             console.log(e)
        //         })
		// },
	}
};
</script>