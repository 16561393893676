<template>
    <section>
        <div class="container hero">
            <div class="mx-5">
                <div class="detail-sticky-top-desktop detail-sticky-top-mobile has-background-white">
                    <div class="has-text-weight-semibold has-text-primary is-size-3">
                        {{ title }}
                    </div>
                    <div class="columns">
                        <div class="column is-2">
                            <b-button
                                icon-pack="fas"
                                icon-left="angle-left"
                                class="has-background-warning has-text-white has-text-weight-semibold"
                                @click="goBack()"
                            >KEMBALI
                            </b-button>
                        </div>
                        <div class="column is-2">
                            <b-button
                                icon-pack="fas"
                                icon-left="sync"
                                class="has-background-success has-text-white has-text-weight-semibold"
                                @click="refreshData()"
                            >MUAT ULANG
                            </b-button>
                        </div>
                    </div>
                    <div class="has-text-weight-semibold has-text-primary is-size-4 ml-5">
                        Rincian Rekrutmen {{ idPK }}
                    </div>
                    <hr class="line-break">
                </div>
                <div class="detail-content-desktop detail-content-mobile">
                    <div
                        class="has-text-weight-semibold has-text-primary is-size-4 ml-5 mr-3 mt-1"
                    >
                        <div class="columns">
                            <div class="column is-4 is-offset-8">
                                <b-field class="">
                                    <b-input
                                        placeholder="Search..."
                                        rounded
                                        icon-pack="fas"
                                        icon-right="search"
                                        @input="loadAsyncDataRekrutmen"
                                        v-model="searchRekrutmen">
                                    </b-input>
                                </b-field>
                            </div>
                        </div>
                        <b-table
                            class="is-size-7"
                            paginated
                            backend-pagination
                            :total="totalRekrutmen"
                            :per-page="perPageRekrutmen"
                            @page-change="onPageChangeRekrutmen"
                            sticky-header
                            bordered
                            icon-pack="fas"
                            sort-icon="angle-up"
                            sort-icon-size="is-small"
                            backend-sorting
                            @sort="onSortRekrutmen"
                            :default-sort-direction="defaultSortDirectionRekrutmen"
                            :data="dataRekrutmen"
                            :mobile-cards="false"
                            :loading="isLoadingRekrutmen"
                        >
                            <b-table-column header-class="has-background-light" field="no" label="NO" centered v-slot="props">
                                {{dataRekrutmen.indexOf(props.row) + 1 + ((varPageNumberingRekrutmen - 1) * 10)}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="nama" label="NAMA KANDIDAT" centered v-slot="props">
                                {{props.row.tanggal_pkwt ? props.row.nama : 'ANONYM'}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="tanggal_cv" label="CV OK" centered v-slot="props">
                                {{props.row.tanggal_cv}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="tanggal_seleksi" label="PROSES SELEKSI" centered v-slot="props">
                                {{props.row.tanggal_seleksi}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="tanggal_training" label="TRAINING" centered v-slot="props">
                                {{props.row.tanggal_training}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="tanggal_pkwt" label="PKWT" centered v-slot="props">
                                {{props.row.tanggal_pkwt}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="tanggal_penempatan" label="PENEMPATAN" centered v-slot="props">
                                {{props.row.tanggal_penempatan}}
                            </b-table-column>
                            <template #bottom-left>
                                <b>Show {{ (totalRekrutmen > 0 ? 1 : 0) + ((varPageNumberingRekrutmen - 1) * 10)}} - {{totalRekrutmen - (varPageNumberingRekrutmen * 10) > 0 ? varPageNumberingRekrutmen * 10 : totalRekrutmen}} of {{totalRekrutmen}} data</b>
                            </template>
                        </b-table>
                        <br>
                    </div>
                    <!-- <div
                        v-if="title != 'Pemantauan PK Ditunda'"
                        class="has-text-weight-semibold has-text-primary is-size-4 my-2 ml-5 mt-4 mr-3"
                    >
                        <b-tooltip
                            :active="flag != 'Ya' ? true : false"
                            label="Jabatan untuk PK ini tidak memiliki garansi"
                            size="is-small"
                            position="is-bottom"
                            multilined>
                            <b-button
                                class="has-background-success has-text-white has-text-weight-semibold mb-4"
                                @click="openModal('garansi')"
                                :disabled="flag != 'Ya'"
                            >GARANSI
                            </b-button>
                        </b-tooltip>
                        <div class="columns">
                            <div class="column is-4 is-offset-8">
                                <b-field class="">
                                    <b-input
                                        placeholder="Search..."
                                        rounded
                                        icon-pack="fas"
                                        icon-right="search"
                                        @input="loadAsyncDataGaransi"
                                        v-model="searchGaransi">
                                    </b-input>
                                </b-field>
                            </div>
                        </div>
                        <b-table
                            class="is-size-7"
                            paginated
                            backend-pagination
                            :total="totalGaransi"
                            :per-page="perPageGaransi"
                            @page-change="onPageChangeGaransi"
                            sticky-header
                            bordered
                            icon-pack="fas"
                            sort-icon="angle-up"
                            sort-icon-size="is-small"
                            backend-sorting
                            @sort="onSortGaransi"
                            :default-sort-direction="defaultSortDirectionGaransi"
                            :data="dataGaransi"
                            :mobile-cards="false"
                            :loading="isLoadingGaransi"
                        >
                            <b-table-column header-class="has-background-light" field="nik" label="NIK" centered v-slot="props">
                                {{props.row.nik}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="nama" label="NAMA KARYAWAN" centered v-slot="props">
                                {{props.row.nama}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="tanggal_berhenti" label="TANGGAL TIDAK AKTIF" centered v-slot="props">
                                {{props.row.tanggal_berhenti}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="alasan_berhenti" label="ALASAN GARANSI" centered v-slot="props">
                                {{props.row.alasan_berhenti}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="no_dht" label="NO DHT" centered v-slot="props">
                                {{props.row.no_dht}}
                            </b-table-column>
                            <b-table-column header-class="has-background-light" field="status" label="STATUS" centered v-slot="props">
                                {{props.row.status == "Disetujui" ? "Karyawan sudah digaransikan" : props.row.status}}
                            </b-table-column>
                            <template #bottom-left>
                                <b>Show {{ (totalGaransi > 0 ? 1 : 0) + ((varPageNumberingGaransi - 1) * 10)}} - {{totalGaransi - (varPageNumberingGaransi * 10) > 0 ? varPageNumberingGaransi * 10 : totalGaransi}} of {{totalGaransi}} data</b>
                            </template>
                        </b-table>
                        <br>
                    </div> -->
                </div>
            </div>
        </div>
        <b-modal has-modal-card v-model="isGaransiModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Input Karyawan Garansi</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isGaransiModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <ValidationObserver ref="observer">
                            <form ref="form">
                                <div class="column mx-4">
                                    <b-field class="hz-label" label="" horizontal>
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">NIK<span class="mandatory">*</span></span>
                                        </template>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-autocomplete
                                                :loading="isNikLoading"
                                                open-on-focus
                                                v-model="mutationNik"
                                                :data="employeesNik"
                                                maxlength="10"
                                                minlength="10"
                                                field="NIK"
                                                @typing="searchEmployees('nik', false)"
                                                @select.self="option => fillNameDkk((option ? option.NAMA : null), (option ? option.TANGGAL : null), (option ? option.NO_DHT : null), (option ? option.ALASAN : null), (option ? option.NO_KTP : null))"
                                                placeholder="Ketik dan pilih dari dropdown"
                                                @keypress.native="onlyNumber"
                                                pattern="^[0-9]*"
                                                validation-message="Hanya bisa angka"
                                                required>
                                                <template slot="empty">
                                                    Tidak ditemukan hasil untuk
                                                    {{ mutationNik }}
                                                </template>
                                            </b-autocomplete>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </b-field>
                                    <b-field class="hz-label" label="" horizontal>
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">Nama<span class="mandatory">*</span></span>
                                        </template>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-autocomplete
                                                :loading="isNameLoading"
                                                open-on-focus
                                                v-model="mutationName"
                                                :data="employeesName"
                                                field="NAMA"
                                                @typing="searchEmployees('name', false)"
                                                @select.self="option => fillNikDkk((option ? option.NIK : null), (option ? option.TANGGAL : null), (option ? option.NO_DHT : null), (option ? option.ALASAN : null), (option ? option.NO_KTP : null))"
                                                placeholder="Ketik dan pilih dari dropdown"
                                                @keypress.native="onlyAlphabet"
                                                pattern="^[A-Za-z\s]*"
                                                validation-message="Hanya bisa alfabet dan spasi"
                                                required>
                                                <template slot="empty">
                                                    Tidak ditemukan hasil untuk
                                                    {{ mutationName }}
                                                </template>
                                            </b-autocomplete>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </b-field>
                                    <b-field class="hz-label" label="" horizontal>
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">Tanggal Tidak Aktif<span class="mandatory">*</span></span>
                                        </template>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-datepicker
                                                :loading="isSubmitLoading"
                                                v-model="selectedDateTemp"
                                                :date-formatter="dateFormatter"
                                                @input="saveDate"
                                                :mobile-native="false"
                                                disabled
                                                required>
                                            </b-datepicker>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </b-field>
                                    <b-field class="hz-label" label="" horizontal>
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">Alasan Garansi<span class="mandatory">*</span></span>
                                        </template>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-input
                                                :loading="isSubmitLoading"
                                                maxlength="100"
                                                v-model="reason"
                                                @keypress.native="onlyAlphaNum"
                                                pattern="^[A-Za-z0-9\s]*"
                                                validation-message="Hanya bisa alfabet, angka dan spasi"
                                                disabled
                                                required>
                                            </b-input>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </b-field>
                                    <b-field class="hz-label" label="" horizontal>
                                        <template #label>
                                            <span class="is-family-primary has-text-weight-medium">No DHT<span class="mandatory">*</span></span>
                                        </template>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-input
                                                :loading="isSubmitLoading"
                                                v-model="noDht"
                                                disabled
                                                required>
                                            </b-input>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </b-field>
                                </div>
                                <div class="columns mt-4">
                                    <div class="column is-flex is-justify-content-center">
                                        <b-field class="">
                                            <b-button
                                                :loading="isSubmitLoading"
                                                expanded
                                                class="has-background-success has-text-white has-text-weight-semibold"
                                                @click="createGaransi()"
                                            >Simpan</b-button>
                                        </b-field>
                                    </div>
                                    <!-- <div class="column is-3">
                                        <b-field class="">
                                            <b-button
                                                expanded
                                                class="has-background-danger-light has-text-white has-text-weight-bold"
                                                @click="isRecruitModal = false"
                                            >TIDAK</b-button>
                                        </b-field>
                                    </div> -->
                                </div>
                            </form>
                        </ValidationObserver>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
    </section>
</template>

<script>
import moment from 'moment'
import debounce from 'lodash.debounce'
import { mapGetters } from "vuex";

export default {
    data() {
        // const data = [
        //     { 
        //         'id_kandidat': '001',
        //         'nama': 'Arnan Dwika',
        //         'cv_ok': '24-11-2021',
        //         'proses_seleksi': '29-11-2021',
        //         'training': '03-01-2022',
        //         'pkwt': '15-12-2021',
        //         'penempatan': '22-01-2022',
        //         'no_pk': '1234-HO-IDM-08-33-IT', },
        //     { 
        //         'id_kandidat': '002',
        //         'nama': 'Diasmara',
        //         'cv_ok': '09-08-2021',
        //         'proses_seleksi': '15-10-2021',
        //         'training': '03-01-2022',
        //         'pkwt': '07-12-2021',
        //         'penempatan': '29-01-2022',
        //         'no_pk': '1000-HO-IDM-01-22-IT', },
        //     { 
        //         'id_kandidat': 'Anonim',
        //         'nama': 'Anonim',
        //         'cv_ok': '24-11-2021',
        //         'proses_seleksi': '29-11-2021',
        //         'training': '03-01-2022',
        //         'pkwt': null,
        //         'penempatan': null,
        //         'no_pk': '1234-HO-IDM-08-33-IT', },
        //     { 
        //         'id_kandidat': '004',
        //         'nama': 'Tata',
        //         'cv_ok': '24-11-2021',
        //         'proses_seleksi': '29-11-2021',
        //         'training': '03-01-2022',
        //         'pkwt': '15-12-2021',
        //         'penempatan': '22-01-2022',
        //         'no_pk': '1000-HO-IDM-01-22-IT', },
        //     { 
        //         'id_kandidat': '005',
        //         'nama': 'Budi',
        //         'cv_ok': '24-11-2021',
        //         'proses_seleksi': '29-11-2021',
        //         'training': '03-01-2022',
        //         'pkwt': '15-12-2021',
        //         'penempatan': '22-01-2022',
        //         'no_pk': '1234-HO-IDM-08-33-IT', },
        //     { 
        //         'id_kandidat': '006',
        //         'nama': 'Nini',
        //         'cv_ok': '24-11-2021',
        //         'proses_seleksi': '29-11-2021',
        //         'training': '03-01-2022',
        //         'pkwt': '15-12-2021',
        //         'penempatan': null,
        //         'no_pk': '1000-HO-IDM-01-22-IT', },
        // ]
        // const dataGaransi = [
        //     {
        //         'id': 1,
        //         'nik': '2015276116',
        //         'name': 'Arnan Dwika Diasmara',
        //         'invalid_date': '29-03-2022',
        //         'reason': 'pindah kewarganegaraan',
        //         'no_dht': 'asd123-asd123',
        //         'no_pk': '1234-HO-IDM-08-33-IT',
        //     },
        //     {
        //         'id': 2,
        //         'nik': '202123123',
        //         'name': 'Nanra',
        //         'invalid_date': '12-04-2022',
        //         'reason': 'tidak cocok',
        //         'no_dht': 'zxc345-zxc345',
        //         'no_pk': '1000-HO-IDM-01-22-IT',
        //     },
        //     {
        //         'id': 3,
        //         'nik': '212121212',
        //         'name': 'Kadwi',
        //         'invalid_date': '08-12-2021',
        //         'reason': 'pindah perusahaan',
        //         'no_dht': 'ert567-ert567',
        //         'no_pk': '1234-HO-IDM-08-33-IT',
        //     },
        //     {
        //         'id': 4,
        //         'nik': 'nik',
        //         'name': 'name',
        //         'invalid_date': 'invalid_date',
        //         'reason': 'reason',
        //         'no_dht': 'no_dht',
        //         'no_pk': '1000-HO-IDM-01-22-IT',
        //     },
        //     {
        //         'id': 5,
        //         'nik': 'nik',
        //         'name': 'name',
        //         'invalid_date': 'invalid_date',
        //         'reason': 'reason',
        //         'no_dht': 'no_dht',
        //         'no_pk': '1234-HO-IDM-08-33-IT',
        //     },
        //     {
        //         'id': 6,
        //         'nik': 'nik',
        //         'name': 'name',
        //         'invalid_date': 'invalid_date',
        //         'reason': 'reason',
        //         'no_dht': 'no_dht',
        //         'no_pk': '1000-HO-IDM-01-22-IT',
        //     },
        // ]
        return {
            dataRekrutmen: [],
            searchRekrutmen: '',
            isLoadingRekrutmen: true,
            checkboxPositionRekrutmen: 'left',
            defaultSortDirectionRekrutmen: 'desc',
            sortOrderRekrutmen: 'desc',
            sortFieldRekrutmen: 'id_user',
            totalRekrutmen: 0,
            perPageRekrutmen: 10,
            pageRekrutmen: 1,
            varPageNumberingRekrutmen: 1,
            dataGaransi: [],
            title: '',
            //checkedRows: [],
            id: null,
            idPK: null,
            noPk: null,
            noKtp: null,
            checkboxPositionGaransi: 'left',
            defaultSortDirectionGaransi: 'desc',
            sortOrderGaransi: 'desc',
            sortFieldGaransi: 'id',
            search: [],
            tempData: [],
            dataTable: [],
            searchGaransi: '',
            totalGaransi: 0,
            perPageGaransi: 10,
            pageGaransi: 1,
            varPageNumberingGaransi: 1,
            tempDataGaransi: [],
            dataTableGaransi: [],
            status: 'menunggu approval',
            isGaransiModal: false,
            reason: null,
            selectedDate: null,
            selectedDateTemp: null,
            noDht: null,
            isLoadingGaransi: true,
            isSubmitLoading: true,
            mutationNik: '',
            employeesNik: [],
            mutationName: '',
            employeesName: [],
            isNikLoading: true,
            isNameLoading: true,
            pickNikNama: false,
            flag: null,
            isZeroFulfilled: null,
            creatorNik: null,
        }
    },

    computed: {
        ...mapGetters({
            userLogin: "isUserLogin",
            access: "access"
        }),
    },

    created() {
        if (this.$route.params.routing == 'pk-aktif') {
            this.title = 'Pemantauan PK Aktif'
        } else if (this.$route.params.routing == 'pk-ditunda') {
            this.title = 'Pemantauan PK Ditunda'
        } else if (this.$route.params.routing == 'pk-dibatalkan') {
            this.title = 'Riwayat PK Dibatalkan'
        } else if (this.$route.params.routing == 'pk-ditolak') {
            this.title = 'Riwayat PK Ditolak'
        } else if (this.$route.params.routing == 'pk-kadaluarsa') {
            this.title = 'Riwayat PK Kadaluarsa'
        } else if (this.$route.params.routing == 'pk-terpenuhi') {
            this.title = 'Riwayat PK Terpenuhi'
        }
        this.id = this.$route.params.PK

        //this.checkedRows.sort((a, b) => a.no_pk > b.no_pk && 1 || -1)

        // for (let i = 0 ; i<this.checkedRows.length ; i++) {
        //     let temp = this.data.filter( obj => obj.no_pk === no_pk )
        //     this.dataTable[i] = temp
        //     this.tempData[i] = temp
        //     this.search[i] = ''
        // }
        let temp = this.data.filter( obj => obj.no_pk === this.no_pk )
        let tempGaransi = this.dataGaransi.filter( obj => obj.no_pk === this.no_pk )
        this.dataTable[0] = temp
        this.tempData[0] = temp
        this.search[0] = ''
        this.dataTableGaransi[0] = tempGaransi
        this.tempDataGaransi[0] = tempGaransi
        this.searchGaransi[0] = ''
    },

    async mounted () {
        await this.getDetailPK()

        // if (this.title != 'Pemantauan PK Ditunda') {
        //     await this.loadAsyncDataGaransi()
        // }
        
        await this.loadAsyncDataRekrutmen()
        this.isLoadingGaransi = false
        this.isLoadingRekrutmen = false
        this.isSubmitLoading = false
        this.isNikLoading = false
        this.isNameLoading = false
    },

    methods: {
        dateFormatter(dt){
            return moment(dt).format('DD-MM-YYYY')
        },
        
        async refreshData () {
            this.isLoadingGaransi = true
            this.isLoadingRekrutmen = true
            this.isSubmitLoading = true
            this.isNikLoading = true
            this.isNameLoading = true
            await this.getDetailPK()
            // await this.loadAsyncDataGaransi()
            await this.loadAsyncDataRekrutmen()
            this.isLoadingGaransi = false
            this.isLoadingRekrutmen = false
            this.isSubmitLoading = false
            this.isNikLoading = false
            this.isNameLoading = false
        },

        loadAsyncDataRekrutmen: debounce(function () {
            if (!this.searchRekrutmen) {
                this.searchRekrutmen = ''
            }
            this.isLoadingRekrutmen = true
            let searchRekrutmenBypass = this.searchRekrutmen.replace("&", "%26")
            const params = [
                `column=${this.sortFieldRekrutmen}`,
                `sort=${this.sortOrderRekrutmen}`,
                `page=${this.pageRekrutmen}`,
                `search=${searchRekrutmenBypass}`,
                `id=${this.id}`
            ].join('&')
            //console.log(params)

            this.$axios.get(process.env.VUE_APP_HRIS_API + `get-applicant?${params}`, 
                {
                    withCredentials: false, //harus ditambahkan ke hris
                })
                .then( response  => {
                    this.dataRekrutmen = []
                    let currentTotal = response.data.data.total_results
                    this.totalRekrutmen = currentTotal
                    response.data.data.data.forEach((item) => {
                        // if (item.tanggal_berhenti) {
                        //     item.tanggal_berhenti = item.tanggal_berhenti.substring(0, item.tanggal_berhenti.indexOf(" "))
                        // }
                        // if (item.estimasi_lead_time) {
                        //     item.estimasi_lead_time = item.estimasi_lead_time.substring(0, item.estimasi_lead_time.indexOf(" "))
                        // }
                        this.dataRekrutmen.push(item)
                    })
                    this.varPageNumberingRekrutmen = this.pageRekrutmen
                    //console.log(this.dataRekrutmen)
                })
                .catch( e => {
                    this.dataRekrutmen = []
                    this.totalRekrutmen = 0
                    console.log(e)

                    if (e.response.status != 401) {
                        let message = null
                        if (e.response.data.status && e.response.data.status != 0) {
                            message = e.response.data.message
                        } else if (e.response.data.message == 'Applicant data is not found') {
                            message = 'Data pelamar tidak ditemukan'
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }

                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
                    }
                })
                .finally(() => {
                    this.isLoadingRekrutmen = false
                })
        }, 400),

        onSortRekrutmen(field, order) {
            this.sortFieldRekrutmen = field
            this.sortOrderRekrutmen = order
            this.loadAsyncDataRekrutmen()
        },

        onPageChangeRekrutmen(page) {
            this.pageRekrutmen = page
            this.loadAsyncDataRekrutmen()
        },

        async createGaransi () {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
                    this.$buefy.snackbar.open({
                        message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                        type: 'is-info',
                        position: 'is-top',
                        actionText: 'Tutup',
                        indefinite: false,
                    })
                } else {
                    if (!this.pickNikNama) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Mohon mengisi NIK dan Nama dengan cara memilih dari pilihan yang ada',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000,
                        })
                        return
                    } else if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                        return
                    }
                    this.isSubmitLoading = true
                    this.isNikLoading = true
                    this.isNameLoading = true

                    let postData = {
                        id_pk: this.id,
                        nik: this.mutationNik,
                        nama: this.mutationName,
                        tanggal_berhenti: this.selectedDate,
                        alasan_berhenti: this.reason,
                        no_dht: this.noDht,
                        no_ktp: this.noKtp,
                        code: 3
                    }
                    //console.log(JSON.stringify(postData))

                    let iconType = ''
                    let titleMessage = ''
                    let textMessage = ''
                    let iconColor = ''
                    let index = 0
                    let isError = false

                    await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `warrants`, postData)
                        .then( response => {
                            titleMessage = 'Pengajuan garansi berhasil diajukan'
                            iconType = 'success'
                            textMessage = this.mutationNik + " - " + this.noDht
                            iconColor = 'is-success'
                            this.isSubmitLoading = false
                            this.isNikLoading = false
                            this.isNameLoading = false
                        })
                        .catch( e => {
                            if (e.response.status != 401) {
                                titleMessage = 'Gagal mengajukan garansi!'
                                iconType = 'error'
                                if (e.response.data.status && e.response.data.status != 0) {
                                    textMessage = e.response.data.message
                                } else {
                                    textMessage = 'Terjadi kesalahan pada server'
                                }
                                iconColor = 'is-danger'
                                console.log(e)
                                this.isSubmitLoading = false
                                this.isNikLoading = false
                                this.isNameLoading = false
                                isError = true
                            }
                        })
                        .finally(() => {
                            if (titleMessage) {
                                this.$swal({
                                    html: true,
                                    icon: iconType,
                                    title: titleMessage,
                                    html: textMessage,
                                    customClass: {
                                        icon: iconColor,
                                        title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                        htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                        confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                    }
                                }).then((isConfirmed) => {
                                    if (isConfirmed && !isError)
                                    window.location.reload()
                                })
                            }
                        })
                }
            })
        },

        onlyAlphabet ($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },
        
        onlyNumber ($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[0-9]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },
        
        fillNikDkk (nik, date, dht, reason, noKtp) {
            this.mutationNik = nik
            this.selectedDate = date
            this.selectedDateTemp = new Date(date)
            this.noDht = dht
            this.reason = reason
            this.noKtp = noKtp
			this.searchEmployees('nik', true)
		},

		fillNameDkk (name, date, dht, reason, noKtp) {
            this.mutationName = name
            this.selectedDate = date
            this.selectedDateTemp = new Date(date)
            this.noDht = dht
            this.reason = reason
            this.noKtp = noKtp
			this.searchEmployees('name', true)
		},

		searchEmployees: debounce(function (search, selecting) {
            this.pickNikNama = selecting
			let param = null
			if (search == 'nik') {
				this.isNikLoading = true
				param = this.mutationNik
			} else if (search == 'name') {
				this.isNameLoading = true
				param = this.mutationName
			}

            let postData = {
                search: param,
                nomorPk: this.noPk,
                code: 7
            }

            this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'hrms', postData)
                .then( response => {
                    //console.log(response.data.result)
					if (search == 'nik') {
						this.employeesNik = []
						response.data.result.forEach(element => {
							this.employeesNik.push(element)
						});
						this.isNikLoading = false
					} else if (search == 'name') {
						this.employeesName = []
						response.data.result.forEach(element => {
							this.employeesName.push(element)
						});
						this.isNameLoading = false
					}
                })
                .catch( e => {
                    console.log(e)

                    if (e.response.status != 401) {
                        let message = null
                        if (e.response.data.status && e.response.data.status != 0) {
                            message = e.response.data.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }

                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
                    }
                })
        }, 400),
        
        saveDate() {
			this.selectedDate = moment(this.selectedDateTemp).format('YYYY-MM-DD')
        },
        
        onlyAlphaNum($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async getDetailPK () {
            let postData = {
                id: this.id,
                code: 2
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'pks', postData)
                .then( response => {
                    this.idPK = response.data.result.id_pk
                    this.noPk = response.data.result.nomor_pk
                    this.flag = response.data.result.flag_garansi
                    this.isZeroFulfilled = response.data.result.jumlah_terpenuhi > 0 ? false : true
                    this.creatorNik = response.data.result.nik_dibuat
                })
                .catch( e => {
                    console.log(e)

                    if (e.response.status != 401) {
                        let message = null
                        if (e.response.data.status && e.response.data.status != 0) {
                            message = e.response.data.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }

                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
                    }
                })
        },

        onSortGaransi(field, order) {
            this.sortFieldGaransi = field
            this.sortOrderGaransi = order
            // this.loadAsyncDataGaransi()
        },

        onPageChangeGaransi(page) {
            this.pageGaransi = page
            // this.loadAsyncDataGaransi()
        },

        loadAsyncDataGaransi: debounce(function () {
            if (!this.searchGaransi) {
                this.searchGaransi = ''
            }
            this.isLoadingGaransi = true
            // let searchGaransiBypass = this.searchGaransi.replace("&", "%26")
            // const params = [
            //     `column=${this.sortFieldGaransi}`,
            //     `sort=${this.sortOrderGaransi}`,
            //     `page=${this.pageGaransi}`,
            //     `search=${searchGaransiBypass}`,
            //     `id_pk=${this.id}`
            // ].join('&')
            // console.log(params)
            let postData = {
                column: this.sortFieldGaransi,
                sort: this.sortOrderGaransi,
                page: this.pageGaransi,
                search: this.searchGaransi,
                id_pk: this.id,
                code: 7
            }

            this.$axios.post(process.env.VUE_APP_GATEWAY_API + `warrants`, postData)
                .then( response  => {
                    this.dataGaransi = []
                    let currentTotal = response.data.result.total_results
                    this.totalGaransi = currentTotal
                    response.data.result.data.forEach((item) => {
                        // if (item.tanggal_berhenti) {
                        //     item.tanggal_berhenti = item.tanggal_berhenti.substring(0, item.tanggal_berhenti.indexOf(" "))
                        // }
                        // if (item.estimasi_lead_time) {
                        //     item.estimasi_lead_time = item.estimasi_lead_time.substring(0, item.estimasi_lead_time.indexOf(" "))
                        // }
                        this.dataGaransi.push(item)
                    })
                    this.varPageNumberingGaransi = this.pageGaransi
                    //console.log(this.dataGaransi)
                })
                .catch( e => {
                    this.dataGaransi = []
                    this.totalGaransi = 0
                    console.log(e)

                    if (e.response.status != 401) {
                        let message = null
                        if (e.response.data.status && e.response.data.status != 0) {
                            message = e.response.data.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }

                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
                    }
                })
                .finally(() => {
                    this.isLoadingGaransi = false
                })
        }, 400),

        // openModal(modalType) {
        //     if (modalType == 'garansi') {
        //         if (this.userLogin.nik != this.creatorNik) {
        //             this.$buefy.snackbar.open({
        //                 message: 'Hanya pembuat PK yang dapat melakukan garansi',
        //                 type: 'is-info',
        //                 position: 'is-top',
        //                 actionText: 'Tutup',
        //                 indefinite: false,
        //             })
        //             return
        //         } else if (this.isZeroFulfilled) {
        //             this.$buefy.snackbar.open({
        //                 message: 'Belum ada yang sudah terpenuhi dari PK ini',
        //                 type: 'is-info',
        //                 position: 'is-top',
        //                 actionText: 'Tutup',
        //                 indefinite: false,
        //             })
        //             return
        //         } else {
        //             this.selectedDate = null
        //             this.mutationNik = ''
        //             this.mutationName = ''
        //             this.reason = null
        //             this.noDht = null
        //             this.isGaransiModal = true
        //         }
        //     }
        // },

        goBack() {
            this.$router.push({name: this.$route.params.routing})
        },

        filter: function () {
            for (let j in this.search) {
                let searching = new RegExp(this.search[j], 'i')
                let data = []
                for (let i in this.dataTable[j]) {
                    //if (this.data[i].no_pk == this.checkedRows[j].no_pk) {
                        if (this.dataTable[j][i].id_kandidat.toString().match(searching)) {
                            data.push(this.dataTable[j][i])
                        } else if (this.dataTable[j][i].nama.match(searching)) {
                            data.push(this.dataTable[j][i])
                        } else {
                            if (this.dataTable[j][i].cv_ok != null) {
                                if (this.dataTable[j][i].cv_ok.match(searching)) {
                                    data.push(this.dataTable[j][i])
                                }
                            }
                            if (this.dataTable[j][i].proses_seleksi) {
                                if (this.dataTable[j][i].proses_seleksi.match(searching)) {
                                    data.push(this.dataTable[j][i])
                                } 
                            }
                            if (this.dataTable[j][i].training) {
                                if (this.dataTable[j][i].training.match(searching)) {
                                    data.push(this.dataTable[j][i])
                                }
                            }
                            if (this.dataTable[j][i].pkwt) {
                                if (this.dataTable[j][i].pkwt.match(searching)) {
                                    data.push(this.dataTable[j][i])
                                }
                            }
                            if (this.dataTable[j][i].penempatan) {
                                if (this.dataTable[j][i].penempatan.match(searching)) {
                                    data.push(this.dataTable[j][i])
                                }
                            }
                        }
                    //}
                }
                this.tempData[j] = data
            }
        },

        filterGaransi: function () {
            for (let j in this.searchGaransi) {
                let searching = new RegExp(this.searchGaransi[j], 'i')
                let data = []
                for (let i in this.dataTableGaransi[j]) {
                    //if (this.data[i].no_pk == this.checkedRows[j].no_pk) {
                        if (this.dataTableGaransi[j][i].nik.toString().match(searching)) {
                            data.push(this.dataTableGaransi[j][i])
                        } else if (this.dataTableGaransi[j][i].name.match(searching)) {
                            data.push(this.dataTableGaransi[j][i])
                        } else if (this.dataTableGaransi[j][i].invalid_date.match(searching)) {
                            data.push(this.dataTableGaransi[j][i])
                        } else if (this.dataTableGaransi[j][i].reason.match(searching)) {
                            data.push(this.dataTableGaransi[j][i])
                        } else if (this.dataTableGaransi[j][i].no_dht.match(searching)) {
                            data.push(this.dataTableGaransi[j][i])
                        }
                    //}
                }
                this.tempDataGaransi[j] = data
            }
        }
    },

    watch: {
        search: function(){
            this.filter()
        },
        searchGaransi: function(){
            this.filterGaransi()
        }
    },
}
</script>
