<template>
	<div class="container hero">
		<div class="mx-5">
			<b-field class="has-text-weight-semibold has-text-primary is-size-3">
                Budget PK
            </b-field>
            <b-button
                icon-pack="fas"
                icon-left="angle-left"
                class="has-background-warning has-text-white has-text-weight-semibold mb-5"
                @click="goBack()"
            >KEMBALI
            </b-button>
            <div class="ml-5">
                <div class="has-text-weight-medium has-text-primary is-size-4 mt-1">
                    {{ title }}
                </div>
                <div class="ml-5 mt-5 pt-2 has-text-weight-medium has-text-primary">
                    <ValidationObserver ref="observer">
                        <form ref="form">
                            <div class="columns is-desktop is-flex-desktop is-align-items-center-desktop">
                                <div class="column is-3">
                                    <span class="is-family-primary has-text-weight-medium">Budget<span class="mandatory">*</span></span>
                                </div>
                                <div class="column is-4">
                                    <b-field>
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-upload
                                                :loading="isSubmitLoading" 
                                                v-model="file" 
                                                required
                                                class="button has-background-success has-text-white has-text-weight-medium mb-3 button-sizing">
                                                <span>{{ file.name || "Upload File"}}</span>
                                            </b-upload>
                                            <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </b-field>
                                </div>
                                <div class="column is-4">
                                    <b-button
                                        :loading="isSubmitLoading"
                                        class="has-background-warning has-text-white has-text-weight-medium mb-3 button-sizing"
                                        @click="downloadTemplate"
                                    >Download Format
                                    </b-button>
                                </div>
                            </div>
                            <b-field class="has-text-centered mt-5">
                                <b-button
                                    :loading="isSubmitLoading"
                                    class="is-size-5 has-background-success has-text-white has-text-weight-bold"
                                    @click="uploadBudget"
                                >Simpan</b-button>
                            </b-field>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            id_data: null,
            title: null,
            experience: null,
            workingDays: null,
            shift: null,
            warranty: null,
            file: [],
            isInputLoading: false,
            isSubmitLoading: false,
        };
    },
    created() {
        if (this.$route.params.routingTo == 'add') {
            this.title = 'Upload Budget'
        } else if (this.$route.params.routingTo == 'edit') {
            this.title = 'Ubah Jabatan'
        }

        if (this.$route.params.master) {
            this.id_data = this.$route.params.master
        }
        //console.log(this.id_data)
    },
    methods: {
        clearDate () {
            this.selectedDate = null
        },
        goBack() {
            this.$router.push({name: 'budget-pk'})
        },

        async downloadTemplate () {
            this.isSubmitLoading = true
            let postData = {
                code: 7
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'budgets', postData,
                {responseType: 'arraybuffer'})
                .then( response => {
                    //handle kembalian file pdf
					let blob=new Blob([response.data]);
					// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
					// 	window.navigator.msSaveOrOpenBlob(blob);
					// 	return;
					// }
					let link=document.createElement('a');
					link.href=window.URL.createObjectURL(blob);
					link.setAttribute('download', 'TemplateBudget.xlsx');
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(link.href)
							, 100
                    })
                })
                .catch( e => {
                    let converter = require('arraybuffer-to-string');
                    if (e.response.status != 401) {
                        let convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
                        let tempMessage = JSON.parse(convertResponse);
                        let message = 'Terjadi kesalahan pada server'
                        if (tempMessage.message == 'Budget PK data is not found') {
                            message = 'Budget untuk jabatan yang sesuai dengan departemen anda tidak ditemukan'
                        } else if (tempMessage.status && tempMessage.status != 0) {
                            message = e.response.data.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }
                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
                    }
                })
                .finally(this.isSubmitLoading = false)
        },

        async uploadBudget() {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            
                            const postData = new FormData()
                            postData.set("file", this.file)
                            postData.set("code", 3)
                            let iconType = ''
                            let titleMessage = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'budgets', postData, {responseType: 'arraybuffer'})
                                .then( response => {
                                    let converter = require('arraybuffer-to-string');
                                    let convertResponse = converter(response.data); //terjemahkan response arraybuffer ke string
                                    if (convertResponse[0] == '{') {
                                        iconType = 'success',
                                        titleMessage = 'Budget berhasil disimpan',
                                        iconColor = 'is-success'
                                        //this.emptyField()
                                        this.isSubmitLoading = false
                                        this.$router.push({name: 'budget-pk'})
                                    } else {
                                        let blob=new Blob([response.data]);
                                        // if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
                                        // 	window.navigator.msSaveOrOpenBlob(blob);
                                        // 	return;
                                        // }
                                        let link=document.createElement('a');
                                        link.href=window.URL.createObjectURL(blob);
                                        link.setAttribute('download', 'GagalUploadBudget.xlsx');
                                        link.click();
                                        setTimeout(function () {
                                            // For Firefox it is necessary to delay revoking the ObjectURL
                                            window.URL.revokeObjectURL(link.href)
                                                , 100
                                        })

                                        iconType = 'error'
                                        titleMessage = 'Gagal mengunggah & menyimpan sebagian data budget!'
                                        textMessage = 'Silakan cek data yang tidak masuk pada file excel yang terdownload kembali'
                                        iconColor = 'is-danger'

                                        this.isSubmitLoading = false
                                        this.$router.push({name: 'budget-pk'})
                                    }
                                })
                                .catch( e => {
                                    let converter = require('arraybuffer-to-string');
                                    if (e.response.status != 401) {
                                        let convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
                                        let tempMessage = JSON.parse(convertResponse);
                                        iconType = 'error'
                                        titleMessage = 'Gagal menyimpan budget!'
                                        if (tempMessage.status && tempMessage.status != 0) {
                                            textMessage = tempMessage.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server. Pastikan file yang diupload merupakan file template dari budget'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            text: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        }
    }
};
</script>
