<template>
	<section id="app" class="pt-3 is-centered has-background-info">
		<div class="columns">
			<div class="column m-3 has-background-info is-hidden-mobile is-2">
				<b-sidebar
					type="is-info"
					:fullheight="true"
					:overlay="false"
					:right="false"
					:can-cancel="false"
					mobile="reduce"
					v-model="open"
					class="is-hidden-mobile is-3"
				>
				<div class="container hero is-fullheight is-flex is-align-items-center is-justify-content-center">
						<b-image
						class="fill_image is-fullwidth"
						:src="require('@/assets/pkonline.jpg')"
						alt="The Buefy Logo"
						ratio=""
					></b-image>
				</div>
				</b-sidebar>
			</div>
			<div id="content" class="column rounded-div has-background-white is-10">
				<div class="container hero is-fullheight">
					<div class="hero-body">
						<form ref="form">
							<div class="mb-1 column is-3-desktop is-offset-9-desktop is-4-touch is-offset-8-touch">
								<b-image
									:src="require('@/assets/logo-blue.png')"
									alt="logo_indomaret"
									ratio=""
								></b-image>
							</div>
							<p class="is-size-2-desktop is-size-4-touch is-family-secondary has-text-info">
								Selamat Datang di 
							</p>
							<p
								class="is-size-2-desktop is-size-4-touch is-family-secondary has-text-info mb-5"
							>{{ title }}</p>
							<b-field 
								class="hz-label-login has-text-left is-size-6-desktop is-size-7-touch"
								label=""
								horizontal
							><template #label>
								<span class="is-family-primary has-text-weight-semibold">NIK<span class="mandatory">*</span></span>
							</template>
								<b-input
									:loading="isLogin"
									v-model="username"
									validation-message="Silahkan isi sesuai NIK Anda"
									placeholder="NIK"
									@keypress.native="enterForm"
									maxlength="10"
									minlength="10"
									expanded
									required>
								</b-input>
							</b-field>
							<b-field 
								class="hz-label-login has-text-left is-size-6-desktop is-size-7-touch"
								label=""
								horizontal
							><template #label>
								<span class="is-family-primary has-text-weight-semibold">Password<span class="mandatory">*</span></span>
							</template>
								<b-input
									:loading="isLogin"
									v-model="password"
									type="password"
									placeholder="Password ESS"
									@keypress.native="enterForm"
									icon-pack="fas"
									password-reveal
									expanded
									required
									validation-message="Silahkan isi sesuai password ESS Anda"
								>
								</b-input>
							</b-field>
							<!-- <b-field class="has-text-left has-text-info">
								<b-switch
									type="is-info"
									size="is-small"
								>Ingat Saya</b-switch>
							</b-field> -->
							<p class="is-size-6-desktop is-size-7-touch has-text-danger has-text-weight-medium has-text-left my-4">Gunakan akun ESS untuk login</p>
							<b-field>
								<b-button 
									:loading="isLogin"
									class="button is-warning has-text-white my-5"
									size="is-medium-desktop"
									@click="login"
								>
									<span class="is-family-primary has-text-weight-bold">Masuk</span>
								</b-button>
							</b-field>
							<!-- <b-field>
								<b-button type="is-text has-text-info"
								>Lupa Password?</b-button>
							</b-field> -->
							<footer id="footer-login">
								<div class="is-family-primary has-text-weight-medium is-size-7 has-text-info is-flex is-align-items-center is-justify-content-center">
									Version 1.13.14
								</div>
							</footer>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
			open: true,
            username: null,
			password: null,
			isLogin: false,
			title: null,
			workEmailExist: false,
			numberExist: false,
        };
	},

	async mounted () {
		this.title = process.env.VUE_APP_NAME
	},

	computed: {
		...mapGetters({
			routingTo: "routingTo"
		}),
	},
	
    methods: {
		...mapActions(['isAuthenticated', 'checkRole', 'logout']),

		async updateUserPosition () {
			let postData = {
				code: 16
			}
			await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
				.then( response => {
					// lewati
				})
				.catch( e => {
					console.log(e.response.data.message)
				})
		},

		async checkWorkEmail () {
			// if (this.username == '2015374008' || this.username == '2015363746') {
			// 	this.workEmailExist = true
			// } else {
				let postData = {
					Data: {
						code: "711",
						parm: {
							nik: this.username
						},
					},
				}

				await this.$axios.post(process.env.VUE_APP_GATEWAY_PORTAL, postData)
					.then( response => {
						if (response.data.Result[0].emailKantor)
							this.workEmailExist = true
						if (response.data.Result[0].HP_Primary && response.data.Result[0].WA)
							this.numberExist = true
					}).catch( e => {
						this.$buefy.snackbar.open({
							message: 'Terjadi kesalahan pada service worker untuk mengecek email kantor',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000,
						})
					})
				if (!this.workEmailExist && !this.numberExist) {
					this.$swal({
						title: '<span class="is-size-2 has-text-warning"><i class="fas fa-envelope fa-2x"></i></span><span class="is-size-3 has-text-danger"><i class="fas fa-exclamation fa-lg"></i></span><br>Anda belum mendaftarkan alamat email kantor, No. WA, No. HP, No. Ext, silakan lengkapi data tersebut pada tautan berikut',
						html: '<a class="has-text-danger" href="' + process.env.VUE_APP_PORTAL_EMAIL + '"><u>' + process.env.VUE_APP_PORTAL_EMAIL + '</u></a><br><a class="has-text-danger" href="' + process.env.VUE_APP_PORTAL_NUMBER + '"><u>' + process.env.VUE_APP_PORTAL_NUMBER + '</u></a>',
						showConfirmButton: false,
						allowOutsideClick: false,
						customClass: {
							title: 'has-text-primary is-family-primary has-text-weight-semibold is-size-4',
							htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium'
						}
					})
				} else if (!this.workEmailExist) {
					this.$swal({
						title: '<span class="is-size-2 has-text-warning"><i class="fas fa-envelope fa-2x"></i></span><span class="is-size-3 has-text-danger"><i class="fas fa-exclamation fa-lg"></i></span><br>Anda belum mendaftarkan alamat email kantor, silakan lengkapi email kantor anda pada tautan berikut',
						html: '<a class="has-text-danger" href="' + process.env.VUE_APP_PORTAL_EMAIL + '"><u>' + process.env.VUE_APP_PORTAL_EMAIL + '</u></a>',
						showConfirmButton: false,
						allowOutsideClick: false,
						customClass: {
							title: 'has-text-primary is-family-primary has-text-weight-semibold is-size-4',
							htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium'
						}
					})
				} else if (!this.numberExist) {
					this.$swal({
						title: '<span class="is-size-2 has-text-warning"><i class="fas fa-envelope fa-2x"></i></span><span class="is-size-3 has-text-danger"><i class="fas fa-exclamation fa-lg"></i></span><br>Anda belum mendaftarkan data No. WA, No. HP, No. Ext, silakan lengkapi data tersebut pada tautan berikut',
						html: '<a class="has-text-danger" href="' + process.env.VUE_APP_PORTAL_NUMBER + '"><u>' + process.env.VUE_APP_PORTAL_NUMBER + '</u></a>',
						showConfirmButton: false,
						allowOutsideClick: false,
						customClass: {
							title: 'has-text-primary is-family-primary has-text-weight-semibold is-size-4',
							htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium'
						}
					})
				}
			// }
		},

		enterForm($event) {
            if ($event.keyCode == 13) {
                this.login()
            }
        },

		// async isAuthenticated () {
		// 	await this.axios.get(process.env.VUE_APP_GATEWAY_API + 'auth/user')
		// 		.then( response => {
		// 			this.setUserLogin(response.data.result)
		// 		})
		// 		.catch( e => {
		// 			console.log(e)
		// 		})
		// },

        async login () {
			 if (!this.$refs.form.checkValidity()) {
                this.$buefy.snackbar.open({
                    message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                    type: 'is-info',
                    position: 'is-top',
                    actionText: 'Tutup',
                    indefinite: false,
                })
            } else {
                if (this.$refs.form.checkValidity()) {
					this.isLogin = true
					let postData = {
						user: {
							nik: this.username,
							pass: this.password
						}
					}
					let iconType = ''
					let message = ''

					await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'login', postData)
						.then( async response => {
							// this.setToken(response.data.result.token)
							// this.setUserLogin(response.data.result.user)
							await this.isAuthenticated()
							await this.checkRole()
							await this.checkWorkEmail()
							if (this.workEmailExist && this.numberExist) {
								await this.updateUserPosition()
								this.isLogin = false
								if (localStorage.routingTo && localStorage.routingTo != 'login') {
									if (localStorage.routingTo == 'master-user') {
										this.$router.push({name: 'master-hak-akses'})
									} else {
										this.$router.push({name: localStorage.routingTo})
									}
								} else {
									this.$router.push('dashboard/beranda')
								}
								localStorage.removeItem('routingTo')
								iconType = 'success'
								message = 'Login berhasil'

								const Toast = this.$swal.mixin({
									toast: true,
									position: 'top-end',
									showConfirmButton: false,
									timer: 5000,
									timerProgressBar: true,
									didOpen: (toast) => {
										toast.addEventListener('mouseenter', this.$swal.stopTimer)
										toast.addEventListener('mouseleave', this.$swal.resumeTimer)
									}
									})

								Toast.fire({
									icon: iconType,
									title: message
								})
							} else {
								await this.logout()
							}
						})
						.catch( e => {
							iconType = 'error'
							message = e.response.data.message
							this.isLogin = false
							const Toast = this.$swal.mixin({
								toast: true,
								position: 'top-end',
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
								didOpen: (toast) => {
									toast.addEventListener('mouseenter', this.$swal.stopTimer)
									toast.addEventListener('mouseleave', this.$swal.resumeTimer)
								}
								})

							Toast.fire({
								icon: iconType,
								title: message
							})
						})
				}
			}
        },
    }
};
</script>
