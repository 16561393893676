<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Persetujuan Master Pejabat Approval
				</div>
				<div class="is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						class="sticky-column"
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						backend-filtering
						@filters-change="onFilter"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:checkbox-position="checkboxPosition"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-1" cell-class="has-background-white sticky-master-approval-1" field="id" label="ID" centered v-slot="props" width="1px">
							<a class="is-underlined" @click="routePage('persetujuan-master-pejabat-approval', props.row.id)">{{props.row.id}}</a>
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-2" cell-class="has-background-white sticky-master-approval-2" field="jenis_pk" label="JENIS PK" centered v-slot="props" width="2px">
							{{props.row.jenis_pk}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-3" cell-class="has-background-white sticky-master-approval-3" field="unit" label="UNIT/PT" centered v-slot="props" width="3px">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-4" cell-class="has-background-white sticky-master-approval-4" field="cabang" label="CABANG" centered v-slot="props" width="4px">
							{{props.row.cabang}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-5" cell-class="has-background-white sticky-master-approval-5" field="dept_khusus" label="BAGIAN/ SUB BAGIAN" centered v-slot="props" width="5px">
							{{props.row.dept_khusus}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-6" cell-class="has-background-white sticky-master-approval-6" field="jab_umum" label="JABATAN UMUM" centered v-slot="props" width="6px">
							{{props.row.jab_umum}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light sticky-master-approval-7" cell-class="has-background-white sticky-master-approval-7" field="alasan_permintaan" label="ALASAN PERMINTAAN" centered v-slot="props" width="7px">
							{{props.row.alasan_permintaan ? props.row.alasan_permintaan : '-'}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_dibuat" label="DIBUAT" centered v-slot="props" width="-1">
							{{props.row.nik_dibuat}} - {{ props.row.nama_dibuat }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_1" label="DISETUJUI STRUKTURAL 1" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_1}} - {{ props.row.nama_diketahui_struktural_1 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_2" label="DISETUJUI STRUKTURAL 2" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_2}} - {{ props.row.nama_diketahui_struktural_2 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_3" label="DISETUJUI STRUKTURAL 3" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_3}} - {{ props.row.nama_diketahui_struktural_3 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_4" label="DISETUJUI STRUKTURAL 4" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_4}} - {{ props.row.nama_diketahui_struktural_4 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_5" label="DISETUJUI STRUKTURAL 5" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_5}} - {{ props.row.nama_diketahui_struktural_5 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_struktural_6" label="DISETUJUI STRUKTURAL 6" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_struktural_6}} - {{ props.row.nama_diketahui_struktural_6 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_fungsional_1" label="DISETUJUI FUNGSIONAL 1" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_fungsional_1}} - {{ props.row.nama_diketahui_fungsional_1 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_fungsional_2" label="DISETUJUI FUNGSIONAL 2" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_fungsional_2}} - {{ props.row.nama_diketahui_fungsional_2 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_fungsional_3" label="DISETUJUI FUNGSIONAL 3" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_fungsional_3}} - {{ props.row.nama_diketahui_fungsional_3 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_diketahui_fungsional_4" label="DISETUJUI FUNGSIONAL 4" centered v-slot="props" width="-1">
							{{props.row.nik_diketahui_fungsional_4}} - {{ props.row.nama_diketahui_fungsional_4 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_1" label="DISETUJUI 1" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_1}} - {{ props.row.nama_disetujui_1 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_2" label="DISETUJUI 2" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_2}} - {{ props.row.nama_disetujui_2 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_3" label="DISETUJUI 3" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_3}} - {{ props.row.nama_disetujui_3 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_4" label="DISETUJUI 4" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_4}} - {{ props.row.nama_disetujui_4 }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light unsticky-master-approval-others" cell-class="unsticky-master-approval-others" field="nik_disetujui_5" label="DISETUJUI 5" centered v-slot="props" width="-1">
							{{props.row.nik_disetujui_5}} - {{ props.row.nama_disetujui_5 }}
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									:loading="isSubmitLoading"
									expanded
									class="has-background-success has-text-white has-text-weight-bold"
									@click="openModal('approve')"
								>SETUJUI</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									:loading="isSubmitLoading"
									expanded
									class="has-background-danger-light has-text-white has-text-weight-bold"
									@click="openModal('reject')"
								>TOLAK</b-button>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isApproveModal">
            <div class="modal-card" style="width: 590px">
				<header class="modal-card-head">
					<p class="modal-card-title has-text-weight-semibold">Persetujuan Master Pejabat Approval</p>
					<!-- <button
						type="button"
						class="delete"
						@click="isApproveModal = false"/> -->
				</header>
				<section class="modal-card-body">
					<div class="has-text-weight-medium has-text-primary has-text-centered">
						Data yang akan diproses:
					</div>
					<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
						<ul>
							<li
								v-for="row in checkedRows"
								:key="row.id"
								:value="row.id"
								class="is-flex is-align-items-center"
							>
								<b-icon
									pack="far"
									icon="dot-circle">
								</b-icon> {{row.id}}
							</li>
						</ul>
					</div>
					<div class="columns mt-4">
						<div class="column is-3 is-offset-3">
							<b-field class="">
								<b-button
									:loading="isSubmitLoading"
									expanded
									class="has-background-success has-text-white has-text-weight-semibold"
									@click="updateMasterApprovalStatus(2)"
								>SETUJUI</b-button>
							</b-field>
						</div>
						<div class="column is-3">
							<b-field class="">
								<b-button
									:loading="isSubmitLoading"
									expanded
									class="has-background-danger-light has-text-white has-text-weight-semibold"
									@click="isApproveModal = false"
								>BATAL</b-button>
							</b-field>
						</div>
					</div>
				</section>
				<!-- <footer class="modal-card-foot">
					<b-button
						label="Close"
						@click="isModalActive = false" />
					<b-button
						label="Login"
						type="is-primary" />
				</footer> -->
			</div>
		</b-modal>
		<b-modal has-modal-card v-model="isRejectModal">
			<div class="modal-card" style="width: 590px">
				<header class="modal-card-head">
					<p class="modal-card-title has-text-weight-semibold">Penolakan Master Pejabat Approval</p>
					<!-- <button
						type="button"
						class="delete"
						@click="isRejectModal = false"/> -->
				</header>
				<section class="modal-card-body">
					<div class="has-text-weight-medium has-text-primary has-text-centered">
						Data yang akan diproses:
					</div>
					<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
						<ul>
							<li
								v-for="row in checkedRows"
								:key="row.id"
								:value="row.id"
								class="is-flex is-align-items-center"
							>
								<b-icon
									pack="far"
									icon="dot-circle">
								</b-icon> {{row.id}}
							</li>
						</ul>
					</div>
					<form ref="reject">
						<div class="column is-half is-offset-one-fifth mt-4">
							<b-field class="hz-label-area" label="">
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Input Alasan Penolakan<span class="mandatory">*</span></span>
								</template>
								<b-input
									:loading="isSubmitLoading"
									class="modal_area"
									placeholder="Maks 100 karakter"
									maxlength="100"
									type="textarea"
									icon-pack="fas"
									icon-right="pencil-alt"
									v-model="reason"
									@keypress.native="onlyAlphaNum"
									pattern="^[A-Za-z0-9\s]*"
									validation-message="Hanya bisa alfabet, angka dan spasi"
									required>
								</b-input>
							</b-field>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="updateMasterApprovalStatus(1)"
									>TOLAK</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isRejectModal = false"
									>BATAL</b-button>
								</b-field>
							</div>
						</div>
					</form>
				</section>
				<!-- <footer class="modal-card-foot">
					<b-button
						label="Close"
						@click="isModalActive = false" />
					<b-button
						label="Login"
						type="is-primary" />
				</footer> -->
			</div>
        </b-modal>
		<!-- <b-modal has-modal-card v-model="isConfirmModal">
            <div class="modal-card" style="width: 860px">
                    <section class="modal-card-body">
                        <div v-if="approveOrReject != null" class="has-text-weight-medium has-text-primary has-text-centered">
                            Apakah Anda yakin akan {{ approveOrReject == 2 ? 'menyetujui' : 'menolak'}} master pejabat approval dengan rincian sebagai berikut:
                        </div>
                        <div v-if="checkedRows.length > 0 && isConfirmModal" class="column mt-3 is-offset-1 is-10 is-primary has-text-primary has-text-weight-medium">
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">ID</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].id }}</p>
                            </b-field>
							<b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Jenis PK</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].jenis_pk }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Unit</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].unit }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Cabang</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].cabang }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Bagian / Sub Bagian</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].dept_khusus }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Jabatan Umum</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].jab_umum }}</p>
                            </b-field>
							<b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
								v-if="checkedRows[indexConfirmation].alasan_permintaan"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Alasan Permintaan</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].alasan_permintaan }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Dibuat</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_dibuat }} - {{ checkedRows[indexConfirmation].nama_dibuat }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 1</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_struktural_1 }} - {{ checkedRows[indexConfirmation].nama_diketahui_struktural_1 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_struktural_2"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 2</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_struktural_2 }} - {{ checkedRows[indexConfirmation].nama_diketahui_struktural_2 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_struktural_3"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 3</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_struktural_3 }} - {{ checkedRows[indexConfirmation].nama_diketahui_struktural_3 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_struktural_4"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 4</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_struktural_4 }} - {{ checkedRows[indexConfirmation].nama_diketahui_struktural_4 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_struktural_5"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 5</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_struktural_5 }} - {{ checkedRows[indexConfirmation].nama_diketahui_struktural_5 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_struktural_6"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural 6</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_struktural_6 }} - {{ checkedRows[indexConfirmation].nama_diketahui_struktural_6 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_fungsional_1"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional 1</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_fungsional_1 }} - {{ checkedRows[indexConfirmation].nama_diketahui_fungsional_1 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_fungsional_2"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional 2</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_fungsional_2 }} - {{ checkedRows[indexConfirmation].nama_diketahui_fungsional_2 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_fungsional_3"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional 3</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_fungsional_3 }} - {{ checkedRows[indexConfirmation].nama_diketahui_fungsional_3 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_diketahui_fungsional_4"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional 4</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_diketahui_fungsional_4 }} - {{ checkedRows[indexConfirmation].nama_diketahui_fungsional_4 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_disetujui_1"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 1</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_disetujui_1 }} - {{ checkedRows[indexConfirmation].nama_disetujui_1 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_disetujui_2"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 2</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_disetujui_2 }} - {{ checkedRows[indexConfirmation].nama_disetujui_2 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_disetujui_3"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 3</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_disetujui_3 }} - {{ checkedRows[indexConfirmation].nama_disetujui_3 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_disetujui_4"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 4</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_disetujui_4 }} - {{ checkedRows[indexConfirmation].nama_disetujui_4 }}</p>
                            </b-field>
                            <b-field 
                                class="hz-label mt-1 mb-2 is-flex is-align-items-center"
                                label="" 
                                horizontal
                                v-if="checkedRows[indexConfirmation].nik_disetujui_5"
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui 5</span>
                                </template>
                                <p class="is-family-primary has-text-weight-medium has-text-primary">: {{ checkedRows[indexConfirmation].nik_disetujui_5 }} - {{ checkedRows[indexConfirmation].nama_disetujui_5 }}</p>
                            </b-field>
                        </div>
                        <div class="columns mt-4">
                            <div class="column is-3 is-offset-3">
                                <b-field class="">
                                    <b-button
										:loading="isSubmitLoading"
                                        expanded
                                        class="has-background-success has-text-white has-text-weight-semibold"
                                        @click="checkStepModal(true)"
                                    >YA</b-button>
                                </b-field>
                            </div>
                            <div class="column is-3">
                                <b-field class="">
                                    <b-button
										:loading="isSubmitLoading"
                                        expanded
                                        class="has-background-danger-light has-text-white has-text-weight-semibold"
                                        @click="checkStepModal(false)"
                                    >TIDAK</b-button>
                                </b-field>
                            </div>
                        </div>
                    </section>
                </div>
        </b-modal> -->
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'
	import { mapGetters, mapActions } from "vuex";

    export default {
        data() {
            // const data = [
            //     { 
			// 		'id': 1,
			// 		'type': 'PK User',
			// 		'location': 'Head Office',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'branch': 'Head Office',
			// 		'department': 'IT Development SD 2',
			// 		'creator': '2015276116 - Arnan',
			// 		'manager': '2021212023 - Dwika',
			// 		'director': '293012312 - Diasmara',
			// 		'final': '1023912309 - Orang', },
            //     { 
			// 		'id': 2,
			// 		'type': 'PK Cadangan',
			// 		'location': 'Jakarta 2',
			// 		'unit': 'PT Inti Cakrawala Citra',
			// 		'branch': 'Head Office',
			// 		'department': 'Operation',
			// 		'creator': '204829123 - Nanra',
			// 		'manager': '247021932 - Kadwi',
			// 		'director': '203954821 - Dias',
			// 		'final': '1023912309 - Orang', },
            //     { 
			// 		'id': 3,
			// 		'type': 'PK Referensi',
			// 		'location': 'Penempatan',
			// 		'unit': 'Unit',
			// 		'branch': 'Cabang',
			// 		'department': 'Departemen',
			// 		'creator': 'nik - nama',
			// 		'manager': 'nik - nama',
			// 		'director': 'nik - nama',
			// 		'final': 'nik - nama', },
            //     { 
			// 		'id': 4,
			// 		'type': 'PK Referensi',
			// 		'location': 'Penempatan',
			// 		'unit': 'Unit',
			// 		'branch': 'Cabang',
			// 		'department': 'Departemen',
			// 		'creator': 'nik - nama',
			// 		'manager': 'nik - nama',
			// 		'director': 'nik - nama',
			// 		'final': 'nik - nama', },
            //     { 
			// 		'id': 5,
			// 		'type': 'PK Referensi',
			// 		'location': 'Penempatan',
			// 		'unit': 'Unit',
			// 		'branch': 'Cabang',
			// 		'department': 'Departemen',
			// 		'creator': 'nik - nama',
			// 		'manager': 'nik - nama',
			// 		'director': 'nik - nama',
			// 		'final': 'nik - nama', },
            //     { 
			// 		'id': 6,
			// 		'type': 'PK Referensi',
			// 		'location': 'Penempatan',
			// 		'unit': 'Unit',
			// 		'branch': 'Cabang',
			// 		'department': 'Departemen',
			// 		'creator': 'nik - nama',
			// 		'manager': 'nik - nama',
			// 		'director': 'nik - nama',
			// 		'final': 'nik - nama', },
            // ]

            return {
                data: [],
                checkboxPosition: 'left',
				defaultSortDirection: 'desc',
				sortOrder: 'desc',
				sortField: 'id',
				checkedRows: [],
				approvedRejectCheckedRows: [],
				search: '',
				isApproveModal: false,
				isRejectModal: false,
				reason: null,
				textModal: '',
				total: 0,
				page: 1,
				varPageNumbering: 1,
				perPage: 10,
				isLoading: true,
				filter: {},
				isDownloading: false,
				file: [],
				isUploading: false,
				isheaderCheckable: true,
				isSubmitLoading: true,
				isConfirmModal: false,
				approveOrReject: null,
				indexConfirmation: 0,
            }
		},
		
		async mounted () {
			await this.loadAsyncData()
			if (this.storedPersetujuanMasterPejabatApproval) {
				this.checkedRows = this.storedPersetujuanMasterPejabatApproval
			}
			this.isSubmitLoading = false
		},

		computed: {
			...mapGetters({
				storedPersetujuanMasterPejabatApproval: "storedPersetujuanMasterPejabatApproval",
			}),
		},

		methods: {
			...mapActions(["setStoredPersetujuanMasterPejabatApproval"]),

			checkStepModal (approved) {
				if (approved) {
					this.approvedRejectCheckedRows.push(this.checkedRows[this.indexConfirmation])
				}
				this.isSubmitLoading = true
				this.isConfirmModal = false
				this.indexConfirmation += 1
				if (this.checkedRows.length > this.indexConfirmation) {
					this.isConfirmModal = true
					this.isSubmitLoading = false
				} else {
					this.updateMasterApprovalStatus(this.approveOrReject)
				}
			},

			// confirmation (status) {
			// 	this.isSubmitLoading = true
			// 	if (status == 1) {
			// 		if (!this.$refs.reject.checkValidity()) {
			// 			this.$buefy.snackbar.open({
			// 				message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
			// 				type: 'is-info',
			// 				position: 'is-top',
			// 				actionText: 'Tutup',
			// 				indefinite: false,
			// 			})
			// 			return
			// 		} else {
			// 			this.isRejectModal = false
			// 			this.approveOrReject = 1
			// 			this.indexConfirmation = 0
			// 			this.approvedRejectCheckedRows = []
						
			// 			this.isConfirmModal = true
			// 		}
			// 	} else {
			// 		this.isApproveModal = false
			// 		this.approveOrReject = 2
			// 		this.indexConfirmation = 0
			// 		this.approvedRejectCheckedRows = []

			// 		this.isConfirmModal = true
			// 	}
			// 	this.isSubmitLoading = false
			// },

			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						this.checkedRows.push(row)
						// if (this.checkedRows.length <=4) {
						// 	this.checkedRows.push(row)
						// } else {
						// 	this.$buefy.snackbar.open({
						// 		message: 'Maksimal data yang dapat dipilih hanya 5',
						// 		type: 'is-info',
						// 		position: 'is-top',
						// 		actionText: 'Tutup',
						// 		indefinite: false,
						// 		duration: 7000
						// 	})
						// }
					}
				}
			},

			onFilter (filter) {
                this.filter = filter
                this.loadAsyncData()
			},

			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},

			openModal(modalType) {
				this.isSubmitLoading = true
				if (this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih master pejabat approval yang ingin disetujui / ditolak',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					this.reason = null
					this.approveOrReject = null
					if (modalType == 'approve') {
						this.isApproveModal = true
					} else if (modalType == 'reject') {
						this.isRejectModal = true
					}
				}
				this.isSubmitLoading = false
			},

			async updateMasterApprovalStatus (status) {
				this.isSubmitLoading = true

				let sendData = []
				this.checkedRows.forEach( element => {
					element.status = status
					if (status == 1) {
						element.keterangan_reject = this.reason
					}

					let tempData = {
						id: element.id,
						status: element.status,
						keterangan_reject: status == 1 ? element.keterangan_reject : null
					}
					sendData.push(tempData)
				})

				let postData = {
					row: sendData,
					code: 12
				}
				// console.log(JSON.stringify(postData))
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				let index = 0
				let isError = false

				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/approvers', postData)
					.then( response => {
						if (status == 2) {
							titleMessage = 'Persetujuan master pejabat approval berhasil'
						} else {
							titleMessage = 'Penolakan master pejabat approval berhasil'
						}
						iconType = 'success',
						this.checkedRows.forEach( element => {
							textMessage += `${element.id}<br/>`
						})
						iconColor = 'is-success'
						this.isSubmitLoading = false
					})
					.catch( e => {
						if (e.response.status != 401) {
							if (status == 2) {
								titleMessage = 'Gagal menyetujui master pejabat approval!'
							} else {
								titleMessage = 'Gagal menolak master pejabat approval!'
							}
							iconType = 'error'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server'
							}
							iconColor = 'is-danger'
						}
						
						console.log(e)
						this.isSubmitLoading = false
						isError = true
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								html: true,
                                icon: iconType,
                                title: titleMessage,
                                html: textMessage,
                                customClass: {
                                    icon: iconColor,
                                    title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                    htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                    confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                }
                            }).then((isConfirmed) => {
								if (isConfirmed && !isError)
								window.location.reload()
							})
						}
					})
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id')) {
                    pushFilter.id = ""
                } else {
					pushFilter.id = this.filter.id
				}
                if (!this.filter.hasOwnProperty('jenis_pk')) {
                    pushFilter.jenis_pk = ""
                } else {
					pushFilter.jenis_pk = this.filter.jenis_pk
				}
                if (!this.filter.hasOwnProperty('unit')) {
                    pushFilter.unit = ""
                } else {
					pushFilter.unit = this.filter.unit
				}
                if (!this.filter.hasOwnProperty('cabang')) {
                    pushFilter.cabang = ""
                } else {
					pushFilter.cabang = this.filter.cabang
				}
                if (!this.filter.hasOwnProperty('dept_khusus')) {
                    pushFilter.dept_khusus = ""
                } else {
					pushFilter.dept_khusus = this.filter.dept_khusus
				}
                if (!this.filter.hasOwnProperty('jab_umum')) {
                    pushFilter.jab_umum = ""
				} else {
					pushFilter.jab_umum = this.filter.jab_umum
				}
				if (!this.filter.hasOwnProperty('alasan_permintaan')) {
                    pushFilter.alasan_permintaan = ""
				} else {
					pushFilter.alasan_permintaan = this.filter.alasan_permintaan
				}
				if (!this.filter.hasOwnProperty('nik_dibuat')) {
                    pushFilter.nik_dibuat = ""
				} else {
					pushFilter.nik_dibuat = this.filter.nik_dibuat
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_1')) {
                    pushFilter.nik_diketahui_struktural_1 = ""
				} else {
					pushFilter.nik_diketahui_struktural_1 = this.filter.nik_diketahui_struktural_1
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_2')) {
                    pushFilter.nik_diketahui_struktural_2 = ""
				} else {
					pushFilter.nik_diketahui_struktural_2 = this.filter.nik_diketahui_struktural_2
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_3')) {
                    pushFilter.nik_diketahui_struktural_3 = ""
				} else {
					pushFilter.nik_diketahui_struktural_3 = this.filter.nik_diketahui_struktural_3
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_4')) {
                    pushFilter.nik_diketahui_struktural_4 = ""
				} else {
					pushFilter.nik_diketahui_struktural_4 = this.filter.nik_diketahui_struktural_4
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_5')) {
                    pushFilter.nik_diketahui_struktural_5 = ""
				} else {
					pushFilter.nik_diketahui_struktural_5 = this.filter.nik_diketahui_struktural_5
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_struktural_6')) {
                    pushFilter.nik_diketahui_struktural_6 = ""
				} else {
					pushFilter.nik_diketahui_struktural_6 = this.filter.nik_diketahui_struktural_6
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_fungsional_1')) {
                    pushFilter.nik_diketahui_fungsional_1 = ""
				} else {
					pushFilter.nik_diketahui_fungsional_1 = this.filter.nik_diketahui_fungsional_1
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_fungsional_2')) {
                    pushFilter.nik_diketahui_fungsional_2 = ""
				} else {
					pushFilter.nik_diketahui_fungsional_2 = this.filter.nik_diketahui_fungsional_2
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_fungsional_3')) {
                    pushFilter.nik_diketahui_fungsional_3 = ""
				} else {
					pushFilter.nik_diketahui_fungsional_3 = this.filter.nik_diketahui_fungsional_3
				}
				if (!this.filter.hasOwnProperty('nik_diketahui_fungsional_4')) {
                    pushFilter.nik_diketahui_fungsional_4 = ""
				} else {
					pushFilter.nik_diketahui_fungsional_4 = this.filter.nik_diketahui_fungsional_4
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_1')) {
                    pushFilter.nik_disetujui_1 = ""
				} else {
					pushFilter.nik_disetujui_1 = this.filter.nik_disetujui_1
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_2')) {
                    pushFilter.nik_disetujui_2 = ""
				} else {
					pushFilter.nik_disetujui_2 = this.filter.nik_disetujui_2
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_3')) {
                    pushFilter.nik_disetujui_3 = ""
				} else {
					pushFilter.nik_disetujui_3 = this.filter.nik_disetujui_3
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_4')) {
                    pushFilter.nik_disetujui_4 = ""
				} else {
					pushFilter.nik_disetujui_4 = this.filter.nik_disetujui_4
				}
				if (!this.filter.hasOwnProperty('nik_disetujui_5')) {
                    pushFilter.nik_disetujui_5 = ""
				} else {
					pushFilter.nik_disetujui_5 = this.filter.nik_disetujui_5
				}

				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
                //     `column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
                //     `page=${this.page}`,
				// 	`search=${searchBypass}`
				// ].join('&')
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					filter: JSON.stringify(pushFilter),
					code: 11
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/approvers`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),
			
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

			routePage(routing, master) {
				this.isRouting = true
				if (this.checkedRows.length > 0) {
					this.setStoredPersetujuanMasterPejabatApproval(this.checkedRows)
				}
				this.$router.push({name: 'detail-master', params: { routing: routing, master: master }})
			}
		}
    }
</script>
