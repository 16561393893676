<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Master Jabatan
				</div>
				<div class="columns">
					<div class="column is-one-fifth">
						<b-button
							expanded
							class="has-background-success has-text-white has-text-weight-bold"
							@click="routePage('add')"
						>TAMBAH BARU
						</b-button>
					</div>
					<div class="column is-one-fifth">
						<b-button
							expanded
							:loading="isDownloading"
							class="has-background-warning has-text-white has-text-weight-bold"
							@click="downloadTemplate"
						>UNDUH FORMAT
						</b-button>
					</div>
					<div class="column is-one-fifth">
						<b-field>
							<b-upload
								expanded
								:loading="isUploading"
								v-model="file"
								class="button has-background-primary-light has-text-white has-text-weight-bold"
								@input="uploadExcel"
								accept=".xlsx"
							><span>{{ file.name || "UNGGAH EXCEL"}}</span>
							</b-upload>
						</b-field>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						backend-filtering
						@filters-change="onFilter"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:checkbox-position="checkboxPosition"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column searchable sortable header-class="has-background-light" field="id" label="ID" centered v-slot="props" width="60px">
							{{props.row.id}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="unit" label="UNIT/PT" centered v-slot="props">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="cabang" label="CABANG" centered v-slot="props">
							{{props.row.cabang}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="dept_khusus" label="BAGIAN/SUB BAGIAN" centered v-slot="props">
							{{props.row.dept_khusus}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="jab_umum" label="JABATAN UMUM" centered v-slot="props">
							{{props.row.jab_umum}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="jab_khusus" label="JABATAN KHUSUS" centered v-slot="props">
							{{props.row.jab_khusus}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="jab_teknis" label="JABATAN TEKNIS" centered v-slot="props">
							{{props.row.jab_teknis}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="kode_jabatan" label="KODE JABATAN" centered v-slot="props">
							{{props.row.kode_jabatan}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="job_class_from" label="JOB CLASS" centered v-slot="props">
							{{props.row.job_class_from}} - {{ props.row.job_class_to }}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="lead_time" label="LEAD TIME (Hari Kalender)" centered v-slot="props">
							{{props.row.lead_time}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="usia" label="USIA" centered v-slot="props">
							{{props.row.usia}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="jenis_kelamin" label="JENIS KELAMIN" centered v-slot="props">
							{{props.row.jenis_kelamin}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="jenjang_pendidikan" label="PENDIDIKAN" centered v-slot="props">
							{{props.row.jenjang_pendidikan}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="jurusan" label="JURUSAN" centered v-slot="props" width="260px">
							{{props.row.jurusan}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="pengalaman" label="PENGALAMAN KERJA" centered v-slot="props">
							{{props.row.pengalaman}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="jumlah_hari_kerja" label="JUMLAH HARI KERJA" centered v-slot="props">
							{{props.row.jumlah_hari_kerja}} hari
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="shift" label="SHIFT" centered v-slot="props">
							{{props.row.shift}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="keterampilan_khusus" label="KETERAMPILAN KHUSUS" centered v-slot="props">
							{{props.row.keterampilan_khusus}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="deskripsi_pekerjaan" label="DESKRIPSI PEKERJAAN" centered v-slot="props" width="260px">
							{{props.row.deskripsi_pekerjaan}}
						</b-table-column>
						<!-- <b-table-column searchable sortable header-class="has-background-light" field="flag_garansi" label="FLAG GARANSI" centered v-slot="props">
							{{props.row.flag_garansi}}
						</b-table-column>
						<b-table-column searchable sortable header-class="has-background-light" field="masa_garansi" label="MASA GARANSI" centered v-slot="props">
							{{props.row.masa_garansi}} hari
						</b-table-column> -->
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-warning has-text-white has-text-weight-bold"
									@click="routePage('edit')"
								>UBAH</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-danger-light has-text-white has-text-weight-bold"
									@click="openModal('delete')"
								>HAPUS</b-button>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isDeleteModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Hapus Jabatan</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isDeleteModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menghapus data di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										class="mr-1"
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.jab_khusus}} - {{row.jab_teknis}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="deletePosition"
									>YA</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isDeleteModal = false"
									>TIDAK</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'

    export default {
        data() {
            // const data = [
            //     { 
			// 		'id': 1,
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'branch': 'Head Office',
			// 		'department': 'IT Software Development 2',
			// 		'general_position': 'MDP Specialist',
			// 		'specific_position': 'IT Software Developer',
			// 		'technical_position': 'Backend Developer',
			// 		'id_position': 'A5',
			// 		'job_class': 'C1-C3',
			// 		'old': '20-25',
			// 		'gender': 'Laki-laki/Perempuan',
			// 		'education': 'D4 / S1 / S2',
			// 		'discipline': 'Teknik Informatika / Sistem Informasi',
			// 		'experience': 'Freshgraduate / Keahlian Khusus',
			// 		'working_day': '5h',
			// 		'shift': 'non shift',
			// 		'specific_skill': 'Java, PHP, C#',
			// 		'description': 'Mampu mengerjakan backend project dan membuat API, serta memahami sekuriti',
			// 		'warranty': 'Ya',
			// 		'warranty_period': '90', },
            //     { 
			// 		'id': 2,
			// 		'unit': 'PT Inti Cakrawala Citra',
			// 		'branch': 'Head Office',
			// 		'department': 'HR',
			// 		'general_position': 'MDP',
			// 		'specific_position': 'Recruiter',
			// 		'technical_position': 'Recruiter',
			// 		'id_position': 'KM2',
			// 		'job_class': 'C1-C3',
			// 		'old': '20-25',
			// 		'gender': 'Perempuan',
			// 		'education': 'D4 / S1 / S2',
			// 		'discipline': 'Ilmu Komunikasi',
			// 		'experience': 'Freshgraduate',
			// 		'working_day': '5h',
			// 		'shift': 'non shift',
			// 		'specific_skill': 'Communication',
			// 		'description': 'Mampu bekerja dalam kelompok dan menghadapi banyak orang',
			// 		'warranty': 'Ya',
			// 		'warranty_period': '60', },
            //     { 
			// 		'id': 3,
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'branch': 'Yogyakarta',
			// 		'department': 'Operation',
			// 		'general_position': 'Staff',
			// 		'specific_position': 'Store Crew',
			// 		'technical_position': 'Store Crew Boy',
			// 		'id_position': 'X1',
			// 		'job_class': 'A1-A3',
			// 		'old': '18-30',
			// 		'gender': 'Laki-laki',
			// 		'education': 'SMA / D4 / D3',
			// 		'discipline': '',
			// 		'experience': 'Keahlian Khusus',
			// 		'working_day': '6h',
			// 		'shift': 'shift',
			// 		'specific_skill': 'Keuangan, administrasi',
			// 		'description': 'Mampu menggunakan kasir dan memiliki public speaking yang bagus',
			// 		'warranty': 'Ya',
			// 		'warranty_period': '90', },
            //     { 
			// 		'id': 4,
			// 		'unit': 'unit',
			// 		'branch': 'branch',
			// 		'department': 'department',
			// 		'general_position': 'general_position',
			// 		'specific_position': 'specific_position',
			// 		'technical_position': 'technical_position',
			// 		'id_position': 'id_position',
			// 		'job_class': 'job_class',
			// 		'old': 'old',
			// 		'gender': 'gender',
			// 		'education': 'education',
			// 		'discipline': 'discipline',
			// 		'experience': 'experience',
			// 		'working_day': 'working_day',
			// 		'shift': 'shift',
			// 		'specific_skill': 'specific_skill',
			// 		'description': 'description',
			// 		'warranty': 'warranty',
			// 		'warranty_period': 'warranty_period', },
            //     { 
			// 		'id': 5,
			// 		'unit': 'unit',
			// 		'branch': 'branch',
			// 		'department': 'department',
			// 		'general_position': 'general_position',
			// 		'specific_position': 'specific_position',
			// 		'technical_position': 'technical_position',
			// 		'id_position': 'id_position',
			// 		'job_class': 'job_class',
			// 		'old': 'old',
			// 		'gender': 'gender',
			// 		'education': 'education',
			// 		'discipline': 'discipline',
			// 		'experience': 'experience',
			// 		'working_day': 'working_day',
			// 		'shift': 'shift',
			// 		'specific_skill': 'specific_skill',
			// 		'description': 'description',
			// 		'warranty': 'warranty',
			// 		'warranty_period': 'warranty_period', },
            //     { 
			// 		'id': 6,
			// 		'unit': 'unit',
			// 		'branch': 'branch',
			// 		'department': 'department',
			// 		'general_position': 'general_position',
			// 		'specific_position': 'specific_position',
			// 		'technical_position': 'technical_position',
			// 		'id_position': 'id_position',
			// 		'job_class': 'job_class',
			// 		'old': 'old',
			// 		'gender': 'gender',
			// 		'education': 'education',
			// 		'discipline': 'discipline',
			// 		'experience': 'experience',
			// 		'working_day': 'working_day',
			// 		'shift': 'shift',
			// 		'specific_skill': 'specific_skill',
			// 		'description': 'description',
			// 		'warranty': 'warranty',
			// 		'warranty_period': 'warranty_period', },
            // ]

            return {
                data: [],
                checkboxPosition: 'left',
				defaultSortDirection: 'desc',
				sortOrder: 'desc',
				sortField: 'id',
				checkedRows: [],
				search: '',
				isApproveModal: false,
				isDeleteModal: false,
				textModal: '',
				total: 0,
				page: 1,
				varPageNumbering: 1,
				perPage: 10,
				isLoading: true,
				filter: {},
				isDownloading: false,
				file: [],
				isUploading: false,
				isheaderCheckable: true,
            }
		},
		
		async mounted () {
			await this.loadAsyncData()
		},

		methods: {
			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						this.checkedRows.push(row)
						// if (this.checkedRows.length <=4) {
						// 	this.checkedRows.push(row)
						// } else {
						// 	this.$buefy.snackbar.open({
						// 		message: 'Maksimal data yang dapat dipilih hanya 5',
						// 		type: 'is-info',
						// 		position: 'is-top',
						// 		actionText: 'Tutup',
						// 		indefinite: false,
						// 		duration: 7000
						// 	})
						// }
					}
				}
			},
			
			async uploadExcel() {
				this.isUploading = true
								
				const postData = new FormData()
				postData.set("file", this.file)
				postData.set("code", 10)
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''

				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData, {responseType: 'arraybuffer'})
					.then( response => {
						this.file = []
						let converter = require('arraybuffer-to-string');
						let convertResponse = converter(response.data); //terjemahkan response arraybuffer ke string
						if (convertResponse[0] == '{') {
							iconType = 'success',
							titleMessage = 'Data jabatan baru berhasil diunggah & disimpan',
							iconColor = 'is-success'
							//this.emptyField()
							this.isUploading = false
							this.loadAsyncData()
						} else {
							let blob=new Blob([response.data]);
							// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
							// 	window.navigator.msSaveOrOpenBlob(blob);
							// 	return;
							// }
							let link=document.createElement('a');
							link.href=window.URL.createObjectURL(blob);
							link.setAttribute('download', 'GagalUploadJabatan.xlsx');
							link.click();
							setTimeout(function () {
								// For Firefox it is necessary to delay revoking the ObjectURL
								window.URL.revokeObjectURL(link.href)
									, 100
							})

							iconType = 'error'
							titleMessage = 'Gagal mengunggah & menyimpan sebagian data jabatan!'
							textMessage = 'Silakan cek data yang tidak masuk pada file excel yang terdownload kembali'
							iconColor = 'is-danger'

							this.isUploading = false
							this.loadAsyncData()
						}
					})
					.catch( e => {
						console.log(e)
						this.file = []
						if (e.response.status != 401) {
							iconType = 'error'
							titleMessage = 'Gagal mengunggah & menyimpan data jabatan!'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server. Pastikan file yang diupload merupakan file template dari master jabatan'
							}
							iconColor = 'is-danger'
						}

						// let converter = require('arraybuffer-to-string');
						// let convertResponse = converter(e.response); //terjemahkan response arraybuffer ke string
						// let tempResponse = JSON.parse(convertResponse);
						// if (tempResponse.status != 401) {
						// 	convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
						// 	tempResponse = JSON.parse(convertResponse);
							
						// 	iconType = 'error'
						// 	titleMessage = 'Gagal mengunggah & menyimpan data jabatan!'
						// 	if (tempResponse.status && tempResponse.status != 0) {
						// 		textMessage = tempResponse.message
						// 	} else {
						// 		textMessage = 'Terjadi kesalahan pada server'
						// 	}
						// 	iconColor = 'is-danger'
						// }
						
						// console.log(e)
						this.isUploading = false
						this.loadAsyncData()
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								icon: iconType,
								title: titleMessage,
								text: textMessage,
								customClass: {
									icon: iconColor,
									title: 'has-text-primary is-family-primary has-text-weight-semibold',
									htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
									confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
								}
							})
						}
					})
			},

			async downloadTemplate () {
				this.isDownloading = true
				let postData = {
					code: 11
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData,
					{responseType: 'arraybuffer'})
					.then( response => {
						//handle kembalian file pdf
						let blob=new Blob([response.data]);
						// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
						// 	window.navigator.msSaveOrOpenBlob(blob);
						// 	return;
						// }
						let link=document.createElement('a');
						link.href=window.URL.createObjectURL(blob);
						link.setAttribute('download', 'TemplateJabatan.xlsx');
						link.click();
						setTimeout(function () {
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(link.href)
								, 100
						})
					})
					.catch( e => {
						console.log(e)
						let converter = require('arraybuffer-to-string');
						let convertResponse = converter(e.response); //terjemahkan response arraybuffer ke string
						let tempMessage = JSON.parse(convertResponse);
						if (tempMessage.status != 401) {
							convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
							tempMessage = JSON.parse(convertResponse);
							let message = 'Terjadi kesalahan pada server'
							if (tempMessage.status && tempMessage.status != 0) {
								message = tempMessage.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(this.isDownloading = false)
			},

			onFilter (filter) {
                this.filter = filter
                this.loadAsyncData()
			},

			openModal(modalType) {
				if (this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin dihapus',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					if (modalType == 'delete') {
						this.isDeleteModal = true
					}
				}
			},

			async deletePosition () {
				this.isDeleteModal = false
				let sendData = []
				this.checkedRows.forEach( element => {
					let tempData = {
						id: element.id
					}
					sendData.push(tempData)
				})
				let postData = {
					row: sendData,
					code: 5
				}
				//console.log(JSON.stringify(postData))
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/positions`, postData)
                    .then( response => {
                            iconType = 'success'
                            titleMessage = 'Data jabatan berhasil dihapus'
                            iconColor = 'is-success'
							this.loadAsyncData()
							this.checkedRows = []
					})
					.catch( e => {
						if (e.response.status != 401) {
							iconType = 'error'
							titleMessage = 'Gagal menghapus data jabatan!'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server'
							}
							iconColor = 'is-danger'
						}
						
						console.log(e)
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								icon: iconType,
								title: titleMessage,
								text: textMessage,
								customClass: {
									icon: iconColor,
									title: 'has-text-primary is-family-primary has-text-weight-semibold',
									htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
									confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
								}
							})
						}
					})
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id')) {
                    pushFilter.id = ""
                } else {
					pushFilter.id = this.filter.id
				}
                if (!this.filter.hasOwnProperty('unit')) {
                    pushFilter.unit = ""
                } else {
					pushFilter.unit = this.filter.unit
				}
                if (!this.filter.hasOwnProperty('cabang')) {
                    pushFilter.cabang = ""
                } else {
					pushFilter.cabang = this.filter.cabang
				}
                if (!this.filter.hasOwnProperty('dept_khusus')) {
                    pushFilter.dept_khusus = ""
				} else {
					pushFilter.dept_khusus = this.filter.dept_khusus
				}
				if (!this.filter.hasOwnProperty('jab_umum')) {
                    pushFilter.jab_umum = ""
                } else {
					pushFilter.jab_umum = this.filter.jab_umum
				}
                if (!this.filter.hasOwnProperty('jab_khusus')) {
                    pushFilter.jab_khusus = ""
                } else {
					pushFilter.jab_khusus = this.filter.jab_khusus
				}
				if (!this.filter.hasOwnProperty('jab_teknis')) {
                    pushFilter.jab_teknis = ""
                } else {
					pushFilter.jab_teknis = this.filter.jab_teknis
				}
				if (!this.filter.hasOwnProperty('kode_jabatan')) {
                    pushFilter.kode_jabatan = ""
                } else {
					pushFilter.kode_jabatan = this.filter.kode_jabatan
				}
				if (!this.filter.hasOwnProperty('job_class_from')) {
                    pushFilter.job_class_from = ""
                } else {
					pushFilter.job_class_from = this.filter.job_class_from
				}
				if (!this.filter.hasOwnProperty('lead_time')) {
                    pushFilter.lead_time = ""
                } else {
					pushFilter.lead_time = this.filter.lead_time
				}
				if (!this.filter.hasOwnProperty('usia')) {
                    pushFilter.usia = ""
                } else {
					pushFilter.usia = this.filter.usia
				}
				if (!this.filter.hasOwnProperty('jenis_kelamin')) {
                    pushFilter.jenis_kelamin = ""
                } else {
					pushFilter.jenis_kelamin = this.filter.jenis_kelamin
				}
				if (!this.filter.hasOwnProperty('jenjang_pendidikan')) {
                    pushFilter.jenjang_pendidikan = ""
                } else {
					pushFilter.jenjang_pendidikan = this.filter.jenjang_pendidikan
				}
				if (!this.filter.hasOwnProperty('jurusan')) {
                    pushFilter.jurusan = ""
                } else {
					pushFilter.jurusan = this.filter.jurusan
				}
				if (!this.filter.hasOwnProperty('pengalaman')) {
                    pushFilter.pengalaman = ""
                } else {
					pushFilter.pengalaman = this.filter.pengalaman
				}
				if (!this.filter.hasOwnProperty('jumlah_hari_kerja')) {
                    pushFilter.jumlah_hari_kerja = ""
                } else {
					pushFilter.jumlah_hari_kerja = this.filter.jumlah_hari_kerja
				}
				if (!this.filter.hasOwnProperty('shift')) {
                    pushFilter.shift = ""
                } else {
					pushFilter.shift = this.filter.shift
				}
				if (!this.filter.hasOwnProperty('keterampilan_khusus')) {
                    pushFilter.keterampilan_khusus = ""
                } else {
					pushFilter.keterampilan_khusus = this.filter.keterampilan_khusus
				}
				if (!this.filter.hasOwnProperty('deskripsi_pekerjaan')) {
                    pushFilter.deskripsi_pekerjaan = ""
                } else {
					pushFilter.deskripsi_pekerjaan = this.filter.deskripsi_pekerjaan
				}
				if (!this.filter.hasOwnProperty('flag_garansi')) {
                    pushFilter.flag_garansi = ""
                } else {
					pushFilter.flag_garansi = this.filter.flag_garansi
				}
				if (!this.filter.hasOwnProperty('masa_garansi')) {
                    pushFilter.masa_garansi = ""
                } else {
					pushFilter.masa_garansi = this.filter.masa_garansi
				}

				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
                //     `column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`search=${searchBypass}`
				// ].join('&')
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					filter: JSON.stringify(pushFilter),
					code: 1
				}
				
                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/positions`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
							if (item.jurusan) {
								item.jurusan = item.jurusan.replace(/\|\|/g, ", ")
							}
							this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(response.data.result)
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
            }, 400),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

			routePage(routingTo) {
				if (routingTo == 'edit' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih data master yang ingin diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'edit' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 data master untuk diubah',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					let master = null
					if (routingTo == 'edit') {
						master = this.checkedRows[0]
					}
					this.$router.push({name: 'form-jabatan', params: { routingTo: routingTo, master: master }})
				}
			}
		}
    }
</script>
