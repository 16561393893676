<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Persetujuan Ubah PK
				</div>
				<div class="columns is-desktop">
					<div class="column is-5">
						<b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Jenis PK</span>
							</template>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-select
									@input="loadAsyncData"
									placeholder=""
									expanded
									class="box-field"
									v-model="PKType"
									required>
									<option selected>All</option>
									<option>User</option>
									<option>Wilayah Baru (Cabang / DCI)</option>
									<option>Referensi</option>
								</b-select>
								<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
							</ValidationProvider>
						</b-field>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						class="sticky-column"
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:checkbox-position="checkboxPosition"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column sortable header-class="has-background-light sticky-master-approval-1" cell-class="has-background-white sticky-master-approval-1" width="1px" field="id_pk" label="NOMOR PK" centered v-slot="props">
							<a class="is-underlined" @click="routePage('persetujuan-ubah-pk', props.row.id_pk)">{{props.row.nomor_pk}}</a>
						</b-table-column>
						<b-table-column header-class="has-background-light sticky-master-approval-2" cell-class="has-background-white sticky-master-approval-2" width="2px" field="jenis_pk" label="JENIS PK" centered v-slot="props">
							{{props.row.jenis_pk}}
						</b-table-column>
						<b-table-column sortable header-class="has-background-light sticky-master-approval-3" cell-class="has-background-white sticky-master-approval-3" width="3px" field="unit" label="UNIT/PT" centered v-slot="props">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column sortable header-class="has-background-light sticky-master-approval-4" cell-class="has-background-white sticky-master-approval-4" width="4px" field="departemen" label="BAGIAN/SUB BAGIAN" centered v-slot="props">
							{{props.row.departemen}}
						</b-table-column>
						<b-table-column sortable header-class="has-background-light sticky-master-approval-5" cell-class="has-background-white sticky-master-approval-5" width="5px" field="jumlah_kebutuhan_lama" label="KEBUTUHAN LAMA" centered v-slot="props">
							<b-field 
								class="hz-label-table is-flex is-align-items-center" 
								label="" 
								style="font-size: 8.4px"
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium has-text-primary">Jumlah Kebutuhan</span>
								</template>
								<p class="has-text-info-light">{{ props.row.jumlah_kebutuhan_pk }}</p>
							</b-field>
							<div class="columns is-desktop">
								<div class="column is-12">
									<b-field 
										class="hz-label-right-table is-flex is-align-items-center" 
										label="" 
										style="font-size: 8.4px"
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium has-text-primary">Laki - Laki</span>
										</template>
										<p class="has-text-info-light">{{ props.row.jumlah_laki_pk }}</p>
									</b-field>
									<b-field 
										class="hz-label-right-table is-flex is-align-items-center" 
										label="" 
										style="font-size: 8.4px"
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium has-text-primary">Perempuan</span>
										</template>
										<p class="has-text-info-light">{{ props.row.jumlah_perempuan_pk }}</p>
									</b-field>
									<b-field 
										class="hz-label-right-table is-flex is-align-items-center" 
										label="" 
										style="font-size: 8.4px"
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium has-text-primary">Bebas</span>
										</template>
										<p class="has-text-info-light">{{ props.row.jumlah_bebas_pk }}</p>
									</b-field>
								</div>
							</div>
						</b-table-column>
						<b-table-column header-class="has-background-light sticky-master-approval-6" cell-class="has-background-white sticky-master-approval-6" width="6px" field="jumlah_kebutuhan" label="KEBUTUHAN BARU" centered v-slot="props">
							<b-field 
								class="hz-label-table is-flex is-align-items-center" 
								label="" 
								style="font-size: 8.4px"
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium has-text-primary">Jumlah Kebutuhan</span>
								</template>
								<p class="has-text-info-light">{{ props.row.jumlah_kebutuhan }}</p>
							</b-field>
							<div class="columns is-desktop">
								<div class="column is-12">
									<b-field 
										class="hz-label-right-table is-flex is-align-items-center" 
										label=""
										style="font-size: 8.4px" 
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium has-text-primary">Laki - Laki</span>
										</template>
										<p class="has-text-info-light">{{ props.row.jumlah_laki }}</p>
									</b-field>
									<b-field 
										class="hz-label-right-table is-flex is-align-items-center" 
										label="" 
										style="font-size: 8.4px"
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium has-text-primary">Perempuan</span>
										</template>
										<p class="has-text-info-light">{{ props.row.jumlah_perempuan }}</p>
									</b-field>
									<b-field 
										class="hz-label-right-table is-flex is-align-items-center" 
										label="" 
										style="font-size: 8.4px"
										horizontal
									>
										<template #label>
											<span class="is-family-primary has-text-weight-medium has-text-primary">Bebas</span>
										</template>
										<p class="has-text-info-light">{{ props.row.jumlah_bebas }}</p>
									</b-field>
								</div>
							</div>
						</b-table-column>
						<b-table-column sortable header-class="has-background-light sticky-master-approval-7" cell-class="has-background-white sticky-master-approval-7" width="7px" field="created_at" label="TANGGAL PENGAJUAN" centered v-slot="props">
							{{props.row.created_at}}
						</b-table-column>
						<!-- <b-table-column header-class="has-background-light" field="status" label="STATUS" width="100" centered v-slot="props">
							<div :class="props.row.status == 0 ? 'is-hidden' : ''">
								<b-tooltip
									:active="props.row.status == 1 ? true : false"
									:label="props.row.keterangan_reject"
									size="is-small"
									position="is-bottom"
									multilined>
									<b-icon
										:key="search"
										:type="props.row.status == 2 ? 'is-success' : 'is-danger'"
										class="is-size-4"
										pack="fas"
										size="is-medium"
										:icon="props.row.status == 2 ? 'check-circle' : 'times-circle'">
									</b-icon>
								</b-tooltip>
							</div>
							<div>{{props.row.nik_approval_rnp}}</div>
							<div>{{props.row.tanggal_approval_rnp}}</div>
						</b-table-column> -->
						<b-table-column header-class="has-background-light" field="nama_dibuat" label="DIBUAT" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_dibuat"
								:label="props.row.status_dibuat == 1 ? props.row.keterangan_reject : props.row.status_dibuat == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_dibuat == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_dibuat}}</div>
							</b-tooltip>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_struktural_1" label="DISETUJUI STRUKTURAL 1" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_struktural_1"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_1' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_1 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_struktural_1 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_1}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_struktural_1 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_struktural_2" label="DISETUJUI STRUKTURAL 2" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_struktural_2"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_2' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_2 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_struktural_2 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_2}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_struktural_2 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_struktural_3" label="DISETUJUI STRUKTURAL 3" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_struktural_3"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_3' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_3 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_struktural_3 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_3}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_struktural_3 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_struktural_4" label="DISETUJUI STRUKTURAL 4" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_struktural_4"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_4' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_4 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_struktural_4 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_4}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_struktural_4 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_struktural_5" label="DISETUJUI STRUKTURAL 5" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_struktural_5"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_5' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_5 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_struktural_5 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_5}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_struktural_5 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_struktural_6" label="DISETUJUI STRUKTURAL 6" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_struktural_6"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_6' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_6 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_struktural_6 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_6}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_struktural_6 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_fungsional_1" label="DISETUJUI FUNGSIONAL 1" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_fungsional_1"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_fungsional_1' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_fungsional_1 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_fungsional_1 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_fungsional_1}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_fungsional_1 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_fungsional_2" label="DISETUJUI FUNGSIONAL 2" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_fungsional_2"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_fungsional_2' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_fungsional_2 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_fungsional_2 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_fungsional_2}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_fungsional_2 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_fungsional_3" label="DISETUJUI FUNGSIONAL 3" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_fungsional_3"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_fungsional_3' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_fungsional_3 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_fungsional_3 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_fungsional_3}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_fungsional_3 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_diketahui_fungsional_4" label="DISETUJUI FUNGSIONAL 4" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_diketahui_fungsional_4"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_fungsional_4' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_fungsional_4 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_diketahui_fungsional_4 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_fungsional_4}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_diketahui_fungsional_4 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_disetujui_1" label="DISETUJUI 1" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_disetujui_1"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_1' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_1 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_disetujui_1 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_1}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_disetujui_1 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_disetujui_2" label="DISETUJUI 2" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_disetujui_2"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_2' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_2 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_disetujui_2 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_2}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_disetujui_2 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_disetujui_3" label="DISETUJUI 3" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_disetujui_3"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_3' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_3 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_disetujui_3 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_3}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_disetujui_3 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_disetujui_4" label="DISETUJUI 4" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_disetujui_4"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_4' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_4 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_disetujui_4 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_4}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_disetujui_4 == null">-</div>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama_disetujui_5" label="DISETUJUI 5" centered v-slot="props">
							<b-tooltip
								v-if="props.row.nama_disetujui_5"
								:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_5' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_5 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
								size="is-small"
								position="is-left"
								multilined>
								<div :class="props.row.status_disetujui_5 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_5}}</div>
							</b-tooltip>
							<div v-if="props.row.nama_disetujui_5 == null">-</div>
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-success has-text-white has-text-weight-bold"
									@click="openModal('approve')"
								>SETUJU</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-danger-light has-text-white has-text-weight-bold"
									@click="openModal('reject')"
								>TOLAK</b-button>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isApproveModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Persetujuan Ubah PK</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isApproveModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menyetujui permohonan pengubahan jumlah kebutuhan untuk PK di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.nomor_pk}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="updateEditPK(2)"
									>YA</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isApproveModal = false"
									>TIDAK</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
		<b-modal has-modal-card v-model="isRejectModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Penolakan Ubah PK</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isRejectModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menolak permohonan pengubahan jumlah kebutuhan untuk PK di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.nomor_pk}}
								</li>
							</ul>
						</div>
						<form ref="reject">
							<div class="column is-half is-offset-one-fifth mt-4">
								<b-field class="hz-label-area" label="">
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Input Alasan Penolakan<span class="mandatory">*</span></span>
									</template>
									<b-input
										:loading="isSubmitLoading"
										class="modal_area"
										placeholder="Maks 100 karakter"
										maxlength="100"
										type="textarea"
										icon-pack="fas"
										icon-right="pencil-alt"
										v-model="reason"
										@keypress.native="onlyAlphaNum"
										pattern="^[A-Za-z0-9\s]*"
										validation-message="Hanya bisa alfabet, angka dan spasi"
										required>
									</b-input>
								</b-field>
							</div>
							<div class="columns mt-4">
								<div class="column is-3 is-offset-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-success has-text-white has-text-weight-semibold"
											@click="updateEditPK(1)"
										>YA</b-button>
									</b-field>
								</div>
								<div class="column is-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-danger-light has-text-white has-text-weight-semibold"
											@click="isRejectModal = false"
										>TIDAK</b-button>
									</b-field>
								</div>
							</div>
						</form>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'
	import { mapGetters, mapActions } from "vuex";

    export default {
        data() {
            // const data = [
            //     { 
			// 		'id': 1,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'approval_rank': 'DISETUJUI MANAGER',
			// 		'approval_substitution': '2015276116 - Arnan Dwika',
			// 		'approval_replaced': '200500000 - Diasmara',
			// 		'approval_submitter': '200100100 - Nama',
			// 		'substitution_date': '01-10-2021', },
            //     { 
			// 		'id': 2,
			// 		'no_pk': '2222-HO-IGR-01-22-HR',
			// 		'unit': 'PT Inti Cakrawala Citra',
			// 		'bagian': 'HR Recruitment & Placement',
			// 		'approval_rank': 'PERSETUJUAN FINAL',
			// 		'approval_substitution': 'NIK - Nama',
			// 		'approval_replaced': '251231642 - Seseorang',
			// 		'approval_submitter': '202102345 - Dwika',
			// 		'substitution_date': '26-01-2022', },
            //     { 
			// 		'id': 3,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 7',
			// 		'approval_rank': 'DISETUJUI DIREKTUR',
			// 		'approval_substitution': '2059283912 - Ar',
			// 		'approval_replaced': '205827324 - Nan',
			// 		'approval_submitter': '209582434 - Dwi',
			// 		'substitution_date': '19-04-2022', },
            //     { 
			// 		'id': 4,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'approval_rank': 'DISETUJUI DIREKTUR',
			// 		'approval_substitution': 'NIK - Nama',
			// 		'approval_replaced': 'NIK - Nama',
			// 		'approval_submitter': 'NIK - Nama',
			// 		'substitution_date': 'dd-mm-yyyy', },
            //     { 
			// 		'id': 5,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'approval_rank': 'DISETUJUI DIREKTUR',
			// 		'approval_substitution': 'NIK - Nama',
			// 		'approval_replaced': 'NIK - Nama',
			// 		'approval_submitter': 'NIK - Nama',
			// 		'substitution_date': 'dd-mm-yyyy', },
            //     { 
			// 		'id': 6,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'approval_rank': 'DISETUJUI DIREKTUR',
			// 		'approval_substitution': 'NIK - Nama',
			// 		'approval_replaced': 'NIK - Nama',
			// 		'approval_submitter': 'NIK - Nama',
			// 		'substitution_date': 'dd-mm-yyyy', },
            // ]

            return {
				data: [],
				PKType: 'All',
                checkboxPosition: 'left',
				defaultSortDirection: 'desc',
				sortOrder: 'desc',
				sortField: 'id',
				checkedRows: [],
				search: '',
				isApproveModal: false,
				isRejectModal: false,
				textModal: '',
				total: 0,
				page: 1,
				varPageNumbering: 1,
				perPage: 10,
				isLoading: true,
				isSubmitLoading: true,
				reason: null,
				isheaderCheckable: true,
				isRouting: false,
            }
        },
		
		async mounted () {
			await this.loadAsyncData()
			if (this.storedUbahPK) {
				this.checkedRows = this.storedUbahPK
			}
			await this.getUser()
			this.isSubmitLoading = false
		},

		computed: {
			...mapGetters({
				storedUbahPK: "storedUbahPK",
			}),
		},

		methods: {
			...mapActions(["setStoredUbahPK"]),

			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						this.checkedRows.push(row)
						// if (this.checkedRows.length <=4) {
						// 	this.checkedRows.push(row)
						// } else {
						// 	this.$buefy.snackbar.open({
						// 		message: 'Maksimal data yang dapat dipilih hanya 5',
						// 		type: 'is-info',
						// 		position: 'is-top',
						// 		actionText: 'Tutup',
						// 		indefinite: false,
						// 		duration: 7000
						// 	})
						// }
					}
				}
			},

			// validateCheckedRow (row) {
			// 	let isCheckable = false
			// 	this.checkedRows.forEach((item) => {
			// 		if (item.id == row.id) {
			// 			isCheckable = true
			// 		}
			// 	})
				
			// 	if (this.checkedRows.length <=4) {
			// 		isCheckable = true
			// 	}

			// 	return isCheckable
			// },

			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			
			async updateEditPK (status) {
				if (status == 1) {
					if (!this.$refs.reject.checkValidity()) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return
					}
				}

				this.isSubmitLoading = true

				let sendData = []
				this.checkedRows.forEach( element => {
					element.status = status
					if (status == 1) {
						element.keterangan_reject = this.reason
					}

					let tempData = {
						id: element.id,
						status: element.status,
						keterangan_reject: status == 1 ? element.keterangan_reject : null
					}
					sendData.push(tempData)
				})

				let postData = {
					row: sendData,
					code: 5
				}
				//console.log(JSON.stringify(postData))
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				let index = 0
				let isError = false

				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pk-edits`, postData)
					.then( response => {
						if (status == 2) {
							titleMessage = 'Pengubahan jumlah kebutuhan berhasil disetujui'
						} else {
							titleMessage = 'Pengubahan jumlah kebutuhan berhasil ditolak'
						}
						iconType = 'success',
						response.data.result.forEach(() => {
							textMessage += `${this.checkedRows[index].nomor_pk}<br/>`
							index++
						})
						iconColor = 'is-success'
						this.isSubmitLoading = false
					})
					.catch( e => {
						if (e.response.status != 401) {
							if (status == 2) {
								titleMessage = 'Gagal menyetujui pengubahan jumlah kebutuhan!'
							} else {
								titleMessage = 'Gagal menolak pengubahan jumlah kebutuhan!'
							}
							iconType = 'error'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server'
							}
							iconColor = 'is-danger'
						}
						
						console.log(e)
						this.isSubmitLoading = false
						isError = true
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								html: true,
                                icon: iconType,
                                title: titleMessage,
                                html: textMessage,
                                customClass: {
                                    icon: iconColor,
                                    title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                    htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                    confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                }
                            }).then((isConfirmed) => {
								if (isConfirmed && !isError)
								window.location.reload()
							})
						}
					})
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
			},
			
			onPageChange(page) {
                this.page = page
                this.loadAsyncData()
			},
			
			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}
				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
				// 	`column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`search=${searchBypass}`,
				// 	`jenis_pk=${this.PKType}`
				// ].join('&')
				// console.log(params)
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					jenis_pk: this.PKType,
					code: 1
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pk-edits`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
							// if (item.created_at) {
							// 	item.created_at = item.created_at.substring(0, item.created_at.indexOf(" "))
							// }
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(this.data)
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),

			async getUser() {
				let postData = {
					code: 1
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'auth', postData)
					.then( response => {
						this.userNik = response.data.result.nik
						this.userJobClass = response.data.result.job_class
					})
					.catch( e => {
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
			},
			
			openModal(modalType) {
				if (this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih perubahan jumlah kebutuhan PK yang ingin disetujui / ditolak',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					if (modalType == 'approve' || modalType == 'reject') {
						let canOpenModal = true
						let onSubstitute = false
						let message = null
						if (this.checkedRows.length > 5) {
							canOpenModal = false
							message = "Maksimal data yang dapat dipilih hanya 5"
						}
						this.checkedRows.forEach(element => {
							if (canOpenModal) {
								if (element.status_diketahui_struktural_1 == 1 || element.status_diketahui_struktural_2 == 1 || element.status_diketahui_struktural_3 == 1 || element.status_diketahui_struktural_4 == 1 || element.status_diketahui_struktural_5 == 1 || element.status_diketahui_struktural_6 == 1 || 
								element.status_diketahui_fungsional_1 == 1 || element.status_diketahui_fungsional_2 == 1 || element.status_diketahui_fungsional_3 == 1 || element.status_diketahui_fungsional_4 == 1 || 
								element.status_disetujui_1 == 1 || element.status_disetujui_2 == 1 || element.status_disetujui_3 == 1 || element.status_disetujui_4 == 1 || element.status_disetujui_5 == 1) {
									canOpenModal = false
									message = "Terdapat perubahan jumlah kebutuhan PK yang sudah ditolak"
								} else if (element.status_diketahui_struktural_1 == 2 && element.status_diketahui_struktural_2 == 2 && element.status_diketahui_struktural_3 == 2 && element.status_diketahui_struktural_4 == 2 && element.status_diketahui_struktural_5 == 2 && element.status_diketahui_struktural_6 == 2 && 
								element.status_diketahui_fungsional_1 == 2 && element.status_diketahui_fungsional_2 == 2 && element.status_diketahui_fungsional_3 == 2 && element.status_diketahui_fungsional_4 == 2 && 
								element.status_disetujui_1 == 2 && element.status_disetujui_2 == 2 && element.status_disetujui_3 == 2 && element.status_disetujui_4 == 2 && element.status_disetujui_5 == 2) {
									canOpenModal = false
									message = "Terdapat perubahan jumlah kebutuhan PK yang sudah disetujui"
								} else if (element.status_diketahui_struktural_1 == 0 && element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_1 != this.userNik/* || this.userJobClass < 'D1'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_2 == 0 && element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_2 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_2 == 0 && element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_2 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_3 == 0 && element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_struktural_3 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_3 == 0 && element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_3 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_3 == 0 && element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_3 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_4 == 0 && element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_struktural_4 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_4 == 0 && element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_struktural_4 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_4 == 0 && element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_4 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_4 == 0 && element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_4 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan perubahan jumlah kebutuhan PK tidak untuk anda"
									}
								}
							}
						});
						if (!canOpenModal) {
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000,
							})
						} else {
							if (modalType == 'approve') {
								this.isApproveModal = true
							} else if (modalType == 'reject') {
								this.reason = null
								this.isRejectModal = true
							}
						}
					}
				}
			},

			routePage(routing, PK) {
				this.isRouting = true
				if (this.checkedRows.length > 0) {
					this.setStoredUbahPK(this.checkedRows)
				}
				this.$router.push({name: 'detail-pk', params: { routing: routing, PK: PK }})
			}
		}
    }
</script>
