<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Riwayat PK Terpenuhi
				</div>
				<div class="columns is-desktop">
					<div class="column is-5">
						<b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Jenis PK</span>
							</template>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-select
									@input="loadAsyncData"
									placeholder=""
									expanded
									class="box-field"
									v-model="PKType"
									required>
									<option selected>All</option>
									<option>User</option>
									<option>Wilayah Baru (Cabang / DCI)</option>
									<option>Referensi</option>
								</b-select>
								<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
							</ValidationProvider>
						</b-field>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
						<!-- <div class="column is-5 is-size-6">
							<b-field 
								class="hz-label-master is-flex is-align-items-center" 
								label="" 
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Status</span>
								</template>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-select
										@input="loadAsyncData"
										placeholder=""
										expanded
										class="box-field"
										v-model="filterStatus"
										required>
										<option selected>Semua</option>
										<option>PK Terpenuhi</option>
										<option>PK Dibatalkan</option>
										<option>PK Ditolak</option>
										<option>PK Kadaluarsa</option>
									</b-select>
									<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
								</ValidationProvider>
							</b-field>
						</div> -->
						<div class="column is-4 is-offset-8">
							<b-field class="">
								<b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
							</b-field>
						</div>
					</div>
					<b-table
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						backend-filtering
						@filters-change="onFilter"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:checkbox-position="checkboxPosition"
                        :header-checkable="false"
                        :is-row-checkable="validateCheckedRow"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column searchable sortable header-class="has-background-light" field="id_pk" label="NOMOR PK" centered v-slot="props">
							<a class="is-underlined" @click="routePage('pk-terpenuhi', 'detail-pk', props.row.id)">{{props.row.id_pk}}</a>
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="nomor_pk" label="ID PK" centered v-slot="props">
							{{props.row.nomor_pk}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="jenis_pk" label="JENIS PK" centered v-slot="props">
							{{props.row.jenis_pk}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="unit" label="UNIT/PT" centered v-slot="props">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="cabang" label="CABANG" centered v-slot="props">
							{{props.row.cabang}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="departemen" label="BAGIAN/SUB BAGIAN" centered v-slot="props">
							{{props.row.departemen}}
						</b-table-column>
						<b-table-column searchable header-class="has-background-light" field="tanggal_pk_selesai" label="TANGGAL PK TERPENUHI" centered v-slot="props">
							{{props.row.tanggal_pk_selesai}}
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-success has-text-white has-text-weight-bold"
									@click="routePage('pk-terpenuhi', 'detail-rekrutmen', null)"
								>RINCIAN</b-button>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import moment from 'moment'
	import debounce from 'lodash.debounce'

    export default {
        data() {
            return {
				data: [],
				filterStatus: 'Semua',
				PKType: 'All',
                checkboxPosition: 'left',
                defaultSortDirection: 'desc',
				checkedRows: [],
				sortOrder: 'desc',
				sortField: 'id',
				search: '',
				total: 0,
				perPage: 10,
				page: 1,
				varPageNumbering: 1,
				isLoading: true,
				filter: {},
				isRouting: false,
            }
		},
		
		async mounted () {
			await this.loadAsyncData()
			this.isSubmitLoading = false
		},

		methods: {
			onFilter (filter) {
                this.filter = filter
				this.loadAsyncData()
			},

			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						if (this.checkedRows.length <=0) {
							this.checkedRows.push(row)
						} else {
							this.$buefy.snackbar.open({
								message: 'Maksimal data yang dapat dipilih hanya 1',
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					}
				}
			},

            validateCheckedRow (row) {
                let isCheckable = false
				this.checkedRows.forEach((item) => {
					if (item.id == row.id) {
						isCheckable = true
					}
				})
				
				if (this.checkedRows.length <=0) {
					isCheckable = true
                }
                
                return isCheckable
            },

			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
			},

			onPageChange(page) {
                this.page = page
                this.loadAsyncData()
			},

			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id_pk')) {
                    pushFilter.id_pk = ""
                } else {
					pushFilter.id_pk = this.filter.id_pk
				}
				if (!this.filter.hasOwnProperty('nomor_pk')) {
                    pushFilter.nomor_pk = ""
                } else {
					pushFilter.nomor_pk = this.filter.nomor_pk
				}
				if (!this.filter.hasOwnProperty('jenis_pk')) {
                    pushFilter.jenis_pk = ""
                } else {
					pushFilter.jenis_pk = this.filter.jenis_pk
				}
                if (!this.filter.hasOwnProperty('unit')) {
                    pushFilter.unit = ""
                } else {
					pushFilter.unit = this.filter.unit
				}
				if (!this.filter.hasOwnProperty('cabang')) {
                    pushFilter.cabang = ""
                } else {
					pushFilter.cabang = this.filter.cabang
				}
                if (!this.filter.hasOwnProperty('departemen')) {
                    pushFilter.departemen = ""
                } else {
					pushFilter.departemen = this.filter.departemen
				}
                if (!this.filter.hasOwnProperty('tanggal_pk_selesai')) {
                    pushFilter.tanggal_pk_selesai = ""
                } else {
					pushFilter.tanggal_pk_selesai = this.filter.tanggal_pk_selesai
				}

				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
				// 	`column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`search=${searchBypass}`,
				// 	`jenis_pk=${this.PKType}`
				// ].join('&')
				// console.log(params)
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					jenis_pk: this.PKType,
					status_pk: this.filterStatus,
					filter: JSON.stringify(pushFilter),
					code: 9
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
							// if (item.tanggal_pk_selesai) {
							// 	item.tanggal_pk_selesai = item.tanggal_pk_selesai.substring(0, item.tanggal_pk_selesai.indexOf(" "))
							// }
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(this.data)
                    })
                    .catch( e => {
                        this.data = []
						this.total = 0
                        console.log(e)
						
						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),

			routePage(routingFrom, routingTo, PK) {
				if (routingTo == 'detail-rekrutmen' && this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih PK yang ingin dilihat terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (routingTo == 'detail-rekrutmen' && this.checkedRows.length > 1) {
					this.$buefy.snackbar.open({
						message: 'Hanya bisa memilih 1 PK untuk masuk detail rekrutmen',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					this.isRouting = true
					if (!PK){
						PK = this.checkedRows[0].id
					}
					this.$router.push({name: routingTo, params: { routing: routingFrom, PK: PK }})
				}
			},
		}
    }
</script>
