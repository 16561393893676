<template>
	<section>
		<div class="container hero">
			<div class="mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					Persetujuan Garansi
				</div>
				<div class="columns is-desktop">
					<div class="column is-5">
						<b-field 
							class="hz-label-master is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium">Jenis PK</span>
							</template>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-select
									@input="loadAsyncData"
									placeholder=""
									expanded
									class="box-field"
									v-model="PKType"
									required>
									<option selected>All</option>
									<option>User</option>
									<option>Wilayah Baru (Cabang / DCI)</option>
									<option>Referensi</option>
								</b-select>
								<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
							</ValidationProvider>
						</b-field>
					</div>
				</div>
				<div class="is-size-7">
					<div class="columns">
                        <div class="column is-4 is-offset-8">
                            <b-field class="">
                                <b-input
									placeholder="Search..."
									rounded
									icon-pack="fas"
									icon-right="search"
									@input="loadAsyncData"
									v-model="search">
								</b-input>
                            </b-field>
                        </div>
                    </div>
                    <b-table
                        class="is-size-7"
						paginated
						backend-pagination
						:total="total"
						:per-page="perPage"
						@page-change="onPageChange"
						sticky-header
						bordered
						icon-pack="fas"
						sort-icon="angle-up"
						sort-icon-size="is-small"
						backend-sorting
						@sort="onSort"
						:default-sort-direction="defaultSortDirection"
						:data="data"
						:checked-rows.sync="checkedRows"
						:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
						checkable
						hoverable
						@select="addCheckedRows"
						:header-checkable="isheaderCheckable"
						:checkbox-position="checkboxPosition"
						:mobile-cards="false"
						:loading="isLoading"
						sticky-checkbox
					>
						<b-table-column header-class="has-background-light" field="id_pk" label="NOMOR PK" centered v-slot="props">
							<a class="is-underlined" @click="routePage('persetujuan-garansi', props.row.id_pk)">{{props.row.nomor_pk}}</a>
						</b-table-column>
						<b-table-column header-class="has-background-light" field="jenis_pk" label="JENIS PK" centered v-slot="props">
							{{props.row.jenis_pk}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="unit" label="UNIT/PT" centered v-slot="props">
							{{props.row.unit}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="departemen" label="BAGIAN/SUB BAGIAN" centered v-slot="props">
							{{props.row.dept_khusus}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nik" label="NIK" centered v-slot="props">
							{{props.row.nik}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="nama" label="NAMA KARYAWAN" centered v-slot="props">
							{{props.row.nama}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="tanggal_berhenti" label="TANGGAL TIDAK AKTIF" centered v-slot="props">
							{{props.row.tanggal_berhenti}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="alasan_berhenti" label="ALASAN GARANSI" centered v-slot="props">
							{{props.row.alasan_berhenti}}
						</b-table-column>
						<b-table-column header-class="has-background-light" field="no_dht" label="NO DHT" centered v-slot="props">
							{{props.row.no_dht}}
						</b-table-column>
						<template #bottom-left>
							<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 10)}} - {{total - (varPageNumbering * 10) > 0 ? varPageNumbering * 10 : total}} of {{total}} data</b>
						</template>
					</b-table>
					<div class="columns">
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-success has-text-white has-text-weight-bold"
									@click="openModal('approve')"
								>SETUJU</b-button>
							</b-field>
						</div>
						<div class="column is-one-fifth">
							<b-field class="">
								<b-button
									expanded
									class="has-background-danger-light has-text-white has-text-weight-bold"
									@click="openModal('reject')"
								>TOLAK</b-button>
							</b-field>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal has-modal-card v-model="isApproveModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Persetujuan Garansi</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isApproveModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menyetujui pengajuan garansi untuk PK di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.nomor_pk}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="updateGaransi(2)"
									>YA</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isApproveModal = false"
									>TIDAK</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
		<b-modal has-modal-card v-model="isRejectModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Penolakan Garansi</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isRejectModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menolak pengajuan garansi untuk PK di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.nomor_pk}}
								</li>
							</ul>
						</div>
						<form ref="reject">
							<div class="column is-half is-offset-one-fifth mt-4">
								<b-field class="hz-label-area" label="">
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Input Alasan Penolakan<span class="mandatory">*</span></span>
									</template>
									<b-input
										:loading="isSubmitLoading"
										class="modal_area"
										placeholder="Maks 100 karakter"
										maxlength="100"
										type="textarea"
										icon-pack="fas"
										icon-right="pencil-alt"
										v-model="reason"
										@keypress.native="onlyAlphaNum"
										pattern="^[A-Za-z0-9\s]*"
										validation-message="Hanya bisa alfabet, angka dan spasi"
										required>
									</b-input>
								</b-field>
							</div>
							<div class="columns mt-4">
								<div class="column is-3 is-offset-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-success has-text-white has-text-weight-semibold"
											@click="updateGaransi(1)"
										>YA</b-button>
									</b-field>
								</div>
								<div class="column is-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-danger-light has-text-white has-text-weight-semibold"
											@click="isRejectModal = false"
										>TIDAK</b-button>
									</b-field>
								</div>
							</div>
						</form>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'
	import { mapGetters, mapActions } from "vuex";

    export default {
        data() {
            // const data = [
            //     { 
			// 		'id': 1,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'nik': '2015276116',
			// 		'name': 'Arnan',
			// 		'date_resign': '20-01-2022',
			// 		'reason': 'Mengurus keluarga',
			// 		'dht_number': 'DHT-2020123123', },
            //     { 
			// 		'id': 2,
			// 		'no_pk': '2222-HO-IGR-01-22-HR',
			// 		'unit': 'PT Inti Cakrawala Citra',
			// 		'bagian': 'HR Recruitment & Placement',
			// 		'nik': '2000123123',
			// 		'name': 'Dwika',
			// 		'date_resign': '02-11-2021',
			// 		'reason': 'Diterima perusahaan lain',
			// 		'dht_number': 'DHT-2021098098', },
            //     { 
			// 		'id': 3,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 7',
			// 		'nik': '1000123123',
			// 		'name': 'Diasmara',
			// 		'date_resign': '25-09-2022',
			// 		'reason': 'Pergi ke luar negeri',
			// 		'dht_number': 'DHT-101010101', },
            //     { 
			// 		'id': 4,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'nik': 'nik',
			// 		'name': 'nama',
			// 		'date_resign': 'dd-mm-yyyy',
			// 		'reason': 'alasan',
			// 		'dht_number': 'nomor dht', },
            //     { 
			// 		'id': 5,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'nik': 'nik',
			// 		'name': 'nama',
			// 		'date_resign': 'dd-mm-yyyy',
			// 		'reason': 'alasan',
			// 		'dht_number': 'nomor dht', },
            //     { 
			// 		'id': 6,
			// 		'no_pk': '1000-HO-IDM-01-22-IT',
			// 		'unit': 'PT Indomarco Prismatama',
			// 		'bagian': 'IT Software Development 2',
			// 		'nik': '202020123',
			// 		'name': 'Dwi',
			// 		'date_resign': '20-12-2021',
			// 		'reason': 'Mengurus Keluarga',
			// 		'dht_number': 'DHT-2020123123', },
            // ]

            return {
				data: [],
				PKType: 'All',
                checkboxPosition: 'left',
                defaultSortDirection: 'desc',
				checkedRows: [],
				sortOrder: 'desc',
				sortField: 'id',
				search: '',
				isApproveModal: false,
				isRejectModal: false,
				textModal: '',
				reason: null,
				total: 0,
				perPage: 10,
				page: 1,
				varPageNumbering: 1,
				isLoading: true,
				isSubmitLoading: true,
				isheaderCheckable: true,
				isRouting: false,
            }
        },
		computed: {
			...mapGetters({
				storedPersetujuanGaransi: "storedPersetujuanGaransi",
			}),
		},

	async mounted () {
		await this.loadAsyncData()
		if (this.storedPersetujuanGaransi) {
			this.checkedRows = this.storedPersetujuanGaransi
		}
        this.isLoadingGaransi = false
        this.isSubmitLoading = false
    },

		methods: {
			...mapActions(["setStoredPersetujuanGaransi"]),

			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						this.checkedRows.push(row)
						// if (this.checkedRows.length <=4) {
						// 	this.checkedRows.push(row)
						// } else {
						// 	this.$buefy.snackbar.open({
						// 		message: 'Maksimal data yang dapat dipilih hanya 5',
						// 		type: 'is-info',
						// 		position: 'is-top',
						// 		actionText: 'Tutup',
						// 		indefinite: false,
						// 		duration: 7000
						// 	})
						// }
					}
				}
			},

			// validateCheckedRow (row) {
			// 	let isCheckable = false
			// 	this.checkedRows.forEach((item) => {
			// 		if (item.id == row.id) {
			// 			isCheckable = true
			// 		}
			// 	})
				
			// 	if (this.checkedRows.length <=4) {
			// 		isCheckable = true
			// 	}

			// 	return isCheckable
			// },

			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},

			async updateGaransi (status) {
				if (status == 1) {
					if (!this.$refs.reject.checkValidity()) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return
					}
				}

				this.isSubmitLoading = true

				let sendData = []
				this.checkedRows.forEach( element => {
					element.status = status
					if (status == 1) {
						element.keterangan_reject = this.reason
					}

					let tempData = {
						id: element.id,
						status: element.status,
						keterangan_reject: status == 1 ? element.keterangan_reject : null
					}
					sendData.push(tempData)
				})

				let postData = {
					row: sendData,
					code: 5
				}
				//console.log(JSON.stringify(postData))
				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				let index = 0
				let isError = false

				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `warrants`, postData)
					.then( response => {
						if (status == 2) {
							titleMessage = 'Pengajuan garansi berhasil disetujui'
						} else {
							titleMessage = 'Pengajuan garansi berhasil ditolak'
						}
						iconType = 'success',
						response.data.result.forEach(() => {
							textMessage += `${this.checkedRows[index].nomor_pk}<br/>`
							index++
						})
						iconColor = 'is-success'
						this.isSubmitLoading = false
					})
					.catch( e => {
						if (e.response.status != 401) {
							if (status == 2) {
								titleMessage = 'Gagal menyetujui pengajuan garansi!'
							} else {
								titleMessage = 'Gagal menolak pengajuan garansi!'
							}
							iconType = 'error'
							if (e.response.data.status && e.response.data.status != 0) {
								textMessage = e.response.data.message
							} else {
								textMessage = 'Terjadi kesalahan pada server'
							}
							iconColor = 'is-danger'
						}
						
						console.log(e)
						this.isSubmitLoading = false
						isError = true
					})
					.finally(() => {
						if (titleMessage) {
							this.$swal({
								html: true,
                                icon: iconType,
                                title: titleMessage,
                                html: textMessage,
                                customClass: {
                                    icon: iconColor,
                                    title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                    htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                    confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                }
                            }).then((isConfirmed) => {
								if (isConfirmed && !isError)
								window.location.reload()
							})
						}
					})
			},

			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
			},
			
			onPageChange(page) {
                this.page = page
                this.loadAsyncData()
			},

			loadAsyncData: debounce(function () {
				if (!this.search) {
					this.search = ''
				}
				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
				// const params = [
				// 	`column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`search=${searchBypass}`,
				// 	`jenis_pk=${this.PKType}`
				// ].join('&')
				// console.log(params)
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					id_pk: this.id,
					code: 1
				}

				this.$axios.post(process.env.VUE_APP_GATEWAY_API + `warrants`, postData)
					.then( response  => {
						this.data = []
						let currentTotal = response.data.result.total_results
						this.total = currentTotal
						response.data.result.data.forEach((item) => {
							// if (item.tanggal_berhenti) {
							//     item.tanggal_berhenti = item.tanggal_berhenti.substring(0, item.tanggal_berhenti.indexOf(" "))
							// }
							this.data.push(item)
						})
						this.varPageNumbering = this.page
						//console.log(this.data)
					})
					.catch( e => {
						this.data = []
						this.total = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),

			openModal(modalType) {
				if (this.checkedRows.length == 0) {
					this.$buefy.snackbar.open({
						message: 'Pilih garansi PK yang ingin disetujui / ditolak',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					if (modalType == 'approve') {
						this.isApproveModal = true
					} else if (modalType == 'reject') {
						this.isRejectModal = true
					}
				}
			},

			routePage(routing, PK) {
				this.isRouting = true
				if (this.checkedRows.length > 0) {
					this.setStoredPersetujuanGaransi(this.checkedRows)
				}
				this.$router.push({name: 'detail-pk', params: { routing: routing, PK: PK }})
			}
		}
    }
</script>
