<template>
	<div class="container hero">
		<div class="mx-5">
            <div class="detail-sticky-top-desktop detail-sticky-top-mobile has-background-white">
                <div class="has-text-weight-semibold has-text-primary is-size-3">
                    {{ title }}
                </div>
                <div class="columns">
                    <div class="column is-2">
                        <b-button
                            icon-pack="fas"
                            icon-left="angle-left"
                            class="has-background-warning has-text-white has-text-weight-semibold"
                            @click="goBack()"
                        >KEMBALI
                        </b-button>
                    </div>
                    <div class="column is-2">
                        <b-button
                            icon-pack="fas"
                            icon-left="sync"
                            class="has-background-success has-text-white has-text-weight-semibold"
                            @click="refreshData()"
                        >MUAT ULANG
                        </b-button>
                    </div>
                </div>
                <div class="has-text-weight-semibold has-text-primary is-size-4 ml-5">
                    Rincian Master {{ id }}
                </div>
                <hr class="line-break">
            </div>
            <div class="ml-5 detail-content-desktop detail-content-mobile">
				<div class="columns is-desktop has-text-weight-medium mt-1">
					<div class="column is-12">
                        <b-field 
							class="hz-label-detail-master mt-2 mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jenis PK</span>
							</template>
							<p class="has-text-info-light">{{ pkType }}</p>
						</b-field>
                        <b-field 
							class="hz-label-detail-master mt-2 mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Unit</span>
							</template>
							<p class="has-text-info-light">{{ unit }}</p>
						</b-field>
                        <b-field 
							class="hz-label-detail-master mt-2 mb-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Cabang</span>
							</template>
							<p class="has-text-info-light">{{ branch }}</p>
						</b-field>
						<b-field 
							class="hz-label-detail-master my-5 is-flex is-align-items-center"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Bagian/Sub Bagian</span>
							</template>
							<p class="has-text-info-light">{{ department }}</p>
						</b-field>
						<b-field 
							class="hz-label-detail-master my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Jabatan Umum</span>
							</template>
							<p class="has-text-info-light">{{ generalPosition }}</p>
						</b-field>
                        <b-field 
							class="hz-label-detail-master my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Alasan Permintaan</span>
							</template>
							<p class="has-text-info-light">{{ reason ? reason : '-' }}</p>
						</b-field>
                        <b-field 
							class="hz-label-detail-master my-5 is-flex is-align-items-center" 
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-medium has-text-primary">Approval</span>
							</template>
						</b-field>
                        <div class="column is-9 is-offset-1 mt-3">
                            <b-field class="hz-label-detail-master is-flex is-align-items-center" label="" horizontal>
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Dibuat</span>
                                </template>
                                <p class="has-text-info-light">{{ creator }}</p>
                            </b-field>
                            <b-field
                                v-for="(structural, index) in arrayStructural"
                                :key="structural"
                                class="hz-label-detail-master is-flex is-align-items-center"
                                label=""
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Struktural {{ index++ }}</span>
                                </template>
                                <p class="has-text-info-light">{{ structural }}</p>
                            </b-field>
                            <b-field
                                v-for="(functional, index) in arrayFunctional"
                                :key="functional"
                                class="hz-label-detail-master is-flex is-align-items-center"
                                label=""
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui Fungsional {{ index++ }}</span>
                                </template>
                                <p class="has-text-info-light">{{ functional }}</p>
                            </b-field>
                            <b-field
                                v-for="(approved, index) in arrayApproved"
                                :key="approved"
                                class="hz-label-detail-master is-flex is-align-items-center"
                                label=""
                                horizontal
                            >
                                <template #label>
                                    <span class="is-family-primary has-text-weight-medium has-text-primary">Disetujui {{ index++ }}</span>
                                </template>
                                <p class="has-text-info-light">{{ approved }}</p>
                            </b-field>
                        </div>
                    </div>
                </div>
            </div>
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            id: null,
            cancelReason: null,
            masterPositionsId: null,
            pkType: null,
            idBackupPK: null,
            unit: null,
            branch: null,
            department: null,
            generalPosition: null,
            reason: null,
            creator: '',
            arrayStructural: [],
            arrayFunctional: [],
            arrayApproved: [],
            master: null,
            isLoading: true,
        }
    },
    created() {
        if(this.$route.params.routing == 'persetujuan-master-pejabat-approval') {
            this.title = 'Persetujuan Master Pejabat Approval'
        }
        this.id = this.$route.params.master
    },

    async mounted () {
        await this.getDetailMaster()
        //await this.getBudgetPK()
        this.isLoading = false
    },

    methods: {
        async refreshData () {
            this.isLoading = true
            await this.getDetailMaster()
            //await this.getBudgetPK()
            this.isLoading = false
        },

        async getDetailMaster () {
            let postData = {
                id: this.id,
                code: 13
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/approvers', postData)
                .then( response => {
                    this.arrayStructural = []
                    this.arrayFunctional = []
                    this.arrayApproved = []
                    this.master = response.data.result
                    this.pkType = this.master.jenis_pk
                    this.unit = this.master.unit
                    this.branch = this.master.cabang
                    this.department = this.master.dept_khusus
                    this.generalPosition = this.master.jab_umum
                    this.reason = this.master.alasan_permintaan
                    this.creator = this.master.nik_dibuat ? this.master.nik_dibuat + " - " + this.master.nama_dibuat + " - " + this.master.jabatan_dibuat : ''
                    let structural1 = this.master.nik_diketahui_struktural_1 ? this.master.nik_diketahui_struktural_1 + " - " + this.master.nama_diketahui_struktural_1 + " - " + this.master.jabatan_diketahui_struktural_1 : ''
                    structural1 ? this.arrayStructural.push(structural1) : 0
                    let structural2 = this.master.nik_diketahui_struktural_2 ? this.master.nik_diketahui_struktural_2 + " - " + this.master.nama_diketahui_struktural_2 + " - " + this.master.jabatan_diketahui_struktural_2 : ''
                    structural2 ? this.arrayStructural.push(structural2) : 0
                    let structural3 = this.master.nik_diketahui_struktural_3 ? this.master.nik_diketahui_struktural_3 + " - " + this.master.nama_diketahui_struktural_3 + " - " + this.master.jabatan_diketahui_struktural_3 : ''
                    structural3 ? this.arrayStructural.push(structural3) : 0
                    let structural4 = this.master.nik_diketahui_struktural_4 ? this.master.nik_diketahui_struktural_4 + " - " + this.master.nama_diketahui_struktural_4 + " - " + this.master.jabatan_diketahui_struktural_4 : ''
                    structural4 ? this.arrayStructural.push(structural4) : 0
                    let structural5 = this.master.nik_diketahui_struktural_5 ? this.master.nik_diketahui_struktural_5 + " - " + this.master.nama_diketahui_struktural_5 + " - " + this.master.jabatan_diketahui_struktural_5 : ''
                    structural5 ? this.arrayStructural.push(structural5) : 0
                    let structural6 = this.master.nik_diketahui_struktural_6 ? this.master.nik_diketahui_struktural_6 + " - " + this.master.nama_diketahui_struktural_6 + " - " + this.master.jabatan_diketahui_struktural_6 : ''
                    structural6 ? this.arrayStructural.push(structural6) : 0
                    let functional1 = this.master.nik_diketahui_fungsional_1 ? this.master.nik_diketahui_fungsional_1 + " - " + this.master.nama_diketahui_fungsional_1 + " - " + this.master.jabatan_diketahui_fungsional_1 : ''
                    functional1 ? this.arrayFunctional.push(functional1) : 0
                    let functional2 = this.master.nik_diketahui_fungsional_2 ? this.master.nik_diketahui_fungsional_2 + " - " + this.master.nama_diketahui_fungsional_2 + " - " + this.master.jabatan_diketahui_fungsional_2 : ''
                    functional2 ? this.arrayFunctional.push(functional2) : 0
                    let functional3 = this.master.nik_diketahui_fungsional_3 ? this.master.nik_diketahui_fungsional_3 + " - " + this.master.nama_diketahui_fungsional_3 + " - " + this.master.jabatan_diketahui_fungsional_3 : ''
                    functional3 ? this.arrayFunctional.push(functional3) : 0
                    let functional4 = this.master.nik_diketahui_fungsional_4 ? this.master.nik_diketahui_fungsional_4 + " - " + this.master.nama_diketahui_fungsional_4 + " - " + this.master.jabatan_diketahui_fungsional_4 : ''
                    functional4 ? this.arrayFunctional.push(functional4) : 0
                    let approved1 = this.master.nik_disetujui_1 ? this.master.nik_disetujui_1 + " - " + this.master.nama_disetujui_1 + " - " + this.master.jabatan_disetujui_1 : ''
                    approved1 ? this.arrayApproved.push(approved1) : 0
                    let approved2 = this.master.nik_disetujui_2 ? this.master.nik_disetujui_2 + " - " + this.master.nama_disetujui_2 + " - " + this.master.jabatan_disetujui_2 : ''
                    approved2 ? this.arrayApproved.push(approved2) : 0
                    let approved3 = this.master.nik_disetujui_3 ? this.master.nik_disetujui_3 + " - " + this.master.nama_disetujui_3 + " - " + this.master.jabatan_disetujui_3 : ''
                    approved3 ? this.arrayApproved.push(approved3) : 0
                    let approved4 = this.master.nik_disetujui_4 ? this.master.nik_disetujui_4 + " - " + this.master.nama_disetujui_4 + " - " + this.master.jabatan_disetujui_4 : ''
                    approved4 ? this.arrayApproved.push(approved4) : 0
                    let approved5 = this.master.nik_disetujui_5 ? this.master.nik_disetujui_5 + " - " + this.master.nama_disetujui_5 + " - " + this.master.jabatan_disetujui_5 : ''
                    approved5 ? this.arrayApproved.push(approved5) : 0
                })
                .catch( e => {
                    console.log(e)

                    if (e.response.status != 401) {
                        let message = null
                        if (e.response.data.status && e.response.data.status != 0) {
                            message = e.response.data.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }

                        this.$buefy.snackbar.open({
                            message: message,
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                            duration: 7000
                        })
                    }
                })
        },

        goBack() {
            this.$router.push({name: this.$route.params.routing})
        }
    }
}
</script>
