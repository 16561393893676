import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userLogin: null,
		access: null,
		storedPKAktif: null,
		storedPKDitunda: null,
		storedPersetujuanPK: null,
		storedPKBelumDiprosesAtasan: null,
		storedPKBelumGiliranSaya: null,
		storedGantiPejabat: null,
		storedUbahPK: null,
		storedPersetujuanGaransi: null,
		storedPersetujuanMasterPejabatApproval: null,
		//token: null
	},
	mutations: {
		SET_USERLOGIN(state, userLogin) {
			state.userLogin = userLogin
		},

		SET_ACCESS(state, access) {
			state.access = access
		},

		SET_STOREDPKAKTIF(state, storedPKAktif) {
			state.storedPKAktif = storedPKAktif
		},

		SET_STOREDPKDITUNDA(state, storedPKDitunda) {
			state.storedPKDitunda = storedPKDitunda
		},

		SET_STOREDPERSETUJUANPK(state, storedPersetujuanPK) {
			state.storedPersetujuanPK = storedPersetujuanPK
		},

		SET_STOREDPKBELUMDIPROSESATASAN(state, storedPKBelumDiprosesAtasan) {
			state.storedPKBelumDiprosesAtasan = storedPKBelumDiprosesAtasan
		},

		SET_STOREDPKBELUMGILIRANSAYA(state, storedPKBelumGiliranSaya) {
			state.storedPKBelumGiliranSaya = storedPKBelumGiliranSaya
		},

		SET_STOREDGANTIPEJABAT(state, storedGantiPejabat) {
			state.storedGantiPejabat = storedGantiPejabat
		},

		SET_STOREDUBAHPK(state, storedUbahPK) {
			state.storedUbahPK = storedUbahPK
		},

		SET_STOREDPERSETUJUANGARANSI(state, storedPersetujuanGaransi) {
			state.storedPersetujuanGaransi = storedPersetujuanGaransi
		},

		SET_STOREDPERSETUJUANMASTERPEJABATAPPROVAL(state, storedPersetujuanMasterPejabatApproval) {
			state.storedPersetujuanMasterPejabatApproval = storedPersetujuanMasterPejabatApproval
		},
		// SET_TOKEN(state, token) {
		// 	state.token = token
		// },
	},
	actions: {
		async isAuthenticated (context) {
			return await new Promise ((resolve,reject) => {
				let postData = {
					code: 1
				}
				axios.post(
					process.env.VUE_APP_GATEWAY_API + 'auth', postData, 
					{
						withCredentials: true, //harus ditambahkan jika backend cookies
				  	})
					.then( response => {
						context.commit('SET_USERLOGIN', response.data.result)
						localStorage.user = response.data.result.nik
						//context.commit('SET_TOKEN', response.data.result.token)
						resolve(response.data.result)
					})
					.catch( e => {
						if (e.response.status == 401) {
							Vue.swal({
								title: '<i class="fas fa-exclamation-triangle fa-2x"></i>',
								html: 'Sesi Anda saat ini sudah berakhir<br>Silakan melakukan login ulang',
								customClass: {
									title: 'has-text-danger-light',
									htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
									confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
								}
							})
							context.commit('SET_USERLOGIN', null)
							localStorage.removeItem('user')
							router.push({name: 'login'})
						}
						reject(e)
					})
			})
		},

		async checkRole (context) {
			return await new Promise ((resolve,reject) => {
				let postData = {
					code: 9
				}
				axios.post(
					process.env.VUE_APP_GATEWAY_API + 'master/users', postData, 
					{
						withCredentials: true, //harus ditambahkan jika backend cookies
				  	})
					.then( response => {
						//console.log(response.data.result)
						context.commit('SET_ACCESS', response.data.result)
						//context.commit('SET_TOKEN', response.data.result.token)
						resolve(response.data.result)
					})
					.catch( e => {
						console.log(e)
						if (e.response.status == 401) {
							context.commit('SET_USERLOGIN', null)
							localStorage.removeItem('user')
							router.push({name: 'login'})
						}
						reject(e)
					})
			})
		},

		// setToken (context, payload) {
		// 	context.commit('SET_TOKEN', payload)
		// },

		setUserLogin (context, payload) {
			context.commit('SET_USERLOGIN', payload)
			localStorage.user = payload.nik
		},

		setAccess (context, payload) {
			context.commit('SET_ACCESS', payload)
		},

		setStoredPKAktif (context, payload) {
			context.commit('SET_STOREDPKAKTIF', payload)
		},

		setStoredPKDitunda (context, payload) {
			context.commit('SET_STOREDPKDITUNDA', payload)
		},

		setStoredPersetujuanPK (context, payload) {
			context.commit('SET_STOREDPERSETUJUANPK', payload)
		},

		setStoredPKBelumDiprosesAtasan (context, payload) {
			context.commit('SET_STOREDPKBELUMDIPROSESATASAN', payload)
		},

		setStoredPKBelumGiliranSaya (context, payload) {
			context.commit('SET_STOREDPKBELUMGILIRANSAYA', payload)
		},

		setStoredGantiPejabat (context, payload) {
			context.commit('SET_STOREDGANTIPEJABAT', payload)
		},

		setStoredUbahPK (context, payload) {
			context.commit('SET_STOREDUBAHPK', payload)
		},

		setStoredPersetujuanGaransi (context, payload) {
			context.commit('SET_STOREDPERSETUJUANGARANSI', payload)
		},

		setStoredPersetujuanMasterPejabatApproval (context, payload) {
			context.commit('SET_STOREDPERSETUJUANMASTERPEJABATAPPROVAL', payload)
		},

		async logout (context) {
			const postData = {
				Data: {
					nik: this.getters.isUserLogin.nik
				}
			}
			//console.log(JSON.stringify(postData))
			return await new Promise ((resolve,reject) => {
				axios.post(
					process.env.VUE_APP_GATEWAY_ESS + 'Logout', postData, 
					{
						withCredentials: true, //harus ditambahkan jika backend cookies
					})
					.then( response => {
						context.commit('SET_USERLOGIN', null)
						context.commit('SET_STOREDPKAKTIF', null)
						context.commit('SET_STOREDPKDITUNDA', null)
						context.commit('SET_STOREDPERSETUJUANPK', null)
						context.commit('SET_STOREDPKBELUMDIPROSESATASAN', null)
						context.commit('SET_STOREDPKBELUMGILIRANSAYA', null)
						context.commit('SET_STOREDGANTIPEJABAT', null)
						context.commit('SET_STOREDUBAHPK', null)
						context.commit('SET_STOREDPERSETUJUANGARANSI', null)
						context.commit('SET_STOREDPERSETUJUANMASTERPEJABATAPPROVAL', null)
						localStorage.removeItem('user')
						resolve(response.data.result)
					})
					.catch( e => {
						console.log(e)
						if (e.response.status == 401) {
							context.commit('SET_USERLOGIN', null)
							localStorage.removeItem('user')
							router.push({name: 'login'})
						}
						reject(e)
					})
			})
		},
	},

	getters: {
		//isAuthenticated: state => !!state.token,
		isUserLogin: state => state.userLogin,
		access: state => state.access,
		storedPKAktif: state => state.storedPKAktif,
		storedPKDitunda: state => state.storedPKDitunda,
		storedPersetujuanPK: state => state.storedPersetujuanPK,
		storedPKBelumDiprosesAtasan: state => state.storedPKBelumDiprosesAtasan,
		storedPKBelumGiliranSaya: state => state.storedPKBelumGiliranSaya,
		storedGantiPejabat: state => state.storedGantiPejabat,
		storedUbahPK: state => state.storedUbahPK,
		storedPersetujuanGaransi: state => state.storedPersetujuanGaransi,
		storedPersetujuanMasterPejabatApproval: state => state.storedPersetujuanMasterPejabatApproval,
	},

	modules: {
	}
})
