<template>
	<div class="container hero">
		<div class="mx-5">
			<b-field class="has-text-weight-semibold has-text-primary is-size-3">
				Laporan Permintaan Karyawan
			</b-field>
			<br>
			<div class="ml-5 mt-3 has-text-weight-medium has-text-primary">
				<ValidationObserver ref="observer">
					<div class="columns is-desktop">
						<div class="column is-9">
							<b-field 
								class="hz-label-master is-flex is-align-items-center" 
								label="" 
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Pilih Laporan<span class="mandatory">*</span></span>
								</template>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-select placeholder="" expanded class="box-field" v-model="reportType" required>
										<option value="MAPK">Monitoring Approval Permintaan Karyawan</option>
										<option value="LPPK">Laporan Pemenuhan Permintaan Karyawan</option>
										<option value="LPKB">Laporan Permintaan Karyawan VS Budget Tahunan Karyawan</option>
										<option value="LSLP">Laporan Service Level Permintaan Karyawan</option>
									</b-select>
									<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
								</ValidationProvider>
							</b-field>
							<b-field 
								class="hz-label-master is-flex is-align-items-center"
								label="" 
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Unit/PT</span>
								</template>
								
									<b-autocomplete
										:loading="isInputLoading"
										open-on-focus
										v-model="unit"
										:data="getAsyncUnitData"
										field="unit"
										@typing="setPickUnit(false)"
										@select="option => filterBranches((option ? option.unit : null))"
										placeholder="Ketik dan pilih dari dropdown"
										@keypress.native="departmentPositionValidation"
										pattern="^[A-Za-z0-9\s\-&().+/]*"
										validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
										<template slot="empty">
											Tidak ditemukan hasil untuk
											{{ unit }}
										</template>
									</b-autocomplete>
									
							</b-field>
							<b-field 
								class="hz-label-master is-flex is-align-items-center" 
								label="" 
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Cabang</span>
								</template>
								<b-autocomplete
									:loading="isInputLoading"
									open-on-focus
									v-model="branch"
									:data="getAsyncBranchData"
									field="cabang"
									@typing="setPickBranch(false)"
									@select="option => filterDepartments((option ? option.cabang : null))"
									placeholder="Ketik dan pilih dari dropdown"
									@keypress.native="departmentPositionValidation"
									pattern="^[A-Za-z0-9\s\-&().+/]*"
									validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
									<template slot="empty">
										Tidak ditemukan hasil untuk
										{{ branch }}
									</template>
								</b-autocomplete>
							</b-field>
							<b-field 
								class="hz-label-master is-flex is-align-items-center" 
								label="" 
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Bagian Khusus</span>
								</template>
								<b-autocomplete
									:loading="isInputLoading"
									open-on-focus
									v-model="department"
									:data="getAsyncDepartmentData"
									field="dept_khusus"
									@typing="setPickDepartment(false)"
									@select="option => filterPositions((option ? option.id : null), (option ? option.dept_khusus : null))"
									placeholder="Ketik dan pilih dari dropdown"
									@keypress.native="departmentPositionValidation"
									pattern="^[A-Za-z0-9\s\-&().+/]*"
									validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
									<template slot="empty">
										Tidak ditemukan hasil untuk
										{{ department }}
									</template>
								</b-autocomplete>
							</b-field>
							<b-field 
								class="hz-label-master is-flex is-align-items-center" 
								label="" 
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Jabatan Khusus</span>
								</template>
								<b-autocomplete
									:loading="isInputLoading"
									open-on-focus
									v-model="position"
									:data="getAsyncPositionData"
									@typing="setPickPosition(false)"
									@select="setPickPosition(true)"
									field="jab_khusus"
									placeholder="Ketik dan pilih dari dropdown"
									@keypress.native="departmentPositionValidation"
									pattern="^[A-Za-z0-9\s\-&().+/]*"
									validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /">
									<template slot="empty">
										Tidak ditemukan hasil untuk
										{{ position }}
									</template>
								</b-autocomplete>
							</b-field>
							<b-field 
								class="hz-label-master is-flex is-align-items-center" 
								label="" 
								horizontal
							>
								<template #label>
									<span class="is-family-primary has-text-weight-medium">Status<span class="mandatory">*</span></span>
								</template>
								<b-select placeholder="" expanded class="box-field" v-model="reportStatusPK">
									<option selected>All</option>
									<option>Outstanding</option>
								</b-select>
							</b-field>
							<div class="columns is-desktop is-flex is-align-items-center">
								<div class="column is-4">
									<span class="is-family-primary has-text-weight-medium has-text-left">Periode<span class="mandatory">*</span></span>
								</div>
								<div class="column is-4">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-datepicker
											type="month"
											v-model="tempStartPeriod"
											@input="saveStartPeriod"
											icon-pack="fas"
											icon-right="calendar-alt"
											trap-focus
											:mobile-native="false">
										</b-datepicker>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</div>
									<div class="is-family-primary has-text-weight-medium has-text-left">s/d</div>
								<div class="column is-4">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-datepicker
											type="month"
											v-model="tempEndPeriod"
											@input="saveEndPeriod"
											:min-date="tempStartPeriod"
											icon-pack="fas"
											icon-right="calendar-alt"
											trap-focus
											:disabled="!tempStartPeriod"
											:mobile-native="false">
										</b-datepicker>
										<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
									</ValidationProvider>
								</div>
							</div>
						</div>
					</div>
				</ValidationObserver>
                <br>
                <b-field class="has-text-centered mt-5">
					<b-button
						:loading="isSubmitLoading"
						class="has-background-success has-text-white has-text-weight-bold"
						@click="downloadReport"
					>Generate Report</b-button>
				</b-field>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
			isInputLoading: true,
			reportType: null,
			reportStatusPK: 'All',
			tempStartPeriod: null,
			startPeriod: null,
			tempEndPeriod: null,
			endPeriod: null,
			unit: '',
			branch: '',
			department: '',
			position: '',
			distinctUnits: [],
			distinctBranches: [],
			filteredBranches: [],
			masterDepartments: null,
			filteredDepartments: [],
			masterPositions: null,
			filteredPositions: [],
			masterDepartmentsId: null,
			masterPositionsId: null,
			isSubmitLoading: true,
			pickUnit: false,
			pickBranch: false,
			pickDepartment: false,
			pickPosition: false,
        };
	},

	async mounted () {
		await this.getDistinctUnits()
		await this.getDistinctBranches()
		await this.getAllDepartments()
		await this.getAllPositions()
		this.isInputLoading = false
		this.isSubmitLoading = false
	},
	
	computed: {
		getAsyncUnitData() {
			if (this.pickUnit == false) {
				return this.distinctUnits.filter((option) => {
					return option.unit
						.toString()
						.toLowerCase()
						.indexOf(this.unit.toLowerCase()) >= 0
				})
			} else {
				return this.distinctUnits
			}
		},

		getAsyncBranchData () {
			if (this.pickBranch == false) {
				return this.filteredBranches.filter((option) => {
					return option.cabang
						.toString()
						.toLowerCase()
						.indexOf(this.branch.toLowerCase()) >= 0
				})
			} else {
				return this.filteredBranches
			}
        },

		getAsyncDepartmentData() {
			if (this.pickDepartment == false) {
				return this.filteredDepartments.filter((option) => {
					return option.dept_khusus
						.toString()
						.toLowerCase()
						.indexOf(this.department.toLowerCase()) >= 0
				})
			} else {
				return this.filteredDepartments
			}
		},

		getAsyncPositionData() {
			if (this.pickPosition) {
				return this.filteredPositions.filter((option) => {
					return option.jab_khusus
						.toString()
						.toLowerCase()
						.indexOf(this.position.toLowerCase()) >= 0
				})
			} else {
				return this.filteredPositions
			}
		},
	},

    methods: {
		setPickPosition (selecting) {
            this.pickPosition = selecting
		},

		setPickDepartment (selecting) {
            this.pickDepartment = selecting
		},

		setPickBranch (selecting) {
            this.pickBranch = selecting
		},
		
		setPickUnit (selecting) {
            this.pickUnit = selecting
		},
		
		async downloadReport () {
			this.$refs.observer.validate().then(async success => {
				if (!success) {
                    this.$buefy.snackbar.open({
                        message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                        type: 'is-info',
                        position: 'is-top',
                        actionText: 'Tutup',
                        indefinite: false,
                    })
                } else {
					this.isSubmitLoading = true

					let postData = {
						tipe: this.reportType,
						unit: this.unit,
						cabang: this.branch,
						departemen: this.department,
						jabatan: this.position,
						status: this.reportStatusPK,
						periodeAwal: this.startPeriod,
						periodeAkhir: this.endPeriod,
						code: 1
					}
					// let unitBypass = this.unit.replace("&", "%26")
					// let departmentBypass = this.department.replace("&", "%26")
					// let positionBypass = this.position.replace("&", "%26")
					// const params = [
					// 	`tipe=${this.reportType}`,
					// 	`unit=${unitBypass}`,
					// 	`cabang=${this.branch}`,
					// 	`departemen=${departmentBypass}`,
					// 	`jabatan=${positionBypass}`,
					// 	`status=${this.reportStatusPK}`,
					// 	`periodeAwal=${this.startPeriod}`,
					// 	`periodeAkhir=${this.endPeriod}`
					// ].join('&')
					// console.log(JSON.stringify(params))

					await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `reports`, postData,
						{responseType: 'arraybuffer'})
						.then( response => {
							//handle kembalian file pdf
							let blob=new Blob([response.data]);
							// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
							// 	window.navigator.msSaveOrOpenBlob(blob);
							// 	return;
							// }
							let link=document.createElement('a');
							link.href=window.URL.createObjectURL(blob);
							link.setAttribute('download', this.reportType+'.xlsx');
							link.click();
							setTimeout(function () {
								// For Firefox it is necessary to delay revoking the ObjectURL
								window.URL.revokeObjectURL(link.href)
									, 100
							})
						})
						.catch( e => {
							let converter = require('arraybuffer-to-string');
							if (e.response.status != 401) {
								let convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
								let tempMessage = JSON.parse(convertResponse);
								let message = 'Terjadi kesalahan pada server'
								if (tempMessage.message == 'Data Report tidak ditemukan') {
									message = 'Report untuk PK yang sesuai dengan inputan dan role anda tidak ditemukan'
								} else if (tempMessage.status != 0) {
									message = tempMessage.message
								} else {
									message = 'Terjadi kesalahan pada server'
								}
								this.$buefy.snackbar.open({
									message: message,
									type: 'is-info',
									position: 'is-top',
									actionText: 'Tutup',
									indefinite: false,
									duration: 7000
								})
							}
						})
						.finally(this.isSubmitLoading = false)
				}
			})
		},
		
		saveEndPeriod() {
			this.endPeriod = moment(this.tempEndPeriod).format('YYYY-MM-DD')
		},

		saveStartPeriod() {
			this.startPeriod = moment(this.tempStartPeriod).format('YYYY-MM-DD')
			this.endPeriod = null
			this.tempEndPeriod = null
		},

		async getDistinctUnits () {
			let postData = {
                code: 7
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
					response.data.result.forEach(element => {
						this.distinctUnits.push(element)
					})
                    //console.log(this.distinctUnits)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},
		
		async getDistinctBranches () {
			let postData = {
                code: 8
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
					response.data.result.forEach(element => {
						this.distinctBranches.push(element)
					})
                    //console.log(this.distinctBranches)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},
		
		async getAllDepartments () {
			let postData = {
                code: 6
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterDepartments.push(element)
					// });
					this.masterDepartments = response.data.result
                    //console.log(this.masterDepartments)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		filterBranches (unit) {
            this.filteredBranches = this.distinctBranches.filter((option) => {
                return option.unit == unit
			})
			if (this.unit != unit) {
				this.branch = ''
				this.department = ''
				this.position = ''
			}
			this.setPickUnit(true)
		},

		filterDepartments (branch) {
            this.filteredDepartments = this.masterDepartments.filter((option) => {
                return option.cabang == branch && option.unit == this.unit
			})
			if (this.branch != branch) {
				this.department = ''
				this.position = ''
			}
			this.setPickBranch(true)
		},

		filterPositions (departmentId, department) {
            let tempFilteredPositions = this.masterPositions.filter((option) => {
                return option.id_master_departemen == departmentId
			})
			this.masterDepartmentsId = departmentId
			if (this.department != department) {
				this.position = ''
			}

			let uniquePositions = new Set()

			this.filteredPositions = [...new Set(
					tempFilteredPositions.filter(item => {
						if (!uniquePositions.has(item.jab_khusus)) {
							uniquePositions.add(item.jab_khusus);
							return true;
						}
						return false;
					})
				)]

			this.setPickDepartment(true)
		},

		departmentPositionValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
		},

		async getAllPositions () {
			let postData = {
				code: 6
			}
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/positions', postData)
                .then( response => {
                    // response.data.result.forEach(element => {
                    //     this.masterPositions.push(element)
					// });
					this.masterPositions = response.data.result
                    //console.log(this.masterDepartments)
                })
                .catch( e => {
					console.log(e)
					
					if (e.response.status != 401) {
						let message = null
						if (e.response.data.status && e.response.data.status != 0) {
							message = e.response.data.message
						} else {
							message = 'Terjadi kesalahan pada server'
						}

						this.$buefy.snackbar.open({
							message: message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
							duration: 7000
						})
					}
                })
		},

		// setMasterPositionsId (id) {
		// 	this.masterPositionsId = id
		// 	this.setPickPosition(true)
		// }
    }
};
</script>
