<template>
	<section>
		<div class="container hero">
			<div class="mb-1 mx-5">
				<div class="has-text-weight-semibold has-text-primary is-size-3">
					PK Belum Diproses Atasan
				</div>
				<div class="columns is-desktop">
					<div class="column is-5" style="padding: 2px">
						<b-field 
							class="hz-label-master is-flex is-align-items-center" 
							custom-class="is-small"
							label="" 
							horizontal
						>
							<template #label>
								<span class="is-family-primary has-text-weight-semibold">Jenis PK</span>
							</template>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-select
									size="is-small"
									@input="loadAsyncData(false)"
									placeholder=""
									expanded
									v-model="PKType"
									required>
									<option selected>All</option>
									<option>User</option>
									<option>Wilayah Baru (Cabang / DCI)</option>
									<option>Referensi</option>
								</b-select>
								<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
							</ValidationProvider>
						</b-field>
					</div>
				</div>
				<div class="columns">
					<div class="column is-4 is-offset-8 is-size-7">
						<b-field class="">
							<b-input
								size="is-small"
								placeholder="Search..."
								rounded
								icon-pack="fas"
								icon-right="search"
								@input="loadAsyncData(false)"
								v-model="search">
							</b-input>
						</b-field>
					</div>
				</div>
			</div>
			<!-- <b-table
				paginated
				backend-pagination
				:total="total"
				:per-page="perPage"
				@page-change="onPageChange"
				sticky-header
				bordered
				icon-pack="fas"
				sort-icon="angle-up"
				sort-icon-size="is-small"
				backend-sorting
				@sort="onSort"
				:default-sort-direction="defaultSortDirection"
				:data="data"
				:checked-rows.sync="checkedRows"
				:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
				:checkable="filterApproval == '3' ? false : true"
				:header-checkable="isheaderCheckable"
				:is-row-checkable="validateCheckedRow"
				:checkbox-position="checkboxPosition"
				:mobile-cards="false"
				:loading="isLoading"
				sticky-checkbox
				style="font-size: 10.25px"
				:height="total > 2 ? '500px' : ''"
				:key="total"
			> -->
			<b-table
				class="specific-table sticky-column"
				paginated
				backend-pagination
				:total="total"
				:per-page="perPage"
				@page-change="onPageChange"
				backend-filtering
				@filters-change="onFilter"
				sticky-header
				bordered
				icon-pack="fas"
				sort-icon="angle-up"
				sort-icon-size="is-small"
				backend-sorting
				@sort="onSort"
				:default-sort-direction="defaultSortDirection"
				:data="data"
				:checked-rows.sync="checkedRows"
				:custom-is-checked="(checkedRows, data) => checkedRows.id == data.id"
				checkable
				hoverable
				@select="addCheckedRows"
				:header-checkable="isheaderCheckable"
				:is-row-checkable="validateCheckedRow"
				:checkbox-position="checkboxPosition"
				:mobile-cards="false"
				:loading="isLoading"
                :row-class="(row, index) => ('has-background-white')"
				sticky-checkbox
				style="font-size: 8.4px"
				height="360px"
			>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-1" cell-class="sticky-pk-approval-1" width="11px" field="id_pk" label="NOMOR PK" centered v-slot="props">
					<a class="is-underlined" @click="routePage('pk-belum-diproses-atasan', props.row.id)">{{props.row.id_pk}}</a>
				</b-table-column>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-2" cell-class="sticky-pk-approval-2" width="12px" field="unit_code" label="UNIT / PT" centered v-slot="props">
					{{props.row.unit_code}}
				</b-table-column>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-3" cell-class="sticky-pk-approval-3" width="13px" field="departemen" label="BAGIAN / SUB PK" centered v-slot="props">
					{{props.row.departemen}}
				</b-table-column>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-4" cell-class="sticky-pk-approval-4" width="14px" field="jab_teknis" label="JABATAN TEKNIS" centered v-slot="props">
					{{props.row.jab_teknis}}
				</b-table-column>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-5" cell-class="sticky-pk-approval-5" width="15px" field="job_class_from" label="JOB CLASS" centered v-slot="props">
					{{props.row.job_class_from}} - {{ props.row.job_class_to }}
				</b-table-column>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-6" cell-class="sticky-pk-approval-6" width="16px" field="lokasi_kerja" label="LOKASI KERJA" centered v-slot="props">
					{{props.row.lokasi_kerja}}
				</b-table-column>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-7" cell-class="sticky-pk-approval-7" width="17px" field="budget" label="BUDGET" centered v-slot="props">
					{{props.row.budget}}
				</b-table-column>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-8" cell-class="sticky-pk-approval-8" width="18px" field="jumlah_kebutuhan" label="JUMLAH" centered v-slot="props">
					{{props.row.jumlah_kebutuhan}}
				</b-table-column>
				<b-table-column searchable sortable header-class="has-background-light sticky-pk-approval-9" cell-class="sticky-pk-approval-9" width="19px" field="alasan_permintaan" label="ALASAN PERMINTAAN" centered v-slot="props">
					{{props.row.alasan_permintaan}}
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_dibuat" label="DIBUAT" centered v-slot="props">
					<b-tooltip
						v-if="props.row.nama_dibuat"
						:label="props.row.status_dibuat == 1 ? props.row.keterangan_reject : props.row.status_dibuat == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_dibuat == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_dibuat}}</div>
					</b-tooltip>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_struktural_1" label="DISETUJUI STRUKTURAL 1" centered v-slot="props">
					<div :class="props.row.status_dibuat == 2 && props.row.status_diketahui_struktural_1 == 0 && props.row.nik_diketahui_struktural_1 && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_struktural_1"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_1' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_1 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_struktural_1 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_1}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_struktural_1 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_struktural_2" label="DISETUJUI STRUKTURAL 2" centered v-slot="props">
					<div :class="((props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_struktural_2 == 0 && props.row.nik_diketahui_struktural_2) || 
						(!props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_struktural_2 == 0 && props.row.nik_diketahui_struktural_2)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_struktural_2"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_2' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_2 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_struktural_2 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_2}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_struktural_2 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_struktural_3" label="DISETUJUI STRUKTURAL 3" centered v-slot="props">
					<div :class="((props.row.status_diketahui_struktural_2 == 2 && props.row.status_diketahui_struktural_3 == 0 && props.row.nik_diketahui_struktural_3) || 
						(!props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_struktural_3 == 0 && props.row.nik_diketahui_struktural_3) || 
						(!props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_struktural_3 == 0 && props.row.nik_diketahui_struktural_3)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_struktural_3"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_3' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_3 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_struktural_3 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_3}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_struktural_3 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_struktural_4" label="DISETUJUI STRUKTURAL 4" centered v-slot="props">
					<div :class="((props.row.status_diketahui_struktural_3 == 2 && props.row.status_diketahui_struktural_4 == 0 && props.row.nik_diketahui_struktural_4) || 
						(!props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_diketahui_struktural_4 == 0 && props.row.nik_diketahui_struktural_4) || 
						(!props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_struktural_4 == 0 && props.row.nik_diketahui_struktural_4) || 
						(!props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_struktural_4 == 0 && props.row.nik_diketahui_struktural_4)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_struktural_4"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_4' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_4 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_struktural_4 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_4}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_struktural_4 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_struktural_5" label="DISETUJUI STRUKTURAL 5" centered v-slot="props">
					<div :class="((props.row.status_diketahui_struktural_4 == 2 && props.row.status_diketahui_struktural_5 == 0 && props.row.nik_diketahui_struktural_5) || 
						(!props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_diketahui_struktural_5 == 0 && props.row.nik_diketahui_struktural_5) || 
						(!props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_diketahui_struktural_5 == 0 && props.row.nik_diketahui_struktural_5) || 
						(!props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_struktural_5 == 0 && props.row.nik_diketahui_struktural_5) || 
						(!props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_struktural_5 == 0 && props.row.nik_diketahui_struktural_5)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_struktural_5"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_5' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_5 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_struktural_5 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_5}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_struktural_5 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_struktural_6" label="DISETUJUI STRUKTURAL 6" centered v-slot="props">
					<div :class="((props.row.status_diketahui_struktural_5 == 2 && props.row.status_diketahui_struktural_6 == 0 && props.row.nik_diketahui_struktural_6) || 
						(!props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_diketahui_struktural_6 == 0 && props.row.nik_diketahui_struktural_6) || 
						(!props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_diketahui_struktural_6 == 0 && props.row.nik_diketahui_struktural_6) || 
						(!props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_diketahui_struktural_6 == 0 && props.row.nik_diketahui_struktural_6) || 
						(!props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_struktural_6 == 0 && props.row.nik_diketahui_struktural_6) || 
						(!props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_struktural_6 == 0 && props.row.nik_diketahui_struktural_6)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_struktural_6"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_struktural_6' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_struktural_6 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_struktural_6 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_struktural_6}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_struktural_6 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_fungsional_1" label="DISETUJUI FUNGSIONAL 1" centered v-slot="props">
					<div :class="((props.row.status_diketahui_struktural_6 == 2 && props.row.status_diketahui_fungsional_1 == 0 && props.row.nik_diketahui_fungsional_1) || 
						(!props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_diketahui_fungsional_1 == 0 && props.row.nik_diketahui_fungsional_1) || 
						(!props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_diketahui_fungsional_1 == 0 && props.row.nik_diketahui_fungsional_1) || 
						(!props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_diketahui_fungsional_1 == 0 && props.row.nik_diketahui_fungsional_1) || 
						(!props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_diketahui_fungsional_1 == 0 && props.row.nik_diketahui_fungsional_1) || 
						(!props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_fungsional_1 == 0 && props.row.nik_diketahui_fungsional_1) || 
						(!props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_fungsional_1 == 0 && props.row.nik_diketahui_fungsional_1)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_fungsional_1"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_fungsional_1' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_fungsional_1 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_fungsional_1 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_fungsional_1}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_fungsional_1 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_fungsional_2" label="DISETUJUI FUNGSIONAL 2" centered v-slot="props">
					<div :class="((props.row.status_diketahui_fungsional_1 == 2 && props.row.status_diketahui_fungsional_2 == 0 && props.row.nik_diketahui_fungsional_2) || 
						(!props.row.nik_diketahui_fungsional_1 && props.row.status_diketahui_struktural_6 == 2 && props.row.status_diketahui_fungsional_2 == 0 && props.row.nik_diketahui_fungsional_2) || 
						(!props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_diketahui_fungsional_2 == 0 && props.row.nik_diketahui_fungsional_2) || 
						(!props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_diketahui_fungsional_2 == 0 && props.row.nik_diketahui_fungsional_2) || 
						(!props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_diketahui_fungsional_2 == 0 && props.row.nik_diketahui_fungsional_2) || 
						(!props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_diketahui_fungsional_2 == 0 && props.row.nik_diketahui_fungsional_2) || 
						(!props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_fungsional_2 == 0 && props.row.nik_diketahui_fungsional_2) || 
						(!props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_fungsional_2 == 0 && props.row.nik_diketahui_fungsional_2)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_fungsional_2"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_fungsional_2' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_fungsional_2 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_fungsional_2 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_fungsional_2}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_fungsional_2 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_fungsional_3" label="DISETUJUI FUNGSIONAL 3" centered v-slot="props">
					<div :class="((props.row.status_diketahui_fungsional_2 == 2 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3) || 
						(!props.row.nik_diketahui_fungsional_2 && props.row.status_diketahui_fungsional_1 == 2 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3) || 
						(!props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && props.row.status_diketahui_struktural_6 == 2 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3) || 
						(!props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3) || 
						(!props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3) || 
						(!props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3) || 
						(!props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3) || 
						(!props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3) || 
						(!props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_fungsional_3 == 0 && props.row.nik_diketahui_fungsional_3)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_fungsional_3"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_fungsional_3' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_fungsional_3 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_fungsional_3 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_fungsional_3}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_fungsional_3 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_diketahui_fungsional_4" label="DISETUJUI FUNGSIONAL 4" centered v-slot="props">
					<div :class="((props.row.status_diketahui_fungsional_3 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && props.row.status_diketahui_fungsional_2 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && props.row.status_diketahui_fungsional_1 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && props.row.status_diketahui_struktural_6 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4) || 
						(!props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_diketahui_fungsional_4 == 0 && props.row.nik_diketahui_fungsional_4)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_diketahui_fungsional_4"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'diketahui_fungsional_4' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_diketahui_fungsional_4 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_diketahui_fungsional_4 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_diketahui_fungsional_4}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_diketahui_fungsional_4 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_disetujui_1" label="DISETUJUI 1" centered v-slot="props">
					<div :class="((props.row.status_diketahui_fungsional_4 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && props.row.status_diketahui_fungsional_3 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && props.row.status_diketahui_fungsional_2 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && props.row.status_diketahui_fungsional_1 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && props.row.status_diketahui_struktural_6 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1) || 
						(!props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_disetujui_1 == 0 && props.row.nik_disetujui_1)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_disetujui_1"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_1' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_1 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_disetujui_1 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_1}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_disetujui_1 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_disetujui_2" label="DISETUJUI 2" centered v-slot="props">
					<div :class="((props.row.status_disetujui_1 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && props.row.status_diketahui_fungsional_4 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && props.row.status_diketahui_fungsional_3 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && props.row.status_diketahui_fungsional_2 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && props.row.status_diketahui_fungsional_1 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && props.row.status_diketahui_struktural_6 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2) || 
						(!props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_disetujui_2 == 0 && props.row.nik_disetujui_2)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_disetujui_2"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_2' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_2 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_disetujui_2 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_2}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_disetujui_2 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_disetujui_3" label="DISETUJUI 3" centered v-slot="props">
					<div :class="((props.row.status_disetujui_2 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && props.row.status_disetujui_1 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && props.row.status_diketahui_fungsional_4 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && props.row.status_diketahui_fungsional_3 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && props.row.status_diketahui_fungsional_2 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && props.row.status_diketahui_fungsional_1 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && props.row.status_diketahui_struktural_6 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3) || 
						(!props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_disetujui_3 == 0 && props.row.nik_disetujui_3)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_disetujui_3"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_3' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_3 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_disetujui_3 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_3}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_disetujui_3 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_disetujui_4" label="DISETUJUI 4" centered v-slot="props">
					<div :class="((props.row.status_disetujui_3 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && props.row.status_disetujui_2 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && props.row.status_disetujui_1 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && props.row.status_diketahui_fungsional_4 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && props.row.status_diketahui_fungsional_3 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && props.row.status_diketahui_fungsional_2 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && props.row.status_diketahui_fungsional_1 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && props.row.status_diketahui_struktural_6 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4) || 
						(!props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_disetujui_4 == 0 && props.row.nik_disetujui_4)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div>
					<b-tooltip
						v-if="props.row.nama_disetujui_4"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_4' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_4 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_disetujui_4 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_4}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_disetujui_4 == null">-</div>
				</b-table-column>
				<b-table-column searchable header-class="has-background-light" field="nama_disetujui_5" label="DISETUJUI 5" centered v-slot="props">
					<!-- <div :class="((props.row.status_disetujui_4 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && props.row.status_disetujui_3 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && props.row.status_disetujui_2 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && props.row.status_disetujui_1 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && props.row.status_diketahui_fungsional_4 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && props.row.status_diketahui_fungsional_3 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && props.row.status_diketahui_fungsional_2 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && props.row.status_diketahui_fungsional_1 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && props.row.status_diketahui_struktural_6 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && props.row.status_diketahui_struktural_5 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && props.row.status_diketahui_struktural_4 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && props.row.status_diketahui_struktural_3 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && props.row.status_diketahui_struktural_2 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && props.row.status_diketahui_struktural_1 == 2 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5) || 
						(!props.row.nik_disetujui_4 && !props.row.nik_disetujui_3 && !props.row.nik_disetujui_2 && !props.row.nik_disetujui_1 && !props.row.nik_diketahui_fungsional_4 && !props.row.nik_diketahui_fungsional_3 && !props.row.nik_diketahui_fungsional_2 && !props.row.nik_diketahui_fungsional_1 && !props.row.nik_diketahui_struktural_6 && !props.row.nik_diketahui_struktural_5 && !props.row.nik_diketahui_struktural_4 && !props.row.nik_diketahui_struktural_3 && !props.row.nik_diketahui_struktural_2 && !props.row.nik_diketahui_struktural_1 && props.row.status_disetujui_5 == 0 && props.row.nik_disetujui_5)) && filterApproval == 2 ? '' : 'is-hidden'">
						<b-tooltip
							:active="props.row.status_pergantian_pejabat == 0 ? true : false"
							:label="'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti"
							size="is-small"
							position="is-left"
							multilined>
							<b-field class="">
								<b-button
									:loading="isSubstitutionButtonLoading"
									expanded
									style="width: 85px; height: 25px"
									class="is-size-7 has-background-success has-text-white"
									@click="openModal('substitution', props.row)"
									:disabled="props.row.status_pergantian_pejabat == 0 ? true : false"
								>Ganti Pejabat</b-button>
							</b-field>
						</b-tooltip>
					</div> -->
					<b-tooltip
						v-if="props.row.nama_disetujui_5"
						:label="props.row.status_pergantian_pejabat == 0 && props.row.tingkat_approval == 'disetujui_5' ? 'Sedang penggantian pejabat menjadi ' + props.row.nama_pejabat_pengganti : props.row.status_disetujui_5 == 2 ? 'Sudah Disetujui' : 'Belum Dilakukan Aksi'"
						size="is-small"
						position="is-left"
						multilined>
						<div :class="props.row.status_disetujui_5 == 2 ? 'has-text-gray' : 'has-text-weight-bold'">{{props.row.nama_disetujui_5}}</div>
					</b-tooltip>
					<div v-if="props.row.nama_disetujui_5 == null">-</div>
				</b-table-column>
				<template #bottom-left>
					<b>Show {{ (total > 0 ? 1 : 0) + ((varPageNumbering - 1) * 5)}} - {{total - (varPageNumbering * 5) > 0 ? varPageNumbering * 5 : total}} of {{total}} data</b>
				</template>
			</b-table>
		</div>
		<b-modal has-modal-card v-model="isApproveModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Persetujuan PK</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isApproveModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Apakah Anda yakin ingin menyetujui permohonan PK di bawah ini?
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.id_pk}}
								</li>
							</ul>
						</div>
						<div class="columns mt-4">
							<div class="column is-3 is-offset-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-success has-text-white has-text-weight-semibold"
										@click="updatePKApproval(2)"
									>SETUJUI PK</b-button>
								</b-field>
							</div>
							<div class="column is-3">
								<b-field class="">
									<b-button
										:loading="isSubmitLoading"
										expanded
										class="has-background-danger-light has-text-white has-text-weight-semibold"
										@click="isApproveModal = false"
									>BATAL</b-button>
								</b-field>
							</div>
						</div>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
		<b-modal has-modal-card v-model="isRejectModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Penolakan PK</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isRejectModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary">
							Data yang akan diproses :
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li
									v-for="row in checkedRows"
									:key="row.id"
									:value="row.id"
									class="is-flex is-align-items-center"
								>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{row.id_pk}}
								</li>
							</ul>
						</div>
						<form ref="reject">
							<div class="column is-half is-offset-one-fifth mt-4">
								<b-field class="hz-label-area" label="">
									<template #label>
										<span class="is-family-primary has-text-weight-medium">Input Alasan Penolakan<span class="mandatory">*</span></span>
									</template>
									<b-input
										:loading="isSubmitLoading"
										class="modal_area"
										placeholder="Maks 100 karakter"
										maxlength="100"
										type="textarea"
										icon-pack="fas"
										icon-right="pencil-alt"
										v-model="reason"
										@keypress.native="onlyAlphaNum"
										pattern="^[A-Za-z0-9\s]*"
										validation-message="Hanya bisa alfabet, angka dan spasi"
										required>
									</b-input>
								</b-field>
							</div>
							<div class="columns mt-4">
								<div class="column is-3 is-offset-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-success has-text-white has-text-weight-semibold"
											@click="updatePKApproval(1)"
										>TOLAK PK</b-button>
									</b-field>
								</div>
								<div class="column is-3">
									<b-field class="">
										<b-button
											:loading="isSubmitLoading"
											expanded
											class="has-background-danger-light has-text-white has-text-weight-semibold"
											@click="isRejectModal = false"
										>BATAL</b-button>
									</b-field>
								</div>
							</div>
						</form>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
		<b-modal has-modal-card v-model="isSubstitutionModal">
            <div class="modal-card" style="width: 590px">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">Pilih approver pengganti</p>
                        <!-- <button
                            type="button"
                            class="delete"
                            @click="isSubstitutionModal = false"/> -->
                    </header>
                    <section class="modal-card-body">
                        <div class="has-text-weight-medium has-text-primary has-text-centered">
							Untuk PK di bawah ini
						</div>
						<div class="column mt-2 is-offset-3 is-6 is-primary has-text-primary has-text-weight-medium">
							<ul>
								<li>
									<b-icon
										pack="far"
										icon="dot-circle">
									</b-icon> {{tempSubstitutionPK ? tempSubstitutionPK.id_pk : null}}
								</li>
							</ul>
						</div>
						<ValidationObserver ref="observer">
							<form ref="substitution">
								<div class="column is-half is-offset-one-quarter mt-4">
									<b-field class="hz-label" label="">
										<template #label>
											<span class="is-family-primary has-text-weight-medium">Pilih approver pengganti<span class="mandatory">*</span></span>
										</template>
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-autocomplete
												:loading="isSubstitutionLoading"
												open-on-focus
												v-model="substitution"
												:data="getAsyncEmployeesSubstitution"
												@typing="setPickSubstitution(false)"
                                            	@select="setPickSubstitution(true)"
												:custom-formatter="option => option.username + ' - ' + option.name"
												placeholder="Ketik dan pilih dari dropdown"
												@keypress.native="userValidation"
												pattern="^[A-Za-z0-9\s-]*"
												validation-message="Hanya bisa alfabet, angka, spasi dan tanda strip"
												required>
												<template slot="empty">
													Tidak ditemukan hasil untuk
													{{ substitution }}
												</template>
											</b-autocomplete>
											<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
										</ValidationProvider>
									</b-field>
								</div>
								<div class="column is-half is-offset-one-fifth mt-4">
									<b-field class="hz-label-area" label="">
										<template #label>
											<span class="is-family-primary has-text-weight-medium">Input Alasan Penggantian<span class="mandatory">*</span></span>
										</template>
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-input
												:loading="isSubmitLoading"
												class="modal_area"
												placeholder="Maks 100 karakter"
												maxlength="100"
												type="textarea"
												icon-pack="fas"
												icon-right="pencil-alt"
												v-model="reasonSubstitution"
												@keypress.native="onlyAlphaNum"
												pattern="^[A-Za-z0-9\s]*"
												validation-message="Hanya bisa alfabet, angka dan spasi"
												required>
											</b-input>
											<p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
										</ValidationProvider>
									</b-field>
								</div>
								<div class="columns mt-4">
									<div class="column is-flex is-justify-content-center">
										<b-field class="">
											<b-button
												:loading="isSubmitLoading"
												expanded
												class="has-background-success has-text-white has-text-weight-semibold"
												@click="createApproverSubstitution()"
											>Simpan</b-button>
										</b-field>
									</div>
									<!-- <div class="column is-3">
										<b-field class="">
											<b-button
												expanded
												class="has-background-danger-light has-text-white has-text-weight-bold"
												@click="isRecruitModal = false"
											>TIDAK</b-button>
										</b-field>
									</div> -->
								</div>
							</form>
						</ValidationObserver>
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive = false" />
                        <b-button
                            label="Login"
                            type="is-primary" />
                    </footer> -->
                </div>
        </b-modal>
	</section>
</template>

<script>
	import debounce from 'lodash.debounce'
	import { mapGetters, mapActions } from "vuex";

    export default {
        data() {
            return {
				data: [],
				PKType: 'All',
                checkboxPosition: 'left',
				defaultSortDirection: 'asc',
				sortOrder: 'asc',
				sortField: 'id',
				checkedRows: [],
				search: '',
				isApproveModal: false,
				isRejectModal: false,
				reason: null,
				reasonSubstitution: null,
				isSubstitutionModal: false,
				tempSubstitutionPK: null,
				textModal: '',
				total: 0,
				perPage: 5,
				page: 1,
				varPageNumbering: 1,
				isLoading: true,
				isSubmitLoading: true,
				userNik: null,
				userJobClass: null,
				substitution: '',
				employeesSubstitution: null,
				substitutionJobClass: null,
				isSubstitutionLoading: false,
				approvalLevel: null,
				substitutedEmployeeName: null,
				isSubstitutionButtonLoading: true,
				pickSubstitution: false,
                filterApproval: 2,
                filterSuspend: 0,
				//dataOnPage: 0,
				filter: {},
				isheaderCheckable: true,
				isRouting: false,
            }
        },

		async mounted () {
			await this.loadAsyncData(false)
			if (this.storedPKBelumDiprosesAtasan) {
				this.checkedRows = this.storedPKBelumDiprosesAtasan
			}
			await this.getUser()
			this.isSubmitLoading = false
			this.isSubstitutionButtonLoading = false
		},

		computed: {
			...mapGetters({
				storedPKBelumDiprosesAtasan: "storedPKBelumDiprosesAtasan",
			}),

			getAsyncEmployeesSubstitution() {
				if (this.pickSubstitution == false) {
					return this.employeesSubstitution.filter((option) => {
						return option.name
							.toString()
							.toLowerCase()
							.indexOf(this.substitution.toLowerCase()) >= 0 ||
							option.username
							.toString()
							.toLowerCase()
							.indexOf(this.substitution.toLowerCase()) >= 0
					})
				} else {
					return this.employeesSubstitution
				}
			},
		},

		methods: {
			...mapActions(["setStoredPKBelumDiprosesAtasan"]),

			onFilter (filter) {
                this.filter = filter
                this.loadAsyncData()
			},

			addCheckedRows (row) {
				if (!this.isRouting) {
					let unchecking = false
					this.checkedRows.forEach((item, index) => {
						if (item.id == row.id) {
							this.checkedRows.splice(index, 1)
							unchecking = true
						}
					})
					if (!unchecking) {
						if (this.checkedRows.length <=4) {
							this.checkedRows.push(row)
						} else {
							this.$buefy.snackbar.open({
								message: 'Maksimal data yang dapat dipilih hanya 5',
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					}
				}
			},

			// handleCheckAll () {
			// 	if (this.checkedRows.length == 5) {
			// 		this.removeCheckedRows()
			// 	}
			// },

			// removeCheckedRows () {
			// 	this.$nextTick(() => {
			// 		this.checkedRows = []
			// 	});
			// },

			setPickSubstitution (selecting) {
				this.pickSubstitution = selecting
			},

			validateCheckedRow (row) {
				let isCheckable = false
				this.checkedRows.forEach((item) => {
					if (item.id == row.id) {
						isCheckable = true
					}
				})
				
				if (this.checkedRows.length <=4) {
					isCheckable = true
				}

				// if (row.status_pergantian_pejabat == 0) {
				// 	isCheckable = false
				// } else if (row.status_diketahui_1 == 1 || row.status_diketahui_2 == 1 || row.status_disetujui_1 == 1 || row.status_disetujui_2 == 1) {
				// 	isCheckable = false
				// } else if (row.status_diketahui_1 == 2 && row.status_diketahui_2 == 2 && row.status_disetujui_1 == 2 && row.status_disetujui_2 == 2) {
				// 	isCheckable = false
				// } else if (row.status_diketahui_1 == 0 && row.nik_diketahui_1) {
				// 	if (row.nik_diketahui_1 != this.userNik/* || this.userJobClass < 'D1'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_diketahui_2 == 0 && row.nik_diketahui_2 && row.status_diketahui_1 == 2) {
				// 	if (row.nik_diketahui_2 != this.userNik/* || this.userJobClass < 'F'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_diketahui_2 == 0 && row.nik_diketahui_2 && row.status_diketahui_1 == 0 && !row.nik_diketahui_1) {
				// 	if (row.nik_diketahui_2 != this.userNik/* || this.userJobClass < 'F'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_disetujui_1 == 0 && row.nik_disetujui_1 && row.status_diketahui_2 == 2) {
				// 	if (row.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_disetujui_1 == 0 && row.nik_disetujui_1 && row.status_diketahui_2 == 0 && !row.nik_diketahui_2 && row.status_diketahui_1 == 2) {
				// 	if (row.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_disetujui_1 == 0 && row.nik_disetujui_1 && row.status_diketahui_2 == 0 && !row.nik_diketahui_2 && row.status_diketahui_1 == 0 && !row.nik_diketahui_1) {
				// 	if (row.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_disetujui_2 == 0 && row.nik_disetujui_2 && row.status_disetujui_1 == 2) {
				// 	if (row.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_disetujui_2 == 0 && row.nik_disetujui_2 && row.status_disetujui_1 == 0 && !row.nik_disetujui_1 && row.status_diketahui_2 == 2) {
				// 	if (row.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_disetujui_2 == 0 && row.nik_disetujui_2 && row.status_disetujui_1 == 0 && !row.nik_disetujui_1 && row.status_diketahui_2 == 0 && !row.nik_diketahui_2 && row.status_diketahui_1 == 2) {
				// 	if (row.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
				// 		isCheckable = false
				// 	}
				// } else if (row.status_disetujui_2 == 0 && row.nik_disetujui_2 && row.status_disetujui_1 == 0 && !row.nik_disetujui_1 && row.status_diketahui_2 == 0 && !row.nik_diketahui_2 && row.status_diketahui_1 == 0 && !row.nik_diketahui_1) {
				// 	if (row.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
				// 		isCheckable = false
				// 	}
				// }

				// if (this.checkedRows.length > 0 && this.checkedRows.length != 5) {
				// 	this.isheaderCheckable = false
				// } else {
				// 	this.isheaderCheckable = true
				// }

				return isCheckable
			},

			async getUserByUnitBranch (unit, branch) {
				this.isSubstitutionLoading = true

				//let unitBypass = unit.replace("&", "%26")
				let postData = {
					unit: unit,
					cabang: branch,
					code: 10
				}

				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
					.then( response => {
						this.employeesSubstitution = null
						// response.data.result.forEach(element => {
						// 	this.employeesSubstitution.push(element)
						// });
						this.employeesSubstitution = response.data.result
						this.isSubstitutionLoading = false
						//console.log(this.employeesSubstitution)
					})
					.catch( e => {
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
			},

			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},

			userValidation($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[a-zA-Z0-9\s-]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},

			async createApproverSubstitution () {
				this.$refs.observer.validate().then(async success => {
					if (!success) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					} else {
						if (!this.$refs.substitution.checkValidity()) {
							this.$buefy.snackbar.open({
								message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
							return
						}
						if (!this.pickSubstitution) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi pejabat pengganti dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
							})
							return
						}
						
						// this.isSubmitLoading = true
						let tingkat_approval = this.approvalLevel

						// if (this.tempSubstitutionPK.status_diketahui_1 == 0) {
						// 	tingkat_approval = "diketahui_1"
						// } else if (this.tempSubstitutionPK.status_diketahui_2 == 0) {
						// 	tingkat_approval = "diketahui_2"
						// } else if (this.tempSubstitutionPK.status_disetujui_1 == 0) {
						// 	tingkat_approval = "Disetujui 1"
						// } else if (this.tempSubstitutionPK.status_disetujui_2 == 0) {
						// 	tingkat_approval = "Disetujui 2"
						// }

						let arraySubstitution = this.substitution.split(" ")

						let postData = {
							id_status_approval: this.tempSubstitutionPK.id_status_approval,
							tingkat_approval: tingkat_approval,
							nik_pejabat_pengganti: arraySubstitution[0],
							nama_pejabat_pengganti: this.substitution.substring(this.substitution.indexOf("- ") + 1),
							alasan_pergantian: this.reasonSubstitution,
							code: 3
						}

						let iconType = ''
						let titleMessage = ''
						let textMessage = ''
						let iconColor = ''
						let index = 0
						let isError = false

						textMessage = this.substitutedEmployeeName + " menjadi " + this.substitution.substring(this.substitution.indexOf("- ") + 1) + " - " + this.tempSubstitutionPK.id_pk

						this.$swal({
							html: true,
							icon: 'question',
							title: 'Apakah Anda yakin ingin mengajukan permohonan ganti pejabat untuk PK di bawah ini?',
							html: textMessage,
							showDenyButton: true,
							confirmButtonText: 'Ya',
							denyButtonText: 'Tidak',
							customClass: {
								icon: 'is-warning',
								title: 'has-text-primary is-family-primary has-text-weight-semibold',
								htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
								confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold',
								denyButton: 'has-background-danger-light is-family-primary button-sizing has-text-white has-text-weight-bold'
							}
						}).then(async (result) => {
							if (result.isConfirmed) {
								this.isSubmitLoading = true
								await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `approver-substitutions`, postData)
									.then( response => {
										titleMessage = 'Pergantian pejabat berhasil diajukan'
										iconType = 'success',
										iconColor = 'is-success'
										this.isSubmitLoading = false
									})
									.catch( e => {
										if (e.response.status != 401) {
											titleMessage = 'Gagal mengajukan pergantian pejabat!'
											iconType = 'error'
											if (e.response.data.status && e.response.data.status != 0) {
												textMessage = e.response.data.message
											} else {
												textMessage = 'Terjadi kesalahan pada server'
											}
											iconColor = 'is-danger'
										}
										
										console.log(e)
										this.isSubmitLoading = false
										isError = true
									})
									.finally(() => {
										if (titleMessage) {
											this.$swal({
												html: true,
												icon: iconType,
												title: titleMessage,
												html: textMessage,
												customClass: {
													icon: iconColor,
													title: 'has-text-primary is-family-primary has-text-weight-semibold',
													htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
													confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
												}
											}).then((isConfirmed) => {
												if (isConfirmed && !isError)
												window.location.reload()
											})
										}
									})
							} else if (result.isDenied) {
								// this.isSubstitutionModal = false
							}
						})
					}
				})
			},

			// searchEmployees: debounce(function (jobClass) {
			// 	this.isSubstitutionLoading = true
				
			// 	const params = [
			// 		`jobClass=${jobClass}`,
			// 		`search=${this.substitution}`
			// 	].join('&')

			// 	console.log(params)

			// 	this.$axios.get(process.env.VUE_APP_GATEWAY_API + 'hrms/master-employee-job-classes?' + params)
			// 		.then( response => {
			// 			this.employeesSubstitution = []
			// 			response.data.result.forEach(element => {
			// 				this.employeesSubstitution.push(element)
			// 			})
			// 			this.isSubstitutionLoading = false
			// 		})
			// 		.catch( e => {
			// 			console.log(e)
			// 		})
			// }, 400),

			async getUser() {
				let postData = {
					code: 1
				}
				await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'auth', postData)
					.then( response => {
						this.userNik = response.data.result.nik
						this.userJobClass = response.data.result.job_class
					})
					.catch( e => {
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
			},

			async updatePKApproval (status) {
				if (status == 1) {
					if (!this.$refs.reject.checkValidity()) {
						this.$buefy.snackbar.open({
							message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return
					}
				}

				// this.isSubmitLoading = true

				let iconType = ''
				let titleMessage = ''
				let textMessage = ''
				let iconColor = ''
				let index = 0
				let isError = false

				this.checkedRows.forEach( element => {
					textMessage += `${element.id_pk}<br/>`
				})

				if (status == 1) {
					this.$swal({
						html: true,
						icon: 'question',
						title: 'Apakah Anda yakin ingin menolak PK di bawah ini?',
						html: textMessage,
						showDenyButton: true,
						confirmButtonText: 'TOLAK PK',
						denyButtonText: 'BATAL',
						customClass: {
							icon: 'is-warning',
							title: 'has-text-primary is-family-primary has-text-weight-semibold',
							htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
							confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold',
							denyButton: 'has-background-danger-light is-family-primary button-sizing has-text-white has-text-weight-bold'
						}
					}).then(async (result) => {
						if (result.isConfirmed) {
							this.isSubmitLoading = true
							let sendData = []
							this.checkedRows.forEach( element => {
								element.status = status
								if (status == 1) {
									element.keterangan_reject = this.reason
								}

								let tempData = {
									id: element.id,
									status: element.status,
									keterangan_reject: element.keterangan_reject
								}
								sendData.push(tempData)
							})
							
							let postData = {
								row: sendData,
								code: 5
							}

							await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `approval-statuses`, postData)
								.then( response => {
									titleMessage = 'Penolakan PK berhasil'
									iconType = 'success',
									iconColor = 'is-success'
									this.isSubmitLoading = false
								})
								.catch( e => {
									if (e.response.status != 401) {
										titleMessage = 'Gagal menolak PK!'
										iconType = 'error'
										if (e.response.data.status && e.response.data.status != 0) {
											textMessage = e.response.data.message
										} else {
											textMessage = 'Terjadi kesalahan pada server'
										}
										iconColor = 'is-danger'
									}
									
									console.log(e)
									this.isSubmitLoading = false
									isError = true
								})
								.finally(() => {
									if (titleMessage) {
										this.$swal({
											html: true,
											icon: iconType,
											title: titleMessage,
											html: textMessage,
											customClass: {
												icon: iconColor,
												title: 'has-text-primary is-family-primary has-text-weight-semibold',
												htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
												confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
											}
										}).then((isConfirmed) => {
											if (isConfirmed && !isError)
											window.location.reload()
										})
									}
								})
						} else if (result.isDenied) {
							// this.isRejectModal = false
							// this.isSubmitLoading = false
						}
					})
				} else if (status == 2) {
					this.isSubmitLoading = true
					let sendData = []
					this.checkedRows.forEach( element => {
						element.status = status

						let tempData = {
							id: element.id,
							status: element.status
						}
						sendData.push(tempData)
					})

					let postData = {
						row: sendData,
						code: 5
					}

					await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `approval-statuses`, postData)
						.then( response => {
							titleMessage = 'Persetujuan PK berhasil'
							iconType = 'success',
							iconColor = 'is-success'
							this.isSubmitLoading = false
						})
						.catch( e => {
							if (e.response.status != 401) {
								titleMessage = 'Gagal menyetujui PK!'
								iconType = 'error'
								if (e.response.data.status && e.response.data.status != 0) {
									textMessage = e.response.data.message
								} else {
									textMessage = 'Terjadi kesalahan pada server'
								}
								iconColor = 'is-danger'
							}
							
							console.log(e)
							this.isSubmitLoading = false
							isError = true
						})
						.finally(() => {
							if (titleMessage) {
								this.$swal({
									html: true,
									icon: iconType,
									title: titleMessage,
									html: textMessage,
									customClass: {
										icon: iconColor,
										title: 'has-text-primary is-family-primary has-text-weight-semibold',
										htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
										confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
									}
								}).then((isConfirmed) => {
									if (isConfirmed)
									window.location.reload()
								})
							}
						})
				}
			},
		
			onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData(false)
			},
			
			onPageChange(page) {
                this.page = page
                this.loadAsyncData(true)
			},
			
			loadAsyncData: debounce(function (pageChanging) {
				if (!this.search) {
					this.search = ''
				}
				if (!pageChanging) {
					this.page = 1
				}

				let pushFilter = {}
				if (!this.filter.hasOwnProperty('id_pk')) {
                    pushFilter.id_pk = ""
                } else {
					pushFilter.id_pk = this.filter.id_pk
				}
                if (!this.filter.hasOwnProperty('unit_code')) {
                    pushFilter.unit_code = ""
                } else {
					pushFilter.unit_code = this.filter.unit_code
				}
                if (!this.filter.hasOwnProperty('departemen')) {
                    pushFilter.departemen = ""
                } else {
					pushFilter.departemen = this.filter.departemen
				}
                if (!this.filter.hasOwnProperty('jab_teknis')) {
                    pushFilter.jab_teknis = ""
                } else {
					pushFilter.jab_teknis = this.filter.jab_teknis
				}
                if (!this.filter.hasOwnProperty('job_class_from')) {
                    pushFilter.job_class_from = ""
                } else {
					pushFilter.job_class_from = this.filter.job_class_from
				}
                if (!this.filter.hasOwnProperty('lokasi_kerja')) {
                    pushFilter.lokasi_kerja = ""
				} else {
					pushFilter.lokasi_kerja = this.filter.lokasi_kerja
				}
				if (!this.filter.hasOwnProperty('budget')) {
                    pushFilter.budget = ""
				} else {
					pushFilter.budget = this.filter.budget
				}
				if (!this.filter.hasOwnProperty('jumlah_kebutuhan')) {
                    pushFilter.jumlah_kebutuhan = ""
				} else {
					pushFilter.jumlah_kebutuhan = this.filter.jumlah_kebutuhan
				}
				if (!this.filter.hasOwnProperty('alasan_permintaan')) {
                    pushFilter.alasan_permintaan = ""
				} else {
					pushFilter.alasan_permintaan = this.filter.alasan_permintaan
				}
				if (!this.filter.hasOwnProperty('nama_dibuat')) {
                    pushFilter.nama_dibuat = ""
				} else {
					pushFilter.nama_dibuat = this.filter.nama_dibuat
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_struktural_1')) {
                    pushFilter.nama_diketahui_struktural_1 = ""
				} else {
					pushFilter.nama_diketahui_struktural_1 = this.filter.nama_diketahui_struktural_1
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_struktural_2')) {
                    pushFilter.nama_diketahui_struktural_2 = ""
				} else {
					pushFilter.nama_diketahui_struktural_2 = this.filter.nama_diketahui_struktural_2
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_struktural_3')) {
                    pushFilter.nama_diketahui_struktural_3 = ""
				} else {
					pushFilter.nama_diketahui_struktural_3 = this.filter.nama_diketahui_struktural_3
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_struktural_4')) {
                    pushFilter.nama_diketahui_struktural_4 = ""
				} else {
					pushFilter.nama_diketahui_struktural_4 = this.filter.nama_diketahui_struktural_4
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_struktural_5')) {
                    pushFilter.nama_diketahui_struktural_5 = ""
				} else {
					pushFilter.nama_diketahui_struktural_5 = this.filter.nama_diketahui_struktural_5
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_struktural_6')) {
                    pushFilter.nama_diketahui_struktural_6 = ""
				} else {
					pushFilter.nama_diketahui_struktural_6 = this.filter.nama_diketahui_struktural_6
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_fungsional_1')) {
                    pushFilter.nama_diketahui_fungsional_1 = ""
				} else {
					pushFilter.nama_diketahui_fungsional_1 = this.filter.nama_diketahui_fungsional_1
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_fungsional_2')) {
                    pushFilter.nama_diketahui_fungsional_2 = ""
				} else {
					pushFilter.nama_diketahui_fungsional_2 = this.filter.nama_diketahui_fungsional_2
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_fungsional_3')) {
                    pushFilter.nama_diketahui_fungsional_3 = ""
				} else {
					pushFilter.nama_diketahui_fungsional_3 = this.filter.nama_diketahui_fungsional_3
				}
				if (!this.filter.hasOwnProperty('nama_diketahui_fungsional_4')) {
                    pushFilter.nama_diketahui_fungsional_4 = ""
				} else {
					pushFilter.nama_diketahui_fungsional_4 = this.filter.nama_diketahui_fungsional_4
				}
				if (!this.filter.hasOwnProperty('nama_disetujui_1')) {
                    pushFilter.nama_disetujui_1 = ""
				} else {
					pushFilter.nama_disetujui_1 = this.filter.nama_disetujui_1
				}
				if (!this.filter.hasOwnProperty('nama_disetujui_2')) {
                    pushFilter.nama_disetujui_2 = ""
				} else {
					pushFilter.nama_disetujui_2 = this.filter.nama_disetujui_2
				}
				if (!this.filter.hasOwnProperty('nama_disetujui_3')) {
                    pushFilter.nama_disetujui_3 = ""
				} else {
					pushFilter.nama_disetujui_3 = this.filter.nama_disetujui_3
				}
				if (!this.filter.hasOwnProperty('nama_disetujui_4')) {
                    pushFilter.nama_disetujui_4 = ""
				} else {
					pushFilter.nama_disetujui_4 = this.filter.nama_disetujui_4
				}
				if (!this.filter.hasOwnProperty('nama_disetujui_5')) {
                    pushFilter.nama_disetujui_5 = ""
				} else {
					pushFilter.nama_disetujui_5 = this.filter.nama_disetujui_5
				}

				this.isLoading = true
				// let searchBypass = this.search.replace("&", "%26")
                // const params = [
				// 	`column=${this.sortField}`,
				// 	`sort=${this.sortOrder}`,
				// 	`page=${this.page}`,
				// 	`search=${searchBypass}`,
				// 	`jenis_pk=${this.PKType}`
				// ].join('&')
				// console.log(params)
				let postData = {
					column: this.sortField,
					sort: this.sortOrder,
					page: this.page,
					search: this.search,
					jenis_pk: this.PKType,
					status: this.filterApproval,
                    filter_tunda: this.filterSuspend,
					filter: JSON.stringify(pushFilter),
					code: 1
				}

                this.$axios.post(process.env.VUE_APP_GATEWAY_API + `pks`, postData)
                    .then( response  => {
						this.data = []
                        let currentTotal = response.data.result.total_results
                        this.total = currentTotal
                        response.data.result.data.forEach((item) => {
                            this.data.push(item)
						})
						this.varPageNumbering = this.page
						//this.dataOnPage = this.data.length
						//console.log(this.data)
                    })
                    .catch(e => {
                        this.data = []
						this.total = 0
						//this.dataOnPage = 0
						console.log(e)

						if (e.response.status != 401) {
							let message = null
							if (e.response.data.status && e.response.data.status != 0) {
								message = e.response.data.message
							} else {
								message = 'Terjadi kesalahan pada server'
							}

							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000
							})
						}
					})
					.finally(() => {
						this.isLoading = false
					})
			}, 400),
			
			async openModal(modalType, substitutionPK) {
				if (this.checkedRows.length == 0 && modalType != 'substitution') {
					this.$buefy.snackbar.open({
						message: 'Pilih PK yang ingin disetujui / ditolak',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
					this.isSubmitLoading = false
					if (modalType == 'approve' || modalType == 'reject') {
						let canOpenModal = true
						let onSubstitute = false
						let message = null
						if (this.checkedRows.length > 5) {
							canOpenModal = false
							message = "Maksimal data yang dapat dipilih hanya 5"
						}
						this.checkedRows.forEach(element => {
							if (canOpenModal) {
								if (element.status_pergantian_pejabat == 0) {
									if (!onSubstitute) {
										message = "Terdapat PK yang sedang diajukan pergantian pejabat:<ul><li>> " + element.id_pk + "</li>"
										onSubstitute = true
									} else if (onSubstitute) {
										message += "<li>> " + element.id_pk + "</li>"
									}
								} else if (element.status_diketahui_struktural_1 == 1 || element.status_diketahui_struktural_2 == 1 || element.status_diketahui_struktural_3 == 1 || element.status_diketahui_struktural_4 == 1 || element.status_diketahui_struktural_5 == 1 || element.status_diketahui_struktural_6 == 1 || 
								element.status_diketahui_fungsional_1 == 1 || element.status_diketahui_fungsional_2 == 1 || element.status_diketahui_fungsional_3 == 1 || element.status_diketahui_fungsional_4 == 1 || 
								element.status_disetujui_1 == 1 || element.status_disetujui_2 == 1 || element.status_disetujui_3 == 1 || element.status_disetujui_4 == 1 || element.status_disetujui_5 == 1) {
									canOpenModal = false
									message = "Terdapat PK yang sudah ditolak"
								} else if (element.status_diketahui_struktural_1 == 2 && element.status_diketahui_struktural_2 == 2 && element.status_diketahui_struktural_3 == 2 && element.status_diketahui_struktural_4 == 2 && element.status_diketahui_struktural_5 == 2 && element.status_diketahui_struktural_6 == 2 && 
								element.status_diketahui_fungsional_1 == 2 && element.status_diketahui_fungsional_2 == 2 && element.status_diketahui_fungsional_3 == 2 && element.status_diketahui_fungsional_4 == 2 && 
								element.status_disetujui_1 == 2 && element.status_disetujui_2 == 2 && element.status_disetujui_3 == 2 && element.status_disetujui_4 == 2 && element.status_disetujui_5 == 2) {
									canOpenModal = false
									message = "Terdapat PK yang sudah disetujui"
								} else if (element.status_diketahui_struktural_1 == 0 && element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_1 != this.userNik/* || this.userJobClass < 'D1'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_2 == 0 && element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_2 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_2 == 0 && element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_2 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_3 == 0 && element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_struktural_3 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_3 == 0 && element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_3 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_3 == 0 && element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_3 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_4 == 0 && element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_struktural_4 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_4 == 0 && element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_struktural_4 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_4 == 0 && element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_4 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_4 == 0 && element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_4 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_5 == 0 && element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_5 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_struktural_6 == 0 && element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_struktural_6 != this.userNik/* || this.userJobClass < 'F'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_1 == 0 && element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_fungsional_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_2 == 0 && element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_fungsional_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_3 == 0 && element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_fungsional_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_diketahui_fungsional_4 == 0 && element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_diketahui_fungsional_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_1 == 0 && element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_1 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_2 == 0 && element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_2 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_3 == 0 && element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_3 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_4 == 0 && element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_4 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 2) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								} else if (element.status_disetujui_5 == 0 && element.nik_disetujui_5 && element.status_disetujui_4 == 0 && !element.nik_disetujui_4 && element.status_disetujui_3 == 0 && !element.nik_disetujui_3 && element.status_disetujui_2 == 0 && !element.nik_disetujui_2 && element.status_disetujui_1 == 0 && !element.nik_disetujui_1 && 
									element.status_diketahui_fungsional_4 == 0 && !element.nik_diketahui_fungsional_4 && element.status_diketahui_fungsional_3 == 0 && !element.nik_diketahui_fungsional_3 && element.status_diketahui_fungsional_2 == 0 && !element.nik_diketahui_fungsional_2 && element.status_diketahui_fungsional_1 == 0 && !element.nik_diketahui_fungsional_1 && 
									element.status_diketahui_struktural_6 == 0 && !element.nik_diketahui_struktural_6 && element.status_diketahui_struktural_5 == 0 && !element.nik_diketahui_struktural_5 && element.status_diketahui_struktural_4 == 0 && !element.nik_diketahui_struktural_4 && element.status_diketahui_struktural_3 == 0 && !element.nik_diketahui_struktural_3 && element.status_diketahui_struktural_2 == 0 && !element.nik_diketahui_struktural_2 && element.status_diketahui_struktural_1 == 0 && !element.nik_diketahui_struktural_1) {
									if (element.nik_disetujui_5 != this.userNik/* || this.userJobClass < 'G'*/) {
										canOpenModal = false
										message = "Tahapan persetujuan PK tidak untuk anda"
									}
								}
							}
						});
						if (!canOpenModal || onSubstitute) {
							if (onSubstitute) {
								message += "</ul>"
							}
							this.$buefy.snackbar.open({
								message: message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
								duration: 7000,
							})
						} else {
							if (modalType == 'approve') {
								this.isApproveModal = true
							} else if (modalType == 'reject') {
								this.reason = null
								this.isRejectModal = true
							}
						}
					} else if (modalType == 'substitution') {
						this.isSubstitutionButtonLoading = true
						let canOpenModal = false
						let message = null
						if (substitutionPK.status_diketahui_struktural_1 == 0 && substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 1"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_1
							}
						} else if (substitutionPK.status_diketahui_struktural_2 == 0 && substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_2
							}
						} else if (substitutionPK.status_diketahui_struktural_2 == 0 && substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_2
							}
						} else if (substitutionPK.status_diketahui_struktural_3 == 0 && substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_3
							}
						} else if (substitutionPK.status_diketahui_struktural_3 == 0 && substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_3
							}
						} else if (substitutionPK.status_diketahui_struktural_3 == 0 && substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_3
							}
						} else if (substitutionPK.status_diketahui_struktural_4 == 0 && substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_4
							}
						} else if (substitutionPK.status_diketahui_struktural_4 == 0 && substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_4
							}
						} else if (substitutionPK.status_diketahui_struktural_4 == 0 && substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_4
							}
						} else if (substitutionPK.status_diketahui_struktural_4 == 0 && substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_4
							}
						} else if (substitutionPK.status_diketahui_struktural_5 == 0 && substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 5"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_5
							}
						} else if (substitutionPK.status_diketahui_struktural_5 == 0 && substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 5"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_5
							}
						} else if (substitutionPK.status_diketahui_struktural_5 == 0 && substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 5"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_5
							}
						} else if (substitutionPK.status_diketahui_struktural_5 == 0 && substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 5"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_5
							}
						} else if (substitutionPK.status_diketahui_struktural_5 == 0 && substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 5"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_5
							}
						} else if (substitutionPK.status_diketahui_struktural_6 == 0 && substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 6"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_6
							}
						} else if (substitutionPK.status_diketahui_struktural_6 == 0 && substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 6"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_6
							}
						} else if (substitutionPK.status_diketahui_struktural_6 == 0 && substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 6"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_6
							}
						} else if (substitutionPK.status_diketahui_struktural_6 == 0 && substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 6"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_6
							}
						} else if (substitutionPK.status_diketahui_struktural_6 == 0 && substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 6"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_6
							}
						} else if (substitutionPK.status_diketahui_struktural_6 == 0 && substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Struktural 6"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_struktural_6
							}
						} else if (substitutionPK.status_diketahui_fungsional_1 == 0 && substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							if (substitutionPK.nik_diketahui_struktural_6 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 1"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_1
							}
						} else if (substitutionPK.status_diketahui_fungsional_1 == 0 && substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 1"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_1
							}
						} else if (substitutionPK.status_diketahui_fungsional_1 == 0 && substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 1"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_1
							}
						} else if (substitutionPK.status_diketahui_fungsional_1 == 0 && substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 1"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_1
							}
						} else if (substitutionPK.status_diketahui_fungsional_1 == 0 && substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 1"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_1
							}
						} else if (substitutionPK.status_diketahui_fungsional_1 == 0 && substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 1"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_1
							}
						} else if (substitutionPK.status_diketahui_fungsional_1 == 0 && substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 1"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_1
							}
						} else if (substitutionPK.status_diketahui_fungsional_2 == 0 && substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_2
							}
						} else if (substitutionPK.status_diketahui_fungsional_2 == 0 && substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							if (substitutionPK.nik_diketahui_struktural_6 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_2
							}
						} else if (substitutionPK.status_diketahui_fungsional_2 == 0 && substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_2
							}
						} else if (substitutionPK.status_diketahui_fungsional_2 == 0 && substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_2
							}
						} else if (substitutionPK.status_diketahui_fungsional_2 == 0 && substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_2
							}
						} else if (substitutionPK.status_diketahui_fungsional_2 == 0 && substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_2
							}
						} else if (substitutionPK.status_diketahui_fungsional_2 == 0 && substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_2
							}
						} else if (substitutionPK.status_diketahui_fungsional_2 == 0 && substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 2"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_2
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							if (substitutionPK.nik_diketahui_struktural_6 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_3 == 0 && substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 3"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_3
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							if (substitutionPK.nik_diketahui_struktural_6 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_diketahui_fungsional_4 == 0 && substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui Fungsional 4"
								this.substitutedEmployeeName = substitutionPK.nama_diketahui_fungsional_4
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							if (substitutionPK.nik_diketahui_struktural_6 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_1 == 0 && substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 1"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_1
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 2) {
							if (substitutionPK.nik_disetujui_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							if (substitutionPK.nik_diketahui_struktural_6 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_2 == 0 && substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 2"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 2) {
							if (substitutionPK.nik_disetujui_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 2) {
							if (substitutionPK.nik_disetujui_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							if (substitutionPK.nik_diketahui_struktural_6 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_3 == 0 && substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 3"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_3
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 2) {
							if (substitutionPK.nik_disetujui_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 2) {
							if (substitutionPK.nik_disetujui_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 2) {
							if (substitutionPK.nik_disetujui_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 2) {
							if (substitutionPK.nik_diketahui_fungsional_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							if (substitutionPK.nik_diketahui_struktural_6 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							if (substitutionPK.nik_diketahui_struktural_5 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							if (substitutionPK.nik_diketahui_struktural_4 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							if (substitutionPK.nik_diketahui_struktural_3 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							if (substitutionPK.nik_diketahui_struktural_2 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							if (substitutionPK.nik_diketahui_struktural_1 == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_4 == 0 && substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
								canOpenModal = true
								this.substitutionJobClass = "D1"
								this.approvalLevel = "Disetujui 4"
								this.substitutedEmployeeName = substitutionPK.nama_disetujui_4
							}
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 2) {
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
						} else if (substitutionPK.status_disetujui_5 == 0 && substitutionPK.nik_disetujui_5 && substitutionPK.status_disetujui_4 == 0 && !substitutionPK.nik_disetujui_4 && substitutionPK.status_disetujui_3 == 0 && !substitutionPK.nik_disetujui_3 && substitutionPK.status_disetujui_2 == 0 && !substitutionPK.nik_disetujui_2 && substitutionPK.status_disetujui_1 == 0 && !substitutionPK.nik_disetujui_1 && 
							substitutionPK.status_diketahui_fungsional_4 == 0 && !substitutionPK.nik_diketahui_fungsional_4 && substitutionPK.status_diketahui_fungsional_3 == 0 && !substitutionPK.nik_diketahui_fungsional_3 && substitutionPK.status_diketahui_fungsional_2 == 0 && !substitutionPK.nik_diketahui_fungsional_2 && substitutionPK.status_diketahui_fungsional_1 == 0 && !substitutionPK.nik_diketahui_fungsional_1 && 
							substitutionPK.status_diketahui_struktural_6 == 0 && !substitutionPK.nik_diketahui_struktural_6 && substitutionPK.status_diketahui_struktural_5 == 0 && !substitutionPK.nik_diketahui_struktural_5 && substitutionPK.status_diketahui_struktural_4 == 0 && !substitutionPK.nik_diketahui_struktural_4 && substitutionPK.status_diketahui_struktural_3 == 0 && !substitutionPK.nik_diketahui_struktural_3 && substitutionPK.status_diketahui_struktural_2 == 0 && !substitutionPK.nik_diketahui_struktural_2 && substitutionPK.status_diketahui_struktural_1 == 0 && !substitutionPK.nik_diketahui_struktural_1) {
							// if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
							// 	canOpenModal = true
							// 	this.substitutionJobClass = "D1"
							// 	this.approvalLevel = "Disetujui 2"
							// }
							canOpenModal = false
							message = 'Tidak diperbolehkan untuk mengganti pejabat'
							// if (substitutionPK.nik_dibuat == this.userNik/* || this.userJobClass < 'F'*/) {
							// 	if (substitutionPK.jenis_pk == 'Wilayah Baru (Cabang / DCI)') {
							// 		canOpenModal = true
							// 		this.substitutionJobClass = "D1"
							// 		this.approvalLevel = "Disetujui 2"
							// 		this.substitutedEmployeeName = substitutionPK.nama_disetujui_2
							// 	} else {
							// 		canOpenModal = false
							// 		message = 'Tidak diperbolehkan untuk mengganti pejabat'
							// 	}
							// }
						}

						if (!canOpenModal) {
							this.$buefy.snackbar.open({
								message: message ? message : 'Hanya pejabat persetujuan tepat sebelumnya yang dapat melakukan penggantian',
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
							this.isSubstitutionButtonLoading = false
						} else {
							this.substitution = ''
							this.reasonSubstitution = null
							this.tempSubstitutionPK = substitutionPK
							this.isSubstitutionModal = true
							await this.getUserByUnitBranch(substitutionPK.unit, substitutionPK.cabang)
							this.isSubstitutionButtonLoading = false
						}
					}
				}
			},

			routePage(routing, PK) {
				this.isRouting = true
				if (this.checkedRows.length > 0) {
					this.setStoredPKBelumDiprosesAtasan(this.checkedRows)
				}
				this.$router.push({name: 'detail-pk', params: { routing: routing, PK: PK }})
			}
		}
    }
</script>
