<template>
    <section>
        <div class="container hero">
            <div class="mx-5">
                <b-field class="has-text-weight-semibold has-text-primary is-size-3">
                    Master Departemen
                </b-field>
                <b-button
                    icon-pack="fas"
                    icon-left="angle-left"
                    class="has-background-warning has-text-white has-text-weight-semibold mb-5"
                    @click="goBack()"
                >KEMBALI
                </b-button>
                <div class="ml-5">
                    <div class="has-text-weight-medium has-text-primary is-size-4 mt-1">
                        {{ title }}
                    </div>
                    <ValidationObserver ref="observer">
                        <form ref="form">
                            <div class="ml-5 mt-5 pt-2 has-text-weight-medium has-text-primary">
                                <div class="columns is-desktop">
                                    <div class="column is-9">
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Unit/PT<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isInputLoading"
                                                    open-on-focus
                                                    v-model="unit"
                                                    :data="getAsyncUnitData"
                                                    @typing="setPickUnit(false)"
                                                    @select="option => prepDirectorates('unit', (option ? option.KodeUnit : null), (option ? option.AliasUnit : null))"
                                                    field="NamaUnit"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentDirectorateValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ unit }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Cabang<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isBranchLoading"
                                                    open-on-focus
                                                    v-model="branch"
                                                    :data="getAsyncBranchData"
                                                    @typing="setPickBranch(false)"
                                                    @select="option => prepDirectorates('branch', (option ? option.AREAID : null))"
                                                    field="AREANAME"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentDirectorateValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ branch }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <!-- <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Direktorat<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isDirectorateLoading"
                                                    open-on-focus
                                                    v-model="directorate"
                                                    :data="getAsyncDirectorateData"
                                                    @typing="setPickDirectorate(false)"
                                                    @select="option => prepDepartments((option ? option.OMOPERATINGUNITNUMBER : null))"
                                                    field="NAME"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentDirectorateValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) +"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ directorate }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field> -->
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Level Bagian<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-select
                                                    :loading="isInputLoading"
                                                    placeholder=""
                                                    expanded
                                                    class="box-field"
                                                    v-model="sectionLevel"
                                                    @input="getDepartmentsByAreaBranch">
                                                    <!-- <option value="1">Direktorat</option>
                                                    <option value="2">Cabang</option>
                                                    <option value="3">Departemen</option>
                                                    <option value="4">Divisi</option> -->
                                                    <option value="5">Bagian</option>
                                                    <option value="6">Sub Bagian</option>
                                                </b-select>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Bagian Umum<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-autocomplete
                                                    :loading="isGeneralDepartmentLoading"
                                                    open-on-focus
                                                    v-model="generalDepartment"
                                                    :data="getAsyncDepartmentData"
                                                    @typing="setPickGeneralDepartment(false, null)"
                                                    @select="option => setPickGeneralDepartment(true, (option ? option.OMOPERATINGUNITNUMBER : null))"
                                                    field="NAME"
                                                    placeholder="Ketik dan pilih dari dropdown"
                                                    @keypress.native="departmentDirectorateValidation"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                    <template slot="empty">
                                                        Tidak ditemukan hasil untuk
                                                        {{ generalDepartment }}
                                                    </template>
                                                </b-autocomplete>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center" 
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Bagian Khusus</span>
                                            </template>
                                            <b-input
                                                :loading="isInputLoading"
                                                v-model="specificDepartment"
                                                @keypress.native="departmentDirectorateValidation"
                                                pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                >
                                            </b-input>
                                        </b-field>
                                    </div>
                                </div>
                                <b-field class="has-text-centered mt-5">
                                    <b-button
                                        :loading="isSubmitLoading"
                                        class="is-size-5 has-background-success has-text-white has-text-weight-bold"
                                        @click="updateId ? updateDepartment() : createDepartment()">Simpan</b-button>
                                </b-field>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            editData: null,
            updateId: null,
            title: null,
            unit: '',
            unitId: null,
            unitAlias: null,
            branch: '',
            branchId: null,
            directorate: '',
            directorateId: null,
            sectionLevel: null,
            generalDepartment: '',
            specificDepartment: null,
            masterUnits: [],
            masterBranches: [],
            masterDirectorates: [],
            masterDepartments: [],
            omoperatingunitnumber: null,
            isInputLoading: false,
            isBranchLoading: false,
            isDirectorateLoading: false,
            isGeneralDepartmentLoading: false,
            isSubmitLoading: false,
            pickUnit: false,
            pickBranch: false,
            pickDirectorate: false,
            pickGeneralDepartment: false,
        };
    },
    created() {
        if (this.$route.params.routingTo == 'add') {
            this.title = 'Tambah Departemen Baru'
        } else if (this.$route.params.routingTo == 'edit') {
            this.title = 'Ubah Departemen'
        }

        if (this.$route.params.master) {
            this.editData = this.$route.params.master
        }
        //console.log(this.editData)
    },
    async mounted () {
        this.isInputLoading = true
        this.isBranchLoading = true
        this.isDirectorateLoading = true
        this.isGeneralDepartmentLoading = true
        this.isSubmitLoading = true
        await this.getAllUnits()
        if (this.editData) {
            this.editingData()
        }
        this.isInputLoading = false
        this.isBranchLoading = false
        this.isDirectorateLoading = false
        this.isGeneralDepartmentLoading = false
        this.isSubmitLoading = false
    },

    computed: {
        getAsyncUnitData() {
            if (this.pickUnit == false) {
                return this.masterUnits.filter((option) => {
                    return option.NamaUnit
                        .toString()
                        .toLowerCase()
                        .indexOf(this.unit.toLowerCase()) >= 0
                })
            } else {
                return this.masterUnits
            }
        },

        getAsyncBranchData() {
            if (this.pickBranch == false) {
                return this.masterBranches.filter((option) => {
                    return option.AREANAME
                        .toString()
                        .toLowerCase()
                        .indexOf(this.branch.toLowerCase()) >= 0
                })
            } else {
                return this.masterBranches
            }
        },

        // getAsyncDirectorateData() {
        //     if (this.pickDirectorate == false) {
        //         return this.masterDirectorates.filter((option) => {
        //             return option.NAME
        //                 .toString()
        //                 .toLowerCase()
        //                 .indexOf(this.directorate.toLowerCase()) >= 0
        //         })
        //     } else {
        //         return this.masterDirectorates
        //     }
        // },

        getAsyncDepartmentData() {
            if (this.pickGeneralDepartment == false) {
                return this.masterDepartments.filter((option) => {
                    return option.NAME
                        .toString()
                        .toLowerCase()
                        .indexOf(this.generalDepartment.toLowerCase()) >= 0
                })
            } else {
                return this.masterDepartments
            }
        },
    },

    methods: {
         setPickGeneralDepartment (selecting, omoperatingunitnumber) {
            this.pickGeneralDepartment = selecting
            this.omoperatingunitnumber = omoperatingunitnumber
            //console.log(this.omoperatingunitnumber)
        },

        setPickDirectorate (selecting) {
            this.pickDirectorate = selecting
        },

        setPickBranch (selecting) {
            this.pickBranch = selecting
        },

        setPickUnit (selecting) {
            this.pickUnit = selecting
        },

        async editingData () {
            this.updateId = this.editData.id
            //this.unit = this.editData.unit
            this.unitAlias = this.editData.unit_code
            //this.branch = this.editData.cabang
            this.branchId = this.editData.cabang_code
            this.directorate = this.editData.direktorat
            this.sectionLevel = this.editData.level_bagian
            this.generalDepartment = this.editData.dept_umum
            this.specificDepartment = this.editData.dept_khusus
            this.omoperatingunitnumber = this.editData.om_operating_unit

            let tempUnit = this.masterUnits.filter((option) => {
                return option.AliasUnit
                    .toString()
                    .toLowerCase()
                    .indexOf(this.unitAlias.toLowerCase()) >= 0
            })
            this.unitId = tempUnit[0].KodeUnit
            this.unit = tempUnit[0].NamaUnit

            await this.getAllBranches()
            let tempBranch = this.masterBranches.filter((option) => {
                return option.AREAID
                    .toString()
                    .toLowerCase()
                    .indexOf(this.branchId.toLowerCase()) >= 0
            })
            this.branch = tempBranch[0].AREANAME

            await this.prepDirectorates(null, null)

            // let tempDirectorate = this.masterDirectorates.filter((option) => {
            //     return option.NAME
            //         .toString()
            //         .toLowerCase()
            //         .indexOf(this.directorate.toLowerCase()) >= 0
            // })
            // this.directorateId = tempDirectorate[0].OMOPERATINGUNITNUMBER
            this.pickUnit = true
            this.pickBranch = true
            this.pickDirectorate = true
            this.pickGeneralDepartment = true
            // await this.prepDepartments(null)
            await this.getDepartmentsByAreaBranch()
            this.editData = null
        },

        async updateDepartment () {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (!this.pickUnit || !this.pickBranch ||
                        //!this.pickDirectorate ||
                        !this.pickGeneralDepartment) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi Unit, Cabang, Direktorat dan Bagian Umum dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            let postData = {
                                unit : this.unit,
                                unit_code : this.unitAlias,
                                cabang_code : this.branchId,
                                cabang : this.branch,
                                level_bagian : this.sectionLevel,
                                dept_umum : this.generalDepartment,
                                dept_khusus : this.specificDepartment,
                                om_operating_unit : this.omoperatingunitnumber,
                                code: 4,
                                id: this.updateId
                            }
                            let iconType = ''
                            let titleMessage = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + `master/departments`, postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data departemen berhasil diubah',
                                    textMessage = response.data.result.dept_umum + ' - ' + response.data.result.dept_khusus
                                    iconColor = 'is-success'
                                    //this.emptyField()
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-departemen'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal mengubah data departemen!'
                                        if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            text: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        },

        onlyAlphaNum($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async prepDepartments (id) {
            if (id) {
                this.setPickDirectorate(true)
                this.directorateId = id
            }
            // if (this.directorateId) {
            //     await this.getDepartmentsByDirectorate(this.directorateId)
            // }
        },

        async getDepartmentsByAreaBranch () {
            this.isGeneralDepartmentLoading = true
            let postData = {
                Data: {
                    code: '12',
                    level: this.sectionLevel,
                    id_cabang: this.branchId,
                    id_unit: this.unitId,
                    nik: localStorage.user
                }
            }
            //console.log(postData)

            await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
                .then( response => {
                    this.masterDepartments = []
                    if (!this.editData) {
                        this.generalDepartment = ''
                        this.specificDepartment = ''
                    }
                    response.data.Result.data.forEach(element => {
                        this.masterDepartments.push(element)
                    });
                    //console.log(this.masterDepartments)
                })
                .catch( e => {
                    this.masterDepartments = []
                    this.generalDepartment = ''
                    console.log(e)
                })
            this.isGeneralDepartmentLoading = false
        },

        // async getDepartmentsByDirectorate (directorateID) {
        //     this.isGeneralDepartmentLoading = true
        //     let postData = {
        //         Data: {
        //             code: '5',
        //             id_direktorat: directorateID,
        //             nik: localStorage.user
        //         }
        //     }

        //     await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
        //         .then( response => {
        //             this.masterDepartments = []
        //             if (!this.editData) {
        //                 this.generalDepartment = ''
        //             }
        //             response.data.Result.forEach(element => {
        //                 this.masterDepartments.push(element)
        //             });
        //             console.log(this.masterDepartments)
        //         })
        //         .catch( e => {
        //             this.masterDepartments = []
        //             this.generalDepartment = ''
        //             console.log(e)
        //         })
        //     this.isGeneralDepartmentLoading = false
        // },

        async prepDirectorates (level, id, alias) {
            if (level == 'unit' && id && alias) {
                this.unitId = id
                this.unitAlias = alias
                this.setPickUnit(true)
                this.branch = ''
                this.branchId = null
                this.masterDepartments = []
                await this.getAllBranches()
                //console.log(this.unitId)
            } else if (level == 'branch' && id) {
                this.branchId = id
                this.setPickBranch(true)
                this.masterDepartments = []
                //console.log(this.branchId)
            }
            // if (this.unitId && this.branchId) {
            //     console.log(this.unitId + " MASUK " + this.branchId)
            //     await this.getDirectoratesByUnitBranch(this.unitId, this.branchId)
            // }
            
            if (this.sectionLevel && this.unitId && this.branchId) {
                await this.getDepartmentsByAreaBranch()
            }
        },

        // async getDirectoratesByUnitBranch (unitID, branchID) {
        //     this.isDirectorateLoading = true
        //     let postData = {
        //         Data: {
        //             code: '9',
        //             id_unit: unitID,
        //             id_cabang: branchID,
        //             nik: localStorage.user
        //         }
        //     }

        //     await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
        //         .then( response => {
        //             if (!this.editData) {
        //                 this.directorate = ''
        //                 this.generalDepartment = ''
        //                 this.directorateId = null
        //             }
        //             this.masterDirectorates = []
        //             this.masterDepartments = []
        //             response.data.Result.forEach(element => {
        //                 this.masterDirectorates.push(element)
        //             });
        //             console.log(this.masterDirectorates)
        //         })
        //         .catch( e => {
        //             this.masterDirectorates = []
        //             this.directorate = ''
        //             this.directorateId = null
        //             this.masterDepartments = []
        //             this.generalDepartment = null
        //             console.log(e)
        //         })
        //     this.isDirectorateLoading = false
        // },

        async getAllUnits () {
            let postData = {
                Data: {
                    code: '1',
                    nik: localStorage.user
                }
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
                .then( response => {
                    response.data.Result.forEach(element => {
                        this.masterUnits.push(element)
                    });
                    //console.log(this.masterUnits)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getAllBranches () {
            this.isBranchLoading = true

            let postData = {
                Data: {
                    code: '12',
                    id_unit: this.unitId,
                    level: '2',
                    nik: localStorage.user
                }
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_HRMS + 'Master/Unit-Cabang', postData)
                .then( response => {
                    this.masterBranches = []
                    // response.data.Result.forEach(element => {
                    //     this.masterBranches.push(element)
                    // });
                    response.data.Result.data.sort(function(a, b){
                        if(a.AREANAME < b.AREANAME) { return -1; }
                        if(a.AREANAME > b.AREANAME) { return 1; }
                        return 0
                    })
                    this.masterBranches = response.data.Result.data
                    //console.log(this.masterBranches)
                })
                .catch( e => {
                    console.log(e)
                })
                .finally(() => {
                    this.isBranchLoading = false
                })
        },

        clearDate () {
            this.selectedDate = null
        },

        goBack() {
            this.$router.push({name: 'master-departemen'})
        },

        emptyField () {
            this.unit = null
            this.branch = null
            this.directorate = null
            this.generalDepartment = null
            this.specificDepartment = null
        },

        departmentDirectorateValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) { //including . - & ( ) +
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async createDepartment() {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (!this.pickUnit || !this.pickBranch ||
                         //!this.pickDirectorate || 
                         !this.pickGeneralDepartment) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon mengisi Unit, Cabang, Direktorat dan Bagian Umum dengan cara memilih dari pilihan yang ada',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true
                            let postData = {
                                unit : this.unit,
                                unit_code : this.unitAlias,
                                cabang_code : this.branchId,
                                cabang : this.branch,
                                level_bagian : this.sectionLevel,
                                dept_umum : this.generalDepartment,
                                dept_khusus : this.specificDepartment,
                                om_operating_unit : this.omoperatingunitnumber,
                                code: 3
                            }
                            //console.log(postData)
                            let iconType = ''
                            let titleMessage = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/departments', postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data departemen baru berhasil disimpan',
                                    textMessage = response.data.result.dept_umum + ' - ' + response.data.result.dept_khusus
                                    iconColor = 'is-success'
                                    //this.emptyField()
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-departemen'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal menyimpan data departemen!'
                                        if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            text: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        }
    }
};
</script>
