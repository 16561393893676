<template>
  <div class="pt-3 is-centered modified-background-info">
		<div class="columns">
			<div class="column is-2">
				<Sidebar/>
			</div>
			<div id="content" class="column rounded-div has-background-white is-10">
				<router-view/>
			</div>
		</div> 
	</div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
// import { mapGetters, mapActions } from "vuex";

export default {
	// data() {
	// 	return {
	// 		workEmailExist: false,
	// 	}
	// },

	components: {
		Sidebar
	},

	// computed: {
	// 	...mapGetters({
	// 		userLogin: "isUserLogin",
	// 		access: "access"
	// 	}),
	// },

	// async mounted () {
	// 	let postData = {
	// 		param: {
	// 			code: '2',
	// 			find: this.userLogin.nik
	// 		}
	// 	}

	// 	await this.$axios.post(process.env.VUE_APP_WORKER_REST, postData)
	// 		.then( response => {
	// 			this.workEmailExist = true
	// 		}).catch( e => {
	// 			this.$buefy.snackbar.open({
	// 				message: 'Terjadi kesalahan pada service worker untuk mengecek email kantor',
	// 				type: 'is-info',
	// 				position: 'is-top',
	// 				actionText: 'Tutup',
	// 				indefinite: false,
	// 			})
	// 		})
		
	// 	if (!this.workEmailExist) {
	// 		this.$swal({
	// 			title: '<span class="is-size-2 has-text-warning"><i class="fas fa-envelope fa-2x"></i></span><span class="is-size-3 has-text-danger"><i class="fas fa-exclamation fa-lg"></i></span><br>Anda belum mendaftarkan alamat email kantor, silakan lengkapi email kantor anda pada tautan berikut',
	// 			html: '<a href="https://portal.hrindomaret.com">https://portal.hrindomaret.com</a>',
	// 			showConfirmButton: false,
	// 			allowOutsideClick: false,
	// 			customClass: {
	// 				title: 'has-text-primary is-family-primary has-text-weight-semibold',
	// 				htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium'
	// 			}
	// 		})
	// 	}
	// }
}
</script>
