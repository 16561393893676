<template>
    <section>
        <div class="container hero">
            <div class="mx-5">
                <b-field class="has-text-weight-semibold has-text-primary is-size-3">
                    Master Manual Book
                </b-field>
                <b-button
                    icon-pack="fas"
                    icon-left="angle-left"
                    class="has-background-warning has-text-white has-text-weight-semibold mb-5"
                    @click="goBack()"
                >KEMBALI
                </b-button>
                <div class="ml-5">
                    <div class="has-text-weight-medium has-text-primary is-size-4 mt-1">
                        {{ title }}
                    </div>
                    <ValidationObserver ref="observer">
                        <form ref="form">
                            <div class="ml-5 mt-5 pt-2 has-text-weight-medium has-text-primary">
                                <div class="columns is-desktop">
                                    <div class="column is-9">
                                        <b-field 
                                            class="hz-label-master is-flex is-align-items-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Nama File<span class="mandatory">*</span></span>
                                            </template>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-input
                                                    :loading="isInputLoading"
                                                    v-model="guideName"
                                                    @keypress.native="guideNameValidation"
                                                    maxlength="45"
                                                    pattern="^[A-Za-z0-9\s\-&().+/]*"
                                                    validation-message="Hanya bisa alfabet, angka, spasi dan tanda berikut: . - & ( ) + /"
                                                    required>
                                                </b-input>
                                                <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </b-field>
                                        <span v-if="updateId">
                                            <b-field 
                                                class="hz-label-master is-flex is-align-items-center" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Role<span class="mandatory">*</span></span>
                                                </template>
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <b-select
                                                        @input="getGroups"
                                                        :loading="isInputLoading"
                                                        expanded
                                                        v-model="role"
                                                        required>
                                                        <option
                                                            v-for="userRole in userRoles"
                                                            :value="userRole.id"
                                                            :key="userRole.id">
                                                            {{userRole.name}}
                                                        </option>
                                                    </b-select>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                            </b-field>
                                            <b-field 
                                                v-if="role > 1"
                                                :class="role > 1 ? 'hz-label-master is-flex is-align-items-center' : 'hz-label-master is-flex is-align-items-center is-hidden'" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Group<span class="mandatory">*</span></span>
                                                </template>
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <b-select
                                                        :loading="isGroupsLoading"
                                                        expanded
                                                        v-model="group"
                                                        required>
                                                        <option
                                                            v-for="userGroup in userGroups"
                                                            :value="userGroup.id"
                                                            :key="userGroup.id">
                                                            {{userGroup.name}}
                                                        </option>
                                                    </b-select>
                                                    <p class="is-size-7 has-text-danger">{{ errors[0] }}</p>
                                                </ValidationProvider>
                                            </b-field>
                                        </span>
                                        <div
                                            class="hz-label-master is-flex is-align-items-center pt-4"
                                            v-if="updateId == null"
                                        >
                                            <span class="is-family-primary has-text-weight-medium">Hak Akses<span class="mandatory">*</span></span>
                                        </div>
                                        <div class="column is-9 is-offset-1" v-if="updateId == null">
                                            <b-field 
                                                class="hz-label-master is-flex is-align-items-center" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Role<span class="mandatory">*</span></span>
                                                </template>
                                                <b-select
                                                    @input="getGroups"
                                                    :loading="isInputLoading"
                                                    expanded
                                                    v-model="role">
                                                    <option
                                                        v-for="userRole in userRoles"
                                                        :value="userRole.id"
                                                        :key="userRole.id">
                                                        {{userRole.name}}
                                                    </option>
                                                </b-select>
                                            </b-field>
                                            <b-field 
                                                v-if="role > 1"
                                                :class="role > 1 ? 'hz-label-master is-flex is-align-items-center' : 'hz-label-master is-flex is-align-items-center is-hidden'" 
                                                label="" 
                                                horizontal
                                            >
                                                <template #label>
                                                    <span class="is-family-primary has-text-weight-medium">Group<span class="mandatory">*</span></span>
                                                </template>
                                                <b-select
                                                    :loading="isGroupsLoading"
                                                    expanded
                                                    v-model="group">
                                                    <option
                                                        v-for="userGroup in userGroups"
                                                        :value="userGroup.id"
                                                        :key="userGroup.id">
                                                        {{userGroup.name}}
                                                    </option>
                                                </b-select>
                                            </b-field>
                                            <b-field class="">
                                                <b-button
                                                    expanded
                                                    :loading="isCheckLoading"
                                                    class="has-background-primary-light has-text-white has-text-weight-bold"
                                                    @click="addAccessEmployee()"
                                                >+</b-button>
                                            </b-field>
                                            <div v-show="accessArray.length > 0">
                                                <div
                                                    v-for="(access, index) in accessArray"
                                                    :key="index"
                                                >
                                                    <b-field class="mb-3 is-flex is-align-items-center">
                                                        <!-- <b-tag
                                                            close-type='is-danger'
                                                            size="is-medium"
                                                            attached
                                                            closable
                                                            aria-close-label="Close tag"
                                                            >
                                                            {{mutation.nik_mutasi}}/{{mutation.nama_mutasi}} || {{mutation.tanggal_mutasi}}
                                                        </b-tag> -->
                                                        <b-input
                                                            :value="access.role + ' | ' + access.group"
                                                            expanded
                                                            readonly>
                                                        </b-input>
                                                        <p class="control">
                                                            <b-button
                                                                type="is-danger"
                                                                label="X"
                                                                @click="deleteAccessArray(index)"
                                                            />
                                                        </p>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div v-show="accessArray.length == 0">
                                                <p class="has-text-danger">Belum ada data hak akses manual book yang ditambahkan</p>
                                            </div>
                                        </div>
                                        <b-field 
                                            class="mt-4 hz-label is-flex is-justify-content-center"
                                            label="" 
                                            horizontal
                                        >
                                            <template #label>
                                                <span class="is-family-primary has-text-weight-medium">Upload File<span v-if="updateId == null" class="mandatory">*</span></span>
                                            </template>
                                            <b-field class="file is-dark" :class="{'has-name': !!file}">
                                                <ValidationProvider :rules="updateId == null ? required : ''">
                                                    <a v-if="updateId" class="mr-4" @click="downloadGuide(filePath)">{{ fileNameFromPath }}</a>
                                                    <b-upload v-model="file" class="file-label" accept=".pdf" :required="updateId == null ? true : false" @input="errorsFile = null">
                                                        <span class="button has-background-success has-text-white is-12">
                                                            <span class="has-text-weight-medium file-label is-family-primary">{{ file.name || "Upload File"}}</span>
                                                        </span>
                                                    </b-upload>
                                                    <p v-if="updateId == null" class="is-size-7 mandatory">{{ errorsFile }}</p>
                                                    <p class="is-size-7 has-text-danger">*File pdf ukuran maksimal 10 MB</p>
                                                    <p class="is-size-7 has-text-danger">*Jika sudah terdapat file yang terupload dengan nama sama sebelumnya maka file tersebut akan terganti (overwrite)</p>
                                                </ValidationProvider>
                                            </b-field>
                                        </b-field>
                                    </div>
                                </div>
                                <b-field class="has-text-centered mt-5">
                                    <b-button
                                        :loading="isSubmitLoading"
                                        class="is-size-5 has-background-success has-text-white has-text-weight-bold"
                                        @click="updateId ? updateGuide() : createGuide()">Simpan</b-button>
                                </b-field>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            editData: null,
            updateId: null,
            title: null,
            guideName: '',
            userRoles: [],
            userGroups: [],
            role: null,
            group: null,
            accessArray: [],
            alreadyAddedAccesses: [],
            file: {},
            errorsFile: '',
            filePath: '',
            fileNameFromPath: '',
            isCheckLoading: true,
            isInputLoading: false,
            isSubmitLoading: false,
            isGroupsLoading: false,
        };
    },
    created() {
        if (this.$route.params.routingTo == 'add') {
            this.title = 'Tambah Manual Book Baru'
        } else if (this.$route.params.routingTo == 'edit') {
            this.title = 'Ubah Manual Book'
        }

        if (this.$route.params.master) {
            this.editData = this.$route.params.master
        }
        //console.log(this.editData)
    },
    async mounted () {
        this.isInputLoading = true
        this.isSubmitLoading = true
        await this.getUserRoles()
        // await this.getGuideAccesses()
        if (this.editData) {
            this.editingData()
        }
        this.isInputLoading = false
        this.isSubmitLoading = false
        this.isGroupsLoading = false
        this.isCheckLoading = false
    },

    methods: {
        async downloadGuide (filePath) {
            this.isDownloading = true
            this.$buefy.snackbar.open({
                message: "Mohon tunggu beberapa saat hingga file pdf muncul pada halaman baru",
                type: 'is-info',
                position: 'is-top',
                actionText: 'Tutup',
                indefinite: false,
                duration: 7000
            })

            let postData = {
                code: 6,
                file_path: filePath
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/guides', postData,
                {responseType: 'arraybuffer'})
                .then( response => {
                    //handle kembalian file pdf
                    let blob=new Blob([response.data], {type: 'application/pdf'});
                    // if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
                    // 	window.navigator.msSaveOrOpenBlob(blob);
                    // 	return;
                    // }
                    let blobObject=window.URL.createObjectURL(blob);
                    window.open(blobObject, "_blank")
                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(blobObject)
                    }, 30000)
                })
                .catch( e => {
                    console.log(e)
                    let converter = require('arraybuffer-to-string');
                    let convertResponse = converter(e.response); //terjemahkan response arraybuffer ke string
                    let tempMessage = JSON.parse(convertResponse);
                    if (tempMessage.status != 401) {
                        convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
                        tempMessage = JSON.parse(convertResponse);
                        let message = 'Terjadi kesalahan pada server'
                        if (tempMessage.status && tempMessage.status != 0) {
                            message = tempMessage.message
                        } else {
                            message = 'Terjadi kesalahan pada server'
                        }
                    } else {
                        message = 'Terjadi kesalahan pada server'
                    }
                    this.$buefy.snackbar.open({
                        message: message,
                        type: 'is-info',
                        position: 'is-top',
                        actionText: 'Tutup',
                        indefinite: false,
                        duration: 7000
                    })
                })
                .finally(this.isDownloading = false)
        },
        
        async updateGuide () {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
                    })
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                    } else {
                        if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true

                            let sendRole = this.userRoles.filter((option) => {
                                return option.id == this.role
                            })
                            let sendGroup = null
                            if (this.role == 1) {
                                sendGroup = this.userGroups.filter((option) => {
                                    return option.id == 9
                                })
                            } else {
                                sendGroup = this.userGroups.filter((option) => {
                                    return option.id == this.group
                                })
                            }

                            const postData = new FormData()
                            if (this.file.name) {
                                postData.set("guide", this.file)
                            }
                            postData.set("code", 4)
                            postData.set("id", this.updateId)
                            postData.set("file_name", this.guideName)
                            postData.set("role", sendRole[0].name)
                            postData.set("group", sendGroup[0].name)

                            let iconType = ''
                            let titleMessage = ''
                            let accessAdded = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/guides', postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data manual book baru berhasil diunggah & disimpan',
                                    iconColor = 'is-success'
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-manual-book'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal mengunggah & menyimpan data manual book!'
                                        if (e.response.data.status && e.response.data.status == 1002) {
                                            this.accessArray.forEach( element => {
                                                accessAdded += '<li> ' + element.role + ' - ' + element.group + '</li>'
                                            })

                                            textMessage = 'Manual book ' + this.name + ' dengan hak akses: <br><ul">' + accessAdded  + '</ul> sudah terdaftar'
                                        } else if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            html: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        },

        async createGuide() {
            this.$refs.observer.validate().then(async success => {
                if (!success) {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
                    })
                    if (!this.file.name) {
                        this.errorsFile = "Field / inputan berikut wajib diisi"
                    }
				} else {
                    if (!this.$refs.form.checkValidity()) {
                        this.$buefy.snackbar.open({
                            message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
                            type: 'is-info',
                            position: 'is-top',
                            actionText: 'Tutup',
                            indefinite: false,
                        })
                        if (!this.file.name) {
                            this.errorsFile = "Field / inputan berikut wajib diisi"
                        }
                    } else {
                        if (this.accessArray.length < 1) {
                            this.$buefy.snackbar.open({
                                message: 'Gagal! Mohon menambahkan hak akses manual book minimal 1',
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                                duration: 7000,
                            })
                        } else if (this.$refs.form.checkValidity()) {
                            this.isSubmitLoading = true

                            const postData = new FormData()
                            postData.set("guide", this.file)
                            postData.set("code", 3)
                            postData.set("file_name", this.guideName)
                            this.accessArray.forEach( element => {
                                postData.append("role_array[]", element.role)
                                postData.append("group_array[]", element.group)
                            })

                            let iconType = ''
                            let titleMessage = ''
                            let accessAdded = ''
                            let textMessage = ''
                            let iconColor = ''

                            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/guides', postData)
                                .then( response => {
                                    iconType = 'success',
                                    titleMessage = 'Data manual book baru berhasil diunggah & disimpan',
                                    iconColor = 'is-success'
                                    this.isSubmitLoading = false
                                    this.$router.push({name: 'master-manual-book'})
                                })
                                .catch( e => {
                                    if (e.response.status != 401) {
                                        iconType = 'error'
                                        titleMessage = 'Gagal mengunggah & menyimpan data manual book!'
                                        if (e.response.data.status && e.response.data.status == 1002) {
                                            this.accessArray.forEach( element => {
                                                accessAdded += '<li> ' + element.role + ' - ' + element.group + '</li>'
                                            })

                                            textMessage = 'Manual book ' + this.name + ' dengan hak akses: <br><ul">' + accessAdded  + '</ul> sudah terdaftar'
                                        } else if (e.response.data.status && e.response.data.status != 0) {
                                            textMessage = e.response.data.message
                                        } else {
                                            textMessage = 'Terjadi kesalahan pada server'
                                        }
                                        iconColor = 'is-danger'
                                    }
                                    
                                    console.log(e)
                                    this.isSubmitLoading = false
                                })
                                .finally(() => {
                                    if (titleMessage) {
                                        this.$swal({
                                            icon: iconType,
                                            title: titleMessage,
                                            html: textMessage,
                                            customClass: {
                                                icon: iconColor,
                                                title: 'has-text-primary is-family-primary has-text-weight-semibold',
                                                htmlContainer: 'has-text-primary is-family-primary has-text-weight-medium',
                                                confirmButton: 'has-background-success is-family-primary button-sizing has-text-white has-text-weight-bold'
                                            }
                                        })
                                    }
                                })
                        }
                    }
                }
            })
        },

        deleteAccessArray (index) {
			this.accessArray.splice(index, 1)
        },

        addAccessEmployee () {
            if (this.guideName &&
                ((this.role == 1) ||
                (this.role == 2 && (this.group == 10 || this.group == 11 || this.group == 12)) ||
                (this.role == 3 && (this.group == 15 || this.group == 13 || this.group == 14)))) {
				let canPush = true
                let tempMessage = null
                
				this.accessArray.forEach( element => {
                    if ((this.role == 1 && element.role_id == this.role) ||
                        (this.role == 2 && (this.group == 10 || this.group == 11 || this.group == 12) && (element.role_id == this.role && element.group_id == this.group)) ||
                        (this.role == 3 && (this.group == 15 || this.group == 13 || this.group == 14) && (element.role_id == this.role && element.group_id == this.group))) {
						canPush = false
						tempMessage = 'Gagal! Data manual book sudah ada'
					}
                })

                // this.alreadyAddedAccesses.forEach( element => {
                //     if ((this.role == 1 && element.role_id == this.role) ||
                //         (this.role == 2 && (this.group == 10 || this.group == 11 || this.group == 12) && (element.role_id == this.role && element.group_id == this.group)) ||
                //         (this.role == 3 && (this.group == 15 || this.group == 13 || this.group == 14) && (element.role_id == this.role && element.group_id == this.group))) {
				// 		canPush = false
				// 		tempMessage = 'Gagal! Data manual book sudah terdaftar pada program'
				// 	}
                // })

				if (canPush) {
                    let searchRole = null
                    let searchGroup = null
                    searchRole = this.userRoles.filter((option) => {
                        return option.id == this.role
                    })
                    if (this.group) {
                        searchGroup = this.userGroups.filter((option) => {
                            return option.id == this.group
                        })
                    }
                    if (this.role == 1) {
                        searchGroup = this.userGroups.filter((option) => {
                            return option.id == 9
                        })
                    }

					let tempObject = {
                        role_id: searchRole[0].id,
                        role: searchRole[0].name,
                        group_id: searchGroup[0].id,
                        group: searchGroup[0].name,
                    }
                    this.accessArray.push(tempObject)
					// console.log(this.accessArray)
					// console.log(JSON.stringify(this.accessArray))
					this.role = null
                    this.group = null
				} else {
					this.$buefy.snackbar.open({
						message: tempMessage,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
			} else {
				this.$buefy.snackbar.open({
					message: 'Gagal! Data tidak valid / data mandatory belum lengkap',
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			}
        },

        async getUserGroups (roleName) {
            this.isGroupsLoading  = true

            let postData = {
                role: roleName,
                code: 7
            }

            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
                .then( response => {
                    this.userGroups = []
                    response.data.result.forEach(element => {
                        this.userGroups.push(element)
                    });
                    //console.log(this.userGroups)
                    this.isGroupsLoading = false
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getUserRoles () {
            let postData = {
                code: 8
            }
            await this.$axios.post(process.env.VUE_APP_GATEWAY_API + 'master/users', postData)
                .then( response => {
                    response.data.result.forEach(element => {
                        this.userRoles.push(element)
                    });
                    //console.log(this.userRoles)
                })
                .catch( e => {
                    console.log(e)
                })
        },

        async getGroups () {
            this.group = null
            let roleName = this.userRoles.filter((option) => {
                return option.id == this.role
            })
            //console.log(roleName[0])
            await this.getUserGroups(roleName[0].name)
        },

        guideNameValidation($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s\-&().+/]+$/i.test(char)) { //including . - & ( )
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        async editingData () {
            this.updateId = this.editData.id
            this.guideName = this.editData.name

            this.filePath = this.editData.file_path
            let index = this.filePath.lastIndexOf("/");
            let result = this.filePath.substring(index + 1);
            this.fileNameFromPath = result

            let role = this.userRoles.filter((option) => {
                return option.name == this.editData.role
            })
            this.role = role[0].id

            await this.getUserGroups(this.editData.role)

            let group = this.userGroups.filter((option) => {
                return option.name == this.editData.group
            })
            this.group = group[0].id
            
            this.editData = null
        },

        onlyAlphaNum($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[a-zA-Z0-9\s]+$/i.test(char)) {
                return true;
            } else {
                $event.preventDefault();
                return false;
            }
        },

        goBack() {
            this.$router.push({name: 'master-manual-book'})
        },

        emptyField () {
            this.unit = null
            this.branch = null
            this.directorate = null
            this.generalDepartment = null
            this.specificDepartment = null
        },
    }
};
</script>
